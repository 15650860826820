import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<section class="rollup">
  <div class="rollup__item">
    <button
      class="button__no-border"
      @click="${x => x.goToGradebook()}">
      <span class="material-symbols-outlined">arrow_back</span>
      Back to Gradebook
    </button>
  </div>

  <header class="header">
    <h1 class="header__title">
      <span class="material-symbols-outlined" aria-label="hidden">view_week</span>
      Custom Groups
    </h1>
    ${T.when(x => x.loaded, T.html`
      <div class="header__container">
        <fluent-checkbox
          :checked="${x => x.hideLocked}"
          @change="${(x, c) => x.toggleHideLocked()}">
          Hide Locked
        </fluent-checkbox>
        <button class="button__secondary" @click="${x => x.addModal()}">
          New Group +
        </button>
        <jnct-searchbar :handleChange="${x => x.search}"></jnct-searchbar>
      </div>
    `)}
  </header>

  ${T.when(x => !x.loaded, T.html`
    <section class="loader">
      <jnct-loading-spinner></jnct-loading-spinner>
    </section>
  `)}

  ${T.when(x => x.loaded, T.html`
  <section class="main">
    <div class="main__header grid">
      <div class="item first">Title</div>
      <div class="material-symbols-outlined lock">lock</div>
      <div class="item"> 
        Type
      </div>
      <div class="item">Grading</div>
      <div class="item">${x => x.lesson}</div>
    </div>
    <ul class="main__list">
      ${T.repeat(x => x.filtered, T.html`
        <li class="group" :style="border-color: ${x => x.color}">
          <div class="group__main">
            <span
              class="material-symbols-outlined group__icon"
              tabindex="0"
              id="${x => 'icon_' + x.id}"
              @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? c.parent.toggleGroup(x.id) : ''}"
              @click="${(x,c) => c.parent.toggleGroup(x.id)}">expand_more</span>
            <div class="group__title">
              <div :innerHTML="${x => x.title}"></div>
              <div
                class="group__edit cursor ${x => x.id === null ? 'none' : ''}"
                tabindex="0"
                @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? c.parent.edit(x) : ''}"
                @click="${(x, c) => c.parent.edit(x)}">
                <span class="material-symbols-outlined">edit</span>
                Edit
              </div>
            </div>
          </div>

          <ul class="list" id="${x => x.id == null ? 'null' : x.id}">
            ${T.repeat((x, c) => c.parent.hideLocked ? c.parent.rollupActivities[x.id]?.filter(act => !act.suppress_until_available) : c.parent.rollupActivities[x.id], T.html`
              <li class="activity grid">
                <div
                  class="activity__title"
                  :innerHTML="${x => x.report_title}"
                  tabindex="0"
                  @click="${(x, c) => c.parentContext.parent.goToActivity(x.link)}"
                  @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? c.parentContext.parent.goToActivity(x.link) : ''}">
                </div>
                <div class="material-symbols-outlined checked disabled lock"> ${x => x.suppress_until_available ? 'check_box' : ''} </div>
                <div class="activity__item">${(x, c) => c.parentContext.parent.displayType(x.type)}</div>
                <div class="activity__item">${x => x.scoring_type}</div>
                <div class="activity__item" :innerHTML="${x => x.lessonName}"></div>
              </li>
              `)}
          </ul>
        </li>
        `)}
    </ul>
  </section>
  `)}
</section>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
}

.rollup {
  box-sizing: border-box;
  padding: 14px 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}
.rollup__item {
  display: flex;
  padding: 12px 0px 12px 4px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--neutral-90);
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
}
.rollup .header {
  width: 100%;
  display: flex;
  padding: 4px 0px 4px 4px;
  align-items: center;
  justify-content: space-between;
}
.rollup .header__title {
  display: flex;
  gap: 12px;
  padding: 0px;
  margin: 0px;
  color: var(--neutral-90);
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
}
.rollup .header__container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
}

.main {
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - 270px);
  display: flex;
  padding-top: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}
.main__header {
  padding: 8px 0px 8px 16px;
  color: var(--neutral-90);
  font-size: 14px;
  font-weight: 500;
  line-height: 170%;
}
.main__header .item {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}
.main__header .item.first {
  padding-left: 58px;
}
.main__list {
  width: 100%;
  overflow-y: auto;
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.group {
  padding: 12px 0px 12px 14px;
  align-items: center;
  align-self: stretch;
  border-left: 2px solid black;
}
.group__main {
  display: flex;
}
.group__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  font-size: 24px;
  transition: transform 0.3s;
  cursor: pointer;
}
.group__title {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--neutral-90);
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
}
.group__edit {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--link);
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}

.list {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: none;
  overflow: hidden;
}
.list.open {
  display: block;
}

.lock {
  font-size: 18px;
  line-height: 42px;
  width: 40px;
  text-align: center;
  height: 29px;
}

.transform {
  transform: rotate(180deg);
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 40px 200px 150px 1fr;
}

.activity__title {
  padding: 8px 8px 8px 58px;
  color: var(--link);
  font-size: 14px;
  font-weight: 400;
  line-height: 170%;
  cursor: pointer;
}
.activity__item {
  display: flex;
  padding: 8px 20px 8px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--neutral-70);
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}

.none {
  display: none;
}`;


import { observable, RWSViewComponent, RWSView, RWSInject } from "@rws-framework/client";
import EventsService, {EventsServiceInstance} from "../../services/EventsService";
import { Course } from '../../types/course';
import { Activity, ActivityModel } from '../../types/activities'
import JunctionUserService, {JunctionUserServiceInstance} from "../../services/JunctionUserService";
import { groupBy } from "../../helpers/utils";
import { RollupModal } from "./modal/component";

type Rollup = {
  title: string,
  id: string,
  color?: string
  activities: Activity[];
};

@RWSView('rollup-manager', null, { template: rwsTemplate, styles })
class RollupManager extends RWSViewComponent  {
  @observable rollups: Rollup[];
  @observable loaded: boolean = false;
  @observable course: Course;
  search = this.handleSearch.bind(this);
  @observable lesson = window.labelBundle.lesson || "Lesson";
  displayType = ActivityModel.displayType;
  @observable searchTerm: string | null;
  @observable rollupActivities: Record<Rollup['id'], Activity[]> = {};
  @observable toggle: boolean = false;
  @observable all_activities: Activity[] | [];
  @observable my_id: string | null;
  @observable hideLocked: boolean;
  getDataHandler = this.getData.bind(this);
  constructor(
    @RWSInject(EventsService) protected eventsService: EventsServiceInstance,
    @RWSInject(JunctionUserService) protected junctionUserService: JunctionUserServiceInstance,
  ) {
    super();
  }

  connectedCallback(): void {
    super.connectedCallback();

    this.getData();
    document.documentElement.addEventListener('rollup:flushdata', this.getDataHandler);
  };

  disconnectedCallback(): void {
    document.documentElement.removeEventListener('rollup:flushdata', this.getDataHandler);
  }

  toggleHideLocked() {
    this.hideLocked = !this.hideLocked;
    if (this.hideLocked) {
      window.localStorage.setItem("ROLLUP_HIDING_LOCKED/" + this.my_id, 'checked');
    } else {
      window.localStorage.removeItem("ROLLUP_HIDING_LOCKED/" + this.my_id);
    }
  }

  goToGradebook() {
    this.$emit('jnct:navigation:gotoreportgrades');
  }

  toggleGroup(id: string) {
    const el = this.shadowRoot.getElementById(id);
    const icon = this.shadowRoot.getElementById('icon_' + id);
    if (el) {
      el.classList.toggle('open');
      icon.classList.toggle('transform');
    }
  }

  goToActivity(link: string): void {
    this.$emit('jnct:openlink:blank', link)
  }

  // getter that will be recalculated each time `rollups` or `searchTerm` changes
  get filtered(): Rollup[] {
    if (!this.searchTerm) {
      return this.rollups;
    }
    
    const searchTermLower = this.searchTerm.toLowerCase();

    return this.rollups
    .map((rollup: Rollup) => {
      const matchesTitle = rollup.title.toLowerCase().includes(searchTermLower);
      const activities = this.rollupActivities[rollup.id];

      const filteredActivities = activities?.filter(activity =>
        activity.report_title.toLowerCase().includes(searchTermLower)
      );

      if (matchesTitle || filteredActivities.length > 0) {
        this.rollupActivities[rollup.id] = matchesTitle ? activities : filteredActivities;
        return {
          ...rollup,
        };
      }

      return null;
    })
    .filter(rollup => rollup !== null) as Rollup[];
  }

  handleSearch(value: string) {
    this.searchTerm = value || null; // Set searchTerm, or null if empty
  }

  async getData() {
    this.course =  await this.eventsService.getCourse();
    const user = await this.junctionUserService.getUser();
    this.my_id = user.mongoId;
    if (this.course.assistants.includes(this.my_id) && this.course.assistants_no_mgr_rights) {
      this.$emit('jnct:rollup:listdefault');
      return;
    };
    this.hideLocked = !!window.localStorage.getItem('ROLLUP_HIDING_LOCKED/' + this.my_id);
    const rollups = await this.getRollup();
    this.rollups = [...rollups];
    const rollup_ids = rollups.map((rollup: Rollup) => rollup.id);
    const activities = await this.eventsService.getActivities();
    const gradedActivities = activities.filter((activity: Activity) => {
      return activity.graded && activity.lesson
    });
    this.all_activities = [...gradedActivities];

    const rollupActivities = groupBy(gradedActivities, activity => {
      if (rollup_ids.indexOf(activity.rollup_id) < 0) {
          activity.rollup_id = null;
      }
      return activity.rollup_id;
    });
    this.rollupActivities = rollupActivities;

    this.loaded = true;
  }

  async getRollup() {
    //@ts-ignore
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    const url = `${APIprefix}stats/rollup/${this.course._id}`;

    try {
      const response = await fetch(url);
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  addModal() {
    const modal = document.createElement('rollup-modal') as RollupModal;
    modal.activities = this.rollupActivities['null'];
    modal.rollups = this.rollups.filter(rollup => rollup.id);
    modal.all_activities = [...this.all_activities];
    modal.course = this.course;
    document.body.appendChild(modal);
  }

  edit(rollup: Rollup) {
    const modal = document.createElement('rollup-modal') as RollupModal;
    modal.rollup = rollup;
    modal.rollups = this.rollups.filter(rollup => rollup.id);
    modal.all_activities = [...this.all_activities];
    modal.course = this.course;
    document.body.appendChild(modal);
  }
}

RollupManager.defineComponent();

export { RollupManager };
