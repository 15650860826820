import { IStudent } from "../../types/students";
import { AssignmentsType } from "./children/ltiSyncAssignments/component";
import { LtiSyncManager, SelectedType } from "./component";

export function startEventListeners(this: LtiSyncManager): void
{

    this.on<boolean>('jnct:ltisyncmanager:set-show-next', (e) => {
        this.showNext = e.detail;
    }); 

    this.on<boolean>('jnct:ltisyncmanager:proceed-fire', (e) => {
        if(e.detail){
            this.ltiStatus = 'loading';   

        }
    })

    // this.on<IStudent[]>('jnct:ltisyncmanager:set-selected-students', (e) => {
    //     if(e.detail){
    //         this.selectedStudents = e.detail;               
    //     }else{
    //         this.selectedStudents = [];               
    //     }
    // });

    // this.on<AssignmentsType[]>('jnct:ltisyncmanager:set-selected-assignments', (e) => {
    //     if(e.detail){
    //         this.selectedAssignments = e.detail;               
    //     }else{
    //         this.selectedAssignments = [];               
    //     }
    // });    

    this.on<boolean>('jnct:ltisyncmanager:proceed-cancel', (e) => {
        console.log(e);
        if(e.detail){
        
            this.ltiStatus = 'none';   
            this.setStep(2);
        }
    })
    
    this.on<boolean>('jnct:ltisyncmanager:proceed-done', (e) => {
        console.log(e);
        this.ltiStatus = 'ok';
    }); 

    this.on<boolean>('jnct:ltisetup:gotoltiproceed', (e) => {
        console.log(e);
        this.active = 1;        
    }); 

    this.on<Error | any>('jnct:ltisyncmanager:proceed-error', (e) => {        
        this.ltiStatus = 'error';        
    });
}