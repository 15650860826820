import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<jnct-modal-header
  title="Late Work Policy"
  :hide="${x => x.genericConfig.switch}"
></jnct-modal-header>

<div class="policy-container">
  <div class="policy-control">
    <fluent-checkbox id="policy-checkbox" @click="${x => x.togglePolicyModal()}"></fluent-checkbox> Automatically accept and apply deduction to late submissions
  </div>

  <div class="deduction-settings-container">
    <div class="deduction-rate-container">
      <span>Set Deduction</span>
      <div class="flex-container">
        <input type="number" id="deduction-rate" placeholder="e.g 30" ?disabled="${x => x.isDisabled ? 'disabled' : ''}" @change="${(x, c) => x.handleChange('penalty', (c.event.target as HTMLInputElement).value)}" /><span>%</span>
      </div>
    </div>
    <div class="deduction-period">
      <span>Per</span>
      <div class="unit-settings">
        <fluent-select title="Days" ?disabled="${x => x.isDisabled ? 'disabled' : ''}" @change="${(x, c) => x.handleChange('humanly_per', (c.event.target as HTMLInputElement).value)}">
          <fluent-option value="Hour">Hour</fluent-option>
          <fluent-option value="Day">Day</fluent-option>
          <fluent-option value="Week">Week</fluent-option>
        </fluent-select>
      </div>
    </div>
  </div>

  <div class="deduction-limit">
    <span>Set Maximum Deduction</span>
    <div class="flex-container">
      <input type="number" id="max-deduction" placeholder="e.g 20" ?disabled="${x => x.isDisabled ? 'disabled' : ''}" @change="${(x, c) => x.handleChange('max', (c.event.target as HTMLInputElement).value)}" /><span>%</span>
    </div>
  </div>

  <div class="buttons">
    <button id="cancel-btn" class="button__secondary" @click="${x => x.genericConfig.switch()}">Cancel</button>
    <button id="save-btn" class="button__primary" ?disabled="${x => x.enableSave ? '' : 'disabled'}" @click="${x => x.showConfirmation = true}">Save</button>
  </div>
</div>

${T.when(x => x.showConfirmation, T.html`
  <fluent-dialog
    id="confirmation_modal"
    trap-focus
    modal
    class="confirmation_modal">

    <header class="modal__header">
      <h1>Do you wish to proceed?</h1>
    </header>

    <div class="modal__text">
      You are about to accept all of the assessments according to your settings. Are you sure?
    </div>

    <div class="modal__flex">
      <button
        class="button__secondary"
        tabindex="0"
        title="Exit modal"
        @click="${x => x.showConfirmation = false}"
        ?disabled="${x => x.isSending ? 'disabled' : ''}">
        Cancel
      </button>
      <button
        class="button__primary"
        tabindex="0"
        title="Save modal"
        @click="${x => x.savePolicy()}"
        ?disabled="${x => x.isSending ? 'disabled' : ''}">
        Proceed
      </button>
    </div>
  </fluent-dialog>
`)}
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.policy-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 20px;
}
.policy-container .policy-control {
  display: flex;
  gap: 10px;
}
.policy-container .deduction-settings-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.policy-container .deduction-settings-container .deduction-rate-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 50%;
}
.policy-container .deduction-settings-container .deduction-rate-container #deduction-rate {
  width: 135px;
}
.policy-container .deduction-settings-container .deduction-period {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 50%;
}
.policy-container .deduction-settings-container .deduction-period .unit-settings {
  display: flex;
  gap: 10px;
}
.policy-container .deduction-settings-container .deduction-period .unit-settings fluent-select {
  min-width: 100px;
  width: 100px;
}
.policy-container .deduction-settings-container .deduction-period .unit-settings fluent-select .control {
  background: var(--neutral-20);
  border-radius: 12px;
}
.policy-container .deduction-settings-container .deduction-period .unit-settings #unit-count {
  width: 150px;
}
.policy-container .flex-container {
  display: flex;
  align-items: center;
  gap: 5px;
}
.policy-container .deduction-limit {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.policy-container .deduction-limit #max-deduction {
  width: 135px;
}
.policy-container .buttons {
  display: flex;
  justify-content: space-between;
}
.policy-container .buttons #save-btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.policy-container .buttons #save-btn:disabled:hover {
  background: var(--primary_color);
  box-shadow: none;
}
.policy-container input {
  border: 1px solid var(--neutral-20);
  border-radius: 4px;
  padding: 10px 16px;
  background: var(--neutral-10);
  transition: all 0.2s ease-in-out;
}
.policy-container input:hover:enabled {
  cursor: pointer;
  background: #f8f8f8;
}
.policy-container input:disabled {
  opacity: 0.5;
}
.policy-container input::placeholder {
  opacity: 0.5;
}

.confirmation_modal::part(positioning-region) {
  height: fit-content;
  width: 360px;
  margin: 40px calc((100% - 360px - var(--navigation)) / 2) auto auto;
  border-radius: 12px;
  box-shadow: 0px 6px 4px 0px rgba(0, 0, 0, 0.15);
  z-index: 12000;
}
.confirmation_modal::part(control) {
  box-sizing: border-box;
  padding: 24px;
  height: auto;
  width: 100%;
}
.confirmation_modal .modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}
.confirmation_modal .modal__header h1 {
  margin: 0px;
  color: var(--neutral-90);
  font-size: 1.42857em;
  font-weight: 500;
  line-height: 150%;
}
.confirmation_modal .modal__text {
  margin-bottom: 48px;
  color: var(--neutral-90);
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}
.confirmation_modal .modal__flex {
  display: flex;
  justify-content: space-between;
}
.confirmation_modal .modal__flex .button__primary:disabled {
  opacity: 0.5;
  cursor: wait;
}`;


import { observable, RWSViewComponent, RWSView, attr, ngAttr } from "@rws-framework/client";
import {Policy, type DefinedPolicy, HumanlyChoices} from '../../../types/activities'

export type LateWorkPolicyModalConfig = {
  policy: Policy;
  save: (payload: {policy: DefinedPolicy | null}) => Promise<void>;
  switch: () => void;
}

@RWSView('policy-modal', null, { template: rwsTemplate, styles })
class LateWorkPolicyModal extends RWSViewComponent  {
  @ngAttr courseId: string;
  @observable isDisabled: boolean = true;
  @observable enableSave: boolean = false;
  @observable genericConfig: LateWorkPolicyModalConfig = {
    policy: {},
    save: () => Promise.resolve(),
    switch: () => {},
  };
  @observable payload: DefinedPolicy = {
    max: 0,
    penalty: 0,
    per: 3600,
    humanly_per: HumanlyChoices.HOUR
  };
  @observable showConfirmation: boolean = false;
  @observable isSending: boolean = false;

  connectedCallback() {
    super.connectedCallback();

    this.$emit('modal:shown');
    document.documentElement.addEventListener('jnct:lateworkmanager:savepolicy', this.savePolicy.bind(this));
  };

  genericConfigChanged(oldValue: any, newValue: any) {
    this.genericConfig = newValue;

    if (Object.keys(newValue).length > 0) {
      this.getLateWorkPolicySettings();
    }
  }

  getLateWorkPolicySettings() {
    const policyCheckbox = this.shadowRoot.querySelector('#policy-checkbox') as HTMLInputElement;
    const deductionRate = this.shadowRoot.querySelector('#deduction-rate') as HTMLInputElement;
    const deductionSelect = this.shadowRoot.querySelector('.unit-settings fluent-select') as HTMLSelectElement;
    const maxDeduction = this.shadowRoot.querySelector('#max-deduction') as HTMLInputElement;
    const policyConfig = this.genericConfig.policy;

    if (!Policy.isNotEmpty(policyConfig)) return;

    policyCheckbox.checked = true;
    this.togglePolicyModal();
    this.payload = policyConfig;

    deductionRate.value = String(policyConfig.penalty);
    deductionSelect.value = LateWorkPolicyModal.calculateHumanyPer(policyConfig.per);
    maxDeduction.value = String(policyConfig.max);
    this.enableSave = this.checkPayloadValues(policyConfig);
  }

  static calculateHumanyPer(per: number): HumanlyChoices {
    return per === 3600 ? HumanlyChoices.HOUR : (per === 86400 ? HumanlyChoices.DAY : HumanlyChoices.WEEK);
  }

  async savePolicy() {
    this.isSending = true;
    const policyCheckbox = this.shadowRoot.querySelector('#policy-checkbox') as HTMLInputElement;
    const payloadToSend = {
      policy: policyCheckbox.checked 
        ? {
            ...this.payload,
            humanly_per: this.payload.humanly_per ?? LateWorkPolicyModal.calculateHumanyPer(this.payload.per)
          }
        : null,
    }

    await this.genericConfig.save(payloadToSend);
    this.isSending = false;
    this.genericConfig.switch();
    location.reload();
  }

  togglePolicyModal() {
    this.isDisabled = !this.isDisabled;
  }

  handleChange(fieldName: string, value: any) {
    if (fieldName !== 'humanly_per') {
      this.payload[fieldName] = parseInt(value);
    } else {
      this.payload[fieldName] = value;
    }

    switch (this.payload.humanly_per) {
      case 'Hour':
        this.payload.per = 3600;
        break;
      case 'Day':
        this.payload.per = 3600 * 24;
        break;
      case 'Week':
        this.payload.per = 3600 * 24 * 7;
        break;
      default:
        this.payload.per = 3600;
        break;
    }

    if (fieldName === 'max') {
      isNaN(this.payload[fieldName]) ? this.payload[fieldName] = 0 : this.payload[fieldName];
    }
    if (fieldName === 'penalty') {
      isNaN(this.payload[fieldName]) ? this.payload[fieldName] = 0 : this.payload[fieldName];
    }

    this.enableSave = this.checkPayloadValues(this.payload);
  }

  checkPayloadValues(currentPayload) {
    return currentPayload.max > 0 && currentPayload.penalty > 0;
  }
}

LateWorkPolicyModal.defineComponent();

export { LateWorkPolicyModal };
