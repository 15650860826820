import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`${T.when(x => x.isAcceptModalOpen, T.html`
  <jnct-modal
    templateName="lwm-accept-modal"
    :genericConfig="${x => x.lwmConfig}"
  >
  </jnct-modal>
`)}

${T.when(x => x.isRejectModalOpen, T.html`
  <jnct-modal
    templateName="lwm-reject-modal"
    :genericConfig="${x => x.lwmConfig}"
  >
  </jnct-modal>
`)}

${T.when(x => x.isPolicyModalOpen, T.html`
  ${T.when(x => x.policySettingAvailable, T.html`
    <jnct-modal
      templateName="policy-modal"
      :genericConfig="${x => x.policyConfig}"
    >
    </jnct-modal>
  `)}

  ${T.when(x => !x.policySettingAvailable, T.html`
    <jnct-modal
      templateName="text-modal"
      :genericConfig="${x => x.textModalConfig}"
    >
    </jnct-modal>
  `)}
`)}
<div class="dash__loading" :style="display: ${x => x.isLoading ? 'flex':'none'}">
  <img
    src="/images/loading_circle.png"
    alt="loading"
    class="dash__spinner" />
    Please wait...
</div>
<div class="layout__container" :style="display: ${x => x.isLoading ? 'none':'block'}">
  <header class="late-work__header">
    <div class="title">
      <span class="material-symbols-outlined policy-icon">update</span>
      <h1>Late Work Manager</h1>
    </div>
    <div class="flex-20">
      <jnct-searchbar :handleChange="${x=>x.search}"></jnct-searchbar>
    </div>
  </header>

  <section class="actions-section">
    <div class="late-work__policy">
      <button class="policy-button" @click="${x => x.openPolicyDialog()}">Set Late Work Policy</button>
      <div class="policy-info">
        <span class="material-symbols-outlined policy-icon">info</span>
        <span class="policy-text">You can accept or reject Late Work manually or set Late Work Policy.</span>
      </div>
    </div>
    ${T.when(x => x.lwmConfig.selectedItems.length > 0, T.html`
      <fluent-select value="Actions" title="Actions" class="actions-container">
        <fluent-option value="accept" class="option__accept" @click="${x => x.openAcceptDialog()}">
          <span>Accept</span>
        </fluent-option>
        <fluent-option id="lwm-selected-actions-reject" value="reject" class="option__reject" @click="${x => x.openRejectDialog()}" :disabled="${x => x.selectedItemsHavePolicyApplied}">
          <span>Reject</span>
        </fluent-option>
      </fluent-select>
    `)}
  </section>

  ${T.when(x => x.lwmConfig.selectedItems.length > 0 && x.selectedItemsHavePolicyApplied, T.html`
    <fluent-tooltip anchor="lwm-selected-actions-reject" position="left">
      <div class="actions-disabled__text">
        One or more of selected items have late work policy applied.
        <br/>
        It is not possible to reject them.
      </div>
    </fluent-tooltip>
  `)}

  <section>
    <div class="late-work__length">
      Showing ${x => x.visibleItems?.length || 0} items
    </div>

    <jnct-table
      :data="${x => x.assessments}"
      :columnsConfig="${x => x.columns}"
      :initialFilters="${x => x.initialFilters}"
      allowChecking="true"
      :afterSelect="${x => x.afterSelect}"
      :afterFilter="${x => x.afterFilter}"
      columnsLayout="60px 18% 12% 25% 10% 15% 15%"></jnct-table>
  </section>
</div>
`;

import './styles/layout.scss';
const styles = T.css`.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

fluent-data-grid {
  border-radius: var(--Medium, 4px);
  border: 2px solid var(--Color-palette-Neutral-20, #EEEFF0);
}

fluent-data-grid-row {
  padding: 0px;
  grid-template-columns: 60px 18% 12% 30% 10% 10% 10% !important;
  border: none;
}

fluent-data-grid-row:nth-child(odd) {
  background-color: white;
}

fluent-data-grid-row:nth-child(even) {
  background-color: var(--color-palette-neutral-8, rgba(155, 160, 165, 0.08));
}

fluent-data-grid-cell {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 12px 16px;
  color: var(--Color-palette-Neutral-70, #3B3C3E);
  border: none;
  font-family: inherit;
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
  white-space: normal;
  text-wrap: pretty;
}
fluent-data-grid-cell.column-header {
  padding: 8px 16px;
  color: var(--Color-palette-Neutral-90, #1B1C1E);
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}
fluent-data-grid-cell.column-header:hover {
  cursor: pointer;
  opacity: 0.8;
}

fluent-select {
  width: 200px;
  min-width: auto;
  font-family: inherit;
}
fluent-select::part(control) {
  width: 200px;
  height: 40px;
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Color-palette-Neutral-20, #EEEFF0);
  color: var(--Color-palette-Neutral-60, #5B5C5E);
  font-family: inherit;
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}

fluent-search {
  font-family: inherit;
}
fluent-search::part(root) {
  display: flex;
  width: 380px;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background: var(--Color-palette-Neutral-20, #EEEFF0);
  color: var(--Color-palette-Neutral-60, #5B5C5E);
  font-family: inherit;
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}
fluent-search::part(root) .input-wrapper {
  background-color: green;
}
fluent-search::part(root) .control {
  color: pink;
}

fluent-checkbox::part(control) {
  border-radius: 0;
}
fluent-checkbox:checked {
  background: #55BAB7;
}

:host(.checked) .control {
  background: #55BAB7;
}

.late-work__header {
  display: flex;
  margin-bottom: 32px;
  padding: var(--Medium, 4px) 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.late-work__header .title {
  display: flex;
  gap: 10px;
  align-items: center;
}
.late-work__header .title span {
  font-size: 22px;
}
.late-work__header .title h1 {
  padding: 0px;
  margin: 0px;
  color: var(--Color-palette-Neutral-90, #1B1C1E);
  font-size: 1.42857em;
  font-weight: 600;
  line-height: 150%;
}
.late-work__length {
  margin-bottom: 8px;
  color: var(--Color-palette-Neutral-70, #3B3C3E);
  font-size: 1em;
  font-weight: 600;
  line-height: 170%;
}
.late-work__policy {
  display: flex;
  align-items: center;
  gap: 20px;
}
.late-work__policy .policy-button {
  background: #fff;
  padding: 8px 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
  border-radius: 12px;
  border: 1px solid var(--Color-palette-Neutral-30, #BCBCBD);
  transition: all 0.2s ease-in-out;
}
.late-work__policy .policy-button:hover:enabled {
  cursor: pointer;
  background: #f3f3f3;
}
.late-work__policy .policy-info {
  color: var(--Color-palette-Neutral-70, #3B3C3E);
  display: flex;
  font-size: 16px;
  font-weight: 300;
  gap: 5px;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}
.late-work__policy .policy-info .policy-icon {
  font-size: 20px;
}

.actions-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.actions-section .actions-container {
  padding: 0;
}
.actions-section .actions-container .option {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.actions-section .actions-container .option__accept {
  padding-left: 30px;
  color: var(--Color-palette-Neutral-70, #3B3C3E);
  transition: all 0.2s ease-in-out;
}
.actions-section .actions-container .option__accept::after {
  position: absolute;
  left: 5px;
  font-size: 16px;
  font-family: "Material Symbols Outlined";
  content: "check";
  -webkit-font-feature-settings: "liga" 1;
  -moz-font-feature-settings: "liga" 1;
  font-feature-settings: "liga" 1;
}
.actions-section .actions-container .option__accept.selected::before {
  display: none;
}
.actions-section .actions-container .option__accept.selected:target {
  display: none;
}
.actions-section .actions-container .option__reject {
  padding-left: 30px;
  color: var(--Color-palette-Action-Error, #DE1135);
  transition: all 0.2s ease-in-out;
}
.actions-section .actions-container .option__reject::after {
  position: absolute;
  left: 5px;
  font-family: "Material Symbols Outlined";
  content: "close";
  -webkit-font-feature-settings: "liga" 1;
  -moz-font-feature-settings: "liga" 1;
  font-feature-settings: "liga" 1;
}
.actions-section .actions-container .option__reject.selected {
  background: #fff;
}
.actions-section .actions-container .option__reject.selected::before {
  display: none;
}

.flex-20 {
  display: flex;
  gap: 20px;
}

.dash__loading {
  height: calc(100vh - var(--header_height) - var(--full_footer_height) - 28px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 160%;
  background-color: #fff;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.dash__spinner {
  animation: rotate 1s linear infinite;
  background-position: center center;
  background-repeat: no-repeat;
  height: 64px;
  width: 64px;
}

.layout__container {
  box-sizing: border-box;
  padding: 24px 32px;
  overflow: hidden auto;
  min-height: 550px;
}

.actions-disabled__text {
  text-align: center;
}`;


import { observable, RWSViewComponent, RWSView, RWSInject, attr, ngAttr } from "@rws-framework/client";
import { ColumnConfig, Data, FiltersInterface } from "../../../types/table";
import assesmentMock from './_assesmentMock';
import columnsConfig from './_columnsConfig';
import LateWorkService, { LateWorkServiceInstance } from "../../../services/LateWorkService";
import { Activity, ActivityStudentScore, Policy, ScoreRecord } from "../../../types/activities";
import ActivitiesService, { ActivitiesServiceInstance } from "../../../services/ActivitiesService";
import {AcceptModalConfig} from '../acceptModal/component'
import {RejectModalConfig} from '../rejectModal/component'
import {LateWorkPolicyModalConfig} from '../lateWorkPolicyModal/component'
import {TextModalConfig} from '../../modal/textModal/component'

type AssessmentType = {
  id: string;
  title: string,
  student: string,
  // grade: string,
  due_date: string,
  submitted_date: string,
  status: string,
}

@RWSView('late-work-manager', null, { template: rwsTemplate, styles })
class LateWorkManager extends RWSViewComponent  {
  @ngAttr courseId: string;
  @attr policy: Policy;
  @attr isLoading: boolean = true;
  @attr policySettingAvailable: boolean = false;
  @observable assessments: any[] = [];
  @observable allAssessments: any[] = [];
  @observable tableData: Data[] = [];
  @observable selectedItems: Data[] = [];
  @observable visibleItems: Data[] = [];
  @observable activity_manager: Activity[];
  @observable activity_student_score: ActivityStudentScore;
  roster_ids: any[] = [];

  get policyConfig(): LateWorkPolicyModalConfig {
    return {
      save: ({ policy }) => this.savePolicy(policy),
      switch: () => this.closeModal(),
      policy: this.policy
    }
  }

  get textModalConfig(): TextModalConfig {
    return {
      switch: () => this.closeModal(),
      headerTitle: 'Late Work Manager',
      text: 'Managing the Late Work Policy for this section should be done on the section template. Please see your coordinator.'
    }
  }

  get lwmConfig(): AcceptModalConfig & RejectModalConfig {
    return {
      selectedItems: this.selectedItems,
      courseId: this.courseId,
      switch: () => this.closeModal(),
    };
  }

  @observable isAcceptModalOpen: boolean = false;
  @observable isRejectModalOpen: boolean = false;
  @observable isPolicyModalOpen: boolean = false;

  columns: ColumnConfig[] = columnsConfig;
  initialFilters: FiltersInterface = {
    status: { type: 'select', choices: 'submitted' }
  }

  constructor(
    @RWSInject(LateWorkService) protected lateWorkService: LateWorkServiceInstance,
    @RWSInject(ActivitiesService) protected activityService: ActivitiesServiceInstance,
  ) {
    super();
  }

  /**
   * If any of selected items is an assessment with policy applied,
   * we must prevent manually rejecting it
   * (late work policy has greater priority)
   */
  get selectedItemsHavePolicyApplied() {
    const selectedIds = this.selectedItems.map((item) => item.id.value);
    const selectedActivities = this.activity_manager.filter((activity) =>
      selectedIds.includes(activity.id)
    );

    return selectedActivities.some(
      (activity) =>
        activity.type === "assessment" &&
        activity.policy &&
        Policy.isNotEmpty(activity.policy)
    );
  }

  fetchData() {
    this.$emit('jnct:dashboardService:getAverage', (data: any) => {
      this.activity_manager = data.activity_manager;
      this.activity_student_score = data.activity_student_score;

      var index = 0
      this.activity_manager
        .filter(activity => this.lateWorkService.isLateActivityType(activity))
        .forEach((activity) => {
        if(!this.activity_student_score[activity.id]) return;

        Object.entries(this.activity_student_score[activity.id]).forEach(([student_id, score]) => {
          const isLate = this.lateWorkService.isLateWork(score);
          const status = this.lateWorkService.getStatus(score) ? this.lateWorkService.getStatus(score) : 'submitted';
          // let grade;
          // if (status === 'accepted') {
          //   grade = this.activityService.getGradeInPercentage(activity, this.activity_student_score, student_id).toFixed(0)
          // } else {
          //   grade = this.activityService.getSubmittedGradeInPercentage(activity, this.activity_student_score, student_id).toFixed(0)
          // }

          if (isLate && this.roster_ids.find(student => student._id === student_id)) {
            var zulu = this.activity_student_score[activity.id][student_id]?.dt_completed
            if (zulu && zulu.indexOf('Z') < 0) zulu += "Z"
            this.tableData[index++] = {
              id: {
                value: activity.activity_id ?? activity.id ?? ''
              },
              title: {
                value: activity.report_title,
                link: `/${activity.link}`,
              },
              student: {
                value: (this.roster_ids.find(student => student._id === student_id) || {}).name || ''
              },
              student_id : {
                value : student_id ? student_id : ''
              },
              // grade: {
              //   value: grade ? grade + '%' : '0%'
              // },
              due_date: {
                value: (activity.display_due_date && activity.display_due_date !== "-/-") ? activity.display_due_date : '-'
              },
              submitted_date: {
                value: zulu
              },
              status: {
                value: status
              },
              type: {
                value: activity.type ? activity.type : ''
              }
            }
          }
        })
      })
      this.assessments = this.tableData.filter(obj => Object.keys(obj).length > 0);
      this.allAssessments = this.tableData.filter(obj => Object.keys(obj).length > 0);
      this.isLoading = false;
    });
  }

  closeModal() {
    const modalType = this.shadowRoot.querySelector('jnct-modal').getAttribute('templatename');
    switch(modalType) {
      case 'accept-modal':
        this.isAcceptModalOpen = !this.isAcceptModalOpen;
      case 'reject-modal':
        this.isRejectModalOpen = !this.isRejectModalOpen;
      case 'policy-modal':
        this.isPolicyModalOpen = !this.isPolicyModalOpen;
      default:
        this.isAcceptModalOpen = false;
        this.isRejectModalOpen = false;
        this.isPolicyModalOpen = false;
    }
  }

  async courseIdChanged(oldVal: any, newVal: any) {
    if( typeof newVal == 'string') {
      this.policySettingAvailable = await this.lateWorkService.isChangingPolicyAvailable();
      this.fetchRoster();
      this.fetchData();
    }
  }

  fetchRoster() {
    this.$emit('jnct:dashboardService:getRoster', (data: any) => {
      this.roster_ids = data;
    })
  }

  openAcceptDialog() {
    this.isAcceptModalOpen = true;
    this.forceReload();
  }

  openRejectDialog() {
    this.isRejectModalOpen = true;
    this.forceReload();
  }

  openPolicyDialog() {
    this.isPolicyModalOpen = true;
    this.forceReload();
  }

  search = (value: any) => {
    this.assessments = this.allAssessments.filter((obj) =>
      obj.title.value.toLowerCase().includes(value.toLowerCase()) || obj.student.value.toLowerCase().includes(value.toLowerCase())
    );
  };

  afterSelect = (items: Data[]) => {
    this.selectedItems = items;
  }

  afterFilter = (items: Data[]) => {
    this.visibleItems = items;
  }

  savePolicy = async (policy: Policy) => {
    //@ts-ignore
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    //@ts-ignore
    const csrfToken = Cookies.get("csrf_token");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({ policy }),
    };
    const response = await fetch(
      APIprefix + "stats/save_defaults/" + this.courseId,
      requestOptions
    );
    const data = await response.json();
    // TODO: Notification
  }
}

LateWorkManager.defineComponent();

export { LateWorkManager };
