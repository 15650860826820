import { ColumnConfig } from "../../../types/table";

const columns: ColumnConfig[] = [
    {
        name: "status",
        pattern: "<jnct-status-badge type='value'></jnct-status-badge>",
        replaceValue: 'status',
        title: "Status",
        allowSorting: true,
        filteringType: "singleChoice",
        options: [
            {
              name: "All",
              value: "all"
            },
            {
                name: "Submitted",
                value: "submitted"
            },
            {
                name: "Accepted",
                value: "accepted"
            },
            {
                name: "Rejected",
                value: "rejected"
            },
        ]
    },
    {
        name: "student",
        title: "Student",
        allowSorting: true
    },
    {
        name: "title",
        title: "Assessment Title",
        allowSorting: true,
        link: true,
    },
    // {
    //     name: "grade",
    //     title: "Grade",
    //     allowSorting: true
    // },
    {
        name: "due_date",
        title: "Due Date",
        allowSorting: true,
        date: true,
    },
    {
        name: "submitted_date",
        title: "Submitted Date",
        allowSorting: true,
        date: true,
    },
];

export default columns;
