import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="box">
  <div class="box__title flex--gap" tabindex="0">
    <span class="material-symbols-outlined" aria-label="hidden">integration_instructions</span>
    Assignment Actions
  </div>
  <div class="actions">
    <ul class="actions__list">
      ${T.repeat(x => x.actions, T.html`
      <li class="action">
        <div
          class="action__points"
          tabindex="0"
          aria-label="${x => x.title}"
          @click="${(x, c) => c.parent.goToAction(x.action)}"
          @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? c.parent.goToAction(x.action) : ''}">
          ${x => Math.max(0, x.points)}
        </div>
        <div class="action__title">
          ${x => x.title}
        </div>
      </li>
    `)}
    </ul>
  </div>
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.box {
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  gap: 24px;
  border-radius: 12px;
  border: 1px solid var(--neutral-20);
  background: #FFF;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}
.box__title {
  color: var(--neutral-90);
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
}

.actions {
  width: 100%;
}
.actions__list {
  display: flex;
  padding: 0px 96px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  list-style: none;
}

.action {
  box-sizing: border-box;
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
}
.action__points {
  box-sizing: border-box;
  display: flex;
  width: 85px;
  height: 85px;
  padding: 19px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: var(--primary-8);
  color: var(--link);
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  cursor: pointer;
}
.action__points:hover {
  color: #0A5281;
  background: var(--primary-36);
  text-decoration: underline;
}
.action__title {
  color: var(--neutral-90);
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}

.flex--gap {
  display: flex;
  align-items: center;
  gap: 12px;
}`;


import { observable, RWSViewComponent, RWSView, RWSInject, ngAttr } from "@rws-framework/client";
import ActivitiesService, {ActivitiesServiceInstance} from '../../services/ActivitiesService';
import EventsService, {EventsServiceInstance} from "../../services/EventsService";
import { Activity, ActivityStudentScore} from "../../types/activities";
import { LateWorkStatus } from "../../types/late-work";
import LateWorkService, { LateWorkServiceInstance } from "../../services/LateWorkService";
type Action = {
  title: string,
  points: number,
  action: string,
};

@RWSView('dashboard-actions', null, { template: rwsTemplate, styles })
class DashboardActions extends RWSViewComponent  {
  @observable needs_grading: number;
  @observable late_assessments: number;
  @ngAttr activities: string;
  @ngAttr activity_student_score: string;

  constructor (
    @RWSInject(LateWorkService) protected lateWorkService: LateWorkServiceInstance,
    @RWSInject(ActivitiesService) protected activityService: ActivitiesServiceInstance,
    @RWSInject(EventsService) protected eventsService: EventsServiceInstance,
  ) {
    super()
  };

   async connectedCallback() {
    super.connectedCallback();

    const roster = await this.eventsService.getRoster();
    const student_ids = roster.map((student) => student._id);
    const activities: Activity[] = (this.activities && JSON.parse(this.activities)) || [];
    const activity_student_score: ActivityStudentScore = this.activity_student_score && JSON.parse(this.activity_student_score);

    const gradingActivities = activities
      .filter(a =>this.activityService.isGradeableActivity(a))
      .map(a => this.activityService.calculateActivityGradeStats(a, activity_student_score, student_ids))
      .filter(a => this.activityService.filterGradeableActivity(a, {
        // this matches the default filters in grading center
        type: ["assessment", "board", "project", "comments"],
        scoring: ['Manual', 'Mixed'],
      }));

    const lateAssessments = activities
      .filter(activity => this.lateWorkService.isLateActivityType(activity))
      .flatMap((activity) =>
        Object.entries(activity_student_score[activity.id] || {})
      )
      .filter(
        ([studentId, score]) =>
          student_ids.includes(studentId) &&
          this.lateWorkService.isLateWork(score) &&
          this.lateWorkService.getStatus(score) === LateWorkStatus.submitted
      ).length;

    const submissions = gradingActivities.reduce((total, current) => total + current.submissions, 0);
    const gradedTotal = gradingActivities.reduce((total, current) => total + current.count_graded, 0);
    const gradedLate = gradingActivities.reduce((total, current) => total + current.count_overdue, 0);

    this.needs_grading = submissions - gradedTotal - gradedLate;
    this.late_assessments = lateAssessments;
  };

  goToAction(action: string) {
    this.$emit(action);
  };

  get actions(): Action[] {
    return [
      {
        title: 'Submissions to Grade',
        points: this.needs_grading,
        action: 'jnct:navigation:gotogradingcenter',
      },
      {
        title: 'Late Assignments',
        points: this.late_assessments,
        action: 'jnct:navigation:gotolateworkmanager'
      },
    ];
  };
}

DashboardActions.defineComponent();

export { DashboardActions };
