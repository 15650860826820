import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="modal-content">
  <jnct-modal-header
    :hide="${x=>x.genericConfig.switch}"
    title="Show/Hide Columns"
  ></jnct-modal-header>
  <div class="content">
    <div>
      Customize your view by selecting the columns you would like to see.
    </div>
    <div class="options_row">
      <div class="header">Dates & Time</div>
      <div class="form_options">
        <div class="option">
          <span>Open Date</span>
          <jnct-checkbox-input  @click="${x=>x.genericConfig.onClick('openDate')}" :default="${x=>x.defaults.openDate}"></jnct-checkbox-input>
        </div>
        <div class="option">
          <span>Due Date</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('dueDate')}" :default="${x=>x.defaults.dueDate}"></jnct-checkbox-input>
        </div>
        <div class="option">
          <span>Close Date</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('closeDate')}" :default="${x=>x.defaults.closeDate}"></jnct-checkbox-input>
        </div>
        <div class="option">
          <span>Preset</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('preset')}" :default="${x=>x.defaults.preset}"></jnct-checkbox-input>
        </div>
        <div class="option">
          <span
            >Visible in Student Gradebook</span
          >
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('visg')}" :default="${x=>x.defaults.visg}"></jnct-checkbox-input>
        </div>
      </div>
    </div>
    <div class="options_row">
      <div class="header">Basic</div>
      <div class="form_options">
        <div class="option">
          <span>Show in Gradebook as</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('siga')}" :default="${x=>x.defaults.siga}"></jnct-checkbox-input>
        </div>
        <div class="option">
          <span>Auto/Manual Graded</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('amGraded')}" :default="${x=>x.defaults.amGraded}"></jnct-checkbox-input>
        </div>
        <div class="option">
          <span>Submissions Allowed</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('submissionsAllowed')}" :default="${x=>x.defaults.submissionsAllowed}"></jnct-checkbox-input>
        </div>
        <div class="option">
          <span>Allow Students to Check Answers During the Assessment</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('allowStudentsToCheck')}" :default="${x=>x.defaults.allowStudentsToCheck}"></jnct-checkbox-input>
        </div>
        <div class="option">
          <span>Allow Students to Review Submitted Assessment</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('allowStudents')}" :default="${x=>x.defaults.allowStudents}"></jnct-checkbox-input>
        </div>
        <div class="option">
          <span>Display Grades</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('displayGrades')}" :default="${x=>x.defaults.displayGrades}"></jnct-checkbox-input>
        </div>
        <div class="option">
          <span>Type</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('type')}" :default="${x=>x.defaults.type}"></jnct-checkbox-input>
        </div>
      </div>
    </div>
    <div class="options_row">
      <div class="header">Question Order and Restrictions</div>
      <div class="form_options">
        <div class="option">
          <span>Shuffle Item Order</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('scramble')}" :default="${x=>x.defaults.scramble}"></jnct-checkbox-input>
        </div>
        <div class="option">
          <span>Lock the Back Button</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('lockBackButton')}" :default="${x=>x.defaults.lockBackButton}"></jnct-checkbox-input>
        </div>
        <div class="option">
          <span>Time Limit</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('timeLimit')}" :default="${x=>x.defaults.timeLimit}"></jnct-checkbox-input>
        </div>
      </div>
    </div>
    <div class="options_row">
      <div class="header">Discussion Board Settings</div>
      <div class="form_options">
        <div class="option">
          <span>Learners Must Post Before Seeing Classmates Posts</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('requireComment')}" :default="${x=>x.defaults.requireComment}"></jnct-checkbox-input>
        </div>
        <div class="option">
          <span>Total Points</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('max_score')}" :default="${x=>x.defaults.max_score}"></jnct-checkbox-input>
        </div>
      </div>
    </div>
    <div class="options_row">
      <div class="header">Adaptive Recommendation</div>
      <div class="form_options">
        <div class="option">
          <span>Begin Assessment with Confidence Question</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('confidence')}" :default="${x=>x.defaults.confidence}"></jnct-checkbox-input>
        </div>
        <div class="option">
          <span>Use Assessment to Determine Proficiency (Study Center)</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('studycenter')}" :default="${x=>x.defaults.studycenter}"></jnct-checkbox-input>
        </div>
      </div>
    </div>
    <div class="options_row">
      <div class="header">Reopen Attempts</div>
      <div class="form_options">
        <div class="option">
          <span>Reopen Attempts</span>
          <jnct-checkbox-input @click="${x=>x.genericConfig.onClick('resumeMaxAttempts')}" :default="${x=>x.defaults.resumeMaxAttempts}"></jnct-checkbox-input>
        </div>
      </div>
    </div>
  </div>
  <div class="options">
    <span
      ><button class="button__secondary" @click="${x=>x.changeMode('Presets')}">
        Cancel
      </button></span
    >
    <span style="display: flex; gap: 20px">
      <button class="button__primary" @click="${x=>x.save()}">Save</button></span
    >
  </div>
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.modal-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  border: var(--account, 1px) solid var(--Color-palette-Neutral-20, #eeeff0);
  max-height: calc(100vh - 54px);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
}
.modal-content .content {
  padding: 28px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
  width: calc(100% - 48px);
}
.modal-content .content .row {
  border-radius: 12px;
  border: 1px solid var(--Color-palette-Neutral-20, #eeeff0);
  background: var(--White, #fff);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  padding: 16px;
  justify-content: space-between;
  gap: 16px;
  align-self: stretch;
}
.modal-content .content .options_row {
  border-radius: 12px;
  border: 1px solid var(--Color-palette-Neutral-20, #eeeff0);
  background: var(--White, #fff);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 24px;
}
.modal-content .content .options_row .form_options {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.modal-content .content .options_row .form_options .option {
  display: flex;
  justify-content: space-between;
}
.modal-content .content .options_row .sub {
  box-shadow: none;
}
.modal-content .content .options_row .header {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  display: flex;
  gap: 8px;
}
.modal-content .content .preset_conf {
  display: flex;
  gap: 16px;
}
.modal-content .content .preset_conf .header {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
}
.modal-content .content .preset_conf .name {
  width: 60%;
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.modal-content .content .preset_conf .color {
  width: 40%;
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.modal-content .options {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0px -2px 14px 2px rgba(0, 0, 0, 0.05);
  width: calc(100% - 48px);
  bottom: 0px;
}`;

const mode = "open";

import { attr, RWSViewComponent, RWSView, observable } from "@rws-framework/client";


@RWSView('jnct-show-hide-columns-modal', null, { template: rwsTemplate, styles, options: {mode} })
class JunctionShowHideColumnsModal extends RWSViewComponent  {
  @attr genericConfig: any = {}
  @observable defaults: object = {}

  genericConfigChanged(oldVal, newVal){
      if (typeof newVal.defaultValues === "function") {
        this.defaults = newVal.defaultValues();
      
    }
  }
  

  save(){
    this.genericConfig.applyColumns()
    this.genericConfig.switch()
  }

  connectedCallback () {
    super.connectedCallback();
    this.$emit("modal:shown");

  }
};


JunctionShowHideColumnsModal.defineComponent();

export{
  JunctionShowHideColumnsModal
}