import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<jnct-modal-header
  title="${x => x.genericConfig.headerTitle}"
  :hide="${x => x.genericConfig.switch}"
></jnct-modal-header>

<div class="text-modal-body">
  ${x => x.genericConfig.text}
</div>
`;

import './styles/layout.scss';
const styles = T.css`.text-modal-body {
  width: 460px;
  padding: 16px;
  font-size: 16px;
}`;


import { observable, RWSViewComponent, RWSView } from "@rws-framework/client";

export type TextModalConfig = {
  headerTitle: string;
  text: string;
  switch: () => void,
}

@RWSView('text-modal', null, { template: rwsTemplate, styles })
class TextModal extends RWSViewComponent  {
  @observable genericConfig: any = {};

  connectedCallback() {
    super.connectedCallback();

    this.$emit('modal:shown');
  };
}

TextModal.defineComponent();

export { TextModal };
