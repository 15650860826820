import {RWSService} from '@rws-framework/client';

class TimeFormatService extends RWSService {
  shortMonth(date: string): string {
    //Nov 16
    const newDate = new Date(date);

    // Check if the date is valid
    if (isNaN(newDate.getTime())) {
      throw new Error('Invalid date string');
    };

    const dateFormatOptions: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', dateFormatOptions).format(newDate);
    return formattedDate;
  };

  longDateTime(timestamp: string): string {
    //May 12, 2024 08:23AM

    const date = new Date(timestamp);

    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');

    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    const formattedDate = `${month} ${day} ${year} ${hours}:${minutes} ${ampm}`;
    return formattedDate;
  }
}

export default TimeFormatService.getSingleton();

export {TimeFormatService as TimeFormatServiceInstance}
