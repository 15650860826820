import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="selection">
  ${T.when(x => !x.selectedCount, T.html`    
    ${x => x.data.length} ${x => x.name}${x => x.data.length > 1 ? 's' : ''}
    (<span tabindex="0" @click="${(x, c) => x.selectAll(true)}" class="link">Select all</span>)
  `)}

  ${T.when(x => x.selectedCount, T.html`    
    ${x => x.selectedCount} ${x => x.name}${x => x.selectedCount > 1 ? 's' : ''} selected 
    (<span tabindex="0" @click="${(x, c) => x.selectAll(false)}" class="link">Cancel selection</span>)    
  `)}
</div>
<ul class="list">
  ${T.repeat(x => x.data, T.html`
    <li
      class="list__item"
      tabindex="0"
      @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? c.parent.check(x._id) : ''}"
      @click="${(x, c) => c.parent.check(x._id)}"
    >
      <div
        class="checkbox ${x => x.chosen ? 'active' : ''}"
        tabindex="0"
        @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? c.parent.check(x._id) : ''}"
        @click="${(x, c) => c.parent.check(x._id)}">
        ${T.when(x => x.chosen, T.html`
          <span class="material-symbols-outlined checkbox__mark" aria-hidden="true"> check </span>
      `)}
    </div>      
      <div>${(x, c) => x[c.parent.value]}</div>
    </li>
  `)}
</ul>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.selection {
  margin-bottom: 8px;
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 170%;
}

.list {
  list-style: none;
  display: flex;
  margin: 0px 0px 24px 0px;
  padding: 0px;
  max-height: 432px;
  overflow: auto;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-20);
  background: white;
}
.list__item {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 24px 16px;
  gap: 26px;
  align-items: center;
  color: var(--neutral-70);
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
  cursor: pointer;
}
.list__item label {
  width: 100%;
  cursor: pointer;
}
.list__item:nth-child(even) {
  background: var(--neutral-8);
}

.checkbox {
  position: relative;
  width: 22px;
  height: 22px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}
.checkbox.active {
  border-color: var(--primary_color);
  background-color: var(--primary_color);
}
.checkbox__mark {
  position: absolute;
  color: var(--primary_text);
  left: 2px;
  font-size: 19px;
  top: -1px;
  font-weight: 300;
}`;


import { observable, RWSViewComponent, RWSView, attr } from "@rws-framework/client";

type DataType = {
  name: string,
  _id: string,
  chosen?: boolean,
};

@RWSView('list-with-checkbox', null, { template: rwsTemplate, styles })
class ListWithCheckbox extends RWSViewComponent  {
  @attr data: DataType[];
  @attr value: string;
  @attr name: string;
  @observable selectedCount: number = 0;
  changeValue: Function | undefined;

  connectedCallback() {
    super.connectedCallback();
  };

  selectAll(state: boolean) {
    this.data = JSON.parse(JSON.stringify(this.data));
    this.data.forEach(el => el.chosen = state);
    this.selectedCount = this.data.filter(el => el.chosen).length;
    this.changeValue(this.selectedCount, this.data)
  }
  
  check(id: string) {
    this.data = JSON.parse(JSON.stringify(this.data));
    this.data.forEach((el: any) => el._id === id ? el.chosen = !el.chosen : el.chosen);
    this.selectedCount = this.data.filter(el => el.chosen).length;
    this.changeValue(this.selectedCount, this.data)
    return;
  }
}

ListWithCheckbox.defineComponent();

export { ListWithCheckbox };
