import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="wrapper">
  ${x => x.formattedAttempts.length === 0? T.html`
  <table>
    <thead>
      <tr>
        <th>Attempt</th>
        <th>Date Submitted</th>
        <th>Date Started</th>
        <th>Submitted</th>
        <th>Duration</th>
        <th>Grade</th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td colspan="6" class="no-data">No attempts yet</td>
      </tr>
    </tbody>
  </table>
  `: T.html`
  <table>
    
    <thead>
      <tr>
        <th>Attempt</th>
        <th>Date Submitted</th>
        <th>Date Started</th>
        <th>Submitted</th>
        <th>Duration</th>
        <th>Grade</th>
        <th :style="display: ${x => x.showSubmit ? 'table-cell': 'none'};">
          Scoring needed
        </th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      ${T.repeat(x => x.formattedAttempts, T.html`
      <tr>
        <td>${x => x.attemptNumber}</td>

        <td>
          <jnct-date-tabular-display
            :date="${x => x.submittedDate}"
          ></jnct-date-tabular-display>
        </td>
        <td>
          <jnct-date-tabular-display
            :date="${x => x.startedDate}"
          ></jnct-date-tabular-display>
        </td>
        <td>${x => x.status_submitted}</td>
        <td>${x => x.durationMinutes} mins</td>
        <td>
          ${(x, c) => c.parent.showPoints ? x.score : x.score_in_percentage +
          '%'}
        </td>
        <td
          :style="display: ${(x, c) => c.parent.showSubmit ? 'table-cell': 'none'}"
        >
          ${(x, c) => !x.submittedDate ? T.html`
          <button
            class="button__secondary"
            @click="${(x, c) => c.parent.submit(x.raw)}"
          >
            Submit
          </button>
          ` : undefined}
        </td>
        <td>
          ${(x, c) => c.parent.selectedAttemptId !== x.id ? T.html`
          <div class="button-wrapper">
            <button
              class="button__secondary"
              @click="${(x, c) => c.parent.showDetails(x.raw)}"
            >
              View Details
            </button>
          </div>
          ` : undefined}
        </td>
      </tr>
      `)}
    </tbody>
  </table>
  `}
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.wrapper {
  max-height: 275px;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid var(--neutral-20, #eeeff0);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}

table {
  width: 100%;
  border-spacing: 0;
}

thead {
  color: var(--neutral-90, #1b1c1e);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
}

tbody {
  color: var(--neutral-70, #3b3c3e);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}

th {
  padding: 12px 0;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid var(--neutral-20, #eeeff0);
}
th:first-child {
  text-align: left;
  padding-left: 24px;
}
th:last-child {
  padding-right: 24px;
}

td {
  padding: 8px 0;
  text-align: center;
}
td:first-child {
  text-align: left;
  padding-left: 24px;
}
td:last-child {
  padding-right: 24px;
}
td.no-data {
  text-align: center;
}
td jnct-date-tabular-display {
  --font-weight: 400;
  --color: var(--neutral-70);
}

tbody tr:nth-child(odd) {
  background: var(--neutral-8, rgba(155, 160, 165, 0.08));
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}`;

const mode = "open";
import { attr, RWSViewComponent, RWSView, RWSInject, observable } from "@rws-framework/client";
import { AssessmentAttempt } from "../../../types/reports";
import { LearnositySessionDuration } from "../../../helpers/learnositySessionDuration";
import LearnosityService, {LearnosityServiceInstance} from "../../../services/LearnosityService";

type FormattedAssessmentAttempt = {
  id: string;
  attemptNumber: number;
  submittedDate: string;
  startedDate: string;
  durationMinutes: string;
  score_in_percentage: number;
  raw: AssessmentAttempt;
  status_submitted: string;
};

@RWSView('jnct-assessment-detail-attempt-table', null, { template: rwsTemplate, styles, options: {mode} })
class JunctionAssessmentDetailAttemptTable extends RWSViewComponent  {
  @attr attempts: AssessmentAttempt[] = [];
  @attr selectedAttemptId: AssessmentAttempt["id"];
  @attr onShowDetails: (a: AssessmentAttempt) => void = () => {};
  @attr showPoints: boolean = false;
  @attr showSubmit: boolean = false;
  @attr due_date: string;
  @attr courseId: string;
  @attr activityId: string;
  @observable overrides: any;

  constructor(
    @RWSInject(LearnosityService) protected learnosityService: LearnosityServiceInstance,
  ) {
    super();
  }

  submit(attempt) {
   this.$emit('jnct:gradebook:submitforstudent', attempt);
  }

  get formattedAttempts(): FormattedAssessmentAttempt[] {
    return this.attempts.map((score, idx) => ({
      id: score.id,
      attemptNumber: this.attempts.length - idx,
      submittedDate: score.status === "Completed" ? score.dt_completed : "",
      startedDate: score.dt_started,
      durationMinutes: LearnositySessionDuration(score),
      score_in_percentage: Math.round((score.score / score.max_score) * 100),
      score: score.score,
      raw: score,
      status_submitted: this.getStatus(score),
    }));
  }

  getStatus(score): string {
    if (score.status !== "Completed" ) return 'Not Submitted';
    if (this.overrides?.due_date) {
      this.due_date = this.overrides.due_date;
    }
    if (score.overdue || (score.dt_completed && this.due_date && score.dt_completed > this.due_date)) {
      if (this.overrides?.policy) return 'Late (Auto-Deduction Applied)'
      return 'Late';
    }
    return 'On Time';
  };

  showDetails(attempt: AssessmentAttempt) {
    return this.onShowDetails(attempt);
  }

  async connectedCallback() {
    super.connectedCallback();

    if (this.attempts.length > 0) {
      const courseId = this.courseId || this.attempts[0].session_metadata.course_id;
      this.overrides = await this.learnosityService.getOverride(this.activityId, this.attempts[0].user_id, courseId);
    }
  }
}

JunctionAssessmentDetailAttemptTable.defineComponent();

export { JunctionAssessmentDetailAttemptTable };
