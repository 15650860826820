import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="loading-animation"></div>`;

import './styles/layout.scss';
const styles = T.css`.loading-animation {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  width: 64px;
  height: 64px;
  width: 54px;
  height: 54px;
  border: 5px solid var(--loader-color, var(--primary_color, #55BAB7));
  border-right-color: transparent;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate var(--loader-anim-time, 1s) linear infinite;
}
.loading-animation::after {
  content: "";
  width: 5px;
  height: 5px;
  background: var(--loader-color, var(--primary_color, #55BAB7));
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 37px;
}
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-animation:after {
  display: none;
}`;


import { RWSViewComponent, RWSView } from "@rws-framework/client";
import { html } from "@microsoft/fast-element"; 

@RWSView('jnct-loading-spinner', null, { template: rwsTemplate, styles })
class JunctionLoader extends RWSViewComponent  {
    constructor() {
      super();
    }  
}

JunctionLoader.defineComponent();

export { JunctionLoader }