
export class GradeByData {
  static question_types = {
    association: 'Drag and Drop',
    audio: 'Audio Recording',
    'blockblock-on-focus-keyboardchemistry': 'Chemistry',
    choicematrix: 'Mulitple Choice',
    circle: 'Circle',
    classification: 'Classification',
    clozeassociation: 'Drag and Drop',
    clozedropdown: 'Drop Down',
    clozeformula: 'Math',
    clozetext: 'Fill-in',
    custom: 'Custom',
    drawing: 'Drawing',
    fileupload: 'File Upload',
    fillshape: 'Fill Shape',
    formula: 'Math',
    formulaV2: 'Math',
    formulaessay: 'Math',
    graphplotting: 'Graph',
    gridded: 'Gridded',
    highlight: 'Highlight',
    hotspot: 'Hotspot',
    imageclozeassociation: 'Drag and Drop',
    imageclozeassociationV2: 'Drag and Drop',
    imageclozedropdown: 'Drop Down',
    imageclozeformula: 'Fill-in',
    imageclozetext: 'Fill-in',
    imageupload: 'File Upload',
    inline: 'Mulitple Choice',
    longtext: 'Essay',
    longtextV2: 'Essay',
    mcq: 'Mulitple Choice',
    numberline: 'Number Line',
    numberlineplot: 'Number Line',
    orderlist: 'Ordering',
    plaintext:'Essay',
    point: 'Point',
    rating: 'Rating',
    shorttext: 'Essay',
    simplechart:'Graph',
    simpleshading:'Shading',
    sortlist:'Sort',
    texthighlight:'Highlight',
    tokenhighlight:'Highlight',
    video: 'Video Recording',
  }
}
