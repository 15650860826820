import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<fluent-select class="dropdown" @change="${(x, c) => x.action(c.event)}">
  <fluent-option value="" selected>
    ${x => x.name}
  </fluent-option>
  ${T.repeat(x => x.options, T.html`
    <fluent-option
      class="dropdown__option"
      value="${x => x.value}">
      ${x => x.name}
    </fluent-option>
  `)}
</fluent-select>
`;

import './styles/layout.scss';
const styles = T.css`fluent-select {
  display: flex;
  width: 207px;
  min-width: 207px;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
  border: none;
}

fluent-select::part(control), fluent-select::part(listbox) {
  width: 207px;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}

fluent-select::part(control) {
  height: 40px;
}
fluent-select::part(control):hover {
  border: 1px solid var(--neutral-30);
}
fluent-select::part(control):focus, fluent-select::part(control):active {
  box-shadow: 0px 0px 0px 2px var(--Color-Additional-Blue-blue-500, #0097E0);
}

fluent-select::part(listbox) {
  padding: 2px 0px;
  background: white;
}

fluent-option {
  padding: 8px 12px;
  border: none;
  border-radius: unset;
}
fluent-option.selected {
  display: none;
}
fluent-option:not(.selected):hover {
  background: var(--neutral-a8, rgba(153, 160, 167, 0.08));
}`;


import { RWSViewComponent, RWSView, observable, attr } from "@rws-framework/client";
//use this component if you need just a dropdown
interface OptionType {
  name: string,
  value: string,
}

@RWSView('jnct-dropdown', null, { template: rwsTemplate, styles })
class JunctionDropdown extends RWSViewComponent  {
  @attr name: string;
  @attr options: OptionType[];
  action: Function | undefined;

  connectedCallback () {
    super.connectedCallback()
  };
}

JunctionDropdown.defineComponent();

export{ JunctionDropdown };
