import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div
  class="badge"
  style="border: 1px solid ${x=>x.colorObj.border};  background: ${x=>x.colorObj.background}; color: ${x=>x.colorObj.border};"
>
  <span class="text">${x=>x.text}</span>
</div>
`;

import './styles/layout.scss';
const styles = T.css`.badge {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 12px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}
.badge .text {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}`;

const mode = "open";

import { attr, RWSViewComponent, RWSView, observable } from "@rws-framework/client";
import { colors } from "../../formComponents/selectColortInput/component";

@RWSView('jnct-preset-badge', null, { template: rwsTemplate, styles, options: {mode} })
class JunctionPresetBadge extends RWSViewComponent  {
  @attr text: string
  @attr color: string
  @observable colorObj: object = {
    name: "Red",
    background: "#F7D4D7",
    border: "#570F16",
  };

  colorChanged(oldVal, newVal){
    this.colorObj = colors.filter((color) => color.name.toLowerCase() == newVal.toLowerCase())[0]
  }

  connectedCallback () {
    super.connectedCallback();
  }
};


JunctionPresetBadge.defineComponent();

export{
  JunctionPresetBadge
}