import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<header class="layout__header">
  <div class="title">
    <span class="material-symbols-outlined icon"> insert_chart </span>
    Student Deep Dive
  </div>
  <div class="notepanel search">
    <span class="material-symbols-outlined search__icon" aria-hidden="true"
      >search</span
    >
    <input
      type="text"
      aria-label="Search by Students..."
      placeholder="Search by Students..."
      class="search__input"
      @input="${(x, c) => x.inputChange(c.event)}"
    />
  </div>
  ${x=>x.searchUsername}
</header>
<content>
  ${x => x.loading ? T.html`
  <div class="spinner"><jnct-loading-spinner></jnct-loading-spinner></div>
  ` : T.html`
  <div class="table_wrapper">
    <span class="students_number"> ${x=>x.allStudents.length} Students </span>
    <div class="table">
      <div class="header_wrapper">
        <div class="row header">
          <div class="sortable_column" @click="${x => x.toggleSort('name')}">
            Full Name
            <div class="sortable_column_options">
              <span
                class="material-symbols-outlined ${x => x.sort === 'name' && x.order==='asc' ? 'active': ''}"
              >
                arrow_drop_up
              </span>
              <span
                class="material-symbols-outlined ${x => x.sort === 'name' && x.order==='desc' ? 'active': ''}"
              >
                arrow_drop_down
              </span>
            </div>
          </div>
          <div class="sortable_column" @click="${x => x.toggleSort('grade')}">
            Total Grade
            <div class="sortable_column_options">
              <span
                class="material-symbols-outlined ${x => x.order==='asc' && x.sort === 'grade' ? 'active': ''}"
              >
                arrow_drop_up
              </span>
              <span
                class="material-symbols-outlined ${x => x.order==='desc' && x.sort === 'grade' ? 'active': ''}"
              >
                arrow_drop_down
              </span>
            </div>
          </div>
          <div class="sortable_column" @click="${x => x.toggleSort('time')}">
            Last Time Active
            <div class="sortable_column_options">
              <span
                class="material-symbols-outlined ${x => x.order==='asc' && x.sort === 'time' ? 'active': ''}"
              >
                arrow_drop_up
              </span>
              <span
                class="material-symbols-outlined ${x => x.order==='desc' && x.sort === 'time' ? 'active': ''}"
              >
                arrow_drop_down
              </span>
            </div>
          </div>
          <div>Last Submitted Assignment</div>
          <div>Last Page Visited</div>
          <div></div>
        </div>
      </div>
      <div class="data_wrapper">
        ${T.repeat(x => x.showStudents, T.html`
        <div class="row data">
          <div>
            ${(x => T.html`<a
              @click="${(x, c) => c.parent.goToStudentDeepDive(x)}"
              >${x => x.student.fullname}</a
            >`)}
          </div>
          <div>${x => Math.round(x.avg_score)}%</div>
          <div>
            <jnct-date-tabular-display
              :date="${x => x.lastTimeActive}"
            ></jnct-date-tabular-display>
          </div>

          <div>
            ${(x => x.lastSubmittedAssignment ? T.html`<a
              href="/${x => x.lastSubmittedAssignment.link}"
              :innerHTML="${x => x.lastSubmittedAssignment.title}"
            ></a
            >`: T.html`<span>-</span>`)}
          </div>
          <div>
            ${(x => x.lastPageVisited ? T.html`<a
              href="/${x => x.lastPageVisited.link}"
              :innerHTML="${x => x.lastPageVisited.title}"
            ></a
            >` : T.html`<span>-</span>`)}
          </div>
          <div>
            <button
              class="button__link"
              @click="${(x, c) => c.parent.goToStudentDeepDive(x)}"
            >
              <span class="material-symbols-outlined">chevron_right</span>
            </button>
          </div>
        </div>
        `)}
      </div>
    </div>
  </div>
  ` }
</content>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

#jnct-users-table {
  display: flex;
}

.layout__header {
  padding: 4px 0px 4px 0px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  padding: 4px 0px 4px 0px;
}
.layout__header .title {
  display: flex;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  align-items: center;
  gap: 8px;
  color: var(--neutral-90);
}
.layout__header .search__icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 17px;
  left: 28px;
  z-index: 2;
  cursor: pointer;
  color: var(--neutral-50);
}
.layout__header .search {
  display: flex;
  justify-content: center;
  width: 336px;
  height: 40px;
  position: relative;
  cursor: pointer;
}
.layout__header .search__icon {
  top: 7px;
  left: 12px;
  width: 26px;
  height: 26px;
  font-weight: 400;
  position: absolute;
  z-index: 101;
  filter: invert(1);
  line-height: 26px;
  font-size: 20px;
}
.layout__header .search__input {
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px 0 40px;
  border-radius: 12px;
  background-color: #eeeff0;
  color: #5b5c5e;
  font-size: 16px;
  font-weight: 300;
  line-height: 160%;
  border: none;
  cursor: pointer;
  z-index: 100;
  transition: padding 0.4s;
}

.spinner {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 75vh;
}
.table_wrapper .students_number {
  font-size: 14px;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 5px;
}
.table_wrapper .table {
  width: 100%;
  border: 1px solid #eeeff0;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;
}
.table_wrapper .table .data_wrapper {
  overflow-y: scroll;
}
.table_wrapper .table .data_wrapper .row:nth-child(odd) {
  background: rgba(155, 160, 165, 0.0784313725);
}
.table_wrapper .table .header_wrapper {
  border-bottom: 2px solid #eeeff0;
}
.table_wrapper .table .row {
  display: grid;
  grid-template-columns: 4fr 3fr 3fr 5fr 5fr 24px;
  padding: 4px 16px 4px 16px;
  gap: 0 8px;
}
.table_wrapper .table .row div {
  align-items: center;
  display: flex;
  border: 1px 0px 0px 1px;
}
.table_wrapper .table .row div .date {
  display: block;
}
.table_wrapper .table .row .sortable_column {
  cursor: pointer;
}
.table_wrapper .table .row .sortable_column_options {
  display: flex;
  flex-direction: column;
}
.table_wrapper .table .row .sortable_column_options span {
  line-height: 7px;
  color: rgba(27, 28, 30, 0.2);
  font-size: 28px;
}
.table_wrapper .table .row .sortable_column_options .active {
  color: #3b3c3e;
}
.table_wrapper .table .row a {
  text-decoration: none;
  color: #0f75b8;
  cursor: pointer;
}
.table_wrapper .table .header {
  min-height: 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 170%;
  letter-spacing: 0em;
  text-align: left;
}
.table_wrapper .table .data {
  min-height: 80px;
  text-align: left;
  font-weight: 300;
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0em;
}
.table_wrapper .button__link {
  color: black;
}`;

const mode = "open";
import {
  observable,
  RWSViewComponent,
  RWSView,
  RWSInject,
} from "@rws-framework/client";

import StudentDeepDiveService, {
  StudentDeepDiveServiceInstance,
} from "../../services/StudentDeepDiveService";
import { StudentDeepDiveListItem } from "../../types/reports";

@RWSView('jnct-users-table', null, { template: rwsTemplate, styles, options: {mode} })
class UsersTable extends RWSViewComponent  {
  @observable allStudents: StudentDeepDiveListItem[] = [];
  @observable loading = true;

  @observable search: string = "";
  @observable sort: "name" | "grade" | "time" = "name";
  @observable order: "asc" | "desc" = "asc";

  constructor(
    @RWSInject(StudentDeepDiveService)
    protected readonly studentDeepDiveService: StudentDeepDiveServiceInstance
  ) {
    super();
  }

  get showStudents(): StudentDeepDiveListItem[] {
    const filtered = this.search
      ? this.allStudents.filter((value) =>
          value.student.fullname.toLowerCase().includes(this.search)
        )
      : [...this.allStudents];

    const sorted = sortBy(filtered, (student) => {
      switch (this.sort) {
        case "grade":
          return student.avg_score;
        case "name":
          return student.student.sortname;
        case "time":
          return student.lastTimeActive ? student.lastTimeActive.getTime() : 0;
      }
    });

    return this.order === "asc" ? sorted : sorted.reverse();
  }

  loadStudentList() {
    this.studentDeepDiveService
      .getListView()
      .then((students) => {
        this.allStudents = students;
        this.loading = false;
      })
      .catch((err) => {
        this.allStudents = [];
        this.loading = false;

        this.$emit(
          "jnct:notifications:error",
          "Failed to load student deep dive data"
        );
        console.error(err);
      });
  }

  inputChange(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;

    this.search = target.value.toLowerCase();
  }

  goToStudentDeepDive(student: StudentDeepDiveListItem) {
    window.location.href = window.location.href + "/" + student.student.id;
  }

  toggleSort(field: "name" | "grade" | "time") {
    const isFieldAlreadySorted = this.sort === field;
    const order = isFieldAlreadySorted
      ? this.order === "asc"
        ? "desc"
        : "asc"
      : "asc";

    this.sort = field;
    this.order = order;
  }

  connectedCallback() {
    super.connectedCallback();
    this.loadStudentList();
  }
}

UsersTable.defineComponent();

export { UsersTable };

function sortBy<R>(items: R[], mapper: (value: R) => string | number): R[] {
  return [...items].sort((a, b) => {
    const valA = mapper(a);
    const valB = mapper(b);

    return valA === valB ? 0 : valA > valB ? 1 : -1;
  });
}
