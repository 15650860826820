export function changeTimeZone(isoString: string): string {
    // Parsuje datę z ISOString
    const timeZone =
      localStorage.getItem("activity_settings_tz")? localStorage.getItem("activity_settings_tz") : "local";
    // Convert the input date to a Date object
    const date = new Date(isoString);
    if (isNaN(date.getTime())) {
      throw new RangeError("Invalid time value");
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() +1 ).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const hour = String(date.getHours()).padStart(2, "0")
    const minute = String(date.getMinutes()).padStart(2, "0")
    const second = String(date.getSeconds()).padStart(2, "0")
    const millisecond = String(date.getMilliseconds()).padStart(3, "0")

    const formattedDate = `${year}-${month}-${day}T${hour}:${minute}:${second}.${millisecond}`;

    // @ts-ignore
    return new Date(luxon.DateTime.fromISO(
      formattedDate,
      {'zone': timeZone}
    ).toISO()).toISOString()
  }