import { LtiSetup } from "./component"

export default function(this: LtiSetup){
    return {
        options: [
            {
                type: 'all',
                title: 'Sync All Graded Assignments',
                description: 'All assignments marked as graded will be synced to your LMS.',
            },
            {
                type: 'none',
                title: 'Do Not Sync Any Assignments',
                description: 'No assignments will sync to your LMS.',
            },
            {
                type: 'specific',
                title: 'Select Specific Assignments to Sync',
                description: 'Select a subset of graded assignments to sync to your LMS gradebook.',
            },
        ],
        gradebook_options: [
            {
                type: 'assignment',
                title: 'Show Each Assignment',
                description: `Show each ${this.buildTitle} assignment as a unique column in my LMS gradebook. Using this method, we will sync total points for each assignment and a due date for each assignment.`,
            },
            {
                type: 'chapter',
                title: 'Show Each Chapter',
                description: `Show each ${this.buildTitle} chapter as a unique column in my LMS gradebook. Using this method, we will sync total points for each chapter. We will not create any due dates in your LMS.`,
            },
            {
                type: 'chapter-percent',
                title: 'Show Each Chapter - Score out of 100',
                description: `Show each ${this.buildTitle} chapter as a unique column in my LMS gradebook. Using this method, we will sync points out of 100 for each chapter. We will not create any due dates in your LMS.`,
            },
            {
                type: 'total',
                title: `Show ${this.buildTitle} Total`,
                description: `Show ${this.buildTitle} total points as a unique column in my LMS gradebook. Using this method, we will sync total points for the course.  We will not create any due dates in your LMS.`,
            },
            {
                type: 'total-percent',
                title: `Show ${this.buildTitle} Total - Score out of 100`,
                description: `Show ${this.buildTitle} total points as a unique column in my LMS gradebook. Using this method, we will sync total points out of 100 for the course.  We will not create any due dates in your LMS.`,
            },
            {
                type: 'roll-up',
                title: `Show Each Custom Roll-up`,
                description: `Show each ${this.buildTitle} custom roll-up column as a unique column in my LMS gradebook. Using this method, we will sync total points for each custom roll-up column. We will not create any due dates in your LMS.`,
            },
            {
                type: 'roll-up-percent',
                title: `Show Each Custom Roll-up - Score out of 100`,
                description: `Show each ${this.buildTitle} custom roll-up column as a unique column in my LMS gradebook. Using this method, we will sync points out of 100 for each custom roll-up column.  We will not create any due dates in your LMS.`,
            },
        ]
    }
}