import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`${T.when(x => !x.allActivities, T.html`
<section class="loader">
  <jnct-loading-spinner></jnct-loading-spinner>
</section>
`)} ${T.when(x => x.allActivities && x.notBlocked, T.html` ${T.when(x => x.showPresetsList,
T.html`
<jnct-modal
  templateName="jnct-presets-list-modal"
  :genericConfig="${x=>x.pConfig}"
></jnct-modal>
`)} ${T.when(x => x.showBatchUpdate, T.html`

<jnct-modal
  templateName="jnct-batch-update-modal"
  :genericConfig="${x=>x.buConfig}"
></jnct-modal>
`)} ${T.when(x => x.showDefaults, T.html`

<jnct-modal
  templateName="jnct-activity-settings-defaults"
  :genericConfig="${x=>x.dfConfig}"
></jnct-modal>
`)} ${T.when(x => x.showColumns, T.html`
<jnct-modal
  templateName="jnct-show-hide-columns-modal"
  :genericConfig="${x=>x.shConfig}"
></jnct-modal>
`)} ${T.when(x => x.leaveModal, T.html`
<jnct-modal
  templateName="jnct-activity-leaving-modal"
  :genericConfig="${x=>x.lmConfig}"
></jnct-modal>
`)}
<div id="activity_settings">
  <header>
    <div class="title">
      <span class="material-symbols-outlined"> settings_applications </span>
      Activity Settings
    </div>
    <div class="options">
      <button
        class="button__secondary"
        :disabled="${x=>Object.keys(x.tableValChanges).length == 0}"
        @click="${x=>x.cancel()}"
      >
        Cancel
      </button>
      <button
        class="button__primary"
        :disabled="${x=>Object.keys(x.tableValChanges).length == 0}"
        @click="${x=>x.saveTableChanges()}"
      >
        Save Changes
      </button>
    </div>
  </header>
  <div class="options-bar">
    <div class="options">
      <jnct-searchbar :handleChange="${x=>x.search}"></jnct-searchbar>
      <button
        class="button__primary"
        @click="${x=>x.switchBatchUpdateModal()}"
        :disabled="${x=>!(x.who.length>0 && Object.keys(x.tableValChanges).length == 0)}"
      >
        Batch Update
      </button>
      <button
        class="button__secondary ${x => !x.allActivities?.length? 'disabled' : ''}"
        @click="${x=>!x.allActivities?.length ? '' : x.switchPresetListModal()}"
      >
        Modify Presets
      </button>
      ${T.when( x => x.view=="advanced", T.html`
      <button class="button__secondary" @click="${x=>x.switchColumnsModal()}">
        Show<span style="font-size: 12px; margin: 2px">/</span>Hide Columns
      </button>
      `)}

      <button class="button__secondary" @click="${x=>x.switchDefaultsModal()}">
        Defaults
      </button>
    </div>
    <div class="view-select">
      <jnct-select-input
        style="width: 200px"
        default="${x=>x.view == 'calendar' ? 'Calendar View':'Advanced View'}"
        :options="${x=>x.views}"
        :afterChange="${x=>x.changeView}"
        fieldName="view"
        variant="big"
        :disabled="${x=>!(x.who.length===0 && Object.keys(x.tableValChanges).length === 0)}"
      ></jnct-select-input>
    </div>
  </div>
  <div>
    <span class="selected-items"
      >${x=>x.who.length} Activities selected (<span
        class="cancel"
        @click="${x=>x.clearSelected()}"
        >Cancel Selection</span
      >)</span
    >
    ${T.when( x => x.view == 'calendar', T.html`
    <div class="calendar_table">
      <div
        class="header"
        style="
          display: grid;
          grid-template-columns: 75px minmax(230px, 3fr) minmax(100px, 1fr) 230px 230px 230px 80px;
        "
      >
        <div style="display: flex; gap: 8px">
          <jnct-checkbox-input
            :default="${x=>x.selectAll}"
            :afterChange="${x => x.toggleAll}"
          ></jnct-checkbox-input
          >Edit
        </div>
        <div>
          Activity Title<jnct-table-dropdown
            title="lesson"
            columnName="report_title"
            allowSorting="true"
            currentSorting="${x=>x.currentSorting}"
            filtersType="multi-checkbox"
            :options="${x=>x.allLessons}"
            :currentFilter="${x => x.filters.report_title}"
            :changeSorting="${x=>x.changeSorting}"
            :changeFiltering="${x=>x.changeFilters}"
          ></jnct-table-dropdown>
        </div>
        <div class="center">
          Preset<jnct-table-dropdown
            title="Preset Name"
            columnName="preset_name"
            allowSorting="true"
            currentSorting="${x=>x.currentSorting}"
            filtersType="multi-checkbox"
            :options="${x => x.availablePresetsOptions}"
            :currentFilter="${x => x.filters.preset_name}"
            :changeSorting="${x=>x.changeSorting}"
            :changeFiltering="${x=>x.changeFilters}"
          ></jnct-table-dropdown>
        </div>
        <div class="center">
          Open Date
          <jnct-table-dropdown
            title="Open Date"
            columnName="open_date"
            allowSorting="true"
            currentSorting="${x=>x.currentSorting}"
            filtersType="dateRange"
            :defaulttime="${x => x.defaulttime}"
            :currentFilter="${x => x.filters.open_date}"
            :changeSorting="${x=>x.changeSorting}"
            :changeFiltering="${x=>x.changeFilters}"
          ></jnct-table-dropdown>
        </div>
        <div class="center">
          Due Date<jnct-table-dropdown
            title="Due Date"
            columnName="due_date"
            allowSorting="true"
            currentSorting="${x=>x.currentSorting}"
            filtersType="dateRange"
            :currentFilter="${x => x.filters.due_date}"
            :changeSorting="${x=>x.changeSorting}"
            :changeFiltering="${x=>x.changeFilters}"
            :defaulttime="${x => x.defaulttime}"
          ></jnct-table-dropdown>
        </div>
        <div class="center">
          Close Date<jnct-table-dropdown
            title="Close Date"
            columnName="close_date"
            allowSorting="true"
            currentSorting="${x=>x.currentSorting}"
            filtersType="dateRange"
            :currentFilter="${x => x.filters.close_date}"
            :changeSorting="${x=>x.changeSorting}"
            :changeFiltering="${x=>x.changeFilters}"
            :defaulttime="${x => x.defaulttime}"
          ></jnct-table-dropdown>
        </div>
        <div class="center">
          Graded<jnct-table-dropdown
            title="Graded"
            columnName="graded"
            allowSorting="true"
            currentSorting="${x=>x.currentSorting}"
            filtersType="multi-checkbox"
            :options="${x => x.gradedOptions}"
            :currentFilter="${x => x.filters.graded}"
            :changeSorting="${x=>x.changeSorting}"
            :changeFiltering="${x=>x.changeFilters}"
          ></jnct-table-dropdown>
        </div>
      </div>
      <div class="data" id="calendar_view_scroll">
        ${T.repeat( x => x.allActivities, T.html` ${T.when((x,c)=>c.index <
        c.parent.showRows,T.html`

        <div
          class="row"
          style="
            display: grid;
            grid-template-columns: 75px minmax(230px, 3fr) minmax(100px, 1fr) 230px 230px 230px 80px;
          "
        >
          <div class="edit">
            <jnct-checkbox-input
              :default="${(x, c)=>(x.type === 'custom' ? c.parent.selectedIDs.includes('_' + x.id): c.parent.selectedIDs.includes(x.id)) || c.parent.selectAll}"
              :afterChange="${(x, c) => (id, value) => c.parent.selectActivityClick(id, value, c.event)}"
              fieldName="${x=>x.id}"
            ></jnct-checkbox-input>
            <span
              class="material-symbols-outlined icon"
              @click="${(x, c) => c.parent.editOne(x)}"
            >
              edit
            </span>
          </div>
          <div tabindex="100" style="display: flex; gap: 8px">
            ${T.when(x=> x.suppress_until_available || x.open_date_in_future, T.html`
            <span class="material-symbols-outlined icon"> lock </span>
            `)} <a href="${x=>x.dlink}">${x=>T.html`${x.report_title}`}</a>
          </div>
          <div style="display: flex" class="center">
            ${T.when((x, c)=>c.parent.presetsObj[x.preset_id] && x.preset_id,
            T.html`
            <jnct-preset-badge
              style="max-width: 100%"
              color="${(x, c)=>c.parent.presetsObj[x.preset_id].color}"
              text="${(x, c)=>c.parent.presetsObj[x.preset_id].name}"
            ></jnct-preset-badge>
            `)}
          </div>
          <div class="center">
            ${T.when((x, c) => c.parent.hasDateInput.open(x), T.html`
            <jnct-datetime-input
              inType="datetime-local"
              :timezone="${(x, c) => c.parent.timezone}"
              :hideSpecialFutureDates="${(x, c) => !!(c.parent.tableValChanges[x.id]?.suppress_until_available ?? x.suppress_until_available) || !['lesson', 'page'].includes(x.type)}"
              :default="${(x, c)=> c.parent.tableValChanges[x.id]?.open_date ?? x.open_date}"
              :defaulttime="${(x, c) =>c.parent.defaulttime}"
              :afterChange="${(x, c)=>(field, value)=>c.parent.changeTableVal(field, value, x)}"
              fieldName="open_date:${x=>x.id}:${x=>x.type}"
              tabindex="4"
            ></jnct-datetime-input>
            `)}
          </div>
          <div class="center">
            ${T.when((x, c) => c.parent.hasDateInput.due(x), T.html`
            <jnct-datetime-input
              inType="datetime-local"
              :timezone="${(x, c) => c.parent.timezone}"
              :default="${(x, c)=> c.parent.tableValChanges[x.id]?.due_date ?? x.due_date}"
              :defaulttime="${(x, c) =>c.parent.defaulttime}"
              :afterChange="${(x, c)=>(field, value)=>c.parent.changeTableVal(field, value, x)}"
              fieldName="due_date:${x=>x.id}:${x=>x.type}"
              tabindex="5"
            ></jnct-datetime-input>
            `)}
          </div>
          <div class="center">
            ${T.when((x, c) => c.parent.hasDateInput.close(x), T.html`
            <jnct-datetime-input
              inType="datetime-local"
              :timezone="${(x, c) => c.parent.timezone}"
              :default="${(x, c)=> c.parent.tableValChanges[x.id]?.close_date ?? x.close_date}"
              :defaulttime="${(x, c) =>c.parent.defaulttime}"
              :afterChange="${(x, c)=>(field, value)=>c.parent.changeTableVal(field, value, x)}"
              fieldName="close_date:${x=>x.id}:${x=>x.type}"
              tabindex="6"
            ></jnct-datetime-input>
            `)}
          </div>
          <div class="center">
            ${x => !x.course_data_only ? T.html`
            <jnct-checkbox-input
              :default="${(x, c)=> c.parent.tableValChanges[x.id]?.graded ?? x.graded}"
              :afterChange="${(x, c)=>(field, value)=>c.parent.changeTableVal(field, value, x)}"
              fieldName="graded:${x=>x.id}:${x=>x.type}"
            ></jnct-checkbox-input
            >` : T.html`<span>-</span>` }
          </div>
        </div>
        `)} `, { positioning: true })}
      </div>
    </div>
    `)} ${T.when( x => x.view == 'advanced', T.html`
    <div class="advanced_table" id="advanved_view_scroll">
      <div>
        <div class="header">
          <div class="fix" style="display: flex; gap: 8px; width: 75px">
            <jnct-checkbox-input
              :default="${x=>x.selectAll}"
              :afterChange="${x => x.toggleAll}"
            ></jnct-checkbox-input
            >Edit
          </div>
          <div
            style="width: 250px; left: 91px; padding-right: 16px"
            class="fix right_shadow"
          >
            Activity Title
            <jnct-table-dropdown
              title="Title"
              columnName="report_title"
              allowSorting="true"
              filtersType="multi-checkbox"
              :options="${x=>x.allLessons}"
              currentSorting="${x=>x.currentSorting}"
              :currentFilter="${x => x.filters.report_title}"
              :changeSorting="${x=>x.changeSorting}"
              :changeFiltering="${x=>x.changeFilters}"
            ></jnct-table-dropdown>
          </div>
          ${T.when( x => x.showHideColumn.siga, T.html`
          <div style="width: 120px; padding-left: 16px">
            Graded
            <jnct-table-dropdown
              title="Graded"
              columnName="graded"
              allowSorting="true"
              currentSorting="${x=>x.currentSorting}"
              filtersType="multi-checkbox"
              :options="${x => x.gradedOptions}"
              :currentFilter="${x => x.filters.graded}"
              :changeSorting="${x=>x.changeSorting}"
              :changeFiltering="${x=>x.changeFilters}"
            ></jnct-table-dropdown>
          </div>
          `)} ${T.when( x => x.showHideColumn.siga, T.html`
          <div style="width: 120px">Locked</div>
          `)} ${T.when( x => x.showHideColumn.openDate, T.html`
          <div style="width: 230px">
            Open Date
            <jnct-table-dropdown
              title="Open Date"
              columnName="open_date"
              allowSorting="true"
              currentSorting="${x=>x.currentSorting}"
              filtersType="dateRange"
              :currentFilter="${x => x.filters.open_date}"
              :changeSorting="${x=>x.changeSorting}"
              :changeFiltering="${x=>x.changeFilters}"
              :defaulttime="${x => x.defaulttime}"
            ></jnct-table-dropdown>
          </div>
          `)} ${T.when( x => x.showHideColumn.dueDate, T.html`
          <div style="width: 230px">
            Due Date
            <jnct-table-dropdown
              title="Due Date"
              columnName="due_date"
              allowSorting="true"
              currentSorting="${x=>x.currentSorting}"
              filtersType="dateRange"
              :currentFilter="${x => x.filters.due_date}"
              :changeSorting="${x=>x.changeSorting}"
              :changeFiltering="${x=>x.changeFilters}"
              :defaulttime="${x => x.defaulttime}"
            ></jnct-table-dropdown>
          </div>
          `)} ${T.when( x => x.showHideColumn.closeDate, T.html`
          <div style="width: 230px">
            Close Date
            <jnct-table-dropdown
              title="Close Date"
              columnName="close_date"
              allowSorting="true"
              currentSorting="${x=>x.currentSorting}"
              filtersType="dateRange"
              :currentFilter="${x => x.filters.close_date}"
              :changeSorting="${x=>x.changeSorting}"
              :changeFiltering="${x=>x.changeFilters}"
              :defaulttime="${x => x.defaulttime}"
            ></jnct-table-dropdown>
          </div>
          `)} ${T.when( x => x.showHideColumn.preset, T.html`
          <div style="width: 200px; padding-right: 16px">
            Preset<jnct-table-dropdown
              title="Preset Name"
              columnName="preset_name"
              allowSorting="true"
              currentSorting="${x=>x.currentSorting}"
              filtersType="multi-checkbox"
              :options="${x => x.availablePresetsOptions}"
              :currentFilter="${x => x.filters.preset_name}"
              :changeSorting="${x=>x.changeSorting}"
              :changeFiltering="${x=>x.changeFilters}"
            ></jnct-table-dropdown>
          </div>
          `)}${T.when( x => x.showHideColumn.type, T.html`
          <div style="width: 150px">
            Type<jnct-table-dropdown
              title="Type"
              columnName="ttype"
              allowSorting="true"
              filtersType="select"
              :options="${x => x.activityTypeOptions}"
              currentSorting="${x=>x.currentSorting}"
              :currentFilter="${x => x.filters.ttype}"
              :changeSorting="${x=>x.changeSorting}"
              :changeFiltering="${x=>x.changeFilters}"
            ></jnct-table-dropdown>
          </div>
          `)} ${T.when( x => x.showHideColumn.amGraded, T.html`
          <div style="width: 200px">
            Auto/Manual Graded
            <jnct-table-dropdown
              title="Auto/Manual"
              columnName="scoring_type"
              allowSorting="true"
              filtersType="select"
              :options="${x => x.autoManualGradedOptions}"
              currentSorting="${x=>x.currentSorting}"
              :currentFilter="${x => x.filters.scoring_type}"
              :changeSorting="${x=>x.changeSorting}"
              :changeFiltering="${x=>x.changeFilters}"
            ></jnct-table-dropdown>
          </div>
          <div style="width: 150px">Subm Allowed</div>
          `)} ${T.when( x => x.showHideColumn.allowStudentsToCheck, T.html`
          <div style="width: 150px">Check Answers</div>
          `)} ${T.when( x => x.showHideColumn.allowStudents, T.html`
          <div style="width: 150px">Review Submissions</div>
          `)} ${T.when( x => x.showHideColumn.displayGrades, T.html`
          <div style="width: 150px">Display Grades</div>
          `)} ${T.when( x => x.showHideColumn.scramble, T.html`
          <div style="width: 100px">Shuffle</div>
          `)} ${T.when( x => x.showHideColumn.lockBackButton, T.html`
          <div style="width: 150px">Lock Back Button</div>
          `)} ${T.when( x => x.showHideColumn.timeLimit, T.html`
          <div style="width: 100px">Time Limit</div>
          `)} ${T.when( x => x.showHideColumn.studycenter, T.html`
          <div style="width: 150px">Study Center</div>
          `)}${T.when( x => x.showHideColumn.visg, T.html`
          <div style="width: 200px">Vis. Student Gradebook</div>
          `)} ${T.when( x => x.showHideColumn.submissionsAllowed, T.html`
          `)}${T.when( x => x.showHideColumn.requireComment, T.html`
          <div style="width: 150px">Post Before Seeing</div>
          `)}${T.when( x => x.showHideColumn.max_score, T.html`
          <div style="width: 100px">Total Points</div>
          `)}${T.when( x => x.showHideColumn.resumeMaxAttempts, T.html`
          <div style="width: 150px">Reopen Attempts</div>
          `)} ${T.when( x => x.showHideColumn.confidence, T.html`
          <div style="width: 150px">Confidence Quest</div>
          `)}
        </div>
        ${T.repeat( x => x.allActivities, T.html` ${T.when((x,c)=>c.index <
        c.parent.showRows,T.html`
        <div class="row">
          <div class="edit fix right_shadow" style="width: 75px">
            <jnct-checkbox-input
              :default="${(x, c)=>c.parent.selectedIDs.includes(x.id) || c.parent.selectAll}"
              :afterChange="${(x, c) => (id, value) => c.parent.selectActivityClick(id, value, c.event)}"
              fieldName="${x=>x.id}"
            ></jnct-checkbox-input>
            <span
              class="material-symbols-outlined icon"
              @click="${(x, c) => c.parent.editOne(x)}"
            >
              edit
            </span>
          </div>
          <div
            style="width: 250px; left: 91px; padding-right: 16px"
            class="fix right_shadow"
          >
            <a href="${x=>x.dlink}">${x=>T.html`${x.report_title}`}</a>
          </div>
          ${T.when( (x, c) => c.parent.showHideColumn.siga, T.html`
          <div style="width: 120px; padding-left: 16px">
            ${x => !x.course_data_only ? T.html`
            <jnct-checkbox-input
              :default="${(x, c)=> c.parent.tableValChanges[x.id]?.graded ?? x.graded}"
              :afterChange="${(x, c)=>(field, value)=>c.parent.changeTableVal(field, value, x)}"
              fieldName="graded:${x=>x.id}:${x=>x.type}"
            ></jnct-checkbox-input
            >` : T.html`-` }
          </div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.siga, T.html`
          <div style="width: 120px">
            ${(x, c) => c.parent.activityLockStatus(x) === 'none' ? T.html`-` :
            T.html`
            <jnct-checkbox-input
              :default="${(x, c) => c.parent.activityLockStatus(x) === 'locked' || c.parent.activityLockStatus(x) === 'locked-disabled'}"
              :afterChange="${(x, c)=>(field, value)=>c.parent.changeTableVal(field, value, x)}"
              fieldName="suppress_until_available:${x=>x.id}:${x=>x.type}"
              :disabled="${(x, c)=>c.parent.activityLockStatus(x) === 'locked-disabled'}"
            ></jnct-checkbox-input>
            ` }
          </div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.openDate, T.html`
          <div style="width: 230px">
            ${T.when((x, c) => c.parent.hasDateInput.open(x), T.html`
            <jnct-datetime-input
              inType="datetime-local"
              :timezone="${(x, c) => c.parent.timezone}"
              :hideSpecialFutureDates="${(x, c) => !!(c.parent.tableValChanges[x.id]?.suppress_until_available ?? x.suppress_until_available) || !['lesson', 'page'].includes(x.type)}"
              :default="${(x, c)=> c.parent.tableValChanges[x.id]?.open_date ?? x.open_date}"
              :defaulttime="${(x, c) =>c.parent.defaulttime}"
              :afterChange="${(x, c)=>(field, value)=>c.parent.changeTableVal(field, value, x)}"
              fieldName="open_date:${x=>x.id}:${x=>x.type}"
              :tabIndexValue="${(x,c)=>c.index}"
              tabindex="4"
            ></jnct-datetime-input>
            `)}
          </div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.dueDate, T.html`
          <div style="width: 230px">
            ${T.when((x, c) => c.parent.hasDateInput.due(x), T.html`
            <jnct-datetime-input
              inType="datetime-local"
              :timezone="${(x, c) => c.parent.timezone}"
              :default="${(x, c)=> c.parent.tableValChanges[x.id]?.due_date ?? x.due_date}"
              :defaulttime="${(x, c) =>c.parent.defaulttime}"
              :afterChange="${(x, c)=>(field, value)=>c.parent.changeTableVal(field, value, x)}"
              fieldName="due_date:${x=>x.id}:${x=>x.type}"
              tabindex="5"
            ></jnct-datetime-input>
            `)}
          </div>
          `)}${T.when( (x, c) => c.parent.showHideColumn.closeDate, T.html`
          <div style="width: 230px">
            ${T.when((x, c) => c.parent.hasDateInput.close(x), T.html`
            <jnct-datetime-input
              inType="datetime-local"
              :timezone="${(x, c) => c.parent.timezone}"
              :default="${(x, c)=> c.parent.tableValChanges[x.id]?.close_date ?? x.close_date}"
              :defaulttime="${(x, c) =>c.parent.defaulttime}"
              :afterChange="${(x, c)=>(field, value)=>c.parent.changeTableVal(field, value, x)}"
              fieldName="close_date:${x=>x.id}:${x=>x.type}"
              tabindex="6"
            ></jnct-datetime-input>
            `)}
          </div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.preset, T.html`
          <div style="width: 200px; padding-right: 16px">
            ${T.when((x, c)=>c.parent.presetsObj[x.preset_id] && x.preset_id,
            T.html`
            <jnct-preset-badge
              color="${(x, c)=>c.parent.presetsObj[x.preset_id].color}"
              text="${(x, c)=>c.parent.presetsObj[x.preset_id].name}"
            ></jnct-preset-badge>
            `)}
          </div>

          `)} ${T.when( (x, c) => c.parent.showHideColumn.type, T.html`
          <div style="width: 150px">${x=>x.ttype }</div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.amGraded, T.html`
          <div style="width: 200px">
            ${(x, c)=>c.parent.formatScoringType(x)}
          </div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.submissionsAllowed,
          T.html`
          <div style="width: 150px">${x=>x.ssubmission_attempts}</div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.allowStudentsToCheck,
          T.html`
          <div style="width: 150px">${x=>x.inline}</div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.allowStudents, T.html`
          <div style="width: 150px">${x=>x.show_responses}</div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.displayGrades, T.html`
          <div style="width: 150px">
            ${(x, c) => c.parent.displayedGradesValue(x)}
          </div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.scramble, T.html`
          <div style="width: 100px">${x=>x.scramble? 'Yes':'' }</div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.lockBackButton,
          T.html`
          <div style="width: 150px">${x=>x.lock_back_button? 'Yes':'' }</div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.timeLimit, T.html`
          <div style="width: 100px">
            ${(x, c)=>c.parent.formatTimeLimit(x.time_limit) }
          </div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.studycenter, T.html`
          <div style="width: 150px">${x=>x.studycenter? 'Yes' : '' }</div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.visg, T.html`
          <div style="width: 200px">
            ${x=>x.type == 'custom'? (x.show? 'Yes' : 'No') : ''}
          </div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.requireComment,
          T.html`
          <div style="width: 150px">${x=>x.requires_comment? 'Yes':'' }</div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.max_score, T.html`
          <div style="width: 100px">${x=>x.max_score }</div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.resumeMaxAttempts,
          T.html`
          <div style="width: 150px">
            ${x=>x.resume_max_attempts < 0? '∞' : x.resume_max_attempts}
          </div>
          `)} ${T.when( (x, c) => c.parent.showHideColumn.confidence, T.html`
          <div style="width: 150px">${x=>x.confidence? 'Yes':'' }</div>
          `)}
        </div>

        `)} `, { positioning: true })}
      </div>
    </div>
    `)}
  </div>
</div>
`)}
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

#activity_settings {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
#activity_settings a {
  text-decoration: none;
  color: var(--link);
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#activity_settings header {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#activity_settings header .title {
  gap: 8px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
  align-items: center;
}
#activity_settings header .options {
  display: flex;
  gap: 8px;
  align-items: center;
}
#activity_settings .options-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}
#activity_settings .options-bar .options {
  display: flex;
  gap: 8px;
  height: 40px;
}
#activity_settings .options-bar .view-select select {
  height: 40px;
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Color-palette-Neutral-20, #eeeff0);
  color: var(--Color-palette-Neutral-60, #5b5c5e);
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
#activity_settings .selected-items {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
}
#activity_settings .selected-items .cancel {
  cursor: pointer;
  color: var(--link);
}
#activity_settings .calendar_table {
  width: 100%;
  border: 1px solid #eeeff0;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 1fr;
  overflow-x: auto;
}
#activity_settings .calendar_table .header {
  padding: 8px 16px;
  display: flex;
  border-bottom: 2px solid #eeeff0;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 3;
}
#activity_settings .calendar_table .header div {
  padding-top: 10px;
  display: flex;
  gap: 4px;
  padding-left: 8px;
}
#activity_settings .calendar_table .header div.center {
  justify-content: center;
  align-items: center;
}
#activity_settings .calendar_table .header .row {
  height: 32px;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
}
#activity_settings .calendar_table .header .row div {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#activity_settings .calendar_table .data {
  height: calc(100vh - 292px - var(--right_line) - var(--right_line));
  overflow-y: scroll;
}
#activity_settings .calendar_table .data .row {
  display: flex;
  padding: 12px 16px 12px 16px;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
}
#activity_settings .calendar_table .data .row .icon {
  font-size: 20px;
}
#activity_settings .calendar_table .data .row div {
  align-items: center;
  padding-left: 8px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#activity_settings .calendar_table .data .row div.center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
}
#activity_settings .calendar_table .data .row .edit {
  display: flex;
  font-size: 20px;
  gap: 8px;
  align-items: center;
}
#activity_settings .calendar_table .data .row .edit .icon {
  color: var(--link);
  cursor: pointer;
}
#activity_settings .calendar_table .data .row:nth-child(even) {
  background: rgba(155, 160, 165, 0.0784313725);
}
#activity_settings .advanced_table {
  overflow: auto;
  height: calc(100vh - 240px - var(--right_line) - var(--right_line));
  width: 100%;
  border: 1px solid #eeeff0;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 1fr;
}
#activity_settings .advanced_table .right_shadow {
  box-shadow: 8px 0 8px 0px rgba(0, 0, 0, 0.05);
}
#activity_settings .advanced_table .fix {
  position: sticky;
  left: 0px;
  z-index: 1;
  background: inherit;
  padding-left: 16px !important;
  min-height: 26px;
  align-items: center;
  display: flex;
}
#activity_settings .advanced_table .left {
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 15px;
}
#activity_settings .advanced_table .right {
  display: grid;
  grid-template-columns: 1fr;
  overflow-x: scroll;
}
#activity_settings .advanced_table .header {
  padding: 0px 16px 0px 0px;
  display: flex;
  padding-right: 31px;
  border-bottom: 2px solid #eeeff0;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 3;
}
#activity_settings .advanced_table .header div {
  padding: 12px 0px 12px 0px;
  text-wrap: balance;
  text-align: center;
}
#activity_settings .advanced_table .header .row {
  display: flex;
  padding: 16px 16px 16px 0px;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
}
#activity_settings .advanced_table .row {
  display: flex;
  padding: 0px 16px 0px 0px;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
}
#activity_settings .advanced_table .row div {
  padding: 12px 0px 12px 0px;
  text-wrap: balance;
  text-align: center;
}
#activity_settings .advanced_table .row div jnct-checkbox-input,
#activity_settings .advanced_table .row div jnct-datetime-input,
#activity_settings .advanced_table .row div jnct-preset-badge {
  display: inline-block;
}
#activity_settings .advanced_table .row .edit {
  display: flex;
  font-size: 20px;
  gap: 8px;
  align-items: center;
}
#activity_settings .advanced_table .row .edit .icon {
  color: var(--link);
  cursor: pointer;
}
#activity_settings .advanced_table .row:nth-child(even) {
  background: #f5f6f6;
}
#activity_settings .advanced_table .row:nth-child(odd) {
  background: white;
}
#activity_settings .loader {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
#activity_settings .flex__center {
  display: flex;
  justify-content: center;
}`;

const mode = "open";
import { ConfirmationModal } from '../confirmationModal/component';
import {
  observable,
  RWSViewComponent,
  RWSView,
  ngAttr,
  RWSInject,
  DOMService,
  DOMServiceInstance,
  attr,
} from "@rws-framework/client";
import { uniq } from '../../helpers/utils';
import { Activity, ActivityModel, ShowAnswers, ShowScore } from '../../types/activities'
import ActivitiesService, { ActivitiesServiceInstance } from '../../services/ActivitiesService';

type keys = {
  [key: string]: boolean;
};
interface keyable {
  [key: string]: any;
}

enum GradedState {
  GRADED = 'Graded',
  PRACTICE = 'Practice',
  NOT_GRADEABLE = 'Not Gradeable'
}

@RWSView('jnct-activity-settings', null, { template: rwsTemplate, styles, options: {mode} })
class JunctionActivitySettings extends RWSViewComponent  {
  @ngAttr activities: Array<Record<string, any>>;
  @ngAttr courseId: string;
  @ngAttr defaults: any;
  @attr defaulttime: any = '23:59';
  @ngAttr timezone: string;
  @ngAttr notBlocked: boolean;
  @observable showPresetsList: boolean;
  @observable showBatchUpdate: boolean;
  @observable showDefaults: boolean;
  @observable showColumns: boolean;
  @observable leaveModal: boolean
  @observable defaultsObj: object;
  @observable allActivities: any;
  @observable allLessons: Array<String> = ["All"]
  @observable view: string = "calendar";
  @observable selectedIDs: string[] = [];
  @observable showRows: number = 50;
  @observable showHideColumn: keys = {
    openDate: true,
    dueDate: true,
    closeDate: true,
    preset: true,
    visg: true,
    siga: true,
    amGraded: true,
    submissionsAllowed: true,
    allowStudentsToCheck: true,
    allowStudents: true,
    displayGrades: true,
    type: true,
    totalScore: true,
    max_score: true,
    locked: true,
    confidence: true,
    scramble: true,
    lockBackButton: true,
    timeLimit: true,
    studycenter: true,
    requireComment: true,
    resumeMaxAttempts: true,
  };
  @observable shc: keys = { ...this.showHideColumn };
  @observable selectAll: boolean = false;
  @observable presets: Array<{ _id: string; name: string } & Record<string, any>> = [];
  @observable presetsObj: keyable = {};
  @observable currentSorting: string;
  @observable editDefault: string;
  @observable filters: Object = {};
  @observable buConfig = {
    save: async (payload) => {
      //@ts-ignore
      const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
      //@ts-ignore
      const csrfToken = Cookies.get("csrf_token");
      payload["apply_to"] = this.who;
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(
        APIprefix + "stats/activities/v2/" + this.courseId,
        requestOptions
      );
      const data = await response.json();
      location.reload();
    },
    getPresets: () => {
      return this.presets;
    },
    selected: () => {
      return this.who;
    },
    getDefault: () => {
      return this.editDefault;
    },
    switch: () => {
      this.switchBatchUpdateModal();
    },
    getActivityLockStatus: (activity) => this.activityService.getActivityLockStatus(
      activity,
      this.activities as Activity[],
    ),
    getDefaultTime: () => {
      return this.defaulttime;
    }
  };
  @observable lmConfig = {
    switch: () => {
      this.switchLeaveModal()
    },
    save: () => {
      this.switchView()
      this.cancel()

    }
  }

  shConfig = {
    switch: () => {
      this.switchColumnsModal();
    },
    onClick: (key: string) => {
      this.shc = { ...this.shc, [key]: !this.shc[key] };
    },
    defaultValues: () => {
      return this.shc;
    },
    applyColumns: () => {
      this.showHideColumn = { ...this.shc };
    },
  };
  dfConfig = {
    switch: () => {
      this.switchDefaultsModal();
    },
    getDefaults: () => {
      this.defaultsObj["time"] = this.defaulttime;
      return this.defaultsObj;
    },
    save: async (payload) => {
      //@ts-ignore
      const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
      //@ts-ignore
      const csrfToken = Cookies.get("csrf_token");
      payload["apply_to"] = this.who;
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(
        APIprefix + "stats/save_defaults/" + this.courseId,
        requestOptions
      );
      const data = await response.json();
      location.reload();
    },
  };

  pConfig = {
    switch: () => {
      this.switchPresetListModal();
    },
    save: async (payload: object) => {
      //@ts-ignore
      const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
      //@ts-ignore
      const csrfToken = Cookies.get("csrf_token");
      payload["course_id"] = this.courseId;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(
        APIprefix + "presets/" + this.courseId,
        requestOptions
      );
      const data = await response.json();
      this.getPresets(this.courseId);
    },
    delete: async (presetId: string) => {
      //@ts-ignore
      const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
      //@ts-ignore
      const csrfToken = Cookies.get("csrf_token");
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
      };
      const response = await fetch(
        APIprefix + "presets/" + this.courseId + "/" + presetId,
        requestOptions
      );
      const data = await response.json();
      this.getPresets(this.courseId);
    },
    update: async (presetId: string, payload) => {
      //@ts-ignore
      const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
      //@ts-ignore
      const csrfToken = Cookies.get("csrf_token");
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(
        APIprefix + "presets/" + this.courseId + "/" + presetId,
        requestOptions
      );
      const data = await response.json();
      this.getPresets(this.courseId);
    },
    getPresets: () => {
      return this.presets;
    },
  };
  selectedView = "calendar"
  views = ["Calendar View", "Advanced View"];
  selectedData: keyable[] = [];
  @observable who = [];
  @observable tableValChanges = {};
  clickingAwayBind = this.clickingAway.bind(this);
  activityTypeOptions = [
      'All',
      ...ActivityModel
        .types()
        .filter(t => !['course'].includes(t))
        .map(ActivityModel.displayType)
    ]
  autoManualGradedOptions = ['All', 'Auto', 'Manual', 'Mixed']
  gradedOptions = [GradedState.GRADED, GradedState.PRACTICE, GradedState.NOT_GRADEABLE]
  formatTimeLimit = (valueSeconds: number | undefined) => {
    if (valueSeconds === undefined) return ''
    if (valueSeconds === 0) return 'Not timed'

    return `${Math.round(valueSeconds / 60)} min`;
  }

  formatScoringType = (a: Record<string, any>): string  => {
    // see frontend/app/modules/activity_settings/settings.js, `sscoring_type`
    if (a.course_data_only) return '';
    if (!a.scoring_type) return 'Manual';
    if (a.scoring_type === '--') return '';

    return a.scoring_type
  }

  get availablePresetsOptions() {
    return this.presets.map(a => a.name);
  }

  constructor (
    @RWSInject(DOMService) protected domService: DOMServiceInstance,
    @RWSInject(ActivitiesService) protected activityService: ActivitiesServiceInstance,
  ) {
    super()
  }

  async connectedCallback() {
    super.connectedCallback()
    let activity_settings_df: boolean = window.labelBundle.activity_settings_df
    if (window.localStorage.getItem("activity_settings_df")) {
      activity_settings_df = window.localStorage.getItem("activity_settings_df") == "true"
    }
    if (activity_settings_df) {
      this.filters = {'graded': {'multi': ["Graded", "Practice"]}}
    }

    document.addEventListener('jnct:click:catcher', this.clickingAwayBind, true)
    const ele = (this.$("#calendar_view_scroll") as any)
    if (ele) ele.addEventListener("scroll",this.scrolledBind)
    if (window.localStorage.getItem('activity_settings_fields') == 'true') {
      this.changeView(undefined, "Advanced View")
    }
  }

  disconnectedCallback() {
    document.removeEventListener('jnct:click:catcher', this.clickingAwayBind, true);
  }

  clickingAway(e) {
    e.preventDefault();
    if (this.who.length===0 && Object.keys(this.tableValChanges).length == 0) {
      this.$emit('jnct:navigation:gofromwarningurl', e.detail)
    } else {
      const modal = document.createElement('confirmation-modal') as ConfirmationModal;
      modal.title = 'Leaving page';
      modal.text = 'Your changes will not be saved.';
      modal.button__text = 'Leave';
      modal.button__obj = {action: 'jnct:navigation:gofromwarningurl', detail: e.detail};
      document.body.appendChild(modal);
      //@ts-ignore
      document.documentElement.querySelector('#jnct-navigation').active = '';
      //@ts-ignore
      document.documentElement.querySelector('#jnct-navigation').active_dropdown = '';
      return;
    }
  }

  defaulttimeChanged(oldVal: string, newVal: string) {
    if (newVal && newVal !== "{{vm.global_time}}") {
      this.defaulttime = newVal;
    }
  }

  timezoneChanged(oldVal: string, newVal: string) {
    if (newVal && newVal !== "{{vm.time_zone}}") {
      this.timezone = newVal;
    }
  }

  async getPresets(courseId) {
    //@ts-ignore
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    //@ts-ignore
    const csrfToken = Cookies.get("csrf_token");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    };
    const response = await fetch(
      APIprefix + "presets/" + courseId,
      requestOptions
    );
    this.presets = await response.json();
    const prs = {};
    this.presets.forEach((element) => {
      prs[element._id] = {
        name: element.name,
        color: element.color,
      };
    });
    this.presetsObj = prs;
  }

  courseIdChanged(oldVal, newVal) {
    if (newVal != "{{vm.courseId}}") {
      this.getPresets(newVal);
    }
  }

  scrolled = (event) => {
    if (
      event.target.scrollTop /
        (event.target.scrollHeight - event.target.clientHeight) >
      0.9
    ){
      this.showRows += 50;
    }
  }
  scrolledBind = this.scrolled.bind(this);

  switchView = () => {
    if (this.selectedView == "Calendar View") {
      localStorage.setItem("activity_settings_fields", "false");
      const el = this.$("#advanved_view_scroll") as any;
      if (el) el.removeEventListener("scroll", this.scrolledBind, true);
      this.view = "calendar";
      setTimeout(() => {
        const ele = this.$("#calendar_view_scroll") as any;
        if (ele) ele.addEventListener("scroll", this.scrolledBind);
      }, 0);
    }
    if (this.selectedView == "Advanced View") {
      localStorage.setItem("activity_settings_fields", "true");
      const el = this.$("#calendar_view_scroll") as any;
      if (el) el.removeEventListener("scroll", this.scrolledBind, true);

      this.view = "advanced";
      setTimeout(() => {
        const ele = this.$("#advanved_view_scroll") as any;
        if (ele) ele.addEventListener("scroll",this.scrolledBind);
      }, 0);
    }
    this.showRows = 100;
  };

  changeView = (field, value) => {
    this.selectedView = value
    if (Object.keys(this.tableValChanges).length > 0) {
        this.leaveModal = true
    } else {
      this.switchView()
    }
  };

  defaultsChanged(oldVal, NewVal) {
    if (NewVal !== "{{vm.default_resume_attempts}}" && NewVal) {
      this.defaultsObj = typeof NewVal === 'string' ? JSON.parse(NewVal) : NewVal;
    }
  }

  changeTableVal = (name: string, value: string | boolean, activity: Activity) => {
    const words = name.split(":")
    const isDate = ['open_date', 'due_date', 'close_date'].includes(words[0])
    if (value === "" && !isDate) {
      delete this.tableValChanges[words[1]][words[0]]
    } else if (value === '' && isDate) {
      // update to special values for backend
      this.tableValChanges[words[1]] = {
        ...this.tableValChanges[words[1]],
        [words[0]]: words[0] === 'open_date' ? '1970-01-01T00:00:00.000Z' : '2199-01-01T00:00:00.000Z',
        type: words[2],
      }
    } else {
      if ("graded" == words[0]) {
        this.tableValChanges[words[1]] = {
          ...this.tableValChanges[words[1]],
          [words[0]]: value,
          type: words[2],
        };
      }
      else if (words[0] == "suppress_until_available") {
        this.tableValChanges[words[1]] = {
          ...this.tableValChanges[words[1]],
          [words[0]]: value,
          type: words[2],

          // if we enable `suppress_until_available` and the activity does not have an open date
          // we need to apply some default in distant future
          ...((!activity.open_date || activity.open_date < "1971") && value&& {open_date: "2050-01-01T00:00:00.000Z"}),
          // if we disable `suppress_until_available` we should unset `open_date`
          ...(!value && {open_date: ""})
        }
      } else {
        this.tableValChanges[words[1]] = {
          ...this.tableValChanges[words[1]],
          [words[0]]: value,
          type: words[2],
        };
      }
    }

    this.tableValChanges = {...this.tableValChanges}
  }

  cancel() {
    this.tableValChanges = {}
    this.clearSelected()
    this.allActivities = []
    setTimeout(() => {
      this.allActivities = [...this.activities]
    }, 100)
    this.applyFilters(this.filters)
  }

  editOne(objId: any) {
    if (objId.id) {
      this.selectedData = [objId]
      this.selectAll = false
      this.editDefault = objId

      if (objId.type == "custom") {
        this.selectedIDs = ["_" + objId.id]

        this.who = [
          {
            _id: "_" + objId.id,
            type: objId.type,
          } as any,
        ];
      } else {
        this.selectedIDs = [objId.id]

        this.who = [
          {
            type: objId.type,
            _id: objId.id,
          } as any
        ]
      }
    }
    this.switchBatchUpdateModal()
  }

  clearSelected() {
    this.selectedData = [];
    this.selectedIDs = [];
    this.who = [];
    this.selectAll = false
  }

  hasDateInput = {
    open: (activity: Record<string, any>) => {
      return ["assessment", "board", "lesson", "page"].includes(activity.type);
    },
    close: (activity: Record<string, any>) => {
      return ["assessment", "board"].includes(activity.type);
    },
    due: (activity: Record<string, any>) => {
      return [
        "assessment",
        "board",
        "comments",
        "course",
        "e_reader",
        "flashcards",
        "html",
        "iframe",
        "lesson",
        "lti",
        "page",
        "pdf",
        "picture",
        "project",
        "video",
      ].includes(activity.type);
    },
  };

  /**
   * This function formats the displayed `show_score` field in respect to `show_responses` value.
   * The behavior is described via a table:
   *
   * | Allow Students to Review Submitted Assessments (show_responses) | Display Grades (show_score)       | Display Grades (table) |
   * | --------------------------------------------------------------- | --------------------------------- | ---------------------- |
   * | Never                                                           | Immediately (yes)                 | Yes                    |
   * | Never                                                           | When responses are available (no) | No                     |
   * | After Due Date                                                  | Immediately (yes)                 | Yes                    |
   * | After Due Date                                                  | When responses are available (no) | After Due Date         |
   * | After Close Date                                                | Immediately (yes)                 | Yes                    |
   * | After Close Date                                                | When responses are available (no) | After Close Date       |
   * | After Max Attempts                                              | Immediately (yes)                 | Yes                    |
   * | After Max Attempts                                              | When responses are available (no) | After Max Attempts     |
   * | Immediately                                                     | Immediately (yes)                 | Yes                    |
   * | Immediately                                                     | When responses are available (no) | Yes                    |
   */
  displayedGradesValue(a: Activity): string {
    if (a.show_score === undefined || a.show_responses === undefined) return 'No';

    if (a.show_score === ShowScore.IMMEDIATELY) return 'Yes';

    // a.show_score is ShowScore.WITH_RESPONSES

    if (a.show_responses === ShowAnswers.IMMEDIATELY) return 'Yes';
    if (a.show_responses === ShowAnswers.NEVER) return 'No';

    return a.show_responses
  }

  toggleAll = (name, value) => {
    if (!value) {
      this.clearSelected();
    }
    else{
      this.selectAll = value;
      const who: any = [];
      this.selectedData = [...this.allActivities]
      this.selectedIDs = [];
      for (const obj of this.selectedData) {
        if (obj?.id) {
          if (obj.type == "custom") {
            obj.id = "_" + obj.id;
          }
          this.selectedIDs.push(obj.id);
          who.push({
            _id: obj.id,
            type: obj.type,
          });
        }
      }
      this.who = who;
    }

  };

  selectActivityClick = (id: string, value: boolean, event: MouseEvent) => {
    if (!event.shiftKey) return this.addId(id, value);

    document.getSelection().removeAllRanges();

    const mostRecentSelectedId = this.selectedIDs[this.selectedIDs.length - 1];
    const mostRecentSelectedIndex =
      mostRecentSelectedId &&
      this.activities.findIndex(
        (a) =>
          (a.type === "custom" ? `_${a.id}` : a.id) === mostRecentSelectedId
      );
    const selectedIndex = this.activities.findIndex((a) => a.id === id);

    if (mostRecentSelectedId === undefined || mostRecentSelectedIndex === -1 || selectedIndex === -1) return;

    const rangeStart = Math.min(mostRecentSelectedIndex, selectedIndex);
    const rangeEnd = Math.max(mostRecentSelectedIndex, selectedIndex);

    const activitiesToSelect = this.activities.slice(rangeStart, rangeEnd + 1)

    activitiesToSelect.forEach(a => {
      this.addId(a.id, true);
    })
  }

  addId = (id: string, value: boolean) => {
    const who: any = [];
    const asses = this.activities.filter((ob) => ob.id == id)[0];
    if (this.selectedData.includes(asses) && !value) {
      this.selectedData.splice(this.selectedData.indexOf(asses), 1);
    } else if (value && !this.selectedData.includes(asses)) {
      this.selectedData.push(asses);
    }
    this.selectedIDs = [];
    for (const obj of this.selectedData) {
      if (obj?.id) {
        if (obj.type == "custom") {
          obj.id = "_" + obj.id;
        }
        this.selectedIDs.push(obj.id);
        who.push({
          _id: obj.id,
          type: obj.type,
        });
      }
    }
    this.who = who;
  };
  search = (value: any) => {
    this.changeFilters("text", "all", value)
  };
  async saveTableChanges() {
    //@ts-ignore
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    //@ts-ignore
    const csrfToken = Cookies.get("csrf_token");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({ changes: this.tableValChanges }),
    };
    const response = await fetch(
      APIprefix + "stats/activities/v2/" + this.courseId,
      requestOptions
    );
    const data = await response.json();
    location.reload();
  }
  countPresets(){
    if(this.activities && this.presets){
      for (const value of this.presets){
        value["appearances"] = this.activities.filter((obj) => obj.preset_id === value._id).length;
      }
    }
  }

  activityLockStatus(activity: Activity): 'locked' | 'unlocked' | 'locked-disabled' | 'none' {
    return this.activityService.getActivityLockStatus(
      activity,
      this.activities as Activity[],
      (a, ancestors) => {
        const localChange = this.tableValChanges[a.id]?.suppress_until_available !== undefined;
        const currentActivityValue = this.tableValChanges[a.id]?.suppress_until_available ?? (a.suppress_until_available || a.open_date_in_future);

        if (!ancestors) return { isSuppressed: currentActivityValue, localChange };

        /**
         * If lesson or page is locked, backend automatically sets `suppress_until_available` to its children.
         * When user manually unsets the checkbox of the parent, we must ignore the `suppress_until_available` of the child.
         *
         * For more details see ActivitiesService.getActivityLockStatus
         */
        const wasAnyAncestorUnsetExplicitly = [ancestors.lesson, ancestors.page, ancestors.project]
          .filter(Boolean)
          .some(a => this.tableValChanges[a.id]?.suppress_until_available === false && a.suppress_until_available === true);

        const wasSetExplicitly = this.tableValChanges[a.id]?.suppress_until_available === true;

        if (wasAnyAncestorUnsetExplicitly) return { isSuppressed: wasSetExplicitly, localChange: true };

        return { isSuppressed: currentActivityValue, localChange };
      }
    );
  }

  activitiesChanged(oldVal: any, newVal: any) {
    // TODO 2nd condition is always true so why not use JSON.stringify(this.allActivities||"") instead?
    if (newVal && newVal !== "{{vm.allActivities}}") {
      const activities = typeof newVal === "string" ? JSON.parse(newVal) : newVal;
      for (const activity of activities) {
        if (this.presetsObj && activity.preset_id) {
          activity["preset_name"] =
            this.presetsObj[activity.preset_id]?.name || "";
        }
      }

      this.allLessons = uniq(
        activities
          .map((a) => a.lessonName)
          // lessonNames contain HTML tags which we want to drop
          .filter(Boolean)
          .map((name) => this.domService.sanitizeHTML(name, []))
      );
      this.allActivities = activities;
      this.activities = activities;

      if (this.filters && Object.keys(this.filters).length) {
        this.applyFilters(this.filters)
      }
    }
  }

  changeSorting = (type: string, column: string) => {
    const x = [...this.allActivities];
    if (type === "descending") {
      x.sort((a, b) =>
        (a[column] || "") < (b[column] || "")
          ? 1
          : (b[column] || "") < (a[column] || "")
          ? -1
          : 0
      );
    } else {
      x.sort((a, b) =>
        (a[column] || "") > (b[column] || "")
          ? 1
          : (b[column] || "") > (a[column] || "")
          ? -1
          : 0
      );
    }
    this.allActivities = [];
    setTimeout(() => {
      this.allActivities = [...x];
    }, 100);


    this.currentSorting = column + type;
  };

  applyFilters(val){
    if (this.activities?.length > 0) {
      let x = [...this.activities];

      for (const [key, value] of Object.entries(val)) {
        if (value["text"] && key != "all") {
          x = this.filterByText(key, value['text'], x)
        }
        if (value["start"] && key != "all")

          x = x.filter(
            (obj) =>
              obj[key] > value["start"] || this.selectedIDs.includes(obj.id)
          );
        if (value["end"] && key != "all")
          x = x.filter(
            (obj) =>
              obj[key] < value["end"] || this.selectedIDs.includes(obj.id)
          );
        if (value['multi'] && Array.isArray(value['multi']))
          x = this.filterByMultiSelect(key, value['multi'], x);
      }
      if(val["all"]){
        {
          const parts = val["all"]["text"].toLowerCase().split(" ");
          for (const el of parts) {
            x = x.filter((a) => {
              var negate = el.startsWith("-"),
                match = el;
              if (el.startsWith("-")) {
                match = match.substring(1);
              }
              var is_match =
                a.ttype.toLowerCase().includes(match) ||
                (a.scoring_type || "").toLowerCase() == match ||
                (a.report_title || "").toLowerCase().includes(match) ||
                (a.ttime + "").toLowerCase().includes(match) ||
                (a.submission_attempts + "").includes(match) ||
                (a.due_date && a.due_date.includes(match)) ||
                (a.due_time && a.due_time.includes(match)) ||
                (a.open_date && a.open_date.includes(match)) ||
                (a.open_time && a.open_time.includes(match)) ||
                (a.close_date && a.close_date.includes(match)) ||
                (a.close_time && a.close_time.includes(match)) ||
                (a.resp_avail + "").toLowerCase().includes(match) ||
                (match == "selected" && a.selected) ||
                (match == "locked" && a.suppress_until_available) ||
                (match == "graded" && a.graded) ||
                (a.preset_name || "").toLowerCase().includes(match);
              return negate ? !is_match : is_match;
            });
          }
        }
      }
      this.allActivities = []
      this.showRows = 50
      setTimeout(() => {
        this.allActivities = [...x];
      }, 100);
    }
  }

  filterByText(column: string, value: string, activities: Array<Record<string, any>>): Array<Record<string, any>> {
    switch(column) {
      case 'scoring_type': {
        return this.filterByScoringType(value, activities)
      }

      default: {
        return activities.filter((obj) =>
          (obj[column] || "").toLowerCase().includes(
            value.toLowerCase()
          )|| this.selectedIDs.includes(obj.id)
        );
      }
    }
  }

  filterByScoringType(type: string, activities: Array<Record<string, any>>): Array<Record<string, any>> {
    return activities.filter(a => this.formatScoringType(a) === type)
  }

  filterByMultiSelect(column: string, value: string[], activities: Array<Record<string, any>>): Array<Record<string, any>> {
    switch(column) {
      case 'graded': {
        return this.filterByGraded(value as GradedState[], activities);
      }
      case 'report_title': {
        return this.filterByLessonName(value, activities);
      }
      default: {
        return activities.filter(
          (obj) =>
            value.some((option) => obj[column] === option) ||
            this.selectedIDs.includes(obj.id)
        );
      }
    }
  }

  filterByGraded(states: GradedState[], activities: Array<Record<string, any>>): Array<Record<string, any>> {
    const allowGraded = states.includes(GradedState.GRADED);
    const allowNotGradeable = states.includes(GradedState.NOT_GRADEABLE);
    const allowPractice = states.includes(GradedState.PRACTICE);

    return activities.filter(a => {
      if (allowGraded && a.graded) return true;
      if (allowNotGradeable && a.course_data_only) return true;
      if (allowPractice && !a.graded && !a.course_data_only) return true;

      return false;
    })
  }

  filterByLessonName(lessonNames: string[], activities: Array<Record<string, any>>): Array<Record<string, any>> {
    if (lessonNames.length === 0) return activities;

    const lessonIds = this.activities
      .filter(
        (a) =>
          a.type === "lesson" &&
          // we check based on `.includes` since lessonName/report_title
          // may contains HTML tags which could have been removed
          lessonNames.some((name) => a.report_title.includes(name))
      )
      .map((a) => a.id);

    return lessonIds.length ? activities.filter(a => a.lesson && lessonIds.includes(a.lesson.id)) : activities
  }

  filtersChanged(oldVal, newVal) {
    this.applyFilters(newVal)
  }

  changeFilters = (
    type: "text" | "start" | "end" | 'multi',
    column: string,
    value: string | string[] | number | undefined
  ) => {
    const { [column]: currentFilterAtColumn, ...restFilters } = this
      .filters as Record<string, Record<string, string>>;

    const { [type]: currentFilterAtColumnOfType, ...restColumn } =
      currentFilterAtColumn || {};
    // `value` of undefined clears filtering at the column
    const nextFilterAtColumn =
      value === undefined ? undefined : { ...restColumn, [type]: value };

    this.filters = nextFilterAtColumn
      ? { ...restFilters, [column]: nextFilterAtColumn }
      : restFilters
  };

  switchPresetListModal() {
    this.countPresets()
    this.showPresetsList = !this.showPresetsList;
  }
  switchBatchUpdateModal() {
    this.showBatchUpdate = !this.showBatchUpdate;
  }
  switchDefaultsModal() {
    this.showDefaults = !this.showDefaults;
  }
  switchColumnsModal() {
    this.showColumns = !this.showColumns;
  }
  switchLeaveModal() {
    this.leaveModal = !this.leaveModal
  }
}

JunctionActivitySettings.defineComponent();

export { JunctionActivitySettings };
