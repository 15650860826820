import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="gradeby">
  ${T.when(x => x.loaded, T.html`
    <section class="column">
      ${T.when(x => x.assessment?.questions.length > 0, T.html`
        <div class="column__top">
          ${T.when(x => x.assessment?.title, T.html`
            <div class="column__title" :innerHTML="${x => x.assessment.title}">
            </div>
          `)}

          <ul class="column__list">
            ${T.repeat(x => x.showGraded ? x.assessment?.questions : x.assessment?.questions.filter(q => !x.student_questions[q.reference].every(s => s.score!== undefined && s.score !== null)), T.html`
              <li
                class="column__item ${(x, c) => x.reference === c.parent.selectedQuestion.reference ? 'active' : ''}"
                id="${x => x.reference}"
                tabindex="0"
                @click="${(x, c) => c.parent.selectQuestion(x.reference)}"
                @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? c.parent.selectQuestion(x.reference) : ''}">
                  ${x => x.title}
                  ${T.when((x, c) => c.parent.student_questions[x.reference].every(s => s.score!== undefined && s.score !== null), T.html`
                    <span class="material-symbols-outlined">check_circle</span>
                  `)}
              </li>
            `)}
          </ul>
        </div>
      `)}

      ${T.when(x => x.allGraded && !x.showGraded, T.html`
        <div class="column__empty">
          <jnct-success-state headerText="Grading Completed" description="">
          </jnct-success-state>
        </div>
      `)}

      ${T.when(x => x.assessment?.buttons, T.html`
        <div class="column__bottom">
          ${T.repeat(x => x.assessment.buttons, T.html`
            <div class="bottom">
              <fluent-switch
                id="${x => x.id}"
                ?checked="${(x, c) => (c.parent.showGraded && x.id === 'graded_switch') ? 'checked' : ''}"
                class="column__switch"
                @click="${x => x.action()}">
              </fluent-switch>
              ${x => x.title}
            </div>
          `)}
        </div>
      `)}
    </section>
  `)}

  <section class="gradeby__main">
    <grading-header
      class="width-100"
      actions="true"
      name="${x => x.name}"
      :save="${x => x.saveHandler}"
      :disabled="${x => x.disabledSave}">
    </grading-header>

    ${T.when(x => !x.loaded, T.html`
      <div class="main__loader">
        <jnct-loading-spinner></jnct-loading-spinner>
      </div>
    `)}

    ${T.when(x => x.loaded && x.allGraded && !x.showGraded, T.html`
      <div class="column__empty">
        <jnct-success-state
          headerText="Grading Completed"
          description="">
        </jnct-success-state>
        <button
          class="button__primary"
          tabindex="0"
          @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? x.goToGradingCenter() : ''}"
          @click="${x => x.goToGradingCenter()}">
          Back to Grading Center
        </button>
      </div>
    `)}

    ${T.when(x => x.loaded && !(x.allGraded && !x.showGraded), T.html`
      <div class="gradeby__main main">
        <div class="main__question" :innerHTML="${x => x.sanitizeHtml(x.selectedQuestion?.stimulus)}">
        </div>

        <div class="main__body body">
          <div class="body__header">
            ${T.when(x => !x.anonymous, T.html`
              <div class="body__first">Student Name</div>
            `)}

            <div class="body__second">Response</div>
            <div class="body__first flex__row">
              Grade  
              <span class="body__first--thin"> (Max ${x => x.selectedQuestion?.max_score} points)</span>
            </div>
          </div>

          <ul class="body__main">
            ${T.repeat(x => x.student_questions[x.selectedQuestion.reference], T.html`
              <li class="data">
                ${T.when((x, c) => !c.parent.anonymous, T.html`
                  <div class="body__first">
                    ${x => x.name}
                  </div>
                `)}

                <div class="body__second">
                  ${T.when((x, c) => x.type === 'plaintext' || x.type === 'longtext' || x.type === 'longtextV2', T.html`
                    <span :innerHTML="${(x, c) => c.parent.sanitizeHtml(x.response?.value)}"></span>
                  `)}

                  ${T.when((x, c) => x.type === 'audio', T.html`
                  <audio
                    controls="controls"
                    src="https://learnositymediaprocessed.s3.amazonaws.com/${x => x.response?.location}.mp3">
                  </audio>
                  `)}

                  ${T.when((x, c) => x.type === 'video', T.html`
                    <video
                      controls="controls"
                      style="max-height: 200px"
                      src="https://learnositymediaprocessed.s3.amazonaws.com/${x => x.response?.value.assetProcessedPath}">
                    </video>
                  `)}

                  ${T.when((x, c) => x.type === 'fileupload' , T.html`
                    <table>
                      ${T.repeat((x, c) => x.response.value, T.html`
                      <tr>
                        <td>
                          <a
                            href="https://learnositymediaprocessed.s3.amazonaws.com/${ x=> x.assetPath}"
                            target="_blank">
                            ${x => x.name}
                          </a>
                        </td>
                        <td>
                          ${T.when((x, c) => ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'].includes(x.extension), T.html`
                            <img
                              height="100"
                              alt="${x => x.name}"
                              src="https://learnositymediaprocessed.s3.amazonaws.com/${x => x.assetPath}" />
                          `)}

                          ${T.when((x, c) => ['pdf', 'xlsx', 'ppt', 'pptx', 'txt'].includes(x.extension), T.html`
                            <iframe src="https://learnositymediaprocessed.s3.amazonaws.com/${x => x.assetPath}">
                            </iframe>
                          `)}

                          ${T.when(x => x.extension === 'mp4',  T.html`
                            <video
                              controls="controls"
                              style="max-height: 200px"
                              src="https://learnositymediaprocessed.s3.amazonaws.com/${x => x.assetPath}">
                            </video>
                          `)}
                          ${T.when(x => x.extension === 'mp3',  T.html`
                            <audio
                              controls="controls"
                              src="https://learnositymediaprocessed.s3.amazonaws.com/${x => x.assetPath}">
                            </audio>
                          `)}
                        </td>
                      </tr>
                      `)}
                    </table>
                  `)}
                </div>

                <grading-input
                  name="input-${x => x.id}-${(x, c) => c.parent.selectedQuestion?.reference}" 
                  :point="${x => x.score ?? null}"
                  :maxPoints="${(x, c) => c.parent.selectedQuestion?.max_score}"
                  :studentid="${x => x.id}"
                  :changePoint="${(x, c) => c.parent.changePoint}">
                </grading-input>

                ${T.when((x, c) => c.parent.selectedQuestion?.rubric, T.html`
                  <div
                    class="body__comment"
                    @click="${(x, c) => c.parent.openComment(x)}"
                    @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? c.parent.openComment(x) : ''}"
                    tabindex="0">
                    <span class="material-symbols-outlined">comment</span>
                  </div>
                `)}

                ${T.when((x, c) => !c.parent.selectedQuestion?.rubric, T.html`
                  <div
                    class="body__empty">
                  </div>
                `)}
              </li>
            `)}
          </ul>
        </div>
      </div>
    `)}
  </section>
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

.gradeby {
  display: flex;
  height: 100%;
  align-items: flex-start;
  flex: 1 0 0;
}
.gradeby .height {
  height: calc(100% - 16px);
}
.gradeby__main {
  flex: 1 0 0;
  align-self: stretch;
  margin-top: -10px;
  max-height: calc(100vh - 26px);
  overflow: auto;
}
.gradeby .main {
  display: flex;
  padding: 0px 22px 0px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.gradeby .main__question {
  align-self: stretch;
  color: var(--neutral-70);
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
}
.gradeby .main__body {
  display: flex;
  padding-top: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}
.gradeby .main__body .body__header {
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 58px;
  align-self: stretch;
  background: var(--neutral-10);
  color: var(--neutral-90);
  font-size: 1em;
  font-weight: 600;
  line-height: 170%;
}
.gradeby .main__body .body__first {
  display: flex;
  width: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  padding: 8px;
}
.gradeby .main__body .body__first.flex__row {
  flex-direction: row;
  justify-content: flex-start;
  gap: 2px;
}
.gradeby .main__body .body__first--thin {
  font-weight: 400;
}
.gradeby .main__body .body__second {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 10px;
  padding: 8px;
}
.gradeby .main__body .body__main {
  width: 100%;
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.gradeby .main__body .body__main .data {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 58px;
  align-self: stretch;
}
.gradeby .main__body .body__main .data:nth-child(even) {
  background-color: white;
}
.gradeby .main__body .body__main .data:nth-child(odd) {
  background-color: var(--neutral-8);
}
.gradeby .main__body .body__comment {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid var(--neutral-30);
  background: white;
  cursor: pointer;
}
.gradeby .main__body .body__comment:hover {
  background: #F9F9F9;
}
.gradeby .main__body .body__comment:active {
  border: 1px solid var(--neutral-90);
}
.gradeby .main__body .body__comment .material-symbols-outlined {
  font-size: 1.14286em;
  line-height: 1.14286em;
}
.gradeby .main__body .body__empty {
  width: 40px;
}

.column {
  display: flex;
  width: 230px;
  height: calc(100vh - 16px);
  margin: -10px 0px -10px -10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px 0px 0px 12px;
  border-right: 1px solid var(--neutral-20);
  background: var(--neutral-10);
  box-shadow: 4px 0px 16px 0px rgba(0, 0, 0, 0.05);
}
.column__top {
  width: 100%;
  background-color: var(--primary-8);
}
.column__title {
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  font-size: 1em;
  font-weight: 600;
  line-height: 170%;
}
.column__list {
  display: flex;
  margin: 0px;
  padding: 0px 16px 0px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  list-style: none;
}
.column__item {
  display: flex;
  padding: 12px 12px 12px 32px;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 0px 12px 12px 0px;
  color: var(--neutral-90);
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
  cursor: pointer;
}
.column__item.active {
  font-weight: 600;
  background: var(--primary-36);
}
.column__item .material-symbols-outlined {
  color: #23771C;
  font-size: 1.14286em;
}
.column__switch {
  width: 48px;
  height: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.column__bottom .bottom {
  display: flex;
  padding: 0px 8px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  color: var(--neutral-70);
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}

.column__empty {
  display: flex;
  padding: 24px 30px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.width-100 {
  width: 100%;
}`;


import { 
  RWSView, RWSViewComponent, 
  DOMServiceInstance, DOMService, 
  RWSInject, observable, attr
} from '@rws-framework/client';

import ActivitiesService, {ActivitiesServiceInstance} from '../../../services/ActivitiesService';
import {Activity, ActivityStudentScore} from "../../../types/activities";

import { GradingCenterLearnosityFeedbackModal } from '../learnosityFeedbackModal/component';
import { GradeByData } from './data';
//TODO: add Storage.get for user id and user name

type DataType = {
  student_name: string,
  response: string,
  points: any,
};

type QuestionType = {
  id: string,
  title: string,
  graded: boolean,
  text: string,
  data: DataType[],
  maxPoints: number,
  reference: any
};

type ButtonType = {
  title: string,
  action: Function,
};

@RWSView('grade-by-question', null, { template: rwsTemplate, styles })
class GradeByQuestion extends RWSViewComponent  {
  @observable assessment: any;
  @observable name: string = 'Save Changes';
  @observable loaded: boolean = false;
  @observable anonymous: boolean = false;
  @observable showGraded: boolean = false;
  @observable allGraded: boolean = false;
  @observable selectedQuestion: QuestionType;
  @observable disabledSave: boolean = true;
  @observable updateInProgress: boolean = false;
  @observable course: any;
  @observable activity: Activity;
  @observable load_sessions: any;//array
  @observable items: any;
  @observable _items: any;  // just for nonautomarkables
  @observable students: any = {};
  @observable questions: any;//arrat 
  @observable student_questions: any = {};
  @observable all_questions: any;//arrat
  @observable all_student_questions: any = {};
  @observable max_scores: any = {};
  @observable nonautomarkable: string[] = ['video', 'plaintext', 'longtext', 'longtextV2', 'audio', 'fileupload'];
  @observable question_types = GradeByData.question_types;

  constructor (
    @RWSInject(ActivitiesService) protected activityService: ActivitiesServiceInstance,
    @RWSInject(DOMService) protected domService: DOMServiceInstance
  ) {
    super()
  };

  getRoster() {
    return new Promise((resolve) => {
      this.$emit('jnct:dashboardService:getRoster', (data: any) => {
        resolve(data)
      });
    })
  };

  getCourse() {
    return new Promise((resolve) => {
      this.$emit('jnct:dashboardService:getCourse', (data: any) => {
        resolve(data)
      });
    })
  };

  getAverage() {
    return new Promise((resolve) => {
      this.$emit('jnct:dashboardService:getAverage', (data: any) => {
        resolve(data)
      })
    })
  };

  async connectedCallback() {
    super.connectedCallback();

    this.changePoint = this.changePoint.bind(this);

    this.course = await this.getCourse() as any;
    const roster = await this.getRoster() as any[];
    const average = await this.getAverage() as any;
    const activity_student_score = average.activity_student_score;

    this.activity = average.activity_manager.filter((activity: Activity) => activity.id === this.course.componentId)[0];

    //learnosity sessions for each student
    const sessions = {};

    roster.forEach(student => {
      // LTI, board, custom (with or without a lesson) uses the component_id (in the id field)
      let aid = this.activity.activity_id || this.activity.id;

      if (activity_student_score[aid][student._id]) {
        sessions[student._id] = activity_student_score[aid][student._id];
      }
    });

    roster.forEach(student => {
      if (sessions[student._id]) {
        this.students[student._id] = {
          name: student.lastname + ", " + student.firstname,
          session: sessions[student._id]
        }
      }
    })

    const questions = {};
    const all_questions = {};
    const load_sessions = {};
    this.items = this.activity.items || [];
    // Iterate over each student's session responses;
    Object.keys(this.students).forEach(student_id => {
      (this.students[student_id].session.responses || []).forEach(response => {
        if (response.max_score) {
          // Keep track of all questions and items referenced in responses
          all_questions[response.question_reference] = true;
          if (!this.items.includes(response.item_reference)) {
            this.items.push(response.item_reference);
          }
          
          // found all the nonautomarkable that we care about
          if (this.nonautomarkable.includes(response.question_type)) {
            questions[response.question_reference] = true;
            if (!this._items[response.item_reference]) {
              this._items[response.item_reference] = [];
            }
            // Ensure non-automarkable questions are uniquely associated with items
            if (!this._items[response.item_reference].includes(response.question_reference)) {
              this._items[response.item_reference].push(response.question_reference);
            } 
          }
          
          // Keep track of session IDs that need to be loaded
          load_sessions[this.students[student_id].session.session_id] = true;
        }
      });
    });

    this.assessment = {
      title: this.activity.report_title,
      questions: [],
      buttons: [
        {
          id: 'anonymous_switch',
          title: 'Anonymous Grading',
          action: this.anonymousGradingHandler,
        },
        {
          id: 'graded_switch',
          title: 'Show graded',
          action: this.showGradedHandler,
        },
      ]
    };

    // Extract keys to arrays for further processing
    this.questions = Object.keys(questions);
    this.all_questions = Object.keys(all_questions);
    this.load_sessions = Object.keys(load_sessions);

    if (this.all_questions.length) {
      this.init();
    }

    document.documentElement.addEventListener('jnct:learnosity:givemax', this.maxHandler, true)
  };

  init() {
    return new Promise((resolve, reject) => {
      const payload = {
        url: 'sessions/responses',
        activity_id: [],
        user_id: [],
        limit: 50, // extremely important as otherwise we don't recurse and get all the sessions
        session_id: this.load_sessions.length ? this.load_sessions : ['blah'],
        include_response_scores: true
      };
  
      //@ts-ignore
      const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
      //@ts-ignore
      const csrfToken = Cookies.get('csrf_token'); 
      console.log('csrfToken',csrfToken)
      Promise.all([
        fetch(APIprefix + 'grade/' + this.course._id, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrfToken,
          },
          body: JSON.stringify(payload)
        }),
        fetch(APIprefix + 'regrade/get', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrfToken 
          },
          body: JSON.stringify({ item_references: this.items })
        })
      ])
      .then(responses => Promise.all(responses.map(response => response.json())))
      .then(results => {
        const sessions = results[0];
        const res = results[1];

        sessions.forEach(session => {
          if (this.students[session.user_id]) {
            Object.assign(this.students[session.user_id].session, session);
            this.students[session.user_id].feedback = session.feedback;
          }
        });

        this.all_questions.forEach(qref => {
          this.all_student_questions[qref] = [];
          Object.keys(this.students).forEach(student_id => {
            var f = this.students[student_id].feedback;
            (this.students[student_id].session.responses||[]).forEach(response => {
              if (response.question_reference === qref && response.max_score) {
                this.all_student_questions[qref].push({
                  'response_id': response.response_id,
                  'name': this.students[student_id].name,
                  'id': this.students[student_id].session.user_id,
                  'session_id': this.students[student_id].session.session_id,
                  'feedback_session_id': f && f.feedback_session_id,
                  'score': response.score,
                  'max_score': response.max_score,
                  'type': response.question_type,
                  'response': response.response
                });
              }
            })
          })
        })
  
        const _questions = [];
        this.items.forEach(item => {
          res[item].forEach(question => {
            const v = question.data.validation;
            let max_score = 1;
            if (v.max_score) {
                max_score = v.max_score;
            } else if (v.scoring_type === "exactMatch" || v.scoring_type === "partialMatchV2" || v.scoring_type === "partialMatchElementV2") {
                max_score = v.valid_response.score;
            } else if (v.scoring_type === "partialMatch" && 'value' in v.valid_response && v.valid_response.value.length) {
                max_score = v.valid_response.score * v.valid_response.value.length;
            } else if (v.scoring_type === "partialMatchElement" && 'value' in v.valid_response && v.valid_response.value.length) {
                max_score = v.valid_response.score * v.valid_response.value.flat().length;
            }

            _questions.push({
              'stimulus': question.data.stimulus,
              'max_score': max_score,
              'rubric': question.data.metadata && question.data.metadata.rubric_reference,
              'reference': question.reference,
              'title': this.question_types[question.type] || 'Other',
            });
            this.max_scores[question.reference] = max_score;
          });
        });
  
        if (this.questions.length) {
          this.questions.forEach(question => {
            this.student_questions[question] = [];
              Object.keys(this.students).forEach(student_id => {
                const f = this.students[student_id].feedback;
                (this.students[student_id].session.responses || []).forEach(response => {
                  if (this.nonautomarkable.includes(response.question_type) &&
                      response.question_reference === question &&
                      response.max_score
                  ) {
                    this.student_questions[question].push({
                      'response_id': response.response_id,
                      'name': this.students[student_id].name,
                      'id': this.students[student_id].session.user_id,
                      'session_id': this.students[student_id].session.session_id,
                      'feedback_session_id': f && f.feedback_session_id,
                      'score': response.score,
                      'type': response.question_type,
                      'response': response.response
                      });
                    }
                });
              });
            });
            Object.keys(this.student_questions).forEach(question => {
              this.student_questions[question].sort((a, b) => a.name.localeCompare(b.name));
            });
            
            this.items.forEach(item_ref => {
              if (!this._items[item_ref]) return;
              this._items[item_ref].forEach(qref => {
                _questions.forEach(q => {
                  if (q.reference === qref) {
                    this.assessment.questions.push(q);
                    this.student_questions[qref].forEach(student => {
                      const f = this.students[student.id].feedback;
                      student.feedback = f && f.feedback[this.assessment.questions.length - 1];
                    });
                  }
                });
              });
            });
  
            this.assessment.student_questions = this.student_questions;
          };

          this.selectedQuestion = this.assessment.questions.find(q => !this.student_questions[q.reference].every(s => s.score !== undefined && s.score !== null));
          if (!this.selectedQuestion) {
            this.selectedQuestion = this.assessment.questions[0];
            this.showGraded = true;
            this.allGraded = true;
          };

          this.loaded = true;
          //@ts-ignore
          resolve();
        })
        .catch(error => {
            reject(error);
      });
    });
  };

  goToGradingCenter() {
    this.$emit('jnct:navigation:gotogradingcenter');
  };

  anonymousGrading() {
    this.anonymous = !this.anonymous;
    if (!this.anonymous) {
      this.assessment.questions = this.assessment.questions.map(question => {
        question.data.sort((a, b) => a.student_name.localeCompare(b.student_name));
        return question;
      });
    };
    if (this.anonymous) {
      this.assessment.questions = this.assessment.questions.map(question => {
        question.data.sort((a, b) => b.student_name.localeCompare(a.student_name));
        return question;
      });
    }
  };

  openComment(student: any) {
    const modal = document.createElement('grading-center-learnosity-feedback-modal') as GradingCenterLearnosityFeedbackModal;
    modal.question = this.selectedQuestion;
    modal.student = student;
    modal.index = this.assessment.questions.findIndex(el => el.reference === this.selectedQuestion.reference)
    document.body.appendChild(modal);
  }

  anonymousGradingHandler = this.anonymousGrading.bind(this)

  toggleGraded() {
    this.showGraded = !this.showGraded;
  };

  showGradedHandler = this.toggleGraded.bind(this)

  selectQuestion(reference: string) {
    this.selectedQuestion = this.assessment.questions.find(el => el.reference === reference);
  };

  saveHandler = this.save.bind(this);

  maxHandler = this.save.bind(this, true);

  save(max: boolean) {
    if (this.all_questions.length) {
      this.disabledSave = true;
      this.name = 'Saving...';
      this.questions = max? this.all_student_questions : this.assessment.student_questions;
      this.post_init(max);
    }
  };

  async post_init(max: boolean) {
    //@ts-ignore
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    //@ts-ignore
    const csrfToken = Cookies.get('csrf_token'); 

    const entries = {};

    Object.keys(this.questions).forEach(question_reference => {
      const question = this.questions[question_reference];
      question.forEach(response => {
        if (!entries[response.session_id]) {
          entries[response.session_id] = {
            user_id: response.id, 
            session_id: response.session_id,
            activity_id: this.activity.activity_id,
            responses: []
          }
        };

        if (response.score || response.score === 0 || max) {
          const max_score = this.max_scores[question_reference] || 1;

          entries[response.session_id].responses.push({
            response_id: response.response_id, 
            attempted: true, 
            score: max? max_score : response.score,
            feedback: response.feedback,
            max_score: max_score,
          });
        }
      })
    });

    const payload = {
      'courseId': this.course._id, 
      'componentId': this.course.componentId,
      'sessions': Object.keys(entries).map(function(e) {
        return entries[e]
      }).filter(function(e) {
        return e.responses.length
      })
    };

    this.updateInProgress = true;
    try {
      const response = await fetch(APIprefix + "regrade/update", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify(payload)
      });
    
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      this.recurse(data, payload, 10);
    } catch (error) {
      const message = 'Something might have gone wrong. Please check grades after a minute by clicking `Reload`.';
      this.name = 'Save Changes';
      this.$emit('jnct:notifications:info', message);
    }
  };

  async recurse(res: any, payload: any, attempts: number) {
    //@ts-ignore
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    //@ts-ignore
    const csrfToken = Cookies.get('csrf_token'); 

    try {
      const jobResponse = await fetch(APIprefix + "assessment/job_poller", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify(res.jobs)
      });
    
      if (!jobResponse.ok) {
        throw new Error('Network response was not ok');
      }
    
      const update = {
        'user_ids': res.user_ids,
        'component_ids': res.component_ids,
      };
      const job = await jobResponse.json();

      if (job.every(j => j.status === "completed")) {
        const updateResponse = await fetch(APIprefix + "assessment/update/" + this.course._id, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
          },
          body: JSON.stringify(update)
        });
    
        if (!updateResponse.ok) {
          throw new Error('Update response was not ok');
        }
    
        const updated = await updateResponse.json();
        const message = "Assessment re-graded. Please click Reload to see the updated data.";
        this.$emit('jnct:notifications:success', message);
        this.send_messages(payload, updated);
        this.$emit('jnct:dashboardService:flush');
        this.name = 'Save Changes';
      } else if (attempts) {
        setTimeout(() => {
          this.recurse(res, payload, --attempts);
        }, 1000);
      } else {
        const message = "Assessment re-graded. It is taking a little longer for the gradebook to pick these up but we have the data. Please Reload after 5 mins or come back later.";
        this.$emit('jnct:notifications:success', message);
        this.$emit('jnct:dashboardService:flush');
        this.name = 'Save Changes';
        this.send_messages(payload);
      }
    } catch (error) {
      const message = "Assessment re-graded. It is taking a little longer for the gradebook to pick these up but we have the data. Please Reload after 5 mins or come back later.";
      this.name = 'Save Changes';
      this.$emit('jnct:dashboardService:flush');
      this.$emit('jnct:notifications:success', message);
      this.send_messages(payload);
    }
  };

  async send_messages(payload, res?) {
    this.updateInProgress = false;
    var outcome_payload = []
    payload.sessions.forEach(async session => {
      const message = {
        session_id: session.session_id,
        message: "",
        link: this.activity.link,
        title: this.activity.report_title,
        activity_id: session.activity_id,
      };

      this.$emit('jnct:gradingcenter:message', {message: message, courseId: this.course._id, user_id: session.user_id});
      // res can be undefined if we don't come from the right branch
      if (!res) return;
      outcome_payload.push({
        'scores': [
          res.activity_student_score[this.activity.activity_id][session.user_id]
        ],
        'on_behalf_of': session.user_id,
        'component': {
          'id': this.course.componentId,
          'title': this.activity.report_title,
          'activity_id': this.activity.id || session.activity_id,
        }
      })
    })

    try {
      //@ts-ignore
      const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
      //@ts-ignore
      const csrfToken = Cookies.get('csrf_token'); 

      const response = await fetch(`${APIprefix}assessment/outcome/${this.course._id}/${this.course.componentId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify(outcome_payload)
      });
    
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  changePoint(point: string | number, id:string) {
    this.disabledSave = false;
    this.student_questions[this.selectedQuestion.reference].forEach(student => {
      if (student.id === id) {
        student.score = point;
      }

      return student;
    })
  };

  sanitizeHtml(line: string) {
    //added iframe, img, video, audio tags
    const allowedTags = [
      'address', 'article', 'aside', 'footer', 'header',
      'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hgroup',
      'main', 'nav', 'section',
      'blockquote', 'dd', 'div', 'dl', 'dt', 'figcaption', 'figure',
      'hr', 'li', 'main', 'ol', 'p', 'pre', 'ul',
      'a', 'abbr', 'b', 'bdi', 'bdo', 'br', 'cite', 'code', 'data', 'dfn',
      'em', 'i', 'kbd', 'mark', 'q',
      'rb', 'rp', 'rt', 'rtc', 'ruby',
      's', 'samp', 'small', 'span', 'strong', 'sub', 'sup', 'time', 'u', 'var', 'wbr',
      'caption', 'col', 'colgroup', 'table', 'tbody', 'td', 'tfoot', 'th',
      'thead', 'tr', 'img', 'video', 'audio','iframe',
    ];
    if (!line) {
      return;
    }
    return this.domService.sanitizeHTML(line, allowedTags);
  }
}

GradeByQuestion.defineComponent();

export { GradeByQuestion };
