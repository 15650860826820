import {RWSService} from '@rws-framework/client';
import ActivitiesService, {ActivitiesServiceInstance} from "./ActivitiesService";
import EventsService, {EventsServiceInstance} from "./EventsService";
import {RosterRecord} from "../types/users";
import {Activity} from "../types/activities";
import {InstructorCalendarActivity, StudentCalendarActivity} from "../types/calendar";

class CalendarService extends RWSService {
  static _IN_CLIENT: boolean = true;
  dateFormatOptions: Intl.DateTimeFormatOptions = {month: 'short', day: 'numeric'};
  timeFormatOptions: Intl.DateTimeFormatOptions = {hour: 'numeric', minute: '2-digit', hour12: true};
  currentDate: Date = new Date()
  oneWeekFromNow: Date = new Date()
  t: Date = new Date()
  now: string
  ayearout: string

  dateRanges: any = {
    today: 'today',
    next7days: 'next7days',
    laterThan7days: 'laterThan7days',
    other: 'other'
  }

  constructor(
    @ActivitiesService private activitiesService: ActivitiesServiceInstance,
    @EventsService private eventsService: EventsServiceInstance
  ) {
    super();
    this.oneWeekFromNow.setDate(this.currentDate.getDate() + 7)
    this.now = this.t.toISOString()
    this.t.setFullYear(this.t.getFullYear() + 1)
    this.ayearout = this.t.toISOString()
  }

  async getCalendarData(mode: string): Promise<InstructorCalendarActivity[] | StudentCalendarActivity[]> {
    switch (true) {
      case mode.includes('instructor'):
        return await this.getInstructorCalendar();

      case mode.includes('student'):
        return await this.getStudentCalendar();

      default:
        throw new Error(`Invalid course mode: '${mode}'. Expected 'instructor' or 'student'.`);
    }
  }

  async getInstructorCalendar(): Promise<InstructorCalendarActivity[]> {
    const [average, course, roster]: [any, any, RosterRecord[]] = await Promise.all([
      this.eventsService.getAverage(),
      this.eventsService.getCourse(),
      this.eventsService.getRoster()
    ]);

    const roster_ids = roster.map((student: RosterRecord) => student._id)
    const activities = average.activity_manager;
    const score_data = average.activity_student_score;

    const noDueActivities: InstructorCalendarActivity[] = []
    const calendar_data: InstructorCalendarActivity[] = activities.reduce((acc: InstructorCalendarActivity[], activity: Activity) => {
      const dateStr = this.activitiesService.getDueDate(activity)
      const dueDate = new Date(dateStr)
      // if (["project", "comments"].includes(activity.type)) {
      //   console.log(activity)
      // }
      const instructorActivity: InstructorCalendarActivity = {
        id: activity.id,
        courseId: course._id,
        title: this._getActivityTitle(activity, course),
        report_title: activity.report_title,
        type: activity.type,
        link: activity.link + "/" + activity.id + (activity.type == "comments"? "?view=comments" : ""),
        lesson_title: this._getLessonTitle(activity, course),
        graded: activity.graded,
        avg_score: Math.round(this.activitiesService.getAverageGradeInPercentage(activity, score_data)),
        open_date: activity.open_date,
        due_date: dateStr,
        dueCategory: this._categorizeDueDate(dateStr),
        displayDate: null,
        displayTime: null,
        displayOpenDate: null,
        displayOpenTime: null,
        gradedCount: this.activitiesService.getGradedCount(activity, score_data, roster_ids),
        submittedCount: this.activitiesService.getSubmittedCount(activity, score_data, roster_ids),
        rosterCount: roster_ids.length,
        course_data_only: activity.course_data_only,
        suppress_until_available: activity.suppress_until_available,
        open_date_in_future: activity.open_date_in_future
      }

      let validDate = false
      let onlyValidOpenDate = false
      if (this.activitiesService.isValidDate(activity) && dateStr) {
        validDate = true
        instructorActivity.displayDate = new Intl.DateTimeFormat('en-US', this.dateFormatOptions).format(dueDate)
        instructorActivity.displayTime = new Intl.DateTimeFormat('en-US', this.timeFormatOptions).format(dueDate)
      }

      if (activity.open_date && this.now < activity.open_date && activity.open_date < this.ayearout) {
          if (["lesson", "page"].includes(activity.type)) {
            // we want this in the No Date section showing an open date (in case there is no due date, eg validDate==false)
            onlyValidOpenDate = true 
          }
          let openDate = new Date(activity.open_date)
          instructorActivity.displayOpenDate = new Intl.DateTimeFormat('en-US', this.dateFormatOptions).format(openDate)
          instructorActivity.displayOpenTime = new Intl.DateTimeFormat('en-US', this.timeFormatOptions).format(openDate)
      }

      if (validDate) {
        acc.push(instructorActivity)
      } else if (!instructorActivity.course_data_only && instructorActivity.graded || onlyValidOpenDate) {
        noDueActivities.push(instructorActivity)
      }
      return acc
    }, [] as InstructorCalendarActivity[])
      .sort((a: InstructorCalendarActivity, b: InstructorCalendarActivity) => (a.due_date > b.due_date) ? 1 : ((a.due_date === b.due_date) ? 0 : -1));

    return [...calendar_data, ...noDueActivities]
  }

  async getStudentCalendar(): Promise<StudentCalendarActivity[]> {
    const [allActivities, average, user_id, course]: [any, any, string, any] = await Promise.all([
      this.eventsService.getActivityManager(),
      this.eventsService.getStudentAverage(),
      this.eventsService.getMongoId(),
      this.eventsService.getCourse(),
    ]);


    // handling student override dates (1)
    let dues = {}
    average.activity_manager.forEach(x => {
      if (x.student_due) dues[x.id] = x.student_due
    })

    const self_assess = await this.listDoneCheckboxes(course._id)
    const checkedAsDone = self_assess.assessments;
    const activities = allActivities.filter((activity: Activity) => !activity.suppress_until_available || (activity.suppress_until_available && activity.open_date_in_future));
    const score_data = average.activity_student_score;

    let parents;
    const noDueActivities: StudentCalendarActivity[] = []
    const calendar_data: StudentCalendarActivity[] = activities.reduce((acc: StudentCalendarActivity[], activity: Activity) => {

      if (activity.type === "lesson") {
        parents = [activity.id];
      } else if (activity.type === "page") {
        parents = [activity.lesson.id, activity.id];
      }

      // handling student override dates (2)
      if (dues[activity.id]) {
        activity.due_date = dues[activity.id]
      }

      const dateStr = this.activitiesService.getDueDate(activity)
      const dueDate = new Date(dateStr)

      let submissionDate: any = '-';
      if (activity.graded == true && dateStr != null) {
        submissionDate = this.activitiesService.getSubmissionDate(activity, score_data, user_id) || '-';
        if (submissionDate !== '-') {
          submissionDate = new Date(submissionDate);
          const options = {year: 'numeric', month: 'short', day: 'numeric'};
          submissionDate = submissionDate.toLocaleDateString('en-US', options);
        }
      }

      const studentActivity: StudentCalendarActivity = {
        id: activity.id,
        courseId: course._id,
        title: this._getActivityTitle(activity, course),
        report_title: activity.report_title,
        type: activity.type,
        link: activity.link + "/" + activity.id + (activity.type == "comments"? "?view=shared" : ""),
        lesson_title: this._getLessonTitle(activity, course),
        graded: activity.graded,
        submissionDate: submissionDate,
        score: Math.round(this.activitiesService.getGradeInPercentage(activity, score_data, user_id)),
        status: this.activitiesService.getActivityStatus(activity, score_data, user_id),
        open_date: activity.open_date,
        open_date_in_future: activity.open_date_in_future,
        due_date: dateStr,
        done: checkedAsDone.includes(activity.id),
        dueCategory: this._categorizeDueDate(dateStr),
        displayDate: null,
        displayTime: null,
        displayOpenDate: null,
        displayOpenTime: null,
        parents: parents,
        course_data_only: activity.course_data_only
      }

      let validDate = false
      let onlyValidOpenDate = false
      if (this.activitiesService.isValidDate(activity) && dateStr) {
        validDate = true
        studentActivity.displayDate = new Intl.DateTimeFormat('en-US', this.dateFormatOptions).format(dueDate)
        studentActivity.displayTime = new Intl.DateTimeFormat('en-US', this.timeFormatOptions).format(dueDate)
      }

      if (["lesson", "page"].includes(activity.type) && activity.open_date && this.now < activity.open_date && activity.open_date < this.ayearout) {
        // we want this in the No Date section showing an open date (in case there is no due date, validDate==false)
        onlyValidOpenDate = true
        studentActivity.status = "no-pill"
      }

      if (studentActivity.status == "not-open-yet" || onlyValidOpenDate) {
          let openDate = new Date(activity.open_date)
          studentActivity.displayOpenDate = new Intl.DateTimeFormat('en-US', this.dateFormatOptions).format(openDate)
          studentActivity.displayOpenTime = new Intl.DateTimeFormat('en-US', this.timeFormatOptions).format(openDate)
      }

      if (validDate) {
        acc.push(studentActivity)
      } else if (!studentActivity.course_data_only && studentActivity.status !== "practice" || onlyValidOpenDate) {
        noDueActivities.push(studentActivity)
      }
      return acc
    }, [] as StudentCalendarActivity[])
      .sort((a: StudentCalendarActivity, b: StudentCalendarActivity) => (a.due_date > b.due_date) ? 1 : ((a.due_date === b.due_date) ? 0 : -1));

    return [...calendar_data, ...noDueActivities]
  }

  private _getActivityTitle(activity: Activity, course: any): string {
    let title = (activity.lessonName || (activity.lesson?activity.lesson.title:"course")) + ': ' + activity.report_title;
    if (course.no_lesson_title_on_calendar) {
      title = "";
    } else if (activity.type == "lesson") {
      title = activity.lessonName || activity.lesson?.title;
    }
    return title;
  }

  private _getLessonTitle(activity: Activity, course: any): string {
    let lesson_title = (activity.lessonName || (activity.lesson ? activity.lesson.title : "course"));
    if (activity.type == "lesson") {
      lesson_title = activity.lessonName || activity.lesson?.title;
      if (course.no_lesson_title_on_calendar) {
        lesson_title = ""
      }
    }
    return lesson_title
  }

  private _categorizeDueDate(due_date: string): string {
    const dueDateObject = new Date(due_date)
    if (dueDateObject.toDateString() === this.currentDate.toDateString()) {
      return this.dateRanges.today
    }
    if (dueDateObject >= this.currentDate && dueDateObject <= this.oneWeekFromNow) {
      return this.dateRanges.next7days
    }
    if (dueDateObject > this.oneWeekFromNow || !due_date || due_date < '1971' || due_date > '2198') {
      return this.dateRanges.laterThan7days
    }
    return this.dateRanges.other
  };

  getAllChildActivities(activity: StudentCalendarActivity, calendar_data: StudentCalendarActivity[]) {
    return calendar_data.filter(entry => {
      return entry.parents.includes(activity.id) && entry.course_data_only
    }).map(entry => entry.id)
  }

  private async listDoneCheckboxes(courseId: string): Promise<any> {
    try {
      // @ts-ignore
      const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
      const response = await fetch(APIprefix + "user/self_assessments/" + courseId, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
        }
      });

      return await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async updateDoneCheckbox(items: any, remove: boolean, courseId: string): Promise<any> {
    try {
      // @ts-ignore
      const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
      // @ts-ignore
      const csrfToken = Cookies.get('csrf_token');

      const response = await fetch(APIprefix + "user/self_assessments/" + courseId, {
        method: "PUT",
        body: JSON.stringify({course: courseId, items: items, remove: remove}),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        }
      });

      return await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  }

}

export default CalendarService.getSingleton()

export {CalendarService as CalendarServiceInstance}
