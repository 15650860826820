import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`
<div class="modal-content">
  ${T.when(x => x.submiting, T.html`
    <jnct-loading-spinner></jnct-loading-spinner>
  `)}
  ${T.when(x => !x.submiting, T.html`
    <div class="header">
      <span class="title">Submit</span>
      <span class="exit" @click="${x => x.genericConfig.switch()}">Exit</span>
    </div>

    <div class="text">
      Are you sure you want to SUBMIT this attempt on behalf of the student? This cannot be undone by you.
    </div>

    <div class="buttons">
      <button class="button__secondary"  @click="${x=>x.genericConfig.switch()}">
        Cancel
      </button>
      <button class="button__primary" @click="${x => x.professorSubmit()}">
        Submit
      </button>
    </div>
  `)}
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.modal-content {
  width: 360px;
  padding: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.header .title {
  color: var(--neutral-90);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.header .exit {
  color: var(--link);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  cursor: pointer;
}

.text {
  margin-bottom: 48px;
  color: var(--neutral-90);
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}

.buttons {
  display: flex;
  justify-content: space-between;
}`;


import { attr, RWSViewComponent, RWSView, RWSInject, observable } from "@rws-framework/client";

type GenericConfig = {
  switch: () => void,
  courseId: string,
  session: any
}

@RWSView('submit-learnosity-modal', null, { template: rwsTemplate, styles })
class SubmitLearnosityModal extends RWSViewComponent  {
  @observable genericConfig: GenericConfig | null;
  @observable submiting: boolean = false;

  connectedCallback() {
    super.connectedCallback();
    this.$emit('modal:shown');
  }

  professorSubmit() {
    this.submiting = true;
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    //@ts-ignore
    const csrfToken = Cookies.get('csrf_token'); 

    fetch(APIprefix + "assessment/submit", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify(this.genericConfig.session)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(responseObj => {      
      const recurse = (attempts) => {
        fetch(APIprefix + "assessment/job_poller", {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrfToken,
          },
          body: JSON.stringify({'references': [responseObj.job.job_reference]})
        })
        .then(jobResponse => {
          if (!jobResponse.ok) {
              throw new Error('Network response was not ok');
          }
          return jobResponse.json();
        })
        .then(job => {
          if (job[0].status === "completed") {
            const update = {
              'user_ids': [this.genericConfig.session.user_id],
              'component_ids': [this.genericConfig.session.activity_id],
            };
            fetch(APIprefix + "assessment/update/" + this.genericConfig.courseId, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(update)
              })
              .then(updateResponse => {
                if (!updateResponse.ok) {
                  throw new Error('Network response was not ok');
                }
                return updateResponse.json();
              })
              .then(() => {
                this.submiting = false;
                this.$emit('jnct:notifications:info', 'Submitted successfully');
                this.genericConfig.switch();
              })
              .catch(error => {
                const message = "Submitted successfully -- but " + error;
                console.error(error)
                this.submiting = false;

                this.$emit('jnct:notifications:error', message);
                this.genericConfig.switch();
              });
            } else if (attempts) {
              setTimeout(() => {
                recurse(--attempts);
              }, 1000);
            } else {
              this.submiting = false;
              this.$emit('jnct:notifications:info', 'Submitted successfully - still waiting for scoring');
              this.genericConfig.switch();
            }
        })
        .catch(error => {
          console.error(error);
          this.submiting = false;

          this.$emit('jnct:notifications:info', 'Submitted successfully -- but still waiting for scoring; give it a minute and reload');
          this.genericConfig.switch();
        });
      };

      recurse(10);
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
  }
}

SubmitLearnosityModal.defineComponent();

export { SubmitLearnosityModal };
