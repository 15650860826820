
import { ConfirmationModal } from '../components/confirmationModal/component';
import { DateTabularDisplay } from "../components/dateTabularDisplay/component";
import { Icon } from '../components/icon/component';
import { JunctionModalHeader } from "../components/modal/modalHeader/component";
import { JunctionModal } from "../components/modal/component";
import { JunctionTable } from "../components/table/component";
import { JunctionTableDropdown } from '../components/table/tableDropdown/component';

import { JunctionLoader } from '../components/jnct-loader/component';

import { JunctionDateTimeInput } from "../components/formComponents/datetimeInput/component";
import { JunctionCheckboxInput } from "../components/formComponents/checkboxInput/component";
import { JunctionNumberInput } from "../components/formComponents/numberInput/component";
import { JunctionSelectColorInput } from "../components/formComponents/selectColortInput/component";
import { JunctionSelectInput } from "../components/formComponents/selectInput/component";
import { JunctionStringInput } from "../components/formComponents/stringInput/component";
import { JunctionDropdown } from "../components/formComponents/dropdown/component";
import { JunctionSorting } from "../components/formComponents/sortingIcon/component";

import { CourseHeader } from "../components/templateComponents/courseHeader/component";
import { ReportRow } from "../components/templateComponents/reportRow/component";
import { ReportChartRow } from "../components/templateComponents/reportChartRow/component";
import { HighChart } from "../components/templateComponents/highChart/component";
import { ListWithCheckbox } from "../components/templateComponents/list/component";

import { FullCalendar } from '../components/calendar/component';
import { SubscribeModal } from '../components/calendar/subscribe_modal/component';

import { GradingCenter } from '../components/gradingCenter/component';
import { GradingCenterModal } from '../components/gradingCenter/gradingcentermodal/component';
import { GradeByQuestion } from '../components/gradingCenter/gradeByQuestion/component';
import { GradeByStudent } from '../components/gradingCenter/gradeByStudent/component';
import { GradingBoard } from '../components/gradingCenter/gradingBoard/component';
import { GradingInput } from '../components/gradingCenter/gradingInput/component';
import { GradingHeader } from '../components/gradingCenter/gradingHeader/component';
import { GradingCenterFilterModal } from '../components/gradingCenter/gradingCenterFilterModal/component';
import { GradingCenterLearnosityFeedbackModal } from '../components/gradingCenter/learnosityFeedbackModal/component';
import { LearnosityItem } from '../components/gradingCenter/learnosity/component';

import { JunctionActivitySettings } from "../components/activitySettings/component";
import { JunctionPresetBadge } from "../components/activitySettings/presetBadge/component";
import { JunctionPresetsListModal } from "../components/activitySettings/presetsListModal/component";
import { JunctionBatchUpdateModal } from "../components/activitySettings/batchUpdate/component";
import { JunctionActivitySettingsDefaults } from "../components/activitySettings/defaults/component";
import { JunctionShowHideColumnsModal } from "../components/activitySettings/showHideColumns/component";
import { JunctionActivitySettingsForm } from "../components/activitySettings/batchUpdate/activitySettingsForm/component";
import { JunctionActivitySettingsDateAndTimeForm } from "../components/activitySettings/batchUpdate/activitySettingsDateAndTimeForm/component";
import { JunctionActivitySettingsPresetForm } from "../components/activitySettings/batchUpdate/activitySettingsPresetForm/component";
import { JunctionActivitySettingsTooltip } from "../components/activitySettings/batchUpdate/tooltip/component";
import { JunctionActivitySettingsChangeSummary } from "../components/activitySettings/batchUpdate/activitySettingsChangeSummary/component";

import { LateWorkManager } from '../components/lateWorkManager/lateWorkManagerBoard/component';
import { LwmAcceptModal } from '../components/lateWorkManager/acceptModal/component';
import { LwmRejectModal } from '../components/lateWorkManager/rejectModal/component';
import { LateWorkPolicyModal } from '../components/lateWorkManager/lateWorkPolicyModal/component';
import { TextModal } from '../components/modal/textModal/component'

import { LtiSyncManager } from '../components/ltiSyncManager/component';
import { LtiSetup } from '../components/ltiSyncManager/children/ltiSetup/component';
import { LtiSyncProcess } from '../components/ltiSyncManager/children/ltiSyncProcess/component';
import { LtiSyncStudents } from '../components/ltiSyncManager/children/ltiSyncStudents/component';
import { ltiSyncConfirm } from '../components/ltiSyncManager/children/ltiSyncConfirm/component';

import { PerformanceReport } from '../components/performanceReport/component';
import { EmailStudentsModal } from '../components/performanceReport/modal/component';
import { AssessmentDeepDive } from '../components/assessmentDeepDive/component';
import { AssessmentDeepDiveDetails } from '../components/assessmentDeepDive/details/component';
import { StudentSubmissions } from '../components/assessmentDeepDive/details/studentSubmissions/component';
import { StudentSubmissionsDetailsModal } from '../components/assessmentDeepDive/details/studentSubmissions/detailsModal/component';
import { PerformanceByItem } from '../components/assessmentDeepDive/details/performanceByItem/component';
import { PerformanceByLearningObjective } from '../components/assessmentDeepDive/details/performanceByLearningObj/component';
import { LiveProgress } from '../components/assessmentDeepDive/details/liveProgress/component';
import { ItemsDetailedReport } from '../components/assessmentDeepDive/details/itemsDetailedReport/component';
import { ChartBar } from "../components/chartBar/component";
import { UsersTable } from "../components/usersTable/component";
import { StudentDeepDiveDetails } from '../components/studentDeepDive/component';
import { StudentOverall } from "../components/studentDeepDive/studentOverall/component";
import { AssignmentsTable } from "../components/studentDeepDive/assignmentsTable/component";
import { StudentDeepDiveChapterSelectInput } from "../components/studentDeepDive/chapterSelectInput/component";
import { JunctionAssessmentDetailModal } from "../components/studentDeepDive/assessmentDetailModal/component";
import { JunctionAssessmentDetailAttemptTable } from '../components/studentDeepDive/assessmentDetailAttemptTable/component';
import { JunctionAssessmentAttemptLearnosityIframe } from '../components/studentDeepDive/assessmentAttemptLearnosityIframe/component';

import * as declareRWSComponents from '@rws-framework/client/src/components/index';
import {RWSClientInstance} from '@rws-framework/client/src/client';
import { JunctionLtiConfigModal } from '../components/ltiSyncManager/modals/ltiConfigModal/component';
import { GradebookInstructor } from '../components/gradebookInstructor/component';
import { GradebookDropdown } from '../components/gradebookInstructor/gradebookDropdown/component';
import { AdjustGradeModal } from '../components/gradebookInstructor/adjustModal/component';
import { OverrideAssessmentModal } from '../components/gradebookInstructor/overrideModal/component';
import { GradebookCustomColumn } from '../components/gradebookInstructor/customColumn/component';
import { RollupManager } from '../components/rollupManager/component';
import { RollupModal } from '../components/rollupManager/modal/component';

import { DashboardActions } from '../components/dashboardActions/component';

import { GradebookStudent } from '../components/gradebookStudent/component';

export default (parted: boolean = false) => {
    if(!parted){
        ChartBar;
        UsersTable;
        StudentDeepDiveDetails;
        StudentOverall;
        AssignmentsTable;
        StudentDeepDiveChapterSelectInput;
        JunctionAssessmentDetailModal;
        JunctionAssessmentDetailAttemptTable;
        JunctionAssessmentAttemptLearnosityIframe;
        ConfirmationModal;
        DateTabularDisplay;
        Icon;
        JunctionModal;
        JunctionTable;
        JunctionTableDropdown;
        JunctionModalHeader;
        JunctionLoader;

        JunctionDateTimeInput;
        JunctionCheckboxInput;
        JunctionNumberInput;
        JunctionSelectColorInput;
        JunctionSelectInput;
        JunctionStringInput;
        JunctionDropdown;
        JunctionSorting;

        CourseHeader;
        ReportRow;
        ReportChartRow;
        HighChart;
        ListWithCheckbox;

        FullCalendar;
        SubscribeModal;

        GradingCenter;
        GradingCenterModal;
        GradeByQuestion;
        GradeByStudent;
        GradingBoard;
        GradingInput;
        GradingHeader;
        GradingCenterFilterModal;
        GradingCenterLearnosityFeedbackModal;
        LearnosityItem;

        GradebookInstructor;
        GradebookDropdown;
        AdjustGradeModal;
        OverrideAssessmentModal;
        GradebookCustomColumn;
        RollupManager;
        RollupModal;
        GradebookStudent;

        JunctionActivitySettings;
        JunctionPresetBadge;
        JunctionPresetsListModal;
        JunctionBatchUpdateModal;
        JunctionActivitySettingsDefaults;
        JunctionShowHideColumnsModal;
        JunctionActivitySettingsForm;
        JunctionActivitySettingsDateAndTimeForm;
        JunctionActivitySettingsPresetForm;
        JunctionActivitySettingsTooltip;
        JunctionActivitySettingsChangeSummary;

        LateWorkManager;
        LwmAcceptModal;
        LwmRejectModal;
        LateWorkPolicyModal;
        TextModal;

        LtiSyncManager;
        LtiSetup;
        LtiSyncProcess;
        LtiSyncStudents;
        ltiSyncConfirm;
        JunctionLtiConfigModal;

        PerformanceReport;
        EmailStudentsModal;

        AssessmentDeepDive;
        AssessmentDeepDiveDetails;
        ItemsDetailedReport;
        LiveProgress;
        StudentSubmissions;
        StudentSubmissionsDetailsModal;
        PerformanceByItem;
        PerformanceByLearningObjective;
        DashboardActions;
    }

    RWSClientInstance.defineAllComponents();
}
