import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<ul class="list">
  ${T.repeat(x => x.data_list, T.html`
    <li class="list__item">
      <div class="list__box" :innerHTML="${x => x.box}"></div>
      <div class="list__title">
        ${x => x.title}
      </div>
    </li>
  `)}
</ul>
`;

import './styles/layout.scss';
const styles = T.css`.list {
  box-sizing: border-box;
  margin: 0px;
  list-style: none;
  display: flex;
  width: 100%;
  padding: 20px 16px;
  align-items: center;
  justify-content: space-around;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid var(--neutral-20);
  background: white;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}
.list__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.list__box {
  box-sizing: border-box;
  display: flex;
  min-width: 78px;
  height: 78px;
  padding: 19px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 12px;
  border: 1px solid var(--neutral-30);
  color: var(--Gray-1, #333);
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
  text-transform: capitalize;
}
.list__box .time-text {
  font-size: 18px;
  font-weight: 300;
}
.list__title {
  color: var(--neutral-90);
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 160%;
}`;


import { observable, RWSViewComponent, RWSView, attr } from "@rws-framework/client";

type DataType = {
  box: string,
  title: string,
  box_text?: string,
};

@RWSView('report-row', null, { template: rwsTemplate, styles })
class ReportRow extends RWSViewComponent  {
  @attr data_list: DataType[];

  connectedCallback() {
    super.connectedCallback();
  };
}

ReportRow.defineComponent();

export { ReportRow };
