/**
 * Removes given object keys
 * Analogous to [omit](https://lodash.com/docs/4.17.15#omit) from lodash
 */
export function omit<T extends Record<string, unknown>>(
  obj: T,
  keys: ReadonlyArray<keyof T>
): Partial<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keys.includes(key))
  ) as Partial<T>;
}

/**
 * Preserves given object keys
 * Analogous to [pick](https://lodash.com/docs/4.17.15#pick) from lodash
 */
export function pick<T extends Record<string, unknown>, K extends keyof T>(
  obj: T,
  keys: ReadonlyArray<K>
): { [Key in K]: T[Key] } {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) =>
      (keys as readonly string[]).includes(key)
    )
  ) as { [Key in K]: T[Key] };
}

/**
 * Removes object keys for which predicate returns true
 * Analogous to [omitBy](https://lodash.com/docs/4.17.15#omitBy) from lodash
 */
export function omitBy<T extends Record<string, unknown>>(
  obj: T,
  fn: (value: T[keyof T], key: keyof T) => boolean
): Partial<T> {
  return Object.fromEntries(
    (Object.entries(obj) as Array<[keyof T, T[keyof T]]>).filter(
      ([key, value]) => !fn(value, key)
    )
  ) as Partial<T>;
}

/**
 * Applies a function over all object values
 * Analogous to [mapValues](https://lodash.com/docs/4.17.15#mapValues) from lodash
 */
export function mapObject<R extends Record<string, unknown>, T>(
  obj: R,
  mapper: (value: R[keyof R], key: keyof R) => T
): { [K in keyof R]: T } {
  return (Object.entries(obj) as Array<[keyof R, R[keyof R]]>).reduce(
    (acc, [key, val]) => {
      acc[key] = mapper(val, key);
      return acc;
    },
    {} as { [K in keyof R]: T }
  );
}

/**
 * Groups objects by specified property picked via function
 * Analogous to [groupBy](https://lodash.com/docs/4.17.15#groupBy) from lodash
 */
export function groupBy<R>(
  items: R[],
  mapper: (value: R) => string
): Record<string, R[]> {
  return items.reduce((acc, item) => {
    const key = mapper(item);

    if (!acc[key]) acc[key] = [];
    acc[key].push(item);

    return acc;
  }, {} as Record<string, R[]>);
}

/**
 * Sorts items given numeric/string property picked via function
 */
export function sortBy<R>(
  items: R[],
  mapper: (value: R) => string | number
): R[] {
  return [...items].sort((a, b) => {
    const valA = mapper(a);
    const valB = mapper(b);

    return valA === valB ? 0 : valA > valB ? 1 : -1;
  });
}

export function uniq<T>(arr: T[]): T[] {
  return [...new Set(arr)];
}
