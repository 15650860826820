export enum CalendarStatus {
  NotSubmitted = "not-submitted",
  SubmittedLate = "late",
  Graded = "graded",
  Submitted = "submitted",
  InProgress = "in-progress",
  NotStarted = "not-open-yet",
  PendingGrade = "pending-grade",
  Practice = "practice",
  NoPill = "no-pill"
}

export interface CalendarEvent {
  date: string;
  title: string;
  lesson_title: string,
  avg_score: string;
  score: string,
  gradedCount: string;
  submitted: string;
  submittedCount: string;
  status: string;
  open_date_in_future?: string;
  done: boolean;
  type: string;
  url: string;
}

export interface InstructorCalendarActivity {
  id: string,
  courseId: string,
  title: string,
  report_title: string,
  type: string,
  link: string,
  lesson_title: string,
  graded: boolean,
  avg_score: number,
  open_date: string,
  due_date: string,
  dueCategory: string,
  displayDate: string,
  displayTime: string,
  displayOpenDate: string,
  displayOpenTime: string,
  gradedCount: number,
  submittedCount: number,
  rosterCount: number,
  course_data_only: boolean,
  suppress_until_available: boolean,
  open_date_in_future?: string,
}

export interface StudentCalendarActivity {
  id: string,
  courseId: string,
  title: string,
  report_title: string,
  type: string,
  link: string,
  lesson_title: string,
  submissionDate: string,
  graded: boolean,
  score: number,
  status: string,
  open_date: string,
  open_date_in_future?: string,
  due_date: string,
  dueCategory: string,
  displayDate: string,
  displayTime: string,
  displayOpenDate: string,
  displayOpenTime: string,
  done: boolean,
  parents: string[],
  course_data_only: boolean
}
