import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<jnct-select-input
  class="input"
  :default="${x => x.selectedByChapterOption}"
  :options="${x=>x.byChapterOptions}"
  :afterChange="${x=>x.setByChapter}"
  fieldName="chapter"
  height="40px"
></jnct-select-input>
`;

import './styles/layout.scss';
const styles = T.css`.input {
  color: #5b5c5e;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
}`;

const mode = "open";
import { RWSViewComponent, RWSView, attr } from "@rws-framework/client";

@RWSView('jnct-student-deep-dive-chapter-select', null, { template: rwsTemplate, styles, options: {mode} })
class StudentDeepDiveChapterSelectInput extends RWSViewComponent  {
  @attr selectedChapterId: string = "";
  @attr chapters: Array<{ lesson_id: string; lesson_title: string }> = [];

  @attr onChange: (
    a: { lesson_id: string; lesson_title: string } | null
  ) => void = () => {};

  static BY_CHAPTER_OPTION = "All Chapters";

  get selectedByChapterOption() {
    return (
      this.chapters.find((p) => p.lesson_id === this.selectedChapterId)
        ?.lesson_title ?? StudentDeepDiveChapterSelectInput.BY_CHAPTER_OPTION
    );
  }

  get byChapterOptions() {
    const pages = uniq(this.chapters.filter((p) => p.lesson_id !== null).map((p) => p.lesson_title));

    return [StudentDeepDiveChapterSelectInput.BY_CHAPTER_OPTION, ...pages];
  }

  findChapterByName(name: string) {
    return this.chapters.find((p) => p.lesson_title === name);
  }

  setByChapter = (_, value: string) => {
    if (value === StudentDeepDiveChapterSelectInput.BY_CHAPTER_OPTION)
      return this.onChange(null);

    const chapter = this.findChapterByName(value);

    return this.onChange(chapter);
  };
}

StudentDeepDiveChapterSelectInput.defineComponent();

export { StudentDeepDiveChapterSelectInput };

function uniq<T>(arr: T[]): T[] {
  return [...new Set(arr)];
}
