import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`${T.when(x => !x.currentSorting, T.html`
  <img
    src="/images/sort_icon_inactive.svg"
    alt="sort descending"
    @click="${x => x.sortData(x.value)}"
    @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? c.parent.sortData(x.value) : ''}"
    title="Sort descending"
    class="pointer"
    tabindex="0"
  />
`)} 

${T.when(x => x.currentSorting === 'asc', T.html`
  <img
    src="/images/sort_icon_up.svg"
    alt="sort descending"
    @click="${x => x.sortData(x.value)}"
    @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? x.sortData(x.value) : ''}"
    title="Sort descending"
    class="pointer"
    tabindex="0"
  />
`)}

${T.when(x => x.currentSorting === 'desc', T.html`
  <img
    src="/images/sort_icon_down.svg"
    alt="sort ascending"
    @click="${x => x.sortData(x.value)}"
    @keyup="${(x, c) =>(c.event as KeyboardEvent).keyCode === 13 ? x.sortData(x.value) : ''}"
    title="Sort ascending"
    class="pointer"
    tabindex="0"
  />
`)} 
`;

import './styles/layout.scss';
const styles = T.css`.pointer {
  cursor: pointer;
}`;



import { attr, RWSViewComponent, RWSView, observable } from "@rws-framework/client";

@RWSView('jnct-sort', null, { template: rwsTemplate, styles })
class JunctionSorting extends RWSViewComponent  {
  @attr value: any;
  @observable currentSorting: string;
  sortData: Function | undefined;

  valueChanged(oldVal: string | undefined, newVal: string | undefined): void {
    this.value = newVal;
  }

  connectedCallback () {
    super.connectedCallback();
  }
};


JunctionSorting.defineComponent();

export { JunctionSorting };