import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`${T.when(x => x.assignments?.length, T.html`
    <article class="lti__main main">
      <div class="main__title">
        ${T.when(x => !x.showNext, T.html`    
          ${T.when(x => !x.selectedCount, T.html`    
            ${x => x.assignments.filter(y=>y.graded).length} assignments
            (<span tabindex="0" @click="${(x, c) => x.selectAll(true)}" class="link">
              Select all
            </span>)
        `)}

        ${T.when(x => x.selectedCount, T.html`    
          ${x => x.selectedCount} assignment${x => x.selectedCount > 1 ? 's' : ''} selected (
          <span tabindex="0" @click="${(x, c) => x.selectAll(false)}" class="link">
            Cancel selection
          </span>)    
        `)}
      </div>

      <div class="main__table_container">
        <ul class="main__table">
          ${T.repeat(x => x.assignments, T.html`        
          <li class="item">
            <div>
                ${T.when(x=> (x.graded || x.xgraded), T.html`
                <fluent-checkbox 
                  id="${x => x.id}" 
                  :checked="${(x, c) => c.parent.isSelected(x.id)}"
                  @click="${(x, c) => c.parent.checkboxChange(x.id)}"
                  style="--accent-fill-rest: var(--primary_color); --accent-fill-hover: var(--primary_color); --accent-fill-focus: var(--primary_color); --accent-fill-active: var(--primary_color);"
                  >
                </fluent-checkbox>
                `)}
            </div>
            <div :innerHTML="${x => x.lesson ? x.lesson.title : ''}" @click="${(x, c) => c.parent.checkboxChange(x.id)}"></div>
            <label :innerHTML="${x => (x.graded || x.xgraded) && x.report_title || ''}" for="${x => x.id}"></label>
          </li>
          `)}
        </ul>     
      </div>
    </article>`)}
  `)}`;

import './styles/layout.scss';
const styles = T.css`.main * {
  box-sizing: border-box;
}
.main__title {
  margin-bottom: 8px;
  padding: 0px 4px;
  color: var(--neutral-70);
  font-size: 1em;
  font-weight: 600;
  line-height: 170%;
}
.main__table_container {
  height: calc(100vh - 375px);
  overflow-y: auto;
  padding: 15px 0;
}
.main__table {
  display: table;
  width: 100%;
  padding: 0px;
  margin: 0px;
  list-style: none;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}
.main__table .item {
  display: table-row;
  padding: 16px 24px;
  gap: 28px;
  align-items: center;
  align-self: stretch;
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
}
.main__table .item > * {
  display: table-cell;
  padding: 8px;
  color: var(--neutral-90);
  font-size: 14px;
}
.main__table .item:nth-child(even) {
  background-color: white;
}
.main__table .item:nth-child(odd) {
  background-color: var(--neutral-8);
}`;

const shadowOptions = {"mode":"open"};

import { RWSViewComponent, RWSView, attr } from "@rws-framework/client";
import { observable, ViewTemplate, html } from "@microsoft/fast-element";
import { LTIConfigType } from "../../../../types/lti/config";
import { SelectedType } from "../../component";

export type AssignmentsType = {
  id: string,
  report_title: string,
  graded: boolean,
  sync_index: number
  xgraded?: boolean,
  max_score?: number,
  title?: string
}

@RWSView('lti-sync-assignments', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class LtiSyncAssignments extends RWSViewComponent  {
  @observable selectedAll: boolean = false;
  @observable selected: AssignmentsType[] = [];
  @observable selectedCount: number = 0;
  
  @attr assignments: AssignmentsType[] = [];
  @observable showNext: boolean = false;

  selectAll(state: boolean): void {
    this.selectedAll = state
    this.selected = []

    if (state) {
      this.selected = this.assignments
    } else {
      this.selected
    }    

    this.countSelected()
    this.emit()
  }

  isSelected(id: string): boolean {    
    return this.selected.some(sel => sel.id === id)
  }

  checkboxChange(id: string): void {
    if (!this.isSelected(id)) { 
      const assignment = this.assignments.find(sel => sel.id === id)
      if (assignment) {
        this.selected = [...(this.selected), assignment]
      }      
    } else {
      this.selected = this.selected.filter(sel => sel.id !== id)
    }        

    this.countSelected()
    this.emit()
  };

  selectedChanged(oldValue?: SelectedType, newValue?: SelectedType): void {
    this.countSelected()
    this.emit()
  }

  countSelected(): void {
    this.selectedCount = this.selected.length
  }

  assignmentsChanged(oldValue: AssignmentsType[], newValue: AssignmentsType[]): void {
    if (newValue) {
      this.assignments = newValue
    }
  }

  private emit(): void {
    this.dispatchEvent(new CustomEvent<AssignmentsType[]>('change', {
      detail: this.selected
    }))
  }
}

LtiSyncAssignments.defineComponent()

export { LtiSyncAssignments }
