import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div id="${x => x.chart.type}"></div>                                          
`;

import './styles/layout.scss';
const styles = T.css``;


import { observable, RWSViewComponent, RWSView, attr } from "@rws-framework/client";
import { Chart } from "../../../types/chart";

@RWSView('high-chart', null, { template: rwsTemplate, styles })
class HighChart extends RWSViewComponent  {
  @attr chart: Chart;

   connectedCallback() {
    super.connectedCallback();
    if (this.chart.type === 'pie') {
      this.renderPie();
      return;
    }
    if (this.chart.type === 'bar') {
      this.renderBar();
      return;
    }
    this.renderChart();
   };

   renderChart() {
    const Highcharts = require('highcharts');
    require('highcharts/modules/no-data-to-display')(Highcharts);
    require('highcharts/modules/accessibility')(Highcharts);
    Highcharts.chart(this.shadowRoot.getElementById(this.chart.type), {
      chart: {
        type: this.chart.type,
      },
      title: {
        text: '',
     },
     credits: {
        enabled: false,
      },
      xAxis: {
        categories: this.chart.x_data,
        crosshair: true,
        labels: {
          style: {
          color: 'rgba(29, 36, 51, 0.80)',
          textAlign: 'right',
          fontFamily: 'Roboto',
          fontSize: '1.4em',
          fontWeight: '400',
          lineHeight: '160%',
        }
        },
        accessibility: {
          description: this.chart.y_title,
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: this.chart.y_title,
          style: {
            fontSize: '18px',
          }
        },
        labels: {
          style: {
            color: 'rgba(29, 36, 51, 0.80)',
            textAlign: 'right',
            fontFamily: 'inherit',
            fontSize: '1.4em',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '160%',
          }
        },
      },
      tooltip: {
        style: {
          fontSize: '1.4em'
        },
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              color: this.chart.color.hover,
            },
            select: {
              color: this.chart.color.select || this.chart.color.hover,
            },
          },
          borderRadius: {
              radius: '8px'
          },
        },
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            formatter:function() {
              if(this.y != 0) {
                return this.y;
              }
            },
            style: {
              color: 'white',
              textAlign: 'center',
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontWeight: '600',
              lineHeight: '160%',
              outline: 'none',
            },
          },
        }
      },
      legend: {
        itemStyle: {
          fontSize: '18px',
          color: 'rgba(29, 36, 51, 0.80)'
       },
    }, 
      series: this.chart.series,
      lang: {
        noData: 'No Data to Display'
      },
      noData: {
        style: {
          fontWeight: '500',
          fontSize: '20px',
          color: '#9C9C9E'
        }
      }
    });
  }

   renderBar() {
    const Highcharts = require('highcharts');
    require('highcharts/modules/no-data-to-display')(Highcharts);
    require('highcharts/modules/accessibility')(Highcharts);
    Highcharts.chart(this.shadowRoot.getElementById(this.chart.type), {
    chart: {
      type: "bar",
      height: this.chart.series[0].data.length * 30 + 100, //length multipled by (pointWidth + groupPadding)
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      style: {
        fontSize: '1.4em'
      },
    },
    xAxis: {
      categories: this.chart.x_data,
      crosshair: true,
      labels: {
        useHTML: true,
        style: {
          width: '300px',
          textOverflow: 'ellipsis',
          color: 'rgba(29, 36, 51, 0.80)',
          fontSize: "16px",
          fontWeight: '400',
          fontFamily: 'inherit',
          lineHeight: '160%',          
        },
      },
      accessibility: {
        description: this.chart.y_title,
    }
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: '',
      },
      labels: {
        style: {
          color: 'rgba(29, 36, 51, 0.80)',
          textAlign: 'right',
          fontFamily: 'Roboto',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '160%',
        }
      },
    },
    plotOptions: {
      bar: {
        borderRadius: '50%',
        pointWidth: 20, //the width of the bar
        dataLabels: {
          enabled: true,
          formatter:function() {
            if(this.y != 0) {
              return this.y;
            }
          },
        },
      },
      series: {
        groupPadding: 0.5, //space between bars, the real size is groupPadding*pointWidth
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '14px',
          },
      },
      states: {
        hover: {
          color: this.chart.color.hover,
        },
        select: {
          color: this.chart.color.select || this.chart.color.hover,
        },
      },
      }
    },
    legend: {
      itemStyle: {
        fontSize: '18px',
        color: 'rgba(29, 36, 51, 0.80)'
     },
  }, 
    series: this.chart.series,
  lang: {
    noData: 'No Data to Display'
  },
  noData: {
    style: {
      fontWeight: '500',
      fontSize: '20px',
      color: '#9C9C9E'
    }
  }
  });
   }

  renderPie() {
    const sum = this.chart.series[0].data.reduce((sum, el) => sum + el.y, 0);

    const Highcharts = require('highcharts');
    require('highcharts/modules/no-data-to-display')(Highcharts);
    require('highcharts/modules/accessibility')(Highcharts);
    Highcharts.chart(this.shadowRoot.getElementById(this.chart.type), {
      chart: {
        type: 'pie',
        height: '200',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        style: {
          fontSize: '1.2em'
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true, // Disable point selection
          cursor: 'pointer',
          size: 150,
          dataLabels: {
            enabled: true,
            filter: {
              operator: '>',
              property: 'percentage',
              value: 0
            },
            distance: -20, // Adjust the distance of labels from the center
            format: '{point.y}',
            style: {
              color: '#1B1C1E', 
              textShadow: '0px 3.511px 8.777px rgba(0, 0, 0, 0.12)',
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontWeight: '600',
              lineHeight: '130%',
            }
          },
          borderWidth: 0,
        },
        series: {
          dataLabels: [{
            enabled: true,
            distance: -50
        }, {
            enabled: true,
            distance: 20,
            format: '{point.name}',
            style: {
                fontSize: '1.2em',
                textOutline: 'none',
                opacity: 0.7
            },
        }]
        }
    },
      series: this.chart.series,
      lang: {
        noData: 'No Data to Display'
    },
    noData: {
      style: {
        fontWeight: '500',
        fontSize: '20px',
        color: '#9C9C9E'
      }
    },
    });
  }
}

HighChart.defineComponent();

export { HighChart };
