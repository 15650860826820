import {RWSService} from '@rws-framework/client';
import {RosterRecord} from "../types/users";
import {Activity} from "../types/activities";


class EventsService extends RWSService {
  static defaultEventOptions = {
    bubbles: true,
    composed: true,
    cancelable: true
  }

  _dispatch(type: string, detail?: Object, options?: Object) {
    return document.documentElement.dispatchEvent(new CustomEvent(type, Object.assign(Object.assign({
      detail
    }, EventsService.defaultEventOptions), options)));
  }

  getAverage(gradebook: boolean = false): Promise<any> {
    return new Promise((resolve) => {
      // in lieu of figuring out how to pass gradebook on the event details with the callback...
      var cacheType = 'jnct:dashboardService:getAverage' + (gradebook? ':GB' : '')
      this._dispatch(cacheType, (data: any) => resolve(data))
    })
  }

  getStudentAverage(): Promise<any> {
    return new Promise((resolve) => {
      this._dispatch('jnct:dashboardService:getStudentAverage', (data: any) => resolve(data))
    })
  }

  getActivityManager(): Promise<any[]> {
    return new Promise((resolve) => {
      this._dispatch('jnct:dashboardService:getActivityManager', (data: any) => {
        resolve(data)
      })
    })
  };

  getActivities(): Promise<Activity[]> {
    return new Promise((resolve) => {
      this._dispatch('jnct:dashboardService:getActivities', (data: any) => {
        resolve(data)
      });
    })
  };

  getMongoId(): Promise<string> {
    return new Promise((resolve) => {
      this._dispatch('jnct:dashboardService:getMongoId', (data: any) => {
        resolve(data)
      })
    })
  };

  getRoster(): Promise<RosterRecord[]> {
    return new Promise((resolve) => {
      this._dispatch('jnct:dashboardService:getRoster', (data: any) => {
        resolve(data)
      });
    })
  };

  getCourse(): Promise<any> {
    return new Promise((resolve) => {
      this._dispatch('jnct:dashboardService:getCourse', (data: any) => {
        resolve(data)
      })
    })
  };

  getCalendarMode(): Promise<any> {
    return new Promise((resolve) => {
      this._dispatch('jnct:getCalendarMode', (data: any) => {
        resolve(data)
      })
    })
  };

  //course id from dashboard service
  getServiceCourseId(): Promise<any> {
    return new Promise((resolve) => {
      this._dispatch('jnct:dashboardService:getServiceCourseId', (data: any) => {
        resolve(data)
      })
    })
  };

  getLessons(): Promise<any> {
    return new Promise((resolve) => {
      this._dispatch('jnct:dashboardService:getLessons', (data: any) => {
        resolve(data)
      })
    })
  };

  getCourseMode(course_id: string): string {
    if (!course_id) {
      sessionStorage.setItem('mode', '');
      return "presentation";
    };
    let mode = sessionStorage.getItem('mode');
    if (mode && mode.includes(course_id)) {
      return mode.replace(course_id, '');
    };
    sessionStorage.setItem('mode', course_id + "presentation");
    return "presentation";
  };
}

export default EventsService.getSingleton()

export {EventsService as EventsServiceInstance}
