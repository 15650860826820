import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<fluent-dialog
  id="grading_center_modal"
  trap-focus
  :hidden="${x => !x.isOpen}"
  modal
  class="grading_center_modal">

  <header class="modal__header">
    <h1>Grading Center - Instructions</h1>
    <button
      class="modal__header--exit"
      tabindex="0"
      title="Exit modal"
      @click="${x => x.close()}">
      Exit
    </button>
  </header>
 
  <div class="modal__title">
    How do you want to grade 
    <span class="modal__title--bold" :innerHTML="${x => x.text}">
    </span>
    ?
  </div>

  <section class="modal__main">
    ${T.repeat(x => x.cards, T.html`
      <div class="card">
        <div class="card__title">
          ${x => x.title}
        </div>

        <div class="card__text">
          <p>${x => x.text_1}</p>
          <p>${x => x.text_2}</p>
        </div>

        <button
          class="card__button"
          tabindex="0"
          title="${x => x.title}"
          @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? c.parent.grade(x.action) : ''}"
          @click="${(x, c) => c.parent.grade(x.action)}">
          <div>${x => x.title}</div>
          <span class="material-symbols-outlined">arrow_right_alt</span>
        </button>
      </div>
    `)}
  </section>

  <div class="modal__bottom">
    <fluent-checkbox @change="${x => x.save()}">
      Remember my choice and dont show this window again.
    </fluent-checkbox>
  </div>
</fluent-dialog>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.grading_center_modal::part(positioning-region) {
  height: 684px;
  width: 765px;
  margin: auto;
  border-radius: 12px;
}
.grading_center_modal::part(control) {
  box-sizing: border-box;
  padding: 24px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.grading_center_modal .modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}
.grading_center_modal .modal__header h1 {
  margin: 0px;
  color: var(--neutral-90);
  font-size: 1.71429em;
  font-weight: 600;
  line-height: 140%;
}
.grading_center_modal .modal__header--exit {
  padding: 0px;
  color: var(--link);
  font-size: 1.28571em;
  font-weight: 600;
  line-height: 160%;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.grading_center_modal .modal__header--exit:hover, .grading_center_modal .modal__header--exit:active {
  text-decoration: underline;
  color: #0A5281;
}
.grading_center_modal .modal__title {
  margin-bottom: 32px;
  color: var(--neutral-70);
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}
.grading_center_modal .modal__title--bold {
  font-weight: 700;
}
.grading_center_modal .modal__main {
  margin-bottom: 32px;
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}
.grading_center_modal .modal__main .card {
  display: flex;
  padding: 24px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-10);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}
.grading_center_modal .modal__main .card__title {
  color: #333;
  font-size: 1.42857em;
  font-weight: 600;
  line-height: 150%;
}
.grading_center_modal .modal__main .card__text {
  flex: 1 0 0;
  color: var(--neutral-70);
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}
.grading_center_modal .modal__main .card__button {
  background-color: white;
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286em;
  font-weight: 600;
  line-height: 150%;
  cursor: pointer;
}
.grading_center_modal .modal__main .card__button .material-icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
}
.grading_center_modal .modal__main .card__button:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.grading_center_modal .modal__main .card__button:active {
  background: var(--neutral-30);
}
.grading_center_modal .modal__bottom {
  display: flex;
  gap: 8px;
  color: #000;
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
}`;


import { observable, RWSViewComponent, RWSView, attr } from "@rws-framework/client";

type CardType = {
  title: string,
  text_1: string,
  text_2: string,
  action: string,
};

@RWSView('grading-center-modal', null, { template: rwsTemplate, styles })
class GradingCenterModal extends RWSViewComponent  {
  @observable text: string;
  @observable component_id: string;
  @observable cards: CardType[];
  @observable isOpen: boolean = true;
  @observable saveChoice: boolean = false;

  connectedCallback() {
    super.connectedCallback();

    this.cards = [
      {
        title: 'Grade by Question',
        text_1: `When selected, Grade by Question presents each question in an assessment for grading in turn.`,
        text_2: `Student names can be shown or anonymized.`,
        action: 'byquestion',
      },
      {
        title: 'Grade by Student',
        text_1: `When selected, Grade by Student presents all the questions to be graded for each student in turn.`, 
        text_2: `Student names can be shown or anonymized.`,
        action: 'bystudent',
      }
    ]
  };

  grade(action: string) {
    if (this.saveChoice) {
      localStorage.setItem('gradeAssessmentBy', action);
    };

    this.$emit('jnct:gradingcenter:gradeby', {component_id: this.component_id, action: action});
    this.close();
  };

  close() {
    this.isOpen = false;
    this.remove();
  };

  save() {
    this.saveChoice = !this.saveChoice;
  }
}

GradingCenterModal.defineComponent();

export { GradingCenterModal };
