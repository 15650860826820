import {RWSService} from '@rws-framework/client';
import ActivitiesService, {ActivitiesServiceInstance} from "./ActivitiesService";
import EventsService, {EventsServiceInstance} from "./EventsService";
import {
  AssessmentDeepDiveDetailViewRecord,
  AssessmentDeepDiveListViewRecord,
  GradeDistribution,
  StudyCenterReport
} from "../types/assessments";
import {Activity} from "../types/activities";
import {RosterRecord} from "../types/users";

class AssessmentsService extends RWSService {

  constructor(
    @ActivitiesService private activitiesService: ActivitiesServiceInstance,
    @EventsService private eventsService: EventsServiceInstance
  ) {
    super();
  }

  async getDeepDiveListView(): Promise<AssessmentDeepDiveListViewRecord[]> {
    const average: any = await this.eventsService.getAverage();
    const activity_manager: Activity[] = average.activity_manager;
    return activity_manager.reduce((acc: AssessmentDeepDiveListViewRecord[], activity: Activity) => {
      if (activity.type === 'assessment') {
        acc.push({
          id: activity.id,
          title: activity.report_title,
          link: activity.link,
          due_date: activity.due_date,
          avg_score: this.activitiesService.getAverageGradeInPercentage(activity, average.activity_student_score),
          max_score: activity.max_score || 0
        });
      }
      return acc;
    }, [] as AssessmentDeepDiveListViewRecord[]);
  }

  async getDeepDiveDetailView(activity_id: string): Promise<any> {
    const [average, roster]: [any, RosterRecord[]] = await Promise.all([
      this.eventsService.getAverage(),
      this.eventsService.getRoster()
    ]);

    const activity = average.activity_manager.find((activity: Activity) => activity.id === activity_id);

    const gradeDistribution: Record<GradeDistribution, number> = {
      '0-59': 0,
      '60-64': 0,
      '65-69': 0,
      '70-74': 0,
      '75-79': 0,
      '80-84': 0,
      '85-89': 0,
      '90-94': 0,
      '95-100': 0
    }

    const record: AssessmentDeepDiveDetailViewRecord = {
      id: activity.id,
      activity_id: activity_id,
      title: activity.report_title,
      link: activity.link,
      due_date: this.activitiesService.getDueDate(activity),
      avg_score_in_percentage: parseFloat(this.activitiesService.getAverageGradeInPercentage(activity, average.activity_student_score).toFixed(1)),
      median_score_in_percentage: parseFloat(this.activitiesService.getMedianGradeInPercentage(activity, average.activity_student_score).toFixed(1)),
      points_available: activity.max_score || 0,
      status: activity.graded ? 'graded' : 'practice',
      grade_distribution: gradeDistribution,
      student_submissions: []
    }

    roster.forEach((student: RosterRecord) => {
      const student_score = average.activity_student_score?.[activity_id]?.[student._id];
      if (student_score && student_score.score >= 0) {
        this._assignGradeDistribution(this.activitiesService.getGradeInPercentage(activity, average.activity_student_score, student._id), gradeDistribution)

        record.student_submissions.push({
          id: student._id,
          fullname: student.name,
          score_in_percentage: this.activitiesService.getGradeInPercentage(activity, average.activity_student_score, student._id),
          score: student_score.score,
          submission_date: student_score.dt_completed,
          time_spent_in_seconds: student_score.time_spent_in_seconds || 0,
          session_id: student_score.session_id
        })
      }
    });

    return record;
  }

  private _assignGradeDistribution(score: number, gradeDistribution: Record<GradeDistribution, number>) {
    switch (true) {
      case (score < 60):
        gradeDistribution['0-59'] += 1;
        break;
      case (score < 65):
        gradeDistribution['60-64'] += 1;
        break;
      case (score < 70):
        gradeDistribution['65-69'] += 1;
        break;
      case (score < 75):
        gradeDistribution['70-74'] += 1;
        break;
      case (score < 80):
        gradeDistribution['75-79'] += 1;
        break;
      case (score < 85):
        gradeDistribution['80-84'] += 1;
        break;
      case (score < 90):
        gradeDistribution['85-89'] += 1;
        break;
      case (score < 95):
        gradeDistribution['90-94'] += 1;
        break;
      default:
        gradeDistribution['95-100'] += 1;
        break;
    }
  }

  async getPerformanceByLearningObjective(courseId: string): Promise<StudyCenterReport> {
      // @ts-ignore
      const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
      const response = await fetch(`${APIprefix}/studycenter/report/learning-objectives/${courseId}`)
      return await response.json();
  }

  async isStudyCenterAvailable(): Promise<boolean> {
    const course = await this.eventsService.getCourse();
    return !course.noStudyCenter;
  }


}

export default AssessmentsService.getSingleton();

export {AssessmentsService as AssessmentsServiceInstance}
