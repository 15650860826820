import {RWSService} from '@rws-framework/client';
import {Activity, ActivityStudentScore, LastSubmittedActivityPerStudent, ActivityLockStatus, ActivityWithGradeStats} from "../types/activities";
import {CalendarStatus} from "../types/calendar";

class ActivitiesService extends RWSService {
  static _IN_CLIENT: boolean = true;
  getActivityStatus(activity: Activity, activity_student_score: ActivityStudentScore, student_id: string): CalendarStatus {
    const due_date = activity.due_date;
    const open_date = activity.open_date;

    const activity_id = this.getActivityId(activity);
    const submission = activity_student_score[activity_id]?.[student_id];
    const now = new Date().toISOString();

    if (!activity.graded && !activity.course_data_only) {
      return CalendarStatus.Practice;
    }

    if (now < open_date) {
      return CalendarStatus.NotStarted;
    }

    // project submitted late should be considered as pending grade
    if (activity.type === 'project' && submission?.contains_overdue) {
      return CalendarStatus.PendingGrade;
    }

    if (submission?.contains_overdue && !submission?.overdue_accepted && !submission?.manually_accepted && due_date && !submission?.not_displaying_score && !submission?.not_displaying_score_yet) {
      return CalendarStatus.NotSubmitted; // really it is Late and no overdue policy is applied and not accepted from lwm
    }

    if ((submission?.dt_completed || submission?.not_displaying_score || submission?.not_displaying_score_yet) && activity.scoring_type === 'Auto' ) {
      return CalendarStatus.Submitted;
    }

    if (submission?.dt_completed && submission?.instructor_graded_this) {
      return CalendarStatus.Graded;
    }

    if (submission?.needs_grading) {
      return CalendarStatus.PendingGrade;
    }

    if (activity.graded && !submission?.dt_completed && !submission?.not_displaying_score && !submission?.not_displaying_score_yet && due_date < now && activity.type !== 'board') {
      return CalendarStatus.NotSubmitted;
    }

    if (submission?.dt_completed && submission?.score >= 0 && ['board', 'project', 'comments'].includes(activity.type)) {
      return CalendarStatus.Graded;
    }

    if (activity.type === 'lti' && submission?.dt_started < due_date) {
      return submission.dt_completed? CalendarStatus.Graded : CalendarStatus.PendingGrade
    }

    // No pill means that the activity is in progress
    if (activity.graded && !activity.course_data_only) {
      return CalendarStatus.NoPill
    }

    return
  }

  getSubmissionDate(activity: Activity, activity_student_score: ActivityStudentScore, student_id: string): string | undefined {
    const activity_id = this.getActivityId(activity);

    return activity_student_score[activity_id]?.[student_id]?.dt_started || activity_student_score[activity_id]?.[student_id]?.dt_completed;
  }

  getSubmittedCount(activity: Activity, activity_student_score: ActivityStudentScore, roster_ids: Array<string>): number {
    const activity_id = this.getActivityId(activity);

    if (!activity_student_score[activity_id]) {
      return 0;
    }

    let count = 0;
    for (const [student_id, student_score] of Object.entries(activity_student_score[activity_id])) {
      if (student_score.needs_grading || student_score.contains_overdue || student_score.score || student_score.activity_id) {
        const isStudent = roster_ids.includes(student_id);
        const isReopenedProject = activity.type === 'project' && student_score.project_reopened;
        const noProjectComment = activity.type === 'comments' && !student_score.activity_id;
        const noBoardComment = activity.type === 'board' && !student_score.activity_id;

        if (isStudent && !isReopenedProject && !noProjectComment && !noBoardComment) {
          count += 1
        }
      }
    }

    return count;
  }

  getGradedCount(activity: Activity, activity_student_score: ActivityStudentScore, roster_ids: Array<string>): number {
    const activity_id = this.getActivityId(activity);

    if (!activity_student_score[activity_id]) {
      return 0;
    }

    let count = 0;
    for (const [student_id, student_score] of Object.entries(activity_student_score[activity_id])) {
      //manually rejected projects still have needs_grading flag
      if ((!student_score.needs_grading && (student_score.score || (student_score.score == 0 && student_score.activity_id))) || (student_score.needs_grading && student_score.manually_rejected)) {
        const isStudent = roster_ids.includes(student_id);
        const isReopenedProject = activity.type === 'project' && student_score.project_reopened;

        if (isStudent && !isReopenedProject) {
          count += 1
        }
      }
    }

    return count;
  }

  getOverdueCount(activity: Activity, activity_student_score: ActivityStudentScore, roster_ids: Array<string>): number {
    const activity_id = this.getActivityId(activity);

    if (!activity_student_score[activity_id]) {
      return 0;
    }

    let count = 0;
    for (const [student_id, student_score] of Object.entries(activity_student_score[activity_id])) {
      if (student_score.contains_overdue && (!student_score.overdue_accepted && !student_score.manually_accepted && !student_score.manually_rejected)) {
        const isStudent = roster_ids.includes(student_id);
        const isReopenedProject = activity.type === 'project' && student_score.project_reopened;

        if (isStudent && !isReopenedProject) {
          count += 1
        }
      }
    }

    return count;
  }

  getGradeInPercentage(activity: Activity, activity_student_score: ActivityStudentScore, student_id: string): number {
    const activity_id = this.getActivityId(activity);
    // @ts-ignore
    if (!activity.max_score || isNaN(activity.max_score) || activity.max_score === "") return 0;

    const student_score = activity_student_score[activity_id]?.[student_id];
    // @ts-ignore
    if (!student_score || !student_score.score || isNaN(student_score.score) || student_score.score === "") return 0;

    return parseFloat((student_score.score*100/activity.max_score).toFixed(1));
  }

  getSubmittedGradeInPercentage(activity: Activity, activity_student_score: ActivityStudentScore, student_id: string): number {
    const activity_id = this.getActivityId(activity);

    const student_score = activity_student_score[activity_id]?.[student_id];
    if (!activity.max_score || !student_score || !student_score.submitted_score)
      return 0;

    return student_score.submitted_score * 100 / activity.max_score;
  }

  getAverageGradeInPercentage(activity: Activity, activity_student_score: ActivityStudentScore): number {
    const activity_id = this.getActivityId(activity);

    const average = activity_student_score[activity_id]?.average;
    if (!activity.max_score || !average)
      return 0;

    // @ts-ignore
    return average * 100 / activity.max_score;
  }

  getMedianGradeInPercentage(activity: Activity, activity_student_score: ActivityStudentScore): number {
    const median = activity_student_score[activity.id]?.median;
    if (!activity.max_score || !median)
      return 0;

    // @ts-ignore
    return median * 100 / activity.max_score;
  }

  applyGradedToStudent(activity: Activity, activity_student_score: ActivityStudentScore, id: string): string {
    const activity_id = this.getActivityId(activity);

    if (!activity_student_score[activity_id]) {
      return;
    }

    for (const [student_id, student_score] of Object.entries(activity_student_score[activity_id])) {
      if ((!student_score.needs_grading && student_score.score) || (student_score.needs_grading && student_score.manually_rejected)) {
        if (student_id === id) {
          return 'graded';
        }
      }
    }

    return;
  }

  isValidDate(activity: Activity): boolean {
    const isDateInRange = (date: string) => date && date > '1971' && date < '2198';

    const isOpenDateValid = isDateInRange(activity.open_date);
    const isDueDateValid = isDateInRange(activity.due_date);
    const isCloseDateValid = isDateInRange(activity.close_date);

    return isOpenDateValid || isDueDateValid || isCloseDateValid
  }

  getDueDate(activity: Activity): string {
    const now = new Date();
    const never = new Date('2198-10-10');

    const open = new Date(activity.open_date || '2199-12-31')
    const due = new Date(activity.due_date || '2199-12-31')
    const close = new Date(activity.close_date || '2199-12-31')
    if (due < never) {
      return activity.due_date
    } else {
      if (now < close && close < never) {
        return activity.close_date
      // } else if (now < open && open < never || never < close) {
      //   return activity.open_date
      } else {
        return activity.close_date
      }
    }
  }

  isGradeableActivity(activity: Activity) {
    return !activity.suppress_until_available && activity.graded == true;
  }

  filterGradeableActivity(activity: ActivityWithGradeStats, params: Partial<{
    type: Array<Activity['type']> | null,
    scoring: Array<'Auto' | 'Mixed' | 'Manual'>;
    includeAlreadyGraded: boolean;
    lessonIds: string[] | null;
  }>): boolean {
    const type = params.type || ["assessment", "board", "project", "comments"];
    const scoring: string[] = params.scoring || ['Auto', 'Manual', 'Mixed'];
    const includeAlreadyGraded = params.includeAlreadyGraded ?? false;
    const lessonIds = params.lessonIds ?? null;

    const typeCondition = type === null || type.includes(activity.type)

    const scoringCondition = scoring.includes(activity.scoring_type);

    const isAlreadyGraded = (activity.submissions - activity.count_graded - activity.count_overdue) === 0;
    const alreadyGradedCondition = includeAlreadyGraded || activity.scoring_type === 'Auto' || !isAlreadyGraded;

    const lessonCondition = lessonIds === null || lessonIds.includes(activity.lesson?.id);

    return typeCondition && scoringCondition && alreadyGradedCondition && lessonCondition;
  }

  calculateActivityGradeStats(activity: Activity, activity_student_score: ActivityStudentScore, student_ids: string[]): ActivityWithGradeStats {
    return {
      ...activity,
      submissions: this.getSubmittedCount(
        activity,
        activity_student_score,
        student_ids
      ),
      count_graded: this.getGradedCount(
        activity,
        activity_student_score,
        student_ids
      ),
      count_overdue: this.getOverdueCount(
        activity,
        activity_student_score,
        student_ids
      ),
    }
  }

  /**
   * Calculates what the activity status lock is - whether it should be checked/unchecked/disabled or no checkbox.
   *
   * Current understanding is that the lock state of activity depends on:
   * - `suppress_until_available` of a parent lesson (defined by lesson.id)
   * - `suppress_until_available` of a parent page (defined by order in activity list)
   * - whether it belongs to an advanced parent component (defined by `in_advanced`)
   */
  getActivityLockStatus(
    activity: Activity,
    activities: Activity[],
    isActivitySuppressedUntilAvailable: (
      a: Activity,
      ancestors?: { lesson: Activity | null, page: Activity | null, project: Activity | null }
    ) => { isSuppressed: boolean, localChange?: boolean } = a => ({ isSuppressed: !!a.suppress_until_available })
  ): ActivityLockStatus {
    // activities which contain only course data and
    // not being lessons, pages or not belonging to advanced activity
    // cannot be locked or unlocked (no checkbox)
    if (activity.type !== 'lesson' && activity.type !== 'page' && !activity.in_advanced && activity.course_data_only) return 'none';

    const ancestorLesson = this.findAncestorLesson(activity, activities);
    const ancestorPage = this.findAncestorPage(activity, activities);
    const ancestorProject = this.findAncestorProject(activity, activities);
    const isAncestorLessonLocked = !!ancestorLesson && isActivitySuppressedUntilAvailable(ancestorLesson);
    const isAncestorPageLocked = !!ancestorPage && isActivitySuppressedUntilAvailable(ancestorPage);
    const isAncestorProjectLocked = !!ancestorProject && isActivitySuppressedUntilAvailable(ancestorProject);

    // if any of ancestors (lesson, page or project) is locked,
    // the activity is also locked, and its state cannot be changed
    if ((isAncestorLessonLocked && isAncestorLessonLocked.isSuppressed) || (isAncestorPageLocked && isAncestorPageLocked.isSuppressed) || (isAncestorProjectLocked && isAncestorProjectLocked.isSuppressed)) return 'locked-disabled';

    const isLessonOrPage = activity.type === 'lesson' || activity.type === 'page';
    const { isSuppressed, localChange } = isActivitySuppressedUntilAvailable(activity, {
      lesson: ancestorLesson,
      page: ancestorPage,
      project: ancestorProject,
    });

    // for lessons and pages we just look up the `suppress_until_available`
    // and use its value for checkbox state
    if (isLessonOrPage) {
      if (isSuppressed && !activity.cd_sua) return 'locked-disabled';
      return isSuppressed ? "locked" : "unlocked";
    }
    // local changes (before saving and recalculating by the backend) have higher precedence over later logic
    if (activity.in_advanced && localChange) return isSuppressed ? "locked" : "unlocked";

    // the logic below was adapted from `frontend/app/modules/activity_settings/settings.js` (line 50 and below)
    // and needs clarification in terms on the intended behavior

    if (activity.in_advanced && activity.cd_sua) return 'locked';
    if (activity.in_advanced && !isSuppressed && activity.type !== 'comments') return 'unlocked';
    if (!activity.course_data_only) return isSuppressed ? "locked-disabled" : "none";

    return isSuppressed ? "locked-disabled" : "unlocked";
  }

  /**
   * Finds project for the given comment, assumes that the activities list is sorted in such a way,
   * that first non-comment activity preceding the comment must be the project it belongs to.
   */
  private findAncestorProject = cachedFindRelatedActivity((activity: Activity, activities: Activity[]): Activity | null => {
    if (activity.type !== "comments") return null;

    const index = activities.findIndex(a => a.id === activity.id);

    if (index === -1) return null;

    const previousNonComment = activities
      .slice(0, index)
      .findLast((a) => a.type !== "comments");

    return previousNonComment &&
      previousNonComment.type === "project" &&
      previousNonComment.comments_comp === activity.id
      ? previousNonComment
      : null;
  })

  /**
   * Finds page for the given activity, assumes that the activities list is sorted in such a way,
   * that first page activity preceding the activity must be the page it belongs to.
   */
  private findAncestorPage = cachedFindRelatedActivity((activity: Activity, activities: Activity[]): Activity | null => {
    if (activity.type === "lesson" || activity.type === "page") return null;

    const index = activities.findIndex(a => a.id === activity.id);

    if (index === -1) return null;

    return (
      activities.slice(0, index).findLast((a) => a.type === "page") || null
    );
  })

  /** Finds lesson for activity based on its `lesson.id` property */
  private findAncestorLesson = cachedFindRelatedActivity((activity: Activity, activities: Activity[]): Activity | null => {
    if (!activity.lesson || activity.type === 'lesson') return null;

    return (
      activities.find(
        (a) => a.type === "lesson" && a.id === activity.lesson.id
      ) || null
    );
  })

  getLastSubmittedActivities(activity_student_score: ActivityStudentScore): LastSubmittedActivityPerStudent {
    const output: LastSubmittedActivityPerStudent = {};

    for (const [activity_id, students] of Object.entries(activity_student_score)) {
      if (activity_id.startsWith("_")) {
        continue;
      }

      for (const [user_id, user_score] of Object.entries(students)) {
        if (user_id === 'average' || user_id === 'median' || Object.keys(user_score).length <= 1) {
          continue;
        }

        const submission_date = user_score.dt_started || user_score.dt_completed;
        if ((!Object.keys(output).includes(user_id) && submission_date) || submission_date > output[user_id]?.submission_date) {
          output[user_id] = {
            submission_date: submission_date,
            activity_id: activity_id
          }
        }
      }
    }

    return output;
  }

  getActivityId(activity: Activity): string {
    let activity_id = activity.type === 'custom' ? '_' + activity.id : activity.id;
    if (activity.activity_id) {
      activity_id = activity.activity_id;
    }

    return activity_id;
  }

}

export default ActivitiesService.getSingleton();

export {ActivitiesService as ActivitiesServiceInstance}

/**
 * Utility to optimize searching for activities by given predicate within an array.
 * Change to array reference invalidates the cache.
 */
function cachedFindRelatedActivity(findActivityByFunc: (activity: Activity, activities: Activity[]) => Activity | null) {
  const cache = new Map<string, Activity>();
  const cachedList: Activity[] = [];

  return (activity: Activity, activities: Activity[]): Activity | null => {
    if (activities !== cachedList) cache.clear()

    const cachedResult = cache.get(activity.id);

    if (cachedResult) return cachedResult;

    const result = findActivityByFunc(activity, activities);
    cache.set(activity.id, result);

    return result;
  }
}
