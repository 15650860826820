import {AssessmentAttempt} from '../types/reports';

export function LearnositySessionDuration(score: AssessmentAttempt): string {
  let session_time = '';
  const total_time = score.items.reduce((total: number, item) => total + (item.time || 0), 0);
  const minutes = Math.floor(total_time / 60);
  const seconds = total_time % 60
  session_time = minutes + 'm ' + seconds + 's';
  return session_time;
};

export function getLearnosityStatus(score, overrides): string {
  if (score.status !== "Completed" ) return 'Not Submitted';    
  if (score.manually_accepted) return 'Late (Accepted)';
  if (score.manually_rejected) return 'Late (Rejected)';
  if (score.overdue && (overrides?.policy || score.policy_applied)) {
    return 'Late (Auto-Deduction Applied)'
  };
   if (score.overdue) return 'Late';
  
  return 'On Time';
}
