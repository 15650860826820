import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`${T.when(x => !x.loaded, T.html`
  <section class="container__loader">
    <jnct-loading-spinner></jnct-loading-spinner>
  </section>
`)}

${T.when(x => x.loaded, T.html`
  <header class="flex flex__row">
    <div class="title-container flex flex__row gap-8">
        <span class="material-symbols-outlined title-icon">insert_chart</span>
        <h1>Assessment Deep Dive</h1>
    </div>
    <jnct-searchbar :handleChange="${x=>x.search}"></jnct-searchbar>
  </header>
  <div class="layout__container">
    <jnct-table
      :data="${x => x.assessments}"
      :columnsConfig="${x => x.columns}"
      columnsLayout="40% 20% 15% 20% 30px"></jnct-table>
  </div>
`)}
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex__row {
  flex-direction: row;
}
.flex__columns {
  flex-direction: column;
}
.flex.gap-4 {
  gap: 4px;
}
.flex.gap-8 {
  gap: 8px;
}
.flex.gap-12 {
  gap: 12px;
}
.flex.gap-16 {
  gap: 16px;
}
.flex.gap-20 {
  gap: 20px;
}
.flex.gap-32 {
  gap: 32px;
}
.flex.text-container {
  align-items: start;
}

.container__loader {
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

header {
  width: 100%;
  z-index: 2;
  padding: 20px 32px 10px;
  box-sizing: border-box;
  background: #fff;
  transition: all 0.2s ease-in-out;
}
header .title-container .title-icon {
  font-size: 22px;
}
header .title-container h1 {
  font-weight: 400;
  font-size: 20px;
}

.layout__container {
  box-sizing: border-box;
  padding: 24px 32px;
  overflow: visible;
}
.layout__container jnct-table::part(table) {
  max-height: 65vh;
  overflow: auto;
}
.layout__container jnct-table::part(header_wrapper) {
  position: sticky;
  top: 0;
  background: #fff;
}`;


import { observable, RWSViewComponent, RWSView, RWSInject } from "@rws-framework/client";
import { Course } from "../../types/course";
import {RosterRecord} from "../../types/users";
import { ColumnConfig } from "src/types/table";
import AssessmentsService, { AssessmentsServiceInstance } from "../../services/AssessmentsService";

@RWSView('assessment-deep-dive', null, { template: rwsTemplate, styles })
class AssessmentDeepDive extends RWSViewComponent  {
  @observable loaded: boolean = false;
  @observable course: Course;
  @observable activities: any;
  @observable average: any;
  @observable assessments: any[] = [];
  @observable allAssessments: any[] = [];
  @observable assessmentsData: any[] = [];

  columns: ColumnConfig[] = [
    {
      name: "link_title",
      title: "Assessment Title",
      allowSorting: true,
    },
    {
      name: "due_date",
      title: "Due Date",
      allowSorting: true,
      date: true,
      fulldate: true
    },
    {
      name: "average_grade",
      title: "Average Grade",
      allowSorting: true
    },
    {
      name: "points_available",
      title: "Points Available",
      allowSorting: true
    },
    {
      name: "url",
      pattern: "<a href='value'><span class='material-symbols-outlined details-button'>chevron_right</span></a>",
      replaceValue: "url",
      title: '',
      allowSorting: false
    },
  ];

  constructor(@RWSInject(AssessmentsService) private assessmentService: AssessmentsServiceInstance){
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    setTimeout(async () => {
      this.course = await this.getCourse() as Course;
      this.activities = await this.getActivities() as any;
      this.average = await this.getAverage() as any;
      this.assessmentsData = await this.assessmentService.getDeepDiveListView();

      this.prepareData(this.assessmentsData);
      this.loaded = true;
    }, 30)
  };

  prepareData = (data: any[]) => {
    data.forEach((element, index) => {
      this.assessments[index] = {
        "id": {
          "value": element.id,
        },
        "name": {
          "value": element.title,
        },
        "link_title": {
          "value": element.title,
          "link": element.link
        },
        "due_date": {
          "value": element.due_date ? new Date(element.due_date) : '-',
        },
        "average_grade": {
          "value": parseFloat((element.avg_score).toFixed(1)) + '%'
        },
        "points_available": {
          "value": element.max_score
        },
        "url": {
          "value": window.location.href + '/' + element.id,
          "onClick": () => window.location.href = window.location.href + '/' + element.id,
        }
      }
    })
    this.allAssessments = this.assessments;
  }

  getCourse() {
    return new Promise((resolve) => {
      this.$emit('jnct:dashboardService:getCourse', (data: Course) => {
        resolve(data)
      });
    })
  };

  getActivities() {
    return new Promise((resolve) => {
      this.$emit('jnct:dashboardService:getActivities', (data: any) => {
        resolve(data)
      });
    })
  };

  getAverage() {
    return new Promise((resolve) => {
      this.$emit('jnct:dashboardService:getAverage', (data: RosterRecord) => {
        resolve(data)
      });
    })
  };

  search = (value: any) => {
    this.assessments = this.allAssessments.filter((obj) =>
      obj.link_title.value.toLowerCase().includes(value.toLowerCase())
    );
  };
}

AssessmentDeepDive.defineComponent();

export { AssessmentDeepDive };
