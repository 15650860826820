import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="content">
  <slot></slot>
  ${T.when(x => x.enableGradedOption || x.getActivityLockStatus, T.html`
  <div class="options_row">
    <div class="header">
      Basic ${T.when( x => x.changed.basic, T.html`<span
        class="rm_changes"
        @click="${x=>x.resetBasic()}"
        >Remove Changes</span
      >`)}
    </div>
    <div class="form_options">
      ${T.when(x => x.enableGradedOption, T.html`
      <div class="option">
        <span style="display: flex; align-content: center; gap: 4px"
          >${x => x.labels.graded}</span
        >
        ${x => !x.prefillWithDefault && !('graded' in x.bu_payload) ?
        T.html`<span class="change" @click="${x=>x.activateField('graded')}"
          >Change</span
        >` : T.html`<jnct-select-input
          style="width: 200px"
          :options="${x=>x.gradedOptions}"
          :default="${x=> x.gradedInputValue}"
          :afterChange="${x=>x.onGradedChange}"
          fieldName="graded"
        ></jnct-select-input
        >` }
      </div>
      `)} ${T.when(x => x.getActivityLockStatus, T.html`
      <div class="option">
        <span style="display: flex; align-content: center; gap: 4px"
          >Lock <span class="material-symbols-outlined">lock</span></span
        >
        ${x => !x.prefillWithDefault && !('suppress_until_available' in
        x.bu_payload) ? T.html`<span
          class="change"
          @click="${x=>x.activateField('suppress_until_available')}"
          >Change</span
        >` : x.activityLockStatus === 'none' ? T.html`-` : T.html`
        <jnct-checkbox-input
          :default="${(x) => x.activityLockStatus === 'locked' || x.activityLockStatus === 'locked-disabled'}"
          :afterChange="${(x)=>x.onLockStatusChange}"
          fieldName="suppress_until_available"
          :disabled="${(x)=>x.activityLockStatus === 'locked-disabled'}"
        ></jnct-checkbox-input>
        ` }
      </div>
      `)}
    </div>
  </div>
  `)}

  <div class="options_row">
    <div class="header">
      Attempts ${T.when( x => x.changed.attempts, T.html`<span
        class="rm_changes"
        @click="${x=>x.resetAttempts()}"
        >Remove Changes</span
      >`)}
    </div>
    <div class="form_options">
      <div class="option">
        <span style="display: flex; align-content: center; gap: 4px"
          >${x => x.labels.submission_attempts}
          <jnct-activity-settings-tooltip>
            Info: The number of times a learner can submit an assessment. Each
            submission is a new attempt and restarts the assessment. Enter 0 for unlimited.
          </jnct-activity-settings-tooltip>
        </span>
        ${x => !x.prefillWithDefault && !('submission_attempts' in x.bu_payload)
        ? T.html`<span
          class="change"
          @click="${x=>x.activateField('submission_attempts')}"
          >Change</span
        >` : T.html`<jnct-number-input
          :default="${x=> (x.bu_payload.submission_attempts ?? x.current.submission_attempts) || 0}"
          :afterChange="${x=> x.fieldChange}"
          fieldName="submission_attempts"
        ></jnct-number-input
        >` }
      </div>
      <div class="option">
        <span style="display: flex; align-content: center; gap: 4px"
          >${x => x.labels.resume_max_attempts}
          <jnct-activity-settings-tooltip>
            Info: The number of times a user can re-open a submission. In
            re-opening an assessment, a learner sees whether their answers are
            correct and can update them as needed. As an instructor, the
            re-opened assessment appears as a single submission. Enter -1 for unlimited.
          </jnct-activity-settings-tooltip>
        </span>
        ${x => !x.prefillWithDefault && !('resume_max_attempts' in x.bu_payload)
        ? T.html`<span
          class="change"
          @click="${x=>x.activateField('resume_max_attempts')}"
          >Change</span
        >` : T.html`<jnct-number-input
          :default="${x=> (x.bu_payload.resume_max_attempts ?? x.current.resume_max_attempts) || 0}"
          :afterChange="${x=> x.fieldChange}"
          fieldName="resume_max_attempts"
          minValue=-1
        ></jnct-number-input
        >` }
      </div>
    </div>
  </div>

  <div class="options_row">
    <div class="header">
      Grades and Correct Answers ${T.when( x =>
      x.changed.gradesAndCorrectAnswers, T.html`<span
        class="rm_changes"
        @click="${x=>x.resetGradesAndCorrectAnswers()}"
        >Remove Changes</span
      >`)}
    </div>
    <div class="form_options">
      <div class="option">
        <span>${x => x.labels.inline.group}</span>
        ${x => !x.prefillWithDefault && !(x.isAllowCheckAnswerEnabled) ?
        T.html`<span class="change" @click="${x=>x.enableAllowCheckAnswer()}"
          >Change</span
        >` : T.html`<jnct-checkbox-input
          :default="${x=> x.isAllowCheckAnswerEnabled}"
          :afterChange="${x=> (key, value) => value ? x.enableAllowCheckAnswer() : x.disableAllowCheckAnswer()}"
          fieldName="isAllowCheckAnswerEnabled"
        ></jnct-checkbox-input
        >` }
      </div>
      <div
        class="options_row sub ${x => x.isAllowCheckAnswerEnabled ? '' : 'hidden'}"
      >
        <div class="form_options">
          <div class="option">
            <span>${x => x.labels.inline.showIndicator}</span>
            <jnct-select-input
              style="width: 200px"
              :options="${x=>x.showInlineIndicatorOptions}"
              :default="${x=> x.displayedShowIndicator}"
              :afterChange="${x=> x.fieldChange}"
              fieldName="inline"
            ></jnct-select-input>
          </div>
          <div class="option">
            <span>${x => x.labels.inline.showCorrect}</span>
            <jnct-select-input
              style="width: 200px"
              :options="${x=>x.showInlineWithCorrectAnswerOptions}"
              :default="${x=> x.displayedShowCorrectAnswer}"
              :afterChange="${x=> x.showCorrectAnswerChange}"
              fieldName="inline2"
            ></jnct-select-input>
          </div>
        </div>
      </div>

      <div class="option">
        <span>${x => x.labels.show_correct_answers.group}</span>
        ${x => !x.prefillWithDefault &&
        !(x.isAllowStudentToReviewSubmittedAssessmentEnabled) ? T.html`<span
          class="change"
          @click="${x=>x.enableAllowStudentToReviewSubmittedAssessment()}"
          >Change</span
        >` : T.html`<jnct-checkbox-input
          :default="${x=> x.isAllowStudentToReviewSubmittedAssessmentEnabled}"
          :afterChange="${x=> (key, value) => value ? x.enableAllowStudentToReviewSubmittedAssessment() : x.disableAllowStudentToReviewSubmittedAssessment()}"
          fieldName="allowStudentToReviewSubmittedAssessment"
        ></jnct-checkbox-input
        >` }
      </div>
      <div
        class="options_row sub ${x => x.isAllowStudentToReviewSubmittedAssessmentEnabled ? '' : 'hidden'}"
      >
        <div class="form_options">
          <div class="option">
            <span>${x => x.labels.show_responses.showCorrect}</span>
            <jnct-select-input
              style="width: 200px"
              :options="${x=>x.showAnswersOptions}"
              :default="${x=> x.bu_payload.show_responses ?? x.current.show_responses}"
              :afterChange="${x=>x.fieldChange}"
              fieldName="show_responses"
            ></jnct-select-input>
          </div>
          <div class="option">
            <span>${x => x.labels.show_correct_answers.showIndicator}</span>
            <jnct-select-input
              style="width: 200px"
              :options="${x=>x.showAnswersOptions}"
              :default="${x=> x.bu_payload.show_correct_answers ?? x.current.show_correct_answers}"
              :afterChange="${x=>x.fieldChange}"
              fieldName="show_correct_answers"
            ></jnct-select-input>
          </div>
        </div>
      </div>
      <div class="option">
        <span>${x => x.labels.show_score}</span>
        ${x => !x.prefillWithDefault && !('show_score' in x.bu_payload) ?
        T.html`<span class="change" @click="${x=>x.activateField('show_score')}"
          >Change</span
        >` : T.html`<jnct-select-input
          style="width: 200px"
          :options="${x=>x.showScoreOptions}"
          :default="${x=> x.showScoreInputValue}"
          :afterChange="${x=>x.onShowScoreChange}"
          fieldName="show_score"
        ></jnct-select-input
        >` }
      </div>
      <div class="option">
        <span>${x => x.labels.show}</span>
        ${x => !x.prefillWithDefault && !('show' in x.bu_payload) ? T.html`<span
          class="change"
          @click="${x=>x.activateField('show')}"
          >Change</span
        >` : T.html`<jnct-checkbox-input
          :default="${x=> x.bu_payload.show ?? x.current.show}"
          :afterChange="${x=>x.fieldChange}"
          fieldName="show"
        ></jnct-checkbox-input
        >` }
      </div>
    </div>
  </div>

  <div class="options_row">
    <div class="header">
      Question Order and Restrictions ${T.when( x =>
      x.changed.questionOrderAndRestrictions, T.html`<span
        class="rm_changes"
        @click="${x=>x.resetQuestionOrderAndRestrictions()}"
        >Remove Changes</span
      >`)}
    </div>
    <div class="form_options">
      <div class="option">
        <span>${x => x.labels.scramble}</span>
        ${x => !x.prefillWithDefault && !('scramble' in x.bu_payload) ?
        T.html`<span class="change" @click="${x=>x.activateField('scramble')}"
          >Change</span
        >` : T.html`<jnct-checkbox-input
          :default="${x=> x.bu_payload.scramble ?? x.current.scramble}"
          :afterChange="${x=>x.fieldChange}"
          fieldName="scramble"
        ></jnct-checkbox-input
        >` }
      </div>
      <div class="option">
        <span>${x => x.labels.lock_back_button}</span>
        ${x => !x.prefillWithDefault && !('lock_back_button' in x.bu_payload) ?
        T.html`<span
          class="change"
          @click="${x=>x.activateField('lock_back_button')}"
          >Change</span
        >` : T.html`<jnct-checkbox-input
          :default="${x=> x.bu_payload.lock_back_button ?? x.current.lock_back_button}"
          :afterChange="${x=>x.fieldChange}"
          fieldName="lock_back_button"
        ></jnct-checkbox-input
        >` }
      </div>
      <div class="option">
        <span>${x => x.labels.time_limit}</span>
        ${x => !x.prefillWithDefault && !('time_limit' in x.bu_payload) ?
        T.html`<span class="change" @click="${x=>x.activateField('time_limit')}"
          >Change</span
        >` : T.html`<jnct-number-input
          :default="${x=> x.timeLimitInputValue.value}"
          :suffix="${x => x.timeLimitInputValue.suffix}"
          :afterChange="${x=>x.onTimeLimitChange}"
          fieldName="time_limit"
        ></jnct-number-input
        >` }
      </div>
    </div>
  </div>

  <div class="options_row">
    <div class="header">
      <div style="display: flex; gap: 4px">Discussion Board Settings</div>
      ${T.when( x => x.changed.discussionBoardSettings, T.html`<span
        class="rm_changes"
        @click="${x=>x.resetDiscussionBoardSettings()}"
        >Remove Changes</span
      >`)}
    </div>
    <div class="form_options">
      <div class="option">
        <span>${x => x.labels.requires_comment}</span>
        ${x => !x.prefillWithDefault && !('requires_comment' in x.bu_payload) ?
        T.html`<span
          class="change"
          @click="${x=>x.activateField('requires_comment')}"
          >Change</span
        >` : T.html`<jnct-checkbox-input
          :default="${x=> x.bu_payload.requires_comment ?? x.current.requires_comment}"
          :afterChange="${x=>x.fieldChange}"
          fieldName="requires_comment"
        ></jnct-checkbox-input
        >` }
      </div>
      <div class="option">
        <span>${x => x.labels.max_score}</span>
        ${x => !x.prefillWithDefault && !('max_score' in x.bu_payload) ?
        T.html`<span class="change" @click="${x=>x.activateField('max_score')}"
          >Change</span
        >` : T.html`<jnct-number-input
          :default="${x=> (x.bu_payload.max_score ?? x.current.max_score) || 0}"
          :afterChange="${x=>x.fieldChange}"
          fieldName="max_score"
        ></jnct-number-input
        >` }
      </div>
    </div>
  </div>

  <div class="options_row">
    <div class="header">
      Adaptive Recommendation ${T.when( x => x.changed.adaptiveRecommendation,
      T.html`<span
        class="rm_changes"
        @click="${x=>x.resetAdaptiveRecommendation()}"
        >Remove Changes</span
      >`)}
    </div>
    <div class="form_options">
      <div class="option">
        <span>${x => x.labels.confidence}</span>
        ${x => !x.prefillWithDefault && !('confidence' in x.bu_payload) ?
        T.html`<span class="change" @click="${x=>x.activateField('confidence')}"
          >Change</span
        >` : T.html`<jnct-checkbox-input
          :default="${x=> x.bu_payload.confidence ?? x.current.confidence}"
          :afterChange="${x=>x.fieldChange}"
          fieldName="confidence"
        ></jnct-checkbox-input
        >` }
      </div>
      <div class="option">
        <span>${x => x.labels.studycenter}</span>
        ${x => !x.prefillWithDefault && !('studycenter' in x.bu_payload) ?
        T.html`<span
          class="change"
          @click="${x=>x.activateField('studycenter')}"
          >Change</span
        >` : T.html`<jnct-checkbox-input
          :default="${x=> x.bu_payload.studycenter ?? x.current.studycenter}"
          :afterChange="${x=>x.fieldChange}"
          fieldName="studycenter"
        ></jnct-checkbox-input
        >` }
      </div>
    </div>
  </div>

  <div class="options_row">
    <div class="header">
      Late Work Policy Overrides ${T.when( x => x.changed.lateWorkPolicy,
      T.html`<span class="rm_changes" @click="${x=>x.resetLateWorkPolicy()}"
        >Remove Changes</span
      >`)}
    </div>
    <div class="form_options">
      <div class="option">
        <span>${x => x.labels.policy}</span>
        ${x => !x.prefillWithDefault && !(x.isPolicyEnabled) ? T.html`<span
          class="change"
          @click="${x=>x.enablePolicy()}"
          >Change</span
        >` : T.html`<jnct-checkbox-input
          :default="${x=> x.isPolicyEnabled}"
          :afterChange="${x=>(key, value)=> value ? x.enablePolicy() : x.disablePolicy()}"
          fieldName="policy"
        ></jnct-checkbox-input
        >` }
      </div>
      <div class="options_row sub ${x => x.isPolicyEnabled ? '' : 'hidden'}">
        <div class="form_options">
          <div class="option">
            <span>Set Deduction</span>
            <span style="display: flex; gap: 8px; align-items: center">
              <jnct-number-input
                suffix="%"
                :default="${x=>(x.bu_payload.policy?.penalty ?? x.current.policy?.penalty) || 0}"
                :afterChange="${x=>x.changePolicy}"
                fieldName="penalty"
              ></jnct-number-input>
              per
              <jnct-select-input
                style="width: 200px"
                :options="${x=>x.humanlyChoicesOptions}"
                :default="${x=>x.bu_payload.policy?.humanly_per ?? x.current.policy?.humanly_per}"
                :afterChange="${x=>x.changePolicy}"
                fieldName="humanly_per"
              ></jnct-select-input>
            </span>
          </div>
          <div class="option">
            <span>Set Maximum Deduction</span>
            <jnct-number-input
              suffix="%"
              :default="${x=>(x.bu_payload.policy?.max ?? x.current.policy?.max) || 0}"
              :afterChange="${x=>x.changePolicy}"
              fieldName="max"
            ></jnct-number-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.content {
  padding: 28px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
  width: calc(100% - 48px);
  height: 100%;
}
.content .row {
  display: flex;
  justify-content: space-between;
}
.content .row.center {
  justify-content: center;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
}
.content .row button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  text-align: center;
  background: transparent;
}
.content .row .text {
  color: #000;
  align-self: stretch;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
}
.content .row .select_text {
  color: var(--Color-palette-Neutral-70, #3b3c3e);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}
.content .row .title_text {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.content .options_row {
  border-radius: 12px;
  border: 1px solid var(--Color-palette-Neutral-20, #eeeff0);
  background: var(--White, #fff);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 24px;
}
.content .options_row .form_options {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.content .options_row .form_options .option {
  height: 24px;
  display: flex;
  justify-content: space-between;
}
.content .options_row .form_options .option .change {
  cursor: pointer;
  overflow: hidden;
  color: #0f75b8;
  text-align: right;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}
.content .options_row .form_options .hidden {
  display: none;
}
.content .options_row .sub {
  box-shadow: none;
}
.content .options_row .header {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  display: flex;
  gap: 8px;
  justify-content: space-between;
}
.content .options_row .header .rm_changes {
  cursor: pointer;
  overflow: hidden;
  color: var(--Color-palette-Action-Error, #de1135);
  text-align: right;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}`;

const mode = "open";
import { attr, RWSViewComponent, RWSView } from "@rws-framework/client";
import { changeTimeZone } from "../../../../helpers/changeTimeZone";
import { omit, mapObject } from "../../../../helpers/utils";
import {
  ShowScore,
  ShowAnswers,
  ShowInline,
  HumanlyChoices,
  GradedState,
  ActivityLockStatus,
  Activity,
} from "../../../../types/activities";

import { UpdateModel, Update, groups } from "../common";

@RWSView('jnct-activity-settings-form', null, { template: rwsTemplate, styles, options: {mode} })
class JunctionActivitySettingsForm extends RWSViewComponent  {
  @attr prefillWithDefault: boolean = true;
  /** values of updated fields */
  @attr bu_payload: Partial<Update> = {};
  /** set only when editing single activity */
  @attr current: Partial<Update> = {};

  @attr enableGradedOption = true;
  @attr onChange: (a: Partial<Update>) => void = () => {};
  @attr getActivityLockStatus: ((a: Activity) => ActivityLockStatus) | null =
    null;

  /** defaults for each field */
  defaults = UpdateModel.default();
  labels = UpdateModel.labels();

  showAnswersOptions: ShowAnswers[] = [
    ShowAnswers.IMMEDIATELY,
    ShowAnswers.AFTER_MAX_ATTEMPTS,
    ShowAnswers.AFTER_DUE_DATE,
    ShowAnswers.AFTER_CLOSE_DATE,
  ];

  displayGradeOptions: ShowScore[] = [
    ShowScore.WITH_RESPONSES,
    ShowScore.IMMEDIATELY,
  ];

  humanlyChoicesOptions: HumanlyChoices[] = [
    HumanlyChoices.HOUR,
    HumanlyChoices.DAY,
    HumanlyChoices.WEEK,
  ];

  gradedOptions: GradedState[] = [GradedState.GRADED, GradedState.NOT_GRADED];

  get changed() {
    return mapObject(groups, (keys) =>
      keys.some(
        (key) =>
          key in this.bu_payload && this.bu_payload[key] !== this.current[key]
      )
    );
  }

  resetBasic() {
    this.onChange(omit(this.bu_payload, groups.basic));
  }
  resetAttempts() {
    this.onChange(omit(this.bu_payload, groups.attempts));
  }
  resetGradesAndCorrectAnswers() {
    this.onChange(omit(this.bu_payload, groups.gradesAndCorrectAnswers));
  }
  resetQuestionOrderAndRestrictions() {
    this.onChange(omit(this.bu_payload, groups.questionOrderAndRestrictions));
  }
  resetDiscussionBoardSettings() {
    this.onChange(omit(this.bu_payload, groups.discussionBoardSettings));
  }
  resetAdaptiveRecommendation() {
    this.onChange(omit(this.bu_payload, groups.adaptiveRecommendation));
  }
  resetLateWorkPolicy() {
    this.onChange(omit(this.bu_payload, groups.lateWorkPolicy));
  }

  fieldChange = (fieldName: string, value: any) => {
    this.onChange({ ...this.bu_payload, [fieldName]: value });
  };

  activateField = (fieldName: string) => {
    this.onChange({
      ...this.bu_payload,
      [fieldName]: this.defaults[fieldName],
    });
  };

  // "Allow Students to Review Submitted Assessment" related
  enableAllowStudentToReviewSubmittedAssessment() {
    this.onChange({
      ...this.bu_payload,
      show_correct_answers: ShowAnswers.IMMEDIATELY,
      show_responses: ShowAnswers.IMMEDIATELY,
    });
  }

  disableAllowStudentToReviewSubmittedAssessment() {
    this.onChange({
      ...this.bu_payload,
      show_correct_answers: ShowAnswers.NEVER,
      show_responses: ShowAnswers.NEVER,
    });
  }

  get isAllowStudentToReviewSubmittedAssessmentEnabled() {
    const isDefined =
      (this.bu_payload.show_correct_answers ??
        this.current.show_correct_answers) !== undefined &&
      (this.bu_payload.show_responses ?? this.current.show_responses) !==
        undefined;
    const isDisabled =
      (this.bu_payload.show_correct_answers ??
        this.current.show_correct_answers) === ShowAnswers.NEVER &&
      (this.bu_payload.show_responses ?? this.current.show_responses) ===
        ShowAnswers.NEVER;

    return isDefined && !isDisabled;
  }

  // "Allow Students to Check Answers During the Assessment" (`inline` property) related
  //
  // `inline` option is split into two form inputs:
  //  - show indicator
  //  - show correct answer
  //
  // value of `inline` is a incomplete cross-product of NEVER, AFTER_DUE_DATE, IMMEDIATELY
  // which results in NEVER, AFTER_DUE_DATE, AFTER_DUE_DATE_WITH_CORRECT_ANSWERS, IMMEDIATELY, IMMEDIATELY_WITH_CORRECT_ANSWERS and IMMEDIATELY_WITH_CORRECT_ANSWERS_AFTER_DUE_DATE
  //
  // due to dependencies between these two inputs (stored as single value)
  // we have to dynamically:
  //  - generate list of options for inputs
  //  - calculate the currently displayed value in each input
  //  - combine and update the value after change
  enableAllowCheckAnswer() {
    this.onChange({
      ...this.bu_payload,
      inline: ShowInline.IMMEDIATELY,
    });
  }
  disableAllowCheckAnswer() {
    this.onChange({
      ...this.bu_payload,
      inline: ShowInline.NEVER,
    });
  }
  get isAllowCheckAnswerEnabled() {
    const isDefined =
      (this.bu_payload.inline ?? this.current.inline) !== undefined;
    const isDisabled =
      (this.bu_payload.inline ?? this.current.inline) === ShowInline.NEVER;

    return isDefined && !isDisabled;
  }

  showInlineIndicatorOptions: ShowInline[] = [
    ShowInline.IMMEDIATELY,
    ShowInline.AFTER_DUE_DATE,
    ShowInline.NEVER,
  ];

  get showInlineWithCorrectAnswerOptions() {
    const value = this.bu_payload?.inline ?? this.current.inline;

    if (
      value === ShowInline.AFTER_DUE_DATE ||
      value === ShowInline.AFTER_DUE_DATE_WITH_CORRECT_ANSWERS
    )
      return [ShowInline.NEVER, ShowInline.AFTER_DUE_DATE];

    return [
      ShowInline.IMMEDIATELY,
      ShowInline.AFTER_DUE_DATE,
      ShowInline.NEVER,
    ];
  }

  get displayedShowIndicator() {
    return JunctionActivitySettingsForm.displayedInlineShowIndicator(
      this.bu_payload.inline ?? this.current.inline
    );
  }

  public static displayedInlineShowIndicator(value?: Update["inline"]) {
    switch (value) {
      case ShowInline.AFTER_DUE_DATE:
      case ShowInline.AFTER_DUE_DATE_WITH_CORRECT_ANSWERS:
        return ShowInline.AFTER_DUE_DATE;
      case ShowInline.IMMEDIATELY:
      case ShowInline.IMMEDIATELY_WITH_CORRECT_ANSWERS:
      case ShowInline.IMMEDIATELY_WITH_CORRECT_ANSWERS_AFTER_DUE_DATE:
        return ShowInline.IMMEDIATELY;

      case ShowInline.NEVER:
        return ShowInline.NEVER;

      default:
        return value;
    }
  }

  get displayedShowCorrectAnswer() {
    return JunctionActivitySettingsForm.displayedInlineShowCorrectAnswer(
      this.bu_payload?.inline ?? this.current.inline
    );
  }

  public static displayedInlineShowCorrectAnswer(value?: Update["inline"]) {
    switch (value) {
      case ShowInline.IMMEDIATELY_WITH_CORRECT_ANSWERS:
        return ShowInline.IMMEDIATELY;
      case ShowInline.AFTER_DUE_DATE_WITH_CORRECT_ANSWERS:
      case ShowInline.IMMEDIATELY_WITH_CORRECT_ANSWERS_AFTER_DUE_DATE:
        return ShowInline.AFTER_DUE_DATE;

      case ShowInline.AFTER_DUE_DATE:
      case ShowInline.IMMEDIATELY:
      case ShowInline.NEVER:
        return ShowInline.NEVER;

      default:
        return value;
    }
  }

  showCorrectAnswerChange = (_key: "inline", value: ShowInline) => {
    const currentValue = this.bu_payload?.inline ?? this.current.inline;
    const isCurrentIndicatorImmediate = [
      ShowInline.IMMEDIATELY,
      ShowInline.IMMEDIATELY_WITH_CORRECT_ANSWERS,
      ShowInline.IMMEDIATELY_WITH_CORRECT_ANSWERS_AFTER_DUE_DATE,
    ].includes(currentValue);
    const isCurrentIndicatorAfter = [
      ShowInline.AFTER_DUE_DATE,
      ShowInline.AFTER_DUE_DATE_WITH_CORRECT_ANSWERS,
    ].includes(currentValue);

    if (isCurrentIndicatorImmediate && value === ShowInline.IMMEDIATELY)
      return this.fieldChange(
        "inline",
        ShowInline.IMMEDIATELY_WITH_CORRECT_ANSWERS
      );

    if (isCurrentIndicatorImmediate && value === ShowInline.AFTER_DUE_DATE)
      return this.fieldChange(
        "inline",
        ShowInline.IMMEDIATELY_WITH_CORRECT_ANSWERS_AFTER_DUE_DATE
      );

    if (isCurrentIndicatorImmediate && value === ShowInline.NEVER)
      return this.fieldChange("inline", ShowInline.IMMEDIATELY);

    if (isCurrentIndicatorAfter && value === ShowInline.AFTER_DUE_DATE)
      return this.fieldChange(
        "inline",
        ShowInline.AFTER_DUE_DATE_WITH_CORRECT_ANSWERS
      );

    if (isCurrentIndicatorAfter && value === ShowInline.NEVER)
      return this.fieldChange("inline", ShowInline.AFTER_DUE_DATE);
  };

  // "Late Work Policy Overrides" related
  enablePolicy() {
    this.onChange({
      ...this.bu_payload,
      policy: {
        max: 0,
        penalty: 0,
        per: 3600,
        humanly_per: HumanlyChoices.HOUR,
      },
    });
  }

  disablePolicy() {
    this.onChange({
      ...this.bu_payload,
      policy: {},
    });
  }

  get isPolicyEnabled() {

    const value = this.bu_payload.policy ?? this.current.policy;

    if (!!value && Object.keys(value).length) {
      // @ts-ignore
      const humanly_per: any = value.per == 3600 * 24 * 7? HumanlyChoices.WEEK : value.per == 3600 * 24? HumanlyChoices.DAY : HumanlyChoices.HOUR
      if (!this.bu_payload.policy) {
        // @ts-ignore
        this.current.policy.humanly_per = humanly_per
      } else {
        // @ts-ignore
        this.bu_payload.policy.humanly_per = humanly_per
      }
      return true
    }
    return false
  }

  changePolicy = (name: string, value: string) => {

    let updatedPolicy = { ...this.bu_payload.policy, ...this.current.policy, [name]: value };

    if (name === 'humanly_per') {
      switch (value) {
        case HumanlyChoices.HOUR:
          updatedPolicy.per = 3600;
          break;
        case HumanlyChoices.DAY:
          updatedPolicy.per = 3600 * 24;
          break;
        case HumanlyChoices.WEEK:
          updatedPolicy.per = 3600 * 24 * 7;
          break;
        default:
          updatedPolicy.per = 3600;
          break;
      }
    }
    
    this.onChange({
      ...this.bu_payload,
      policy: updatedPolicy,
    });
  };

  get gradedInputValue() {
    return this.bu_payload.graded ?? this.current.graded
      ? GradedState.GRADED
      : GradedState.NOT_GRADED;
  }

  onGradedChange = (field: string, isGraded: GradedState) => {
    this.fieldChange(field, isGraded === GradedState.GRADED);
  };

  showScoreOptions = ["Yes", "No"];

  get showScoreInputValue(): (typeof this)["showScoreOptions"][number] {
    return (this.bu_payload.show_score ?? this.current.show_score) ===
      ShowScore.IMMEDIATELY
      ? "Yes"
      : "No";
  }

  onShowScoreChange = (
    field: string,
    value: (typeof this)["showScoreOptions"][number]
  ) => {
    this.fieldChange(
      field,
      value === "Yes" ? ShowScore.IMMEDIATELY : ShowScore.WITH_RESPONSES
    );
  };

  get timeLimitInputValue() {
    const valueSeconds = this.bu_payload.time_limit ?? this.current.time_limit;
    const valueMinutes = !!valueSeconds
      ? String(Math.round(valueSeconds / 60))
      : "";

    return {
      value: valueMinutes,
      suffix: !!valueSeconds ? "min" : "None",
    };
  }

  public static formatTimeLimit(valueSeconds: number | undefined) {
    const valueMinutes = !!valueSeconds ? String(valueSeconds / 60) : "";

    return {
      value: valueMinutes,
      suffix: !!valueSeconds ? "min" : "None",
    };
  }

  onTimeLimitChange = (field: string, value: any) => {
    this.fieldChange(field, value * 60);
  };

  fieldDateChange = (field: string, dateString: string) => {
    const value = dateString ? changeTimeZone(dateString) : "";
    this.fieldChange(field, value);
  };

  get activityLockStatus(): ActivityLockStatus {
    const value = { ...this.current, ...this.bu_payload };
    const activity = "id" in value ? (value as Activity) : null;

    return activity && this.getActivityLockStatus
      ? this.getActivityLockStatus(activity)
      : value.suppress_until_available
      ? "locked"
      : "unlocked";
  }

  onLockStatusChange = (field: string, value: boolean) => {
    const currentOpenDate = this.bu_payload.open_date ?? this.current.open_date;

    this.onChange({
      ...this.bu_payload,
      suppress_until_available: value,

      // if we enable `suppress_until_available` and the activity does have an open date
      // we need to apply some default in distant future
      ...(!currentOpenDate &&
        value && { open_date: "2050-01-01T00:00:00.000Z" }),
    });
  };
}

JunctionActivitySettingsForm.defineComponent();

export { JunctionActivitySettingsForm };
