import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="dropdown">
  <span
    class="material-symbols-outlined icon ${x => x.isFilterNotApplied ? '' : 'icon--active'}"
    @click="${x=>x.switchDropdown()}"
    style="vertical-align: middle; cursor: pointer"
  >
    ${x => x.isFilterNotApplied ? 'filter_list' : 'filter_alt'}
  </span>
  ${T.when(x=>x.visible, T.html`
  <div class="dropdown-content ${x => x.dropdownPosition}">
    <div class="dropdown_header">
      <span class="title">${x => x.title} Filter</span>
      <button class="button__link" @click="${x=>x.clearFilter()}">
        Clear filter
      </button>
    </div>
    ${T.when(x=>x.allowSorting, T.html`
    <div class="s_header">Sort by</div>
    <div class="section">
      <div @click="${x => x.sortData('ascending')}">
        <input
          type="radio"
          :checked="${x => x.currentSorting===x.columnName+'ascending'}"
        />
        <label>${x => x.ascLabel }</label>
      </div>
      <div @click="${x=> x.sortData('descending')}">
        <input
          type="radio"
          :checked="${x=> x.currentSorting===x.columnName+'descending'}"
        />
        <label>${x => x.descLabel}</label>
      </div>
    </div>
    `)} ${T.when(x=>x.filtersType=="text", T.html`

    <input
      type="text"
      class="text_input"
      placeholder="Search"
      :value="${x => x.currentFilter?.text || ''}"
      @change="${(x, c)=>x.filterChange('text', (c.event.target as any).value)}"
    />
    `)}${T.when(x=>x.filtersType=="select", T.html`

    <jnct-select-input
      :options="${x=>x.options}"
      :default="${x => x.currentFilter?.text || x.options[0] || ''}"
      :afterChange="${x=>x.filterChange}"
      fieldName="text"
    ></jnct-select-input>
    `)} ${T.when(x=>x.filtersType=="dateRange", T.html`

    <div>
      Start<jnct-datetime-input
        inType="datetime-local"
        :default="${x => x.currentFilter?.start}"
        :afterChange="${x=>x.filterChange}"
        fieldName="start"
        :defaulttime="${x => x.defaulttime}"
      ></jnct-datetime-input>
    </div>
    <div>
      End
      <jnct-datetime-input
        inType="datetime-local"
        :default="${x => x.currentFilter?.end}"
        :afterChange="${x=>x.filterChange}"
        fieldName="end"
        :defaulttime="${x => x.defaulttime}"
      ></jnct-datetime-input>
    </div>

    `)} ${T.when(x=>x.filtersType=="multi-checkbox", T.html`
    <div class="multi-checkbox">
      ${T.repeat(x => x.options, T.html`
      <label
        class="multi-checkbox__item"
        @click="${(x, c) => c.parent.multiCheckboxChange(x, !c.parent.isCheckboxChecked(x))}"
      >
        <jnct-checkbox-input
          :default="${(x, c)=> c.parent.isCheckboxChecked(x)}"
          :afterChange="${(x, c) => c.parent.multiCheckboxChange}"
          fieldName="${(x, c) => x}"
        ></jnct-checkbox-input>
        ${(x, c) => x}
      </label>
      `)}
    </div>
    `)}
    <div>
      <button class="button__secondary" @click="${x=>x.switchDropdown()}">
        Close
      </button>
    </div>
  </div>
  `)}
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
  justify-content: center;
}
.dropdown .icon--active {
  color: var(--accent-base-color);
}

.dropdown-content {
  background: #ffffff;
  position: absolute;
  top: 20px;
  min-width: 200px;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.2509803922);
  border: 1px solid #eeeff0;
  z-index: 1;
}
.dropdown-content.right {
  left: 0;
}
.dropdown-content.left {
  right: 0;
}
.dropdown-content .text_input {
  width: calc(100% - 32px);
  border: none;
  display: flex;
  padding: 10px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Color-palette-Neutral-20, #eeeff0);
}
.dropdown-content div {
  padding: 0;
}
.dropdown-content .dropdown_header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-right: 0px;
}
.dropdown-content .dropdown_header .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.dropdown-content .dropdown_header .cancel {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: right;
  color: #0f75b8;
}
.dropdown-content .dropdown_header .button__link {
  font-size: 1rem;
  text-wrap: nowrap;
}
.dropdown-content .s_header {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}
.dropdown-content .section {
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #eeeff0;
  gap: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.dropdown-content .section input[type=radio] {
  accent-color: var(--primary_color);
}
.dropdown-content .section .search {
  border-radius: 12px;
  padding: 10px 5px;
  border: 1px solid #eeeff0;
  width: 100%;
}
.dropdown-content .section .select_input {
  padding: 5px 10px;
  border-radius: 12px;
  width: 100%;
  border: 1px solid #eeeff0;
}

.multi-checkbox {
  max-height: 200px;
  overflow-y: scroll;
}
.multi-checkbox__item {
  display: flex;
  cursor: pointer;
}`;

const mode = "open";
import {
  observable,
  RWSViewComponent,
  RWSView,
  attr,
} from "@rws-framework/client";

@RWSView('jnct-table-dropdown', null, { template: rwsTemplate, styles, options: {mode} })
class JunctionTableDropdown extends RWSViewComponent  {
  @observable visible: boolean = false;
  @observable dropdownPosition: "left" | "right" = "right";
  @attr title: string;
  @attr columnName: string;
  @attr allowSorting: boolean = false;
  @attr currentSorting: string;
  @attr ascLabel: string = "A-Z";
  @attr descLabel: string = "Z-A";
  @attr changeSorting: Function;
  @attr changeFiltering: Function;
  @attr filtersType: "text" | "select" | "dateRange" | "multi-checkbox";
  @attr defaulttime: string = "23:59";
  @attr options: string[];
  @attr currentFilter:
    | Partial<{ text: string; start: string; end: string; multi: string[] }>
    | undefined;

  boundClickAwayHandler = this.clickAwayHander.bind(this);

  get isFilterNotApplied() {
    return (
      !this.currentFilter ||
      Object.keys(this.currentFilter).length === 0 ||
      (!this.currentFilter.text &&
        !this.currentFilter.start &&
        !this.currentFilter.end &&
        !this.currentFilter.multi)
    );
  }

  sortData(type: string) {
    this.changeSorting(type, this.columnName);
  }
  connectedCallback() {
    super.connectedCallback();

    window.addEventListener("click", this.boundClickAwayHandler);
  }

  filterChange = (type, value) => {
    this.changeFiltering(type, this.columnName, value != "All" ? value : "");
  };

  clearFilter = (type) => {
    this.changeFiltering("text", this.columnName, undefined);
  };

  switchDropdown() {
    this.visible = !this.visible;

    if (this.visible)
      this.dropdownPosition = this.shouldPositionDropdownLeft()
        ? "left"
        : "right";
  }

  get currentMultiCheckboxState(): string[] {
    return (this.currentFilter && this.currentFilter.multi) || [];
  }

  shouldPositionDropdownLeft() {
    const { x } = this.getBoundingClientRect();

    // arbitrary value, assumes the dropdown width and some margin
    return window.innerWidth - x < 250;
  }

  isCheckboxChecked(option: string) {
    return this.currentMultiCheckboxState.includes(option);
  }

  multiCheckboxChange = (option: string, value: boolean) => {
    const newState =
      this.currentMultiCheckboxState.includes(option) && value === false
        ? this.currentMultiCheckboxState.filter((o) => o !== option)
        : !this.currentMultiCheckboxState.includes(option) && value === true
        ? [...this.currentMultiCheckboxState, option]
        : this.currentMultiCheckboxState;

    this.changeFiltering(
      "multi",
      this.columnName,
      // if no checkboxes are selected, clear the filter
      newState.length === 0 ? undefined : newState
    );
  };

  clickAwayHander(event: MouseEvent) {
    const clickedInsideComponent = event
      .composedPath()
      .some(
        (el) =>
          el === this || (el instanceof HTMLElement && isInsideFlatPicker(el))
      );

    if (clickedInsideComponent) return;

    this.visible = false;
  }

  disconnectedCallback(): void {
    window.removeEventListener("click", this.boundClickAwayHandler);
  }
}

JunctionTableDropdown.defineComponent();

export { JunctionTableDropdown };

/**
 * Date input rendered by this component mounts directly to `body`
 * and therefore it must be treated separately when detecting clicks inside/outside
 * of the component itself
 */
function isInsideFlatPicker(el: HTMLElement) {
  return el.classList.contains("flatpickr-calendar");
}
