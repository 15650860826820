import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<fluent-dialog
  id="student-submission-learnosity-modal"
  trap-focus
  :hidden="${x => !x.isOpen}"
  modal
  class="student-submission-learnosity-modal">

  <header class="modal__header">
    <div class="title-section">
      <span class="subtitle">${x => x.title}</span>
      <h1 :innerHTML="${x => x.assessmentTitle}"></h1>
    </div>

    <button
      class="modal__header--exit"
      tabindex="0"
      title="Exit modal"
      @click="${x => x.close()}">
      Exit
    </button>
  </header>
  
  <learnosity-item id="student-submissions-learnosity-content"></learnosity-item>
    
</fluent-dialog>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.student-submission-learnosity-modal::part(positioning-region) {
  height: fit-content;
  max-height: calc(100vh - 70px);
  width: 900px;
  margin: 40px auto 30px;
  overflow: auto;
  border-radius: 12px;
  z-index: 5;
}
.student-submission-learnosity-modal::part(control) {
  box-sizing: border-box;
  padding: 24px;
  height: auto;
  width: 100%;
  z-index: 5;
}
.student-submission-learnosity-modal .lrn_btn_blue.item-next {
  background-color: unset !important;
  border: unset !important;
}
.student-submission-learnosity-modal .lrn_btn_blue.item-next:hover {
  background-color: unset !important;
  border: unset !important;
}
.student-submission-learnosity-modal .lrn_btn_blue.item-next:hover::before {
  color: unset !important;
}
.student-submission-learnosity-modal .modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}
.student-submission-learnosity-modal .modal__header .title-section {
  display: flex;
  flex-direction: column;
}
.student-submission-learnosity-modal .modal__header .title-section .subtitle {
  font-size: 16px;
  font-weight: 300;
}
.student-submission-learnosity-modal .modal__header .title-section h1 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}
.student-submission-learnosity-modal .modal__header--exit {
  padding: 0px;
  color: var(--link);
  font-size: 1.28571em;
  font-weight: 600;
  line-height: 160%;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.student-submission-learnosity-modal .modal__header--exit:hover, .student-submission-learnosity-modal .modal__header--exit:active {
  text-decoration: underline;
  color: #0A5281;
}
.student-submission-learnosity-modal .modal__main {
  margin-bottom: 24px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex-direction: column;
}
.student-submission-learnosity-modal .modal__main .main__question {
  color: var(--neutral-70);
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}
.student-submission-learnosity-modal .modal__main .main__student {
  color: var(--neutral-90);
  font-size: 1.28571em;
  font-weight: 500;
  line-height: 160%;
}
.student-submission-learnosity-modal .modal__main .main__response {
  color: var(--neutral-70);
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}
.student-submission-learnosity-modal .modal__feedback {
  margin-bottom: 24px;
}
.student-submission-learnosity-modal .modal__flex {
  display: flex;
  justify-content: flex-end;
}
.student-submission-learnosity-modal .learnosity-iframe {
  width: 100%;
  height: 475px;
}`;


import { observable, RWSViewComponent, RWSView, attr, externalObservable, externalAttr } from "@rws-framework/client";
import { CustomHTMLElement } from "../../../../../components/gradingCenter/gradeByStudent/component";

@RWSView('student-submissions-detail-modal', null, { template: rwsTemplate, styles })
class StudentSubmissionsDetailsModal extends RWSViewComponent  {
  @observable isOpen: boolean = true;
  @attr data: any;
  @attr sessions: any;
  @attr course: any;
  @attr title: string;
  @attr assessmentTitle: string;

  connectedCallback() {
    super.connectedCallback();

    const learnositycomp = this.shadowRoot.getElementById('student-submissions-learnosity-content') as CustomHTMLElement;
    if (learnositycomp) {
      learnositycomp.data = this.data;
      learnositycomp.sessions = this.sessions;
      learnositycomp.course = this.course; 
    }
  };

  close() {
    this.isOpen = false;
    this.remove();
  };
}

StudentSubmissionsDetailsModal.defineComponent();

export { StudentSubmissionsDetailsModal };
