import {RWSService} from '@rws-framework/client';
import {
  ActivityTimes,
  ActivityTimesExtended, GradeByChapter,
  LastPageVisited,
  LastSubmittedAssignment,
  StudentActivityTimes,
  StudentDeepDiveAssignmentRecord, StudentDeepDiveDetailView,
  StudentDeepDiveListItem
} from '../types/reports';
import ActivitiesService, {ActivitiesServiceInstance} from "./ActivitiesService";
import EventsService, {EventsServiceInstance} from "./EventsService";
import {RosterRecord} from "../types/users";
import {Activity, ActivityStudentScore, LastSubmittedActivityPerStudent, LessonAssessments} from "../types/activities";
import {CalendarStatus} from "../types/calendar";


class StudentDeepDiveService extends RWSService {
  constructor(
    @ActivitiesService private activitiesService: ActivitiesServiceInstance,
    @EventsService private eventsService: EventsServiceInstance
  ) {
    super();
  }

  async getListView(): Promise<StudentDeepDiveListItem[]> {
    const [average, course, roster, activity_list]: [any, any, RosterRecord[], Activity[]] = await Promise.all([
      this.eventsService.getAverage(),
      this.eventsService.getCourse(),
      this.eventsService.getRoster(),
      this.eventsService.getActivities()
    ]);

    const courseId = course._id;
    const activity_student_score: ActivityStudentScore = average.activity_student_score;
    const studentActivityTimes: StudentActivityTimes = await this.getStudentActivityTimes(courseId);

    const lastSubmittedActivities: LastSubmittedActivityPerStudent = this.activitiesService.getLastSubmittedActivities(activity_student_score);
    const activities: Record<string, Activity> = (activity_list || []).reduce((acc, activity: Activity) => {
      acc[activity.id] = activity;
      return acc;
    }, {} as Record<string, Activity>);

    const report: StudentDeepDiveListItem[] = roster.map((student: RosterRecord): StudentDeepDiveListItem => {
      return {
        student: {
          id: student._id,
          sortname: student.lastname + ", " + student.firstname,
          fullname: student.name
        },
        avg_score: average.user_scores[student._id]?.percent || 0,
        lastTimeActive: this.getLastTimeActive(studentActivityTimes, student._id),
        lastSubmittedAssignment: this.getLastSubmittedAssignment(student._id, lastSubmittedActivities, activities),
        lastPageVisited: this.getLastPageVisited(studentActivityTimes, student._id, activities)
      }
    });

    return report;
  }

  async getDetailView(student_id: string): Promise<StudentDeepDiveDetailView> {
    const [average, course, roster, activity_list]: [any, any, RosterRecord[], Activity[]] = await Promise.all([
      this.eventsService.getAverage(),
      this.eventsService.getCourse(),
      this.eventsService.getRoster(),
      this.eventsService.getActivities()
    ]);

    const activity_student_score: ActivityStudentScore = average.activity_student_score;
    const activities: Record<string, Activity> = (activity_list || []).reduce((acc, activity: Activity) => {
      acc[activity.id] = activity;
      return acc;
    }, {} as Record<string, Activity>);

    const courseId = course._id;
    const filteredScores = this.filterActivityStudentScore(activity_student_score, student_id);
    const studentActivityTimes: StudentActivityTimes = await this.getStudentActivityTimes(courseId);

    // const studentTimes: ActivityTimes = studentActivityTimes[student_id] || {};
    const assignments = this.getAssignmentsTable(average.activity_manager, activity_student_score, student_id);
    return {
      student: roster.find(student => student._id === student_id),
      header: {
        avg_score: average.user_scores[student_id].percent,
        lastTimeActive: this.getLastTimeActive(studentActivityTimes, student_id),
        lastSubmittedAssignment: this.getLastSubmittedAssignment(student_id, this.activitiesService.getLastSubmittedActivities(filteredScores), activities),
        lastPageVisited: this.getLastPageVisited(studentActivityTimes, student_id, activities),
      },
      // assignment_times_chart: this.extendActivityTimes(studentTimes, activities),
      grade_by_chapter: this.getGradeByChapter(average, student_id, activities),
      submissions_chart: this.countSubmissionsChart(assignments),
      assignments: assignments
    }
  }

  private async getStudentActivityTimes(courseId: string, date_gte: string = '', date_lte: string = ''): Promise<StudentActivityTimes> {
    // @ts-ignore
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    const response = await fetch(`${APIprefix}/stats/studentactivitytimes/${courseId}?date_gte=${date_gte}&date_lte=${date_lte}`, );
    return await response.json()
  }

  private getLastTimeActive(studentActivityTimes: StudentActivityTimes, student_id: string): Date | null {
    const studentData = studentActivityTimes[student_id];
    if (!studentData) {
      return null;
    }

    let lastActive = '';
    Object.values(studentData).forEach(activityTime => {
      if (activityTime.last_time_visited > lastActive) {
        lastActive = activityTime.last_time_visited;
      }
    })

    if (lastActive === '') {
      return null;
    }

    return this.formatDate(lastActive);
  }

  private getLastSubmittedAssignment(student_id: string, lastSubmittedActivities: LastSubmittedActivityPerStudent, activities: Record<string, Activity>): LastSubmittedAssignment {
    const lastSubmitted = lastSubmittedActivities[student_id];
    if (!lastSubmitted) {
      return null;
    }

    const activity_id = lastSubmitted.activity_id;
    const activity: Activity = activities[activity_id];
    return {
      id: activity_id,
      title: activity.report_title,
      link: activity.link
    };
  }

  private getLastPageVisited(studentActivityTimes: StudentActivityTimes, student_id: string, activities: Record<string, Activity>): LastPageVisited {
    const lastPageVisited = {
      id: '',
      title: '',
      link: '',
      lastTimeVisited: ''
    }

    const studentData = studentActivityTimes[student_id];
    if (!studentData) {
      return null;
    }

    Object.entries(studentData).forEach(([page_id, activityTime]) => {
      if (activityTime.last_time_visited > lastPageVisited.lastTimeVisited && Object.keys(activities).includes(page_id)) {
        lastPageVisited.id = page_id;
        lastPageVisited.lastTimeVisited = activityTime.last_time_visited;
        lastPageVisited.title = activities[page_id].report_title;
        lastPageVisited.link = activities[page_id].link;
      }
    })

    if (lastPageVisited.id === '') {
      return null;
    }

    return {
      id: lastPageVisited.id,
      title: lastPageVisited.title,
      link: lastPageVisited.link
    }
  }

  extendActivityTimes(activityTimes: ActivityTimes, activities: Record<string, Activity>): ActivityTimesExtended {
    const extendedActivityTimes: ActivityTimesExtended = {};
    const pages = Object.values(activities).filter(activity => activity.type === 'page');
    pages.forEach(page => {
      if (Object.keys(activityTimes).includes(page.id)) {
        extendedActivityTimes[page.id] = {
          ...activityTimes[page.id],
          title: page.report_title,
          lesson_title: activities[page.lesson.id].report_title
        }
      } else {
        extendedActivityTimes[page.id] = {
          seconds_spent: 0,
          last_time_visited: '',
          lesson_id: page.lesson.id,
          title: page.report_title,
          lesson_title: activities[page.lesson.id].report_title
        }
      }
    })

    return extendedActivityTimes
  }

  private filterActivityStudentScore(activityStudentScore: ActivityStudentScore, studentId: string): ActivityStudentScore {
    /*
      Filter activityStudentScore to only include the student
     */
    const filteredScores: ActivityStudentScore = {};

    for (const [activityId, studentScores] of Object.entries(activityStudentScore)) {
      if (studentScores[studentId]) {
        filteredScores[activityId] = {[studentId]: studentScores[studentId]};
      }
    }

    return filteredScores;
  };

  private getAssignmentsTable(activities: Activity[], activity_student_score: ActivityStudentScore, student_id: string): StudentDeepDiveAssignmentRecord[] {
    return activities
      .filter(activity => activity.graded === true)
      .map(activity => {
      return {
        id: activity.id,
        title: activity.report_title,
        link: activity.link,
        type: activity.type,
        status: this.activitiesService.getActivityStatus(activity, activity_student_score, student_id),
        score_in_percentage: this.activitiesService.getGradeInPercentage(activity, activity_student_score, student_id),
        submission_date: this.activitiesService.getSubmissionDate(activity, activity_student_score, student_id),
        due_date: activity.due_date,
        lesson_id: activity.lesson?.id || "Course Level",
        lesson_title: activity.lesson?.title || "Course Level",
      } as StudentDeepDiveAssignmentRecord
    });
  }

  private countSubmissionsChart(assignments: StudentDeepDiveAssignmentRecord[]): {on_time: number, not_submitted: number, late: number} {
    let on_time = 0;
    let not_submitted = 0;
    let late = 0;

    assignments.forEach(assignment => {
      if (
        [CalendarStatus.Graded, CalendarStatus.Submitted].includes(assignment.status) && 
        assignment.submission_date < (assignment.due_date ? assignment.due_date : '2199-01-01T00:00:00.000Z')
      ) {
        on_time += 1;
      } else if (
        [CalendarStatus.Graded, CalendarStatus.Submitted].includes(assignment.status) && 
        assignment.submission_date > (assignment.due_date ? assignment.due_date : '2199-01-01T00:00:00.000Z')
      ) {
        late += 1;
      } else {
        not_submitted += 1;
      }
    });

    return {
      on_time,
      not_submitted,
      late
    }
  }

  getGradeByChapter(average: any, student_id: string, activities: Record<string, Activity>): GradeByChapter {
    const gradeByChapter: GradeByChapter = {};
    const lessonassessments: LessonAssessments = average.lessonassessments;

    Object.entries(lessonassessments).forEach(([lesson_id, lesson_record]) => {
      let total_score_percentage = 0;
      Object.entries(lesson_record.scores).forEach(([activity_id, student_score]) => {
        if (student_score[student_id] && activities[activity_id]?.max_score) {
          total_score_percentage += student_score[student_id] * 100 / activities[activity_id].max_score;
        }
      })

      gradeByChapter[lesson_id] = {
        title: activities[lesson_id]?.report_title,
        score_in_percentage: total_score_percentage / Object.keys(lesson_record.scores).length
      }

    })

    return gradeByChapter;
  }

  filterActivityTimesByChapter(studentData: ActivityTimesExtended, lesson_id: string): ActivityTimesExtended {
    const response: ActivityTimesExtended = {}
    for (const [page_id, activityTime] of Object.entries(studentData)) {
      if (activityTime.lesson_id === lesson_id) {
        response[page_id] = activityTime;
      }
    }

    return response;
  }

  async filterActivityTimesByDate(student_id: string, date_gte: string = '', date_lte: string = ''): Promise<ActivityTimesExtended> {
    /*
      date_gte format: YYYYMMDD - '20240101'
      date_lte format: YYYYMMDD - '20240101
    */
    const [average, course]: [any, any] = await Promise.all([
      this.eventsService.getAverage(),
      this.eventsService.getCourse()
    ]);

    const courseId = course._id;

    const studentActivityTimes: StudentActivityTimes = await this.getStudentActivityTimes(courseId, date_gte, date_lte);

    const activities: Record<string, Activity> = (average.activity_manager || []).reduce((acc, activity: Activity) => {
      acc[activity.id] = activity;
      return acc;
    }, {} as Record<string, Activity>);

    const studentData: ActivityTimes = studentActivityTimes[student_id];
    if (!studentData) {
      return {} as ActivityTimesExtended;
    }

    return this.extendActivityTimes(studentData, activities)
  }


  formatDate(yyyymmdd: string): Date {
    const year = parseInt(yyyymmdd.substring(0, 4));
    const month = parseInt(yyyymmdd.substring(4, 6)) - 1; // JavaScript months are 0-indexed
    const day = parseInt(yyyymmdd.substring(6, 8));

    return new Date(year, month, day);
  }
}

export default StudentDeepDiveService.getSingleton();

export {StudentDeepDiveService as StudentDeepDiveServiceInstance}
