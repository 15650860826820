import { RWSService } from "@rws-framework/client";
import { AssessmentAttempt } from "../types/reports";
import EventsService, { EventsServiceInstance } from "./EventsService";

declare const Cookies: any;
declare global {
  interface Window {
    edrnaConfig: {
      host: string;
      serverPrefix: string;
      learnosity: { version: string };
    };
    LearnosityItems: any;
    labelBundle: Record<string, any>;
  }
}

/**
 * Required for displaying assessment scores (per attempt) and previewing submitted answers via learnosity iframe.
 */
class StudentDeepDiveLearnosityService extends RWSService {
  static csrfToken = Cookies.get("csrf_token");

  constructor(@EventsService private eventsService: EventsServiceInstance) {
    super();
  }

  /** Fetches activity scores for student from learnosity api */
  async getScores(
    activityId: string,
    studentId: string
  ): Promise<AssessmentAttempt[]> {
    // logic adapted from frontend/app/modules/gradebook/gradebookCtrl.js, line 945
    const request = {
      activity_id: [activityId],
      user_id: [studentId],
      include: { "sessions.session_metadata": ["course_id"] }, // very important for filtering below
      session_id: [],
      include_response_scores: true,
      status: ["Completed", "Incomplete"],
    };

    const signedRequest = await this.getSignature(request);
    const scores = await this.getScoresRequest(signedRequest);
    const course = await this.eventsService.getCourse();

    return scores.data
      .filter(
        (score) =>
          score.session_metadata &&
          score.session_metadata.course_id ==
            (course.section_type == "section" ? course.parent : course._id)
      )
      .map((score, idx) => ({
        ...score,
        id: `${score.user_id}-${score.activity_id}-${idx}`,
      }));
  }

  /**
   * Initializes learnosity iframe, which displays response preview for given activity attempt.
   * Expects a `div` with id equal to `learnosity_assess` to be present in DOM (not Shadow DOM!)
   */
  async initializeApp(session: AssessmentAttempt): Promise<unknown> {
    // logic adapted from frontend/app/core/directives/studentReview.js, line 237
    const isProfessor = true;
    const review_correct_answers = true;

    const request = {
      activity_id: session.activity_id,
      session_id: session.session_id,
      user_id: session.user_id,
      items: session.items.map((item) => item.reference),
      name: "Review",
      rendering_type: "assess",
      state: "review",
      type: "submit_practice",
      mode: "assessment",
      assess_inline: true,
      dynamic_items: {
        try_again: {
          max_attempts: 5,
          random: true,
        },
      },
      config: {
        questions_api_init_options: {
          showCorrectAnswers: isProfessor || review_correct_answers,
          showInstructorStimulus: isProfessor,
        },
        ignore_question_attributes: [],
        title: "",
        subtitle: "",
        navigation: {
          scroll_to_top: false,
          scroll_to_test: false,
          show_intro: false,
          show_outro: false,
          show_next: true,
          show_prev: true,
          show_accessibility: {
            show_colourscheme: true,
            show_fontsize: true,
            show_zoom: true,
          },
          show_configuration: false,
          show_fullscreencontrol: false,
          show_progress: true,
          show_submit: false,
          show_title: false,
          show_save: false,
          show_calculator: false,
          show_itemcount: false,
          skip_submit_confirmation: false,
          toc: false,
          transition: "fade",
          transition_speed: 400,
          warning_on_change: false,
          scrolling_indicator: false,
          show_answermasking: false,
          show_acknowledgements: false,
          auto_save: {
            changed_responses_only: false,
            ui: true,
            saveIntervalDuration: 12,
          },
          item_count: { question_count_option: false },
        },
        labelBundle: {
          item: window.labelBundle.item_question || "Question",
          colorScheme: "Color Scheme",
          paletteInstructions: "Instructions...color",
        },
        regions: {
          items: [
            {
              type: "vertical_element",
              vertical_stretch_option: true,
              scrollable_option: true,
            },
          ],
          right: [],
        },
        ignore_validation: false,
        configuration: {
          onsave_redirect_url: false,
          ondiscard_redirect_url: false,
          fontsize: "normal",
        },
      },
    };

    const signedRequest = await this.getItemSignature(request);
    const itemsApi =
      await StudentDeepDiveLearnosityService.LearnosityItemsAPI();

    return new Promise((resolve, reject) => {
      const app = itemsApi.init(signedRequest, {
        readyListener: () => resolve(app),
        errorListener: reject,
      });
    });
  }

  private getScoresRequest(signedRequest: {
    action: string;
    request: string;
    security: string;
  }) {
    const url =
      "https://data.learnosity.com/" +
      window.edrnaConfig.learnosity.version +
      "/sessions/responses";

    const formData = new FormData();

    Object.entries(signedRequest).forEach(([key, value]) =>
      formData.append(key, value)
    );

    return fetch(url, {
      method: "POST",
      body: formData,
    }).then((res) =>
      res.ok
        ? res.json()
        : Promise.reject(
            new Error(`Failed to get signature ${res.status} ${res.statusText}`)
          )
    );
  }

  private getSignature(
    request: unknown
  ): Promise<{ action: string; request: string; security: string }> {
    const APIprefix: string =
      window.edrnaConfig.host + window.edrnaConfig.serverPrefix;

    return fetch(APIprefix + "assessment/signature/data", {
      method: "POST",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": StudentDeepDiveLearnosityService.csrfToken,
      },
    }).then((res) =>
      res.ok
        ? res.json()
        : Promise.reject(
            new Error(`Failed to get signature ${res.status} ${res.statusText}`)
          )
    );
  }

  private getItemSignature(
    request: unknown
  ): Promise<{ action: string; request: string; security: string }> {
    const APIprefix: string =
      window.edrnaConfig.host + window.edrnaConfig.serverPrefix;

    return fetch(APIprefix + "assessment/signature/items", {
      method: "POST",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": StudentDeepDiveLearnosityService.csrfToken,
      },
    }).then((res) =>
      res.ok
        ? res.json()
        : Promise.reject(
            new Error(`Failed to get signature ${res.status} ${res.statusText}`)
          )
    );
  }

  private static LearnosityItemsAPI(): Promise<any> {
    return new Promise((resolve, reject) => {
      const existing = document.querySelectorAll(
        '[src^="https://items.learnosity.com?' +
          window.edrnaConfig.learnosity.version +
          '"]'
      );

      if (existing.length && window.LearnosityItems) {
        return resolve(window.LearnosityItems);
      }

      const script = document.createElement("script");
      script.src =
        "https://items.learnosity.com?" + window.edrnaConfig.learnosity.version;
      script.onload = () => resolve(window.LearnosityItems);
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }
}

export default StudentDeepDiveLearnosityService.getSingleton();

export { StudentDeepDiveLearnosityService as StudentDeepDiveLearnosityServiceInstance };
