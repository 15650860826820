import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`
<div class="modal-content">
  ${T.when( x => x.currentModal === 'BATCH_UPDATE', T.html`
  <jnct-modal-header
    :hide="${x=>x.genericConfig.switch}"
    title="Batch Update"
  ></jnct-modal-header>

  <div class="content">
    <div class="row">
      <div class="text">You selected ${x=>x.selectedText}.</div>
    </div>
    <div class="row">
      <button
        class="button__secondary"
        @click="${x=> x.displayModal('UPDATE_DATE_TIMES')}"
      >
        Update Dates and Times
      </button>
    </div>
    <div class="row">
      <button
        class="button__secondary"
        ?disabled="${x => x.presets.length === 0}"
        @click="${x=> x.displayModal('APPLY_PRESET')}"
      >
        Apply a Preset
      </button>
    </div>
    <div class="row">
      <button
        class="button__secondary"
        @click="${x=> x.displayModal('APPLY_CUSTOM_SETTINGS')}"
      >
        Update Settings
      </button>
    </div>
  </div>
  `)} ${T.when( x => x.currentModal === 'APPLY_PRESET', T.html`
  <jnct-modal-header
    :hide="${x=>x.genericConfig.switch}"
    title="Batch Settings - Apply a Preset"
  ></jnct-modal-header>

  <jnct-activity-settings-preset-form
    :bu_payload="${x => x.bu_payload}"
    :current="${x => x.current}"
    :prefillWithDefault="${x => !x.multipleSelected}"
    :presets="${x => x.presets}"
    :onChange="${x => x.onBatchUpdateFormChange}"
  >
    <div class="row">
      <div class="title_text">Selected: ${x=> x.selectedText}</div>
    </div>
  </jnct-activity-settings-preset-form>

  <div class="options">
    <span
      ><button
        class="button__secondary"
        @click="${x=>x.goBack()}"
        style="gap: 8px"
      >
        Go Back
      </button></span
    >
    <span style="display: flex; gap: 20px">
      <button
        class="button__primary"
        @click="${x=>x.displayModal('CONFIRM')}"
        :disabled="${x => !x.changed.preset}"
      >
        Save changes
      </button></span
    >
  </div>
  `)} ${T.when( x => x.currentModal == 'CONFIRM', T.html`
  <jnct-modal-header
    :hide="${x=>x.genericConfig.switch}"
    title="Batch Settings - Confirm Changes"
  ></jnct-modal-header>

  <jnct-activity-settings-change-summary
    :presets="${x => x.presets}"
    :buPayloadToSend="${x => x.buPayloadToSend}"
  >
    <div class="row center">
      You will update &nbsp;<b> ${x=>x.selectedText}</b>
    </div>
  </jnct-activity-settings-change-summary>

  <div class="options">
    <span
      ><button
        class="button__secondary"
        @click="${x=>x.goBack()}"
        style="gap: 8px"
      >
        Cancel
      </button></span
    >
    <span style="display: flex; gap: 20px">
      <button class="button__primary" @click="${x=>x.save()}">
        Update
      </button></span
    >
  </div>
  `)} ${T.when( x => x.currentModal == 'APPLY_CUSTOM_SETTINGS', T.html`
  <jnct-modal-header
    :hide="${x=>x.genericConfig.switch}"
    title="Batch Settings"
  ></jnct-modal-header>

  <jnct-activity-settings-form
    :bu_payload="${x => x.bu_payload}"
    :current="${x => x.current}"
    :prefillWithDefault="${x => !x.multipleSelected}"
    :onChange="${x => x.onBatchUpdateFormChange}"
    :getActivityLockStatus="${x => x.getActivityLockStatus}"
  ></jnct-activity-settings-form>

  <div class="options">
    <span
      ><button
        class="button__secondary"
        @click="${x=>x.goBack()}"
        style="gap: 8px"
      >
        Go Back
      </button></span
    >
    <span style="display: flex; gap: 20px">
      <button
        class="button__primary"
        @click="${x=>x.displayModal('CONFIRM')}"
        :disabled="${x => !x.changedCustomSettings}"
      >
        Save changes
      </button></span
    >
  </div>
  `)} ${T.when( x => x.currentModal == 'UPDATE_DATE_TIMES', T.html`
  <jnct-modal-header
    :hide="${x=>x.genericConfig.switch}"
    title="Batch Settings - Dates and Times"
  ></jnct-modal-header>

  <jnct-activity-settings-date-and-time-form
    :bu_payload="${x => x.bu_payload}"
    :current="${x => x.current}"
    :prefillWithDefault="${x => !x.multipleSelected}"
    :onChange="${x => x.onBatchUpdateFormChange}"
    :defaulttime="${x => x.defaulttime}"
  >
    <div
      class="row"
      style="
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
      "
    >
      Selected ${x=>x.selectedText}
    </div></jnct-activity-settings-date-and-time-form
  >

  <div class="options">
    <span
      ><button
        class="button__secondary"
        @click="${x=>x.goBack()}"
        style="gap: 8px"
      >
        Go Back
      </button></span
    >
    <span style="display: flex; gap: 20px">
      <button
        class="button__primary"
        @click="${x=>x.displayModal('CONFIRM')}"
        :disabled="${x => !x.changed.time}"
      >
        Save changes
      </button></span
    >
  </div>
  `)}
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.content {
  padding: 28px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
  width: calc(100% - 48px);
  height: 100%;
}
.content .row {
  display: flex;
  justify-content: space-between;
}
.content .row.center {
  justify-content: center;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
}
.content .row button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  text-align: center;
  background: transparent;
}
.content .row .text {
  color: #000;
  align-self: stretch;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
}
.content .row .select_text {
  color: var(--Color-palette-Neutral-70, #3b3c3e);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}
.content .row .title_text {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.content .options_row {
  border-radius: 12px;
  border: 1px solid var(--Color-palette-Neutral-20, #eeeff0);
  background: var(--White, #fff);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 24px;
}
.content .options_row .form_options {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.content .options_row .form_options .option {
  height: 24px;
  display: flex;
  justify-content: space-between;
}
.content .options_row .form_options .option .change {
  cursor: pointer;
  overflow: hidden;
  color: #0f75b8;
  text-align: right;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}
.content .options_row .form_options .hidden {
  display: none;
}
.content .options_row .sub {
  box-shadow: none;
}
.content .options_row .header {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  display: flex;
  gap: 8px;
  justify-content: space-between;
}
.content .options_row .header .rm_changes {
  cursor: pointer;
  overflow: hidden;
  color: var(--Color-palette-Action-Error, #de1135);
  text-align: right;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}

.modal-content {
  display: flex;
  width: 640px;
  flex-direction: column;
  border: var(--account, 1px) solid var(--Color-palette-Neutral-20, #eeeff0);
  max-height: calc(100vh - 54px);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
}
.modal-content jnct-activity-settings-form,
.modal-content jnct-activity-settings-date-and-time-form,
.modal-content jnct-activity-settings-preset-form {
  overflow-y: auto;
}
.modal-content .options {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0px -2px 14px 2px rgba(0, 0, 0, 0.05);
  width: calc(100% - 48px);
  bottom: 0px;
}`;

const mode = "open";
import {
  attr,
  RWSViewComponent,
  RWSView,
  observable,
} from "@rws-framework/client";
import { omitBy, mapObject } from "../../../helpers/utils";
import {
  Activity,
  ActivityLockStatus,
  ActivityModel,
} from "../../../types/activities";
import { Update, UpdateModel, groups } from "./common";

@RWSView('jnct-batch-update-modal', null, { template: rwsTemplate, styles, options: {mode} })
class JunctionBatchUpdateModal extends RWSViewComponent  {
  @attr genericConfig: any = {};
  @observable getActivityLockStatus: (a: Activity) => ActivityLockStatus = () =>
    "none";
  @observable defaulttime = "23:59";

  @observable currentModal:
    | "BATCH_UPDATE"
    | "CONFIRM"
    | "UPDATE_DATE_TIMES"
    | "APPLY_PRESET"
    | "APPLY_CUSTOM_SETTINGS" = "BATCH_UPDATE";

  @observable selected: Array<{ type: string }> = [];
  @observable presets: Array<{ _id: string; name: string }> = [];

  /** values of updated fields */
  @observable bu_payload: Partial<Update> = {};
  /** set only when editing single activity */
  @observable current: Partial<Update> = {};

  labels = UpdateModel.labels();

  get buPayloadToSend(): Partial<Update> {
    const payload = omitBy(
      this.bu_payload,
      (value, key) => value === this.current[key]
    );

    const payloadWithDatesAdjusted = this.adjustDatesForBackend(payload);

    return payloadWithDatesAdjusted;
  }

  get changed() {
    return mapObject(groups, (keys) =>
      keys.some(
        (key) =>
          key in this.bu_payload && this.bu_payload[key] !== this.current[key]
      )
    );
  }

  get changedCustomSettings() {
    return (
      this.changed.basic ||
      this.changed.attempts ||
      this.changed.gradesAndCorrectAnswers ||
      this.changed.questionOrderAndRestrictions ||
      this.changed.discussionBoardSettings ||
      this.changed.adaptiveRecommendation ||
      this.changed.lateWorkPolicy
    );
  }

  get selectedText() {
    const typeCounts: Record<string, number> = this.selected.reduce(
      (acc, { type }) => Object.assign(acc, { [type]: (acc[type] || 0) + 1 }),
      {} as Record<string, number>
    );

    return Object.entries(typeCounts)
      .map(([key, value]) => {
        const displayedType = ActivityModel.displayType(key);
        const isPlural = displayedType.endsWith("s");
        const singular = isPlural ? displayedType.slice(0, -1) : displayedType;
        const text = value > 1 ? `${singular}s` : singular;

        return `${value} ${text}`;
      })
      .join(", ");
  }

  get multipleSelected() {
    return this.selected.length > 1;
  }

  onBatchUpdateFormChange = (update: Partial<Update>) => {
    this.bu_payload = update;
  };

  genericConfigChanged(olcVal: any, newVal: any) {
    if (typeof newVal.getPresets === "function") {
      this.presets = newVal.getPresets();
    }

    if (typeof newVal.selected === "function") {
      const selected = newVal.selected();

      this.selected = selected;

      if (selected.length === 1) {
        const defaultv = newVal.getDefault();
        const oneSelected = selected[0];

        if (defaultv?.id == oneSelected._id) {
          this.current = defaultv;
        }
      }
    }

    if (typeof newVal.getActivityLockStatus === "function") {
      this.getActivityLockStatus = newVal.getActivityLockStatus;
    }

    if (typeof newVal.getDefaultTime === "function") {
      this.defaulttime = newVal.getDefaultTime();
    }
  }

  displayModal(value: (typeof this)["currentModal"]) {
    this.currentModal = value;
  }

  goBack() {
    this.displayModal("BATCH_UPDATE");
  }

  save() {
    this.genericConfig.switch();
    this.displayModal("BATCH_UPDATE");
    this.genericConfig.save(this.buPayloadToSend);
  }

  adjustDatesForBackend(payload: Partial<Update>): Partial<Update> {
    return {
      ...payload,
      ...(payload.open_date !== undefined && {
        open_date: payload.open_date || "1970-01-01T00:00:00.000Z",
      }),
      ...(payload.due_date !== undefined && {
        due_date: payload.due_date || "2199-01-01T00:00:00.000Z",
      }),
      ...(payload.close_date !== undefined && {
        close_date: payload.close_date || "2199-01-01T00:00:00.000Z",
      }),
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.$emit("modal:shown");
  }
}

JunctionBatchUpdateModal.defineComponent();

export { JunctionBatchUpdateModal };
