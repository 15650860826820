import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<header class="layout__header">
  <div class="layout__header--info">
    <div class="details">
      <div class="details_title">Student Deep Dive</div>
      ${x => x.student && x.details ? T.html`
      <div class="details_username">${x => x.details.student.name}</div>
      <a
        class="button__primary send_email"
        href="mailto:${x => x.details.student.email}"
        >Send Email</a
      >` : undefined}
    </div>
  </div>
</header>

${x => x.student ? T.html`
<div class="layout__grid">
  <div class="student_overall">
    <jnct-student-overall :student="${x => x.student}"></jnct-student-overall>
  </div>
  <div class="grade_by_chapter chart_container">
    <div class="chart_header">
      <div class="title">
        <span class="material-symbols-outlined icon"> insert_chart </span>
        Grade by Chapter
      </div>
      <span class="select">
        
        
      </span>
    </div>

    ${x => x.assignmentsChart ? T.html`
    <jnct-chart-bar
      :chartconfig="${x => x.assignmentsChart}"
      style="width: 100%"
    >
    </jnct-chart-bar
    >` : undefined}
  </div>
  <div class="assignment_completion chart_container">
    <div class="chart_header">
      <div class="title">
        <span class="material-symbols-outlined icon"> pie_chart </span>
        Assignment Completion
      </div>
      <span class="select"> </span>
    </div>
    ${x => x.assignmentCompletion ? T.html`
    <jnct-chart-bar
      :chartconfig="${x => x.assignmentCompletion}"
      style="width: 100%"
    >
    </jnct-chart-bar
    >` : undefined}
  </div>
  
  
  <div class="sdd_assignments chart_container">
    
    ${x => x.assignmentsTable ? T.html`
    <jnct-assignments-table
      :assignments="${x => x.assignmentsTable}"
      :onShowDetails="${x => x.onShowAssignmentDetails}"
    ></jnct-assignments-table>
    ` : undefined}
  </div>
</div>
` : T.html`
<div class="spinner"><jnct-loading-spinner></jnct-loading-spinner></div>
` } ${T.when(x => x.selectedAssignmentId && x.modalConfig, T.html`
<jnct-modal
  templateName="jnct-assessment-detail-modal"
  :genericConfig="${x=>x.modalConfig}"
></jnct-modal>

`)}
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.layout__header {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin-bottom: 32px;
  margin-top: 16px;
}
.layout__header--info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
}
.layout__header--info .details {
  display: flex;
  flex-direction: column;
}
.layout__header--info .details_title {
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}
.layout__header--info .details_username {
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
}
.layout__header .send_email {
  height: unset;
  text-decoration: none;
  text-transform: none;
  margin-top: 16px;
}

.layout__grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 32px;
}
.layout__grid .chart_container {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.0784313725);
  padding: 16px;
  border-radius: 12px;
}
.layout__grid .student_overall {
  grid-column: 1/13;
  max-height: 420px;
}
.layout__grid .grade_by_chapter {
  grid-column: 1/13;
}
.layout__grid .assignment_completion {
  grid-column: 1/13;
}
.layout__grid .deep_dive_on_time {
  grid-column: 6/13;
}
.layout__grid .sdd_assignments {
  grid-column: 1/13;
}

.spinner {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  display: flex;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  align-items: center;
  gap: 8px;
}

.chart_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin-bottom: 32px;
  padding: 4px 0;
}
.chart_header .select {
  min-width: 200px;
}`;

const mode = "open";
import {
  observable,
  RWSViewComponent,
  RWSView,
  attr,
  RWSInject,
} from "@rws-framework/client";

import StudentDeepDiveService, {
  StudentDeepDiveServiceInstance,
} from "../../services/StudentDeepDiveService";
import {
  StudentDeepDiveAssignmentRecord,
  StudentDeepDiveDetailView,
  StudentDeepDiveListItem,
} from "../../types/reports";
import type { JunctionAssessmentDetailModalConfig } from "./assessmentDetailModal/component";

@RWSView('jnct-student-deep-dive-details', null, { template: rwsTemplate, styles, options: {mode} })
class StudentDeepDiveDetails extends RWSViewComponent  {
  @attr studentid: string;

  @observable student: StudentDeepDiveListItem | null = null;
  @observable details: StudentDeepDiveDetailView | null = null;

  @observable selectedByChapter: string | undefined;
  @observable selectedAssignmentId: StudentDeepDiveAssignmentRecord["id"];

  constructor(
    @RWSInject(StudentDeepDiveService)
    protected readonly studentDeepDiveService: StudentDeepDiveServiceInstance
  ) {
    super();
  }

  studentidChanged(_: string, id: string) {
    if (id.startsWith("{{")) return;

    this.loadStudent();
    this.loadDetails();
  }

  loadStudent() {
    const id = this.studentid;

    return this.studentDeepDiveService
      .getListView()
      .then((students) => {
        this.student = students.find(({ student }) => student.id === id);
      })
      .catch((err) => {
        this.$emit(
          "jnct:notifications:error",
          "Failed to load student detail deep dive data"
        );
        console.error(err);
      });
  }

  loadDetails() {
    const id = this.studentid;

    return this.studentDeepDiveService
      .getDetailView(id)
      .then((details) => {
        this.details = details;
      })
      .catch((err) => {
        this.$emit(
          "jnct:notifications:error",
          "Failed to load student detail deep dive data"
        );
        console.error(err);
      });
  }

  setByChapter = (
    value: { lesson_id: string; lesson_title: string } | null
  ) => {
    this.selectedByChapter = value?.lesson_id || undefined;
  };

  onShowAssignmentDetails = (assignment: StudentDeepDiveAssignmentRecord) => {
    this.selectedAssignmentId = assignment.id;
  };

  get chapters(): Array<{ lesson_id: string; lesson_title: string }> {
    return Object.entries(this.details?.grade_by_chapter || {}).map(
      ([lesson_id, item]) => ({ lesson_title: item.title, lesson_id })
    );
  }

  get assignmentsChart() {
    const selectedByChapter = this.selectedByChapter;

    if (!this.details) return null;

    const pages = Object.entries(this.details?.grade_by_chapter || {}).map(
      ([lesson_id, item]) => ({
        title: item.title,
        lesson_id,
        score: item.score_in_percentage,
      })
    );
    const filteredPages = selectedByChapter
      ? pages.filter((a) => a.lesson_id === selectedByChapter)
      : pages;
    const categories = filteredPages.map((p) => p.title);
    const data = filteredPages.map((p) => Math.round(p.score));

    return assignmentsChart(categories, data);
  }

  get assignmentCompletion() {
    if (!this.details) return null;
    return assignmentCompletionChart(this.details.submissions_chart);
  }

  get assignmentsTable() {
    if (!this.details) return null;

    return this.details.assignments;
  }

  get diveOnTimeMock() {
    return diveOnTimeChart();
  }

  get modalConfig(): JunctionAssessmentDetailModalConfig | null {
    const selectedAssignmentId = this.selectedAssignmentId;
    const assignment = (this.details?.assignments || []).find(
      (a) => a.id === selectedAssignmentId
    );
    const student = this.details?.student;

    if (!assignment || !student) return;

    const close = () => {
      this.selectedAssignmentId = undefined;
    };

    return {
      switch: close,
      assignment,
      student,
    };
  }

  connectedCallback() {
    super.connectedCallback();
  }
}

StudentDeepDiveDetails.defineComponent();

export { StudentDeepDiveDetails };

function uniq<T>(arr: T[]): T[] {
  return [...new Set(arr)];
}

function assignmentsChart(categories: string[], data: number[]) {
  return {
    chart: { type: "bar" },
    legend: false,
    title: false,
    // legend: {
    //   align: "left",
    //   itemStyle: {
    //     fontSize: "16px",
    //     fontWeight: 400,
    //     color: "#4f4f4f",
    //   },
    // },
    //   title: {
    //     text: "Assignments",
    //     align: "left",
    //     margin: 30,
    //     style: {
    //       fontSize: "24px",
    //       fontWeight: 600,
    //     },
    //   },
    xAxis: {
      categories,
      title: {
        // text: "Chapter",
        //   style: {
        //     fontSize: "16px",
        //     fontWeight: 400,
        //     color: "#4f4f4f",
        //   },
      },
      labels: {
        style: {
          fontSize: "16px",
          fontWeight: 400,
          color: "#4f4f4f",
        },
      },
      accessibility: {
        description: "chapters",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Grade (%)",
        style: {
          fontSize: "16px",
          fontWeight: 500,
          color: "#4f4f4f",
        },
      },
      labels: {
        style: {
          fontSize: "16px",
          fontWeight: 400,
          color: "#4f4f4f",
        },
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      valueSuffix: "%",
      style: {
        fontSize: "12px",
        fontWeight: 600,
      },
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            color: "#00CAB1",
          },
          select: {
            color: "#00CAB1",
          },
        },
        color: "#BDE6E1",
        borderRadius: "12px",
        stacking: "normal",
        dataLabels: {
          enabled: true,
          inside: false,
          style: {
            fontSize: "12px",
            fontWeight: 600,
            color: "#4f4f4f",
          },
        },
      },
    },
    series: [
      {
        name: "Grade",
        data,
      },
    ],
  };
}

function assignmentCompletionChart(
  data: StudentDeepDiveDetailView["submissions_chart"]
) {
  const assignmentsNumber: number = Object.values(data).reduce((sum, value) => sum + value, 0);
  return {
    chart: {
      type: "pie",
    },
    title: false,
    // title: {
    //   text: "Assignment Completion",
    //   align: "left",
    //   margin: 30,
    //   style: {
    //     fontSize: "24px",
    //     fontWeight: 600,
    //   },
    // },
    tooltip: {
      valueSuffix: "%",
      style: {
        fontSize: "12px",
        fontWeight: 600,
      },
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: [
          {
            enabled: true,
            distance: 20,
            style: {
              fontSize: "12px",
              fontWeight: 600,
            },
          },
          {
            enabled: true,
            distance: -40,
            format: "{point.percentage:.1f}%",
            style: {
              fontSize: "1.2em",
              textOutline: "none",
              opacity: 0.7,
              color: '#FFF'
            },
            filter: {
              operator: ">",
              property: "percentage",
              value: 10,
            },
          },
        ],
      },
    },
    series: [
      {
        name: "Percentage",
        colorByPoint: true,
        data: [
          {
            name: "Submitted on Time",
            y: calculateChartSegment(data.on_time, assignmentsNumber),
            color: "#12A2C2",
          },
          {
            name: "Submitted Late",
            y: calculateChartSegment(data.late, assignmentsNumber),
            color: "#094E5D",
          },
          {
            name: "Not Submitted",
            y: calculateChartSegment(data.not_submitted, assignmentsNumber),
            color: "#9CB8BE",
          },
        ],
      },
    ],
  };
}

function diveOnTimeChart() {
  return {
    chart: {
      type: "bar",
    },
    title: {
      text: "Deep Dive on Time",
      align: "left",
      style: {
        fontSize: "24px",
        fontWeight: 600,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontWeight: 600,
        zIndex: 100000,
      },
    },
    xAxis: {
      categories: [
        "19-Introducción: Tema y meta final",
        "19-Contraseña: Estrategia y Texto",
        "19-Vocabulario",
        "19-Gramática I",
        "19-Gramática II",
        "19-Exploración cultural",
        "19-Contraseña: Estrategia y Proyecto",
        "20-Introducción: Tema y meta final",
      ],
      labels: {
        style: {
          fontSize: "16px",
          fontWeight: 400,
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Time (minutes)",
        style: {
          fontSize: "16px",
          fontWeight: 400,
        },
      },
      labels: {
        style: {
          fontSize: "16px",
          fontWeight: 400,
        },
      },
    },
    legend: {
      reversed: true,
      enabled: false,
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            color: "#00CAB1",
          },
          select: {
            color: "#00CAB1",
          },
        },
        color: "#BDE6E1",
        borderRadius: "12px",
        stacking: "normal",
        dataLabels: {
          enabled: true,
          inside: false,
          style: {
            fontSize: "12px",
            fontWeight: 600,
          },
        },
      },
    },
    series: [
      {
        name: "Student",
        data: [50, 5, 10, 15, 22, 30, 55, 44],
      },
    ],
  };
}

function calculateChartSegment(assignmentsNumber: number, assignmentsTotalNumber: number): number {
  return Math.round((assignmentsNumber / assignmentsTotalNumber) * 100 * 100) / 100;
}
