export const calendarViews = ['dayGridMonth', 'dayGridWeek', 'listDay', 'listYear'] as const;

export type CalendarView = (typeof calendarViews)[number];

export type DateComparator = (date1: Date, date2: Date) => boolean;

export const isCalendarView = (view: unknown): view is CalendarView => (calendarViews as any).includes(view);

export const compareYear: DateComparator = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear();
}

export const compareMonth: DateComparator = (date1, date2) => {
    return compareYear(date1, date2) && date1.getMonth() === date2.getMonth();
};

const getStartOfWeek = (date: Date) => {
    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - date.getDay()
    );
}

export const compareWeek: DateComparator = (date1, date2) => {
    const week1 = getStartOfWeek(date1);
    const week2 = getStartOfWeek(date2);

    return week1.getTime() === week2.getTime();
}

export const compareDay: DateComparator = (date1, date2) => {
    return compareMonth(date1, date2) && date1.getDate() === date2.getDate();
}
