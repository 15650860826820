import { RWSService } from '@rws-framework/client';
import { LTIConfigType } from "../types/lti/config";
import { LTISyncEventData } from '../types/lti/sync';
import { IStudent } from 'src/types/students';
import { AssignmentsType } from '../components/ltiSyncManager/children/ltiSyncAssignments/component';

class LTISyncService extends RWSService {
    static event_names: { [key: string]: string } = {}

    // not called
    // async getLTIConfig(
    //     apiPrefix: string,
    //     courseId: string
    // ): Promise<{ result: any | null, status: 'ok' | 'none' }> {
    //     try {
    //       //@ts-ignore
    //       const csrfToken = Cookies.get("csrf_token");
    //       const response = await fetch(apiPrefix + "lti1.3/config/" + courseId + '/config', {
    //         method: 'GET',
    //         headers: {
    //           'CSRF-Token': csrfToken
    //         }
    //       })

    //       const result = await response.json()
    
    //       if (result.error) {
    //         return {result: null, status: 'none'}
    //       }
    //       return {result, status: 'ok'}

    //     } catch (error: Error | any) {
    //       console.error("LTI Error:", error)
    //       return {result: null, status: 'none'}
    //     }
    // }

    async getStudents(apiPrefix: string, course_config: LTIConfigType, courseId: string) {
        try {
            //@ts-ignore
            const csrfToken = Cookies.get("csrf_token")
            const response = await fetch(apiPrefix + "lti1.3/roster/" + courseId + "/" + course_config[0].guid, {
              method: 'GET',
              headers: {
                'CSRF-Token': csrfToken
              }
            })
            const result = await response.json()
            return result
        } catch (error) {
            console.error("Error:", error)
        }
    }

    // not called
    // goSync(
    //     emit: (payload: LTISyncEventData) => Promise<void>,
    //     course_config: any,
    //     courseId: string,
    //     selectedStudents: IStudent[],
    //     selectedAssignments: AssignmentsType[],
    //     sync_page: LTISyncEventData['sync_page'] = 'manager',
    //     sync_type: LTISyncEventData['sync_type'] = null
    // ) {  
    //     return emit({
    //         sync_page,
    //         sync_type,
    //         config: (course_config as any).error ? null : course_config[0],
    //         students: selectedStudents,
    //         assignments: selectedAssignments,
    //         courseId: courseId
    //     })
    // }
}

export default LTISyncService.getSingleton();

export { LTISyncService as LTISyncServiceInstance }