import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<fluent-dialog
  id="grading_center_learnosity_feedback_modal"
  trap-focus
  :hidden="${x => !x.isOpen}"
  modal
  class="grading_center_learnosity_feedback_modal">

  <header class="modal__header">
    <h1>${x => x.question.title}</h1>

    <button
      class="modal__header--exit"
      tabindex="0"
      title="Exit modal"
      @click="${x => x.close()}">
      Exit
    </button>
  </header>

  <section class="modal__main main">
    <div class="main__question" :innerHTML="${x => x.sanitizeHtml(x.question?.stimulus)}">
    </div>

    <div class="main__student">
      Response - ${x => x.student.name}
    </div>
  
    <div class="main__response">
      ${T.when((x, c) => x.student.type === 'plaintext' || x.student.type === 'longtext' || x.student.type === 'longtextV2', T.html`
        <span :innerHTML="${x => x.sanitizeHtml(x.student.response.value)}"></span>
      `)}

      ${T.when((x, c) => x.student.type === 'audio', T.html`
        <audio
          controls="controls"
          src="https://learnositymediaprocessed.s3.amazonaws.com/${x => x.student.response?.location}.mp3">
        </audio>
      `)}

      ${T.when((x, c) => x.student.type === 'video', T.html`
        <video
          controls="controls"
          style="max-height: 200px"
          src="https://learnositymediaprocessed.s3.amazonaws.com/${x => x.student.response.value.assetProcessedPath}">
        </video>
      `)}

      ${T.when((x, c) => x.student.type === 'fileupload' , T.html`
        <table>
          ${T.repeat((x, c) => x.student.response.value, T.html`
          <tr>
            <td>
              <a
                href="https://learnositymediaprocessed.s3.amazonaws.com/${ x=> x.assetPath}${x => x.extension}"
                target="_blank">
                ${x => x.name}
              </a>
            </td>
            <td>
              ${T.when((x, c) => x.extension !== 'pdf', T.html`
                <img
                  height="100"
                  alt="${x => x.name}"
                  src="https://learnositymediaprocessed.s3.amazonaws.com/${x => x.assetPath}${x => x.extension}" 
                />
              `)}

              ${T.when((x, c) => x.extension === 'pdf', T.html`
              <iframe src="https://learnositymediaprocessed.s3.amazonaws.com/${x => x.assetPath}${x => x.extension}">
              </iframe>
              `)}
              </td>
            </tr>
          `)}
        </table>
      `)}
    </div>
  </section>

  <section class="modal__feedback">
    <iframe
      class="layer learnosity-iframe"
      src="../../../modules/contents/components/templates/assessment/iframe.html"
      id="learnosity-iframe"
      allowfullscreen="true"
      title="iframe for loading assessment"
      aria-label="iframe for loading assessment">
    </iframe>
  </section>

  <div class="modal__flex">
    <button
      class="button__primary"
      tabindex="0"
      title="Save feedback"
      @click="${x => x.loaded ? x.saveFeedback() : ''}">
      ${x => x.loaded ? 'Save and Close' : 'Wait'}
    </button>
  </div>
</fluent-dialog>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.grading_center_learnosity_feedback_modal::part(positioning-region) {
  height: fit-content;
  max-height: calc(100vh - 70px);
  width: 765px;
  margin: 40px auto 30px;
  overflow: auto;
  border-radius: 12px;
}
.grading_center_learnosity_feedback_modal::part(control) {
  box-sizing: border-box;
  padding: 24px;
  height: auto;
  width: 100%;
}
.grading_center_learnosity_feedback_modal .modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}
.grading_center_learnosity_feedback_modal .modal__header h1 {
  margin: 0px;
  color: var(--neutral-90);
  font-size: 1.71429em;
  font-weight: 500;
  line-height: 150%;
}
.grading_center_learnosity_feedback_modal .modal__header--exit {
  padding: 0px;
  color: var(--link);
  font-size: 1.28571em;
  font-weight: 600;
  line-height: 160%;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.grading_center_learnosity_feedback_modal .modal__header--exit:hover, .grading_center_learnosity_feedback_modal .modal__header--exit:active {
  text-decoration: underline;
  color: #0A5281;
}
.grading_center_learnosity_feedback_modal .modal__main {
  margin-bottom: 24px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex-direction: column;
}
.grading_center_learnosity_feedback_modal .modal__main .main__question {
  color: var(--neutral-70);
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}
.grading_center_learnosity_feedback_modal .modal__main .main__student {
  color: var(--neutral-90);
  font-size: 1.28571em;
  font-weight: 500;
  line-height: 160%;
}
.grading_center_learnosity_feedback_modal .modal__main .main__response {
  color: var(--neutral-70);
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}
.grading_center_learnosity_feedback_modal .modal__feedback {
  margin-bottom: 24px;
}
.grading_center_learnosity_feedback_modal .modal__flex {
  display: flex;
  justify-content: flex-end;
}
.grading_center_learnosity_feedback_modal .learnosity-iframe {
  width: 100%;
  height: 475px;
}`;


import { observable, RWSViewComponent, RWSView, attr } from "@rws-framework/client";

@RWSView('grading-center-learnosity-feedback-modal', null, { template: rwsTemplate, styles })
class GradingCenterLearnosityFeedbackModal extends RWSViewComponent  {
  @observable isOpen:boolean = true;
  @observable question: any;
  @observable student: any;
  @observable feedbackApp: any;
  @observable index: number;
  @observable iframeWindow: any;
  @observable loaded: boolean = true; 

  async connectedCallback() {
    super.connectedCallback();

     if (!this.student.feedback_session_id) {
      const request = this.student.session_id;
      const data = await this.fetchFeedback(request);
      this.student.feedback_session_id = data.feedback_session_id;
     }

    this._feedback(this.student, this.question, this.index)
  };

  async fetchFeedback(request) {
    //@ts-ignore
    const csrfToken = Cookies.get('csrf_token');
    try {
      //@ts-ignore
      const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
      const response = await fetch(APIprefix + "assessment/feedback/" + request + '/1', {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        }
      });

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error:", error);
    };
  };

  //iframe for learnosity
  async reloadScript() {
    return new Promise<void | Event>((resolve, reject) => {
      const iframe = this.shadowRoot.getElementById('learnosity-iframe');
      if (!iframe) {
        reject();
      } else {
        // Causes the iframe to reload
        //@ts-ignore
        iframe.src = iframe.src;
        //@ts-ignore
        iframe.onload = () => {
          //@ts-ignore
          this.iframeWindow = iframe.contentWindow;
          //adding div for learnosity feedback
          const div = this.iframeWindow.document.createElement('div');

          div.id = this.student.id + this.question.reference;
          div.classList.add('learnosity-item');
          div.dataset.sessionId = this.student.feedback_session_id;
          div.dataset.reference = this.index;

          this.iframeWindow.document.getElementById('learnosity_assess').append(div)
          resolve();
        }
      }
    });
  };

  async feedbackApi(iframeWindow: any) {
    return new Promise<void | Event>(function(resolve, reject) {
      const document = iframeWindow.document;
      //@ts-ignore
      const existing = document.querySelectorAll('[src^="https://items.learnosity.com?' + window.edrnaConfig.learnosity.version + '"]');
      if (existing.length) {
    
        resolve();
      } else {
        const script = document.createElement('script');
        //@ts-ignore
        script.src = "https://items.learnosity.com?" + window.edrnaConfig.learnosity.version;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      }
    });
  };

  async _feedback(student, question, i: number) {
    const feedback = {
      'rendering_type': 'inline',
      'name': 'Items API demo - feedback activity.',
      'user_id': student.id,
      'activity_id': student.session_id,
      'session_id': student.feedback_session_id,
      'items': [{'id': i, 'reference': question.rubric}],
      'type': 'submit_practice',
      'config': {},
    };

    const signed = await this.getItemSignature(feedback);
    await this.reloadScript();
    const itemsAPI = this.feedbackApi.bind(null, this.iframeWindow)
    await itemsAPI();
    
    //@ts-ignore
    if (this.iframeWindow.LearnosityItems) {
      //@ts-ignore
      this.feedbackApp = this.iframeWindow.LearnosityItems.init(signed, {
        readyListener: function() {
          // what do we need here?
        },
        errorListener: function(e) {
          console.error(e);
        }
      });
    }
  };

  async getItemSignature(request: any) {
    try {
      //@ts-ignore
      const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
      //@ts-ignore
      const csrfToken = Cookies.get('csrf_token');
      const response = await fetch(APIprefix + "assessment/signature/items", {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        }
      });

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error:", error);
    };
  };

  saveFeedback() {
    this.loaded = false;
    this.feedbackApp.save({
      success: () => {
        console.log('success')
        this.loaded = true;
        this.close();
      }
    });
  };

  sanitizeHtml(line: string) {
    //added iframe, img, video, audio tags
    const allowedTags = [
      'address', 'article', 'aside', 'footer', 'header',
      'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hgroup',
      'main', 'nav', 'section',
      'blockquote', 'dd', 'div', 'dl', 'dt', 'figcaption', 'figure',
      'hr', 'li', 'main', 'ol', 'p', 'pre', 'ul',
      'a', 'abbr', 'b', 'bdi', 'bdo', 'br', 'cite', 'code', 'data', 'dfn',
      'em', 'i', 'kbd', 'mark', 'q',
      'rb', 'rp', 'rt', 'rtc', 'ruby',
      's', 'samp', 'small', 'span', 'strong', 'sub', 'sup', 'time', 'u', 'var', 'wbr',
      'caption', 'col', 'colgroup', 'table', 'tbody', 'td', 'tfoot', 'th',
      'thead', 'tr', 'img', 'video', 'audio','iframe',
    ];
    if (!line) {
      return;
    }
    return this.domService.sanitizeHTML(line, allowedTags);
  }

  close() {
    this.isOpen = false;
    this.remove();
  };
}

GradingCenterLearnosityFeedbackModal.defineComponent();

export { GradingCenterLearnosityFeedbackModal };
