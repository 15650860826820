// @ts-ignore
//const window_question = window.labelBundle.item_question || '';
//const window_questions = window.labelBundle.item_questions || '';
const window_question = '';
const window_questions = '';

export class LearnosityData {
  static STATE = {
    AFTER_CLOSE_DATE: "After Close Date",
    AFTER_DUE_DATE: "After Due Date",
    AFTER_DUE_DATE_WITH_CORRECT_ANSWERS: "Correct Answers After Due Date",
    AFTER_MAX_ATTEMPTS: "After Max Attempts",
    IMMEDIATELY: "Immediately",
    IMMEDIATELY_WITH_CORRECT_ANSWERS: "Correct Answers Immediately",
    IMMEDIATELY_WITH_CORRECT_ANSWERS_AFTER_DUE_DATE: "Immediately Correct Answers After Due Date",
    INLINE: "inline",
    NEVER: "Never",
    SHOW_CORRECT_ANSWERS: "show_correct_answers",
    SHOW_RESPONSES: "show_responses",
    SHOW_SCORE: "show_score",
    WITH_RESPONSES: "When responses are available",
  };

  static getAssessmentDeepDiveRequest(activityId: string, courseId: string, items: string[]) {
    return {
        "activity_id": activityId,
        "name": "Item Details",
        "rendering_type": "assess",
        "session_metadata": {"course_id": courseId},
        "state": "review",
        "type": "local_practice",
        "items": items,
        "mode": "assessment",
        "assess_inline": true,
        "config":{
          "questions_api_init_options": {
            "captureOnResumeError": true,
            "showCorrectAnswers": true
          },
          "ignore_question_attributes": [],
          "navigation": {
            "scroll_to_top": false,
            "scroll_to_test": false,
            "show_intro": false,
            "show_outro": false,
          },
          "regions": "items-only",
          "ignore_validation": false,
          "configuration":{
            "responsive_regions": true,
            "onsave_redirect_url": false,
            "ondiscard_redirect_url": false,
            "fontsize": "normal",
            "events": true
          }
        }
      }
  }

  static getRequest(session: any, review_correct_answers: any) {
    return {
      activity_id: session.activity_id,
      session_id: session.session_id,
      user_id: session.user_id,
      items: session.items.map((item: any) => item.reference),
      name: "Review",
      rendering_type: "assess",
      state: "review",
      type: "submit_practice",
      mode: "assessment",
      assess_inline: true,
      dynamic_items: {
        try_again: {
          max_attempts: 5,
          random: true
        }
      },
      config: {
        questions_api_init_options: {
          showCorrectAnswers: session.isProfessor || review_correct_answers,
          showInstructorStimulus: session.isProfessor,
        },
        //@ts-ignore
        ignore_question_attributes: [],
        title: "",
        subtitle: "",
        navigation: {
          "scroll_to_top":false,
          "scroll_to_test":false,
          "show_intro":false,
          "show_outro":true,
          "show_next":true,
          "show_prev":true,
          "show_accessibility":{"show_colourscheme":true,"show_fontsize":true,"show_zoom":true},
          "show_configuration":false,
          "show_fullscreencontrol":false,
          "show_progress":true,
          "show_submit":false,
          "show_title":true,
          "show_save":false,
          "show_calculator":false,
          "show_itemcount":true,
          "skip_submit_confirmation":false,
          "toc":true,
          "transition":"fade",
          "transition_speed":400,
          "warning_on_change":false,
          "scrolling_indicator":false,
          "show_answermasking":false,
          "show_acknowledgements":false,
          "auto_save":{"changed_responses_only":false,"ui":true,"saveIntervalDuration":12},
          "item_count":{"question_count_option":false}
        },
        labelBundle: {
          "item":window_question||"Question",
          "colorScheme":"Color Scheme",
          "paletteInstructions":"Instructions...color",
        },
        regions: {
          "items":[{"type":"slider_element","vertical_stretch_option":true},{"type":"progress_element"}],
          "right":[
            {"type":"previous_button","position":"top"},
            {"type":"next_button","position":"top"},
            {"type":"custom_button","options":{"name":"questions","label":window_questions||"Questions","icon_class":"hamburglar"}},
            {"type":"separator_element"},
            {"type":"accessibility_button","position":"bottom"},
            {"type":"verticaltoc_element"},
          ],
          "top-right":[{"type":"itemcount_element"}],
          "top-left":[{"type":"title_element"}]},
        ignore_validation: false,
        configuration: {
          "onsave_redirect_url":false,
          "ondiscard_redirect_url":false,
          "fontsize":"normal"}
      }
    }
  };
};
