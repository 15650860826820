import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="wrapper" :style="font-size: ${x => x.fontSize}">
  ${x => x.formatted ? T.html`
  <span class="${x => x.boldDate ? 'emphasize' : ''}">
    ${x => x.formatted.date}
  </span>
  <span> ${x => x.formatted.time} </span>
  ` : T.html`
  <span>-</span>
  `}
</div>
`;

import './styles/layout.scss';
const styles = T.css`:host {
  --font-size: 14px;
  --font-weight: 300;
  --color: var(--gray-2);
  --padding: 0;
  --gap: 4px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap);
  padding: var(--padding);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: 160%;
  color: var(--gray-2);
}

.emphasize {
  font-size: calc(var(--font-size) * 5 / 4);
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  color: var(--gray-1);
}`;

const mode = "open";
import { RWSViewComponent, RWSView, attr } from "@rws-framework/client";

declare const luxon: any;

@RWSView('jnct-date-tabular-display', null, { template: rwsTemplate, styles, options: {mode} })
class DateTabularDisplay extends RWSViewComponent  {
  @attr date: Date | string;
  @attr boldDate = false;
  @attr fontSize = "14px";

  get formatted() {
    return DateTabularDisplay.format(this.date);
  }

  public static format(date: Date | string) {
    const dt =
      date instanceof Date
        ? luxon.DateTime.fromJSDate(date)
        : luxon.DateTime.fromISO(date);

    if (!dt.isValid) return null;

    return {
      date: dt.toFormat("LLL d"),
      time: dt.toFormat("h:mm a"),
    };
  }
}

DateTabularDisplay.defineComponent();

export { DateTabularDisplay };
