import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`${T.when(x=>x.filteringType, T.html`
  <span @click="${x=>x.resetFilters()}">Reset filters</span>
`)}

<div class="table_wrapper">
  <div class="table" part="table">
    <div class="header_wrapper" part="table">
      <div
        :style="grid-template-columns: ${x=>x.columnsLayout}"
        class="row header"
      >
        ${T.when(x => x.allowChecking, T.html`
          <div class="element">
            <fluent-checkbox
              :checked="${x => x.isHeaderCheckboxSelected}"
              @change="${(x, c) => x.toggleHeaderCheckbox((c.event.target as HTMLInputElement).checked)}">
            </fluent-checkbox>
          </div>
        `)}
        ${T.repeat(x => x.showColumns, T.html`
        <div class="element">
          ${x => x.title}

          ${T.when(x=>x.filteringType, T.html`
          <div class="dropdown">
            <span
              class="material-symbols-outlined"
              style="vertical-align: middle"
            >
              filter_list
            </span>
            <div class="dropdown-content">
              <div class="dropdown_header">
                <span class="title">${x => x.title} Filter</span>
                <span
                  class="cancel"
                  @click="${(x, c)=>c.parent.resetFilters(x.name)}"
                  >Clear Filter</span
                >
              </div>
              ${T.when(x=>x.allowSorting, T.html`
              <div class="s_header">Sort by</div>
              <div class="section">
                <div
                  @click="${(x, c) => c.parent.sortData(x.name, 'ascending')}"
                >
                  ${T.when( (x, c) =>
                  c.parent.currentSorting===x.name+'ascending', T.html`
                  <input type="radio" checked /> `)} ${T.when( (x, c) =>
                  c.parent.currentSorting!==x.name+'ascending', T.html`
                  <input type="radio" /> `)}
                  <label
                    >${x => x.ascendingLabel ? x.ascendingLabel: 'A-Z'}</label
                  >
                </div>
                <div
                  @click="${(x, c) => c.parent.sortData(x.name, 'descending')}"
                >
                  ${T.when( (x, c) =>
                  c.parent.currentSorting===x.name+'descending', T.html`
                  <input type="radio" checked /> `)} ${T.when( (x, c) =>
                  c.parent.currentSorting!==x.name+'descending', T.html`
                  <input type="radio" /> `)}

                  <label
                    >${x => x.descendingLabel ? x.descendingLabel: 'Z-A'}</label
                  >
                </div>
              </div>

              `)} ${T.when(x=>x.filteringType === 'singleChoice', T.html`
              <div class="s_header">Filter by</div>
              <div class="section">
                <select
                  id="${x=>x.name+'Choice'}"
                  @change="${(x, c) => c.parent.choice(x.name+'Choice', x.name)}"
                  class="select_input"
                >
                ${T.repeat(x=> x.options, T.html`
                    <option
                      value="${x=> x.value}"
                      ?selected="${(x, c) => c.parentContext.parent.filters[c.parent.name]?.choices === x.value}"
                    >
                      ${x=>x.name}
                    </option>
                  `)}
                </select>
              </div>
              `)} ${T.when(x=>x.filteringType === 'multipleChoice', T.html`
              <div class="s_header">Filter by</div>
              <div class="section">
                <select
                  id="${x=>x.name+'Choice'}"
                  @change="${(x, c) => c.parent.choice(x.name+'Choice', x.name)}"
                  multiple
                  class="select_input"
                >
                  ${T.repeat(x=> x.options, T.html`
                  <option
                    value="${x=> x.value}"
                    ?selected="${(x, c) => Array.isArray(c.parentContext.parent.filters[c.parent.name]?.choices) && c.parentContext.parent.filters[c.parent.name]?.choices.includes(x.value)}"
                  >
                    ${x=>x.name}
                  </option>
                  `)}
                </select>
              </div>
              `)} ${T.when(x=>x.filteringType === 'search', T.html`
              <div class="s_header">Filter by</div>
              <div class="section">
                <input
                  id="${x=>x.name+'Search'}"
                  type="text"
                  class="search"
                  aria-label="Search"
                  placeholder="Search"
                  @input="${(x, c) => c.parent.search(x.name+'Search', x.name)}"
                />
              </div>
              `)}
            </div>
          </div>
          `)}

          
          ${T.when(x => x.allowSorting && !x.filteringType, T.html`
            ${T.when((x, c) => !c.parent.currentSorting?.includes(x.name), T.html`
              <img
                src="/images/sort_icon_inactive.svg"
                alt="sort descending"
                @click="${(x, c) => c.parent.sortData(x.name, 'descending')}"
                @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? c.parent.sortData(x.name, 'descending') : ''}"
                title="Add transcript"
                class="pointer"
                tabindex="0"
              />
            `)}

            ${T.when((x, c)=>c.parent.currentSorting===x.name+'ascending', T.html`
              <img
                src="/images/sort_icon_up.svg"
                alt="sort descending"
                @click="${(x, c) => c.parent.sortData(x.name, 'descending')}"
                @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? c.parent.sortData(x.name, 'descending') : ''}"
                title="Add transcript"
                class="pointer"
                tabindex="0"
              />
            `)}

            ${T.when((x, c)=>c.parent.currentSorting===x.name+'descending', T.html`
              <img
                src="/images/sort_icon_down.svg"
                alt="sort ascending"
                @click="${(x, c) => c.parent.sortData(x.name, 'ascending')}"
                @keyup="${(x, c) =>(c.event as KeyboardEvent).keyCode === 13 ? c.parent.sortData(x.name, 'ascending') : ''}"
                title="Add transcript"
                class="pointer"
                tabindex="0"
              />
            `)}
          `)}
        </div>
        `)}
      </div>

      <div class="data_wrapper">
        ${T.repeat(x=>x.showData, T.html`
        <div
          :style="grid-template-columns: ${(x, c)=>c.parent.columnsLayout}"
          class="row data"
          @click="${ (x, c) => x.onRowClick ? c.parent.handleRowClick(x, c) : c.parent.allowDefault()}"
        >
          ${T.when((x, c) => c.parent.allowChecking, T.html`
            <div class="element">
              <fluent-checkbox
                :checked="${(x, c) => c.parent.isItemSelected(x)}"
                @change="${(x, c) => c.parent.toggleItemCheckbox(x, (c.event.target as HTMLInputElement).checked)}">
              </fluent-checkbox>
            </div>
          `)}
          ${T.repeat((x, c) => c.parent.showColumns, T.html`
            ${(x, c) =>
              x.pattern
                ? T.html`
                  <div
                    class="element"
                    @click="${ (x, c) => c.parent[x.name].onClick(c.parent[x.name].onClickValue)}"
                  >
                  ${(x, c) => T.html`${x.pattern.replace("value", c.parent[x.name].value)}`}
                </div>`
                : x.link
                ? T.html`
                  <a
                    class="element link"
                    href="${(x, c) => c.parent[x.name].link}"
                    target="_blank"
                    >
                    <div :innerHTML="${ (x, c) => c.parent[x.name].value}"></div>
                  </a>`
                : x.click
                ? T.html`
                  <div class="element">
                    <button
                      class="button__secondary"
                      @click="${(x, c) => c.parent[x.name].onClick ? c.parent[x.name].onClick(c.parent[x.name]) : ''}"
                      @keyup="${(x, c) => (c.parent[x.name].onClick && (c.event as KeyboardEvent).keyCode === 13) ? c.parent[x.name].onClick(c.parent[x.name]) : ''}"
                      :innerHTML="${ (x, c) => c.parent[x.name].value}">
                    </button>
                  </div>`
                : x.date
                ? T.html`<jnct-date-tabular-display class="element" :date="${(x, c) => c.parent[x.name].value}"></jnct-date-tabular-display>`
                : T.html`<div class="element" :innerHTML="${(x, c) => c.parent[x.name].value}"></div>`
            }
          `)}
        </div>
        `, { positioning: true })}
      </div>
    </div>
  </div>
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

#jnct-table {
  display: flex;
}

.dropdown {
  position: relative;
  display: inline-block;
  justify-content: center;
}

.dropdown-content {
  display: none !important;
  background: #ffffff;
  position: absolute;
  top: 10px;
  width: 300px;
  padding: 16px;
  border-radius: 16px;
  border: 1px;
  gap: 16px;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.2509803922);
  border: 1px solid #eeeff0;
  z-index: 1;
}
.dropdown-content div {
  padding: 0;
}
.dropdown-content .dropdown_header {
  display: flex;
  justify-content: space-between;
  padding-right: 0px;
}
.dropdown-content .dropdown_header .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.dropdown-content .dropdown_header .cancel {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: right;
  color: #0f75b8;
}
.dropdown-content .s_header {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}
.dropdown-content .section {
  width: 262px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #eeeff0;
  gap: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.dropdown-content .section .search {
  border-radius: 12px;
  padding: 10px 5px;
  border: 1px solid #eeeff0;
  width: 100%;
}
.dropdown-content .section .select_input {
  padding: 5px 10px;
  border-radius: 12px;
  width: 100%;
  border: 1px solid #eeeff0;
}

.dropdown:hover .dropdown-content,
.dropdown:focus .dropdown-content,
.dropdown:focus-within .dropdown-content,
.dropdown:active .dropdown-content {
  display: block !important;
}

.layout__header {
  padding: 4px 0px 4px 0px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  padding: 4px 0px 4px 0px;
}
.layout__header .title {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  align-items: center;
  gap: 8px;
}
.layout__header .navigation__search .search__icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 17px;
  left: 28px;
  z-index: 2;
  cursor: pointer;
}
.layout__header .search {
  display: flex;
  justify-content: center;
  width: 336px;
  height: 40px;
  position: relative;
  cursor: pointer;
}
.layout__header .search__icon {
  top: 7px;
  left: 6px;
  width: 26px;
  height: 26px;
  position: absolute;
  z-index: 101;
  filter: invert(1);
}
.layout__header .search__input {
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px 0 35px;
  border-radius: 12px;
  background-color: #eeeff0;
  color: #5b5c5e;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  border: none;
  cursor: pointer;
  z-index: 100;
  transition: padding 0.4s;
}

.table_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.table_wrapper .students_number {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 5px;
}
.table_wrapper .table {
  width: 100%;
  border: 1px solid #eeeff0;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 1fr;
  overflow: visible;
}
.table_wrapper .table .data_wrapper {
  overflow-y: auto;
}
.table_wrapper .table .data_wrapper .row:nth-child(odd) {
  background: rgba(155, 160, 165, 0.0784313725);
}
.table_wrapper .table .header_wrapper {
  border-bottom: 2px solid #eeeff0;
  position: sticky;
  top: 0;
  background: #fff;
}
.table_wrapper .table .row {
  display: grid;
  padding: 0px 0px 0px 16px;
}
.table_wrapper .table .row .element {
  align-items: center;
  display: flex;
  gap: 4px;
}
.table_wrapper .table .row .element .date {
  display: block;
}
.table_wrapper .table .row .sortable_column {
  cursor: pointer;
}
.table_wrapper .table .row .sortable_column_options {
  display: flex;
  flex-direction: column;
}
.table_wrapper .table .row .sortable_column_options span {
  line-height: 7px;
  color: rgba(27, 28, 30, 0.2);
  font-size: 28px;
}
.table_wrapper .table .row .sortable_column_options .active {
  color: #3b3c3e;
}
.table_wrapper .table .row a {
  text-decoration: none;
  color: #0f75b8;
  cursor: pointer;
}
.table_wrapper .table .header {
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.table_wrapper .table .data {
  height: 80px;
  text-align: left;
  font-weight: 400;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0em;
}

.material-symbols-outlined {
  cursor: pointer;
  font-size: 24px;
}
.material-symbols-outlined.details-button {
  font-weight: 300;
  color: var(--neutral-70);
}

.pointer {
  cursor: pointer;
}

.button__secondary {
  min-width: 79px;
  text-wrap: nowrap;
  height: 40px;
  margin: auto;
}

.row-selected {
  background-color: rgba(27, 28, 30, 0.2) !important;
}`;

const mode = "open";
import { observable, RWSViewComponent, RWSView, attr } from "@rws-framework/client";
import { ColumnConfig, Data, FiltersInterface } from "../../types/table";

@RWSView('jnct-table', null, { template: rwsTemplate, styles, options: {mode} })
class JunctionTable extends RWSViewComponent  {
  @attr data: Data[] = [];
  @attr columnsConfig: ColumnConfig[];
  @attr columnsLayout: string;
  @attr allowChecking: boolean = false;
  @attr afterSelect?: (items: Data[]) => void;
  @attr afterFilter?: (items: Data[]) => void;
  @attr initialFilters?: FiltersInterface;
  //add it to have some items selected by default
  @attr defaultSelectedItems?: Data[] = [];
  @observable showColumns: ColumnConfig[];
  @observable showData: Data[];
  @observable currentSorting: string;
  @observable filters?: FiltersInterface = {};
  @observable selectedItems: Data[] = [];

  connectedCallback() {
    super.connectedCallback();

    this.filters = this.initialFilters || {};
    if (this.defaultSelectedItems.length > 0) {
      this.selectedItems = this.defaultSelectedItems;
    }
  }

  initialFiltersChanged() {
    this.filters = this.initialFilters || {};
  }

  allowCheckingChanged( oldValue: boolean, newValue: boolean) {
    this.allowChecking = newValue;
  }

  dataChanged(oldVal: any, newVal: any){
    this.showData = newVal;
    this.selectedItems = (this.selectedItems || []).filter(item => this.showData.includes(item));

    if (newVal.length > 0) {
      this.filtersChanged(undefined, this.filters);
    }
  }

  columnsConfigChanged(olcVal: any, newVal: any){
    this.showColumns = newVal
  }

  resetFilters(name: string) {
    if (name === undefined) {
      for (const [field, value] of Object.entries(this.filters) as [
        string,
        any
      ]) {
        if (value.type == "select") {
          const domElement = this.$(
            "#" + field + "Choice"
          ) as HTMLSelectElement;
          domElement.selectedIndex = this.columnsConfig.find(c => c.name === name)?.options?.findIndex(o => o.value === 'all') ?? -1
        }
        if (value.type == "search") {
          const domElement = this.$("#" + field + "Search") as HTMLInputElement;
          domElement.value = "";
        }
      }
      this.filters = {};
    } else {
      const value = this.filters[name];
      if (value.type == "select") {
        const domElement = this.$("#" + name + "Choice") as HTMLSelectElement;
        domElement.selectedIndex = this.columnsConfig.find(c => c.name === name)?.options?.findIndex(o => o.value === 'all') ?? -1
      }
      if (value.type == "search") {
        const domElement = this.$("#" + name + "Search") as HTMLInputElement;
        domElement.value = "";
      }
      delete this.filters[name];
      this.filters = {...this.filters}
    }
    this.selectedItems = [];
  }
  choice(event: string, field: string) {
    const domElement = this.$("#" + event) as HTMLSelectElement;
    this.filters = {
      ...this.filters,
      [field]: {
        type: "select",
        choices: Array.from(domElement.selectedOptions).map(
          ({ value }) => value
        ),
      },
    };
  }
  search(event: string, field: string) {
    const domElement = this.$("#" + event) as HTMLInputElement;
    this.filters = {
      ...this.filters,
      [field]: {
        type: "search",
        value: domElement.value,
      },
    };
  }

  filtersChanged(oldVal: any, newVal: FiltersInterface) {
    this.filters = newVal;
    this.showData = this.data;
    for (const [field, value] of Object.entries(this.filters) as [
      string,
      any
    ]) {
      if (value.type == "select") {
        if (!value.choices.includes("all")) {
          this.showData = this.showData.filter((obj) =>
            value.choices.includes(obj[field].value)
          );
        }
      }
      if (value.type == "search" && typeof field === "string") {
        this.showData = this.showData.filter((obj) =>
          obj[field].value.toString().toLowerCase().includes(value.value.toLowerCase())
        );
      }
    }
    this.selectedItems = [];

    if (typeof this.afterFilter == "function") {
      this.afterFilter(this.showData);
    }
  }

  sortData(field: string, type: string) {
    if (type === "descending") {
      this.showData.sort((a, b) =>
        (a[field]?.value ?? 0) < (b[field]?.value ?? 0) ? 1 :
        (b[field]?.value ?? 0) <= (a[field]?.value ?? 0) ? -1 : 0
      );
    } else {
      this.showData.sort((a, b) =>
        (a[field]?.value ?? 0) > (b[field]?.value ?? 0) ? 1 :
        (b[field]?.value ?? 0) >= (a[field]?.value ?? 0) ? -1 : 0
      );
    }
    this.currentSorting = field + type;
    this.selectedItems = [];
  }

  selectedItemsChanged(oldVal: any, newVal: any) {
    if (typeof this.afterSelect == "function") {
      this.afterSelect(this.selectedItems);
    }
  }

  get isHeaderCheckboxSelected() {
    return this.showData.length === this.selectedItems.length;
  }

  toggleHeaderCheckbox(checked: boolean) {
    const isAlreadySelected = this.isHeaderCheckboxSelected;

    if (checked === isAlreadySelected) return;

    this.selectedItems = checked ? this.showData : [];
  }

  isItemSelected(item: Data) {
    return this.selectedItems.includes(item)
  }

  toggleItemCheckbox(item: Data, checked: boolean) {
    const isAlreadySelected = this.selectedItems.includes(item);

    if (checked === isAlreadySelected) return;

    this.selectedItems = this.selectedItems.includes(item)
      ? this.selectedItems.filter(i => i !== item)
      : [...this.selectedItems, item]
  }

  handleRowClick(x: any, c: any) {
    const clickedElement = c.event.target;
    if (clickedElement.tagName.toLowerCase() == "button" || clickedElement.tagName.toLowerCase() == "input") {
      return;
    }

    const rowElement = clickedElement.classList.contains("row") ? clickedElement : clickedElement.parentNode;
    if (rowElement.classList.contains("row")) {
      x.onRowClick(x, rowElement);
    }
  }

  allowDefault() {
    /*
      FAST applies preventDefault on @click events by default, to make a href tags work, a handler needs to return true
     */
    return true;
  }
}

JunctionTable.defineComponent();

export { JunctionTable };
