import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<jnct-modal-header
  title="Sync Calendar"
  :hide="${x => x.genericConfig.switch}"
  :shadow="${x => false}"
></jnct-modal-header>

<div class="sub-container">
  <span class="section">
    <p>
      Sync your assignments with an external calendar using one of the methods
      below. The information in your external calendar may be updated with a
      delay.
    </p>
  </span>
  <span class="section border">
    <h3>Google Calendar</h3>
    Click the button below to sync to your Google Calendar

    <a
      class="sync-btn button__secondary"
      target="_blank"
      style="max-width: 155px"
      aria-label="Add to your google calendar"
      href="${x => 'https://calendar.google.com/calendar/u/0/r?cid=' + x.genericConfig.webcal}"
    >
      Sync Calendar
    </a>
  </span>
  <span class="section border">
    <h3>iCal Subscription URL</h3>
    <p>Copy the link below and add it to your calendar</p>
    <input type="text" class="input" value="${x => x.genericConfig.webcal}" />
    <br />
    <button
      class="clipboard-btn button__link"
      @click="${x => x.writeClipboardText(x.genericConfig.https)}"
    >
      Copy to clipboard
      <span class="material-symbols-outlined icon"> content_copy </span>
    </button>
  </span>

  ${T.when(x => window.labelBundle.calendar_link, T.html`
  <span class="section center">
    <a target="_blank" href="${x => window.labelBundle.calendar_link}">
      Help me subscribe to the Calendar
    </a>
  </span>
  `)}
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.sub-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-top: 0;
  max-width: 544px;
  gap: 16px;
}
.sub-container .flex-container {
  display: flex;
  align-items: center;
  gap: 5px;
}
.sub-container .section {
  padding: 0 16px;
}
.sub-container .section.border {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid var(--neutral-20, #eeeff0);
}
.sub-container .section.center {
  text-align: center;
}
.sub-container p {
  font-size: 16px;
  font-weight: 300;
  line-height: 160%;
  text-transform: none;
  margin: 0;
}
.sub-container h3 {
  margin: 0;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.sub-container .sync-btn {
  margin-top: 16px;
}
.sub-container .clipboard-btn {
  font-size: 16px;
  font-weight: 300;
  line-height: 160%;
  text-decoration: none;
}
.sub-container .clipboard-btn .icon {
  vertical-align: middle;
}

.input {
  line-height: 30px;
  background-color: var(--neutral-20);
  border: 1px solid var(--neutral-20);
  border-radius: 10px;
  padding: 8px 12px;
  margin: 8px 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 160%;
  color: var(--neutral-60);
  width: 100%;
  box-sizing: border-box;
}`;


import { observable, RWSViewComponent, RWSView, attr, ngAttr } from "@rws-framework/client"
import { ConfirmationModal } from "../../../components/confirmationModal/component"

@RWSView('subscribe-modal', null, { template: rwsTemplate, styles })
class SubscribeModal extends RWSViewComponent  {
  @attr genericConfig: any = {}

  connectedCallback() {
    super.connectedCallback()
    this.$emit('modal:shown')
  }

  genericConfigChanged(oldValue: any, newValue: any) {
    this.genericConfig = newValue
  }

  async writeClipboardText(text) {
    try {
      await navigator.clipboard.writeText(text)
    } catch (error) {
      console.error(error.message)
    }
  }

}

SubscribeModal.defineComponent()

export { SubscribeModal }
