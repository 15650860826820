import { RWSService } from "@rws-framework/client";

declare const luxon: any;

/**
 * Base facts:
 * `open_date`, `close_date`, `due_date` are stored as UTC iso timestamps, and are shifted to selected zone (via defaults) on the frontend.
 * `global_time` (the defaulttime) is stored as UTC, and is shifted to local on the frontend.
 *
 * This service offers utilities to shift date iso timestamps from and to utc, for UI purposes.
 */
class ActivityDateTimeService extends RWSService {
  public get timezone() {
    return window.localStorage.getItem("activity_settings_tz") || "local";
  }

  /** shift utc iso timestamp to zone configured in activity settings defaults */
  fromUtc(date: string | Date) {
    return ActivityDateTimeService.fromUtc(date, this.timezone);
  }

  /** shift timestamp of zone configured in activity settings defaults back to utc */
  toUtc(date: string | Date) {
    return ActivityDateTimeService.toUtc(date, this.timezone);
  }

  public static timezoneOffsetMinutes(timezone: string): number {
    return luxon.DateTime.now().setZone(timezone).offset;
  }

  /** shift utc iso timestamp to specified zone */
  public static fromUtc(date: string | Date, zone: string) {
    const dateTime =
      date instanceof Date
        ? luxon.DateTime.fromJSDate(date, { zone: "utc" })
        : luxon.DateTime.fromISO(date, { zone: "utc" });

    return dateTime
      .plus({
        minutes:
          ActivityDateTimeService.timezoneOffsetMinutes(zone) -
          ActivityDateTimeService.timezoneOffsetMinutes("local"),
      })
      .toISO();
  }

  /** shift timestamp of specified zone back to utc */
  public static toUtc(date: string | Date, zone: string) {
    const dateTime =
      date instanceof Date
        ? luxon.DateTime.fromJSDate(date, { zone: "local" })
        : luxon.DateTime.fromISO(date, { zone: "local" });

    return dateTime
      .setZone("utc")
      .minus({
        minutes:
          ActivityDateTimeService.timezoneOffsetMinutes(zone) -
          ActivityDateTimeService.timezoneOffsetMinutes("local"),
      })
      .toISO();
  }
}

export default ActivityDateTimeService.getSingleton();

export { ActivityDateTimeService as ActivityDateTimeServiceInstance };
