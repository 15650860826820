import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<section class="box">
  <div class="box__header">
    <div class="section-title header__title">
      <span class="material-symbols-outlined">insert_chart</span>
      <span>Performance By Item</span>
    </div>
    <span class="list-link" title="See Report Details" @click="${x => x.showItemsDetailedReport()}">See Report Details</a>
  </div>
  <div class="content-container width-100">
    <jnct-table
      class="item-list"
      :data="${x => x.assessments}"
      :columnsConfig="${x => x.columns}"
      columnsLayout="40% 30% 25%">
    </jnct-table>
    <div class="item-details width-100">
      <div class="details-header">Item Details</div>
      <div class="details-content">
        <learnosity-item id="student-submissions-learnosity-content"></learnosity-item>
      </div>
    </div>
  </div>
</section>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.box {
  display: flex;
  overflow: hidden;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  border: 2px solid var(--neutral-20);
  background: white;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}
.box__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.box__header .list-link {
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
  color: #0f75b8;
  transition: all 0.2s ease-in-out;
}
.box__header .list-link:hover {
  text-decoration: none;
  color: #3ea9ef;
  cursor: pointer;
}
.box .header__title {
  display: flex;
  gap: 12px;
  flex: 1 0 0;
  color: var(--neutral-90);
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
}
.box .header__data {
  display: flex;
  gap: 48px;
}
.box .header__data .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--neutral-70);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.box .header__data .item__bold {
  color: var(--neutral-90);
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
}
.box .content-container {
  display: flex;
  gap: 10px;
}
.box .content-container .item-list {
  flex: 0 0 35%;
}
.box .content-container .item-details {
  flex: 1;
  display: flex;
  width: auto;
  flex-direction: column;
  border: 1px solid #eeeff0;
  border-radius: 12px;
}
.box .content-container .item-details .details-header {
  font-weight: 600;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 170%;
  border-bottom: 2px solid #eeeff0;
}

.width-100 {
  width: 100%;
}`;


import { observable, RWSViewComponent, RWSView, attr, RWSInject } from "@rws-framework/client";
import { ColumnConfig } from "../../../../types/table";
import LearnosityService, { LearnosityServiceInstance } from "../../../../services/LearnosityService";
import { CustomHTMLElement } from "../../../../components/gradingCenter/gradeByStudent/component";
import { Course } from "../../../../types/course";
import EventsService, { EventsServiceInstance } from "../../../../services/EventsService";
import {PerformanceByItemType} from "../../../../types/assessments";

@RWSView('performance-by-item', null, { template: rwsTemplate, styles })
class PerformanceByItem extends RWSViewComponent  {
  @attr activity: any;
  @attr course: any;
  @observable data: any;
  @observable sessions: any;
  @observable assessments: any[] = [];
  @observable items: string[];

  columns: ColumnConfig[] = [
    {
      name: "number",
      title: "Item number",
      allowSorting: false,
    },
    {
      name: "points",
      title: "Points",
      allowSorting: false,
    },
    {
      name: "average_grade",
      title: "Grade",
      allowSorting: false
    }
  ];

  constructor(
    @RWSInject(LearnosityService) private learnosityService: LearnosityServiceInstance,
    @RWSInject(EventsService) private eventsService: EventsServiceInstance,
  ) {
    super();
  }

  async connectedCallback() {
    super.connectedCallback();

    const performanceByItem = await this.getPerformanceByItem();

    await this.initializeLearnosity();

    this.items = []
    Object.entries(performanceByItem).forEach(([item_reference, performance], index) => {
      this.items.push(item_reference)
      this.assessments = [
        ...this.assessments,
        {
          id: {
            value: item_reference
          },
          number: {
            value: index + 1
          },
          points: {
            value: performance.max_score
          },
          average_grade: {
            value: Math.round(performance.avg_score_in_percentage) + "%"
          },
          onRowClick: (row: any, rowElement: HTMLElement) => {
            // @ts-ignore
            learnositycomp.shadowRoot.getElementById('learnosity-iframe').contentWindow.LearnosityAssess.items().goto(index);

            const siblings = Array.from(rowElement.parentNode?.children || []);
            siblings.forEach((sibling) => {
              const element = sibling as HTMLElement;
              element.classList.remove("row-selected");
            });

            rowElement.classList.add("row-selected");
          }
        },
      ]
    })

    const learnositycomp = this.shadowRoot.getElementById('student-submissions-learnosity-content') as CustomHTMLElement;
    if (learnositycomp) {
      learnositycomp.isItemList = true;
      learnositycomp.data = this.data;
      learnositycomp.sessions = JSON.stringify({'session': {'session_id': "blah", 'isProfessor': true}});
      learnositycomp.course = this.course;
      learnositycomp.items = this.items;
    }
  }

  async initializeLearnosity() {
    const assessment = {
      id: this.activity.acitivity_id ? this.activity.acitivity_id : this.activity.id,
      activity_id: this.activity.acitivity_id ? this.activity.acitivity_id : this.activity.id,
      session_id: [this.activity.student_submissions[0].session_id]
    };
    const student = {_id: this.activity.student_submissions[0].id};
    this.data = await this.learnosityService.getLearnosity(assessment, student, this.course);
    this.sessions = this.data.sessions;
  }

  async getPerformanceByItem(): Promise<PerformanceByItemType> {
    //@ts-ignore
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    //@ts-ignore
    const csrfToken = Cookies.get("csrf_token");
    const response = await fetch(
      // /assessment/performance/{course_id}/{activity_id}
      APIprefix + "assessment/performance/" + this.course._id + "/" + this.activity.activity_id,
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        }
      }
    );
    return await response.json();
  }

  showItemsDetailedReport() {
    window.location.href = `${window.location.href}/items-detailed-report`;
  }
}

PerformanceByItem.defineComponent();

export { PerformanceByItem };
