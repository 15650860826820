import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="body__input ${x => x.hoverEffect ? 'hoverEffect' : ''}" style="${x => x.width ? `width: ${x.width};` : ''}">
  <button
    class="minus"
    @click="${x => x.decreasePoint(x.point)}">
    ${T.when(x => x.point > 0 && !x.error, T.html`
      <span class="material-symbols-outlined icon">remove</span>
    `)}
  </button>

  <input
    placeholder="-"
    id="${x => x.studentid}"
    type="${x => x.type || 'text'}"
    @input="${(x, c) => x.change((c.event.target as HTMLInputElement).value)}"
    :value="${x => x.point}"
    class="input ${x => x.type ? x.type : ''}">

  <button
    class="minus"
    @click="${x => x.increasePoint(x.point)}">
    ${T.when(x => x.point < x.maxPoints && !x.error, T.html`
      <span class="material-symbols-outlined icon">add</span>
    `)}
  </button>
</div>

${T.when(x => x.error, T.html`
  <div class="error">
    <span class="material-symbols-outlined error__icon">info</span>
    ${x => x.error}
  </div>
`)}
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.body__input {
  box-sizing: border-box;
  display: flex;
  width: 90px;
  height: 24px;
  margin-bottom: 4px;
  padding: 4px 4px 4px 8px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background: var(--neutral-20);
}
.body__input.hoverEffect {
  width: 123px;
}
.body__input.hoverEffect .input {
  height: 20px;
  width: 60px;
}
.body__input.hoverEffect .minus {
  visibility: hidden;
}
.body__input.hoverEffect:hover {
  border: 1px solid var(--Color-Neutral-Solid-neutral-300, #BCBCBD);
}
.body__input.hoverEffect:hover .minus {
  visibility: visible;
}
.body__input .input {
  box-sizing: border-box;
  width: 46px;
  height: 24px;
  border: none;
  background: var(--neutral-20);
  color: var(--neutral-70);
  text-align: center;
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
}
.body__input .minus {
  display: flex;
  justify-content: center;
  width: 16px;
  padding: 0px;
  border: none;
  background-color: inherit;
  cursor: pointer;
}
.body__input .icon {
  font-size: 1.14285em;
}

.error {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--action-error);
  font-size: 0.85714em;
  font-weight: 400;
  line-height: 130%;
}
.error__icon {
  height: 14px;
  color: var(--action-error);
  font-size: 1em;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}`;


import { attr, observable, RWSViewComponent, RWSView } from "@rws-framework/client";

@RWSView('grading-input', null, { template: rwsTemplate, styles })
class GradingInput extends RWSViewComponent  {
  @attr type: string;
  @attr hoverEffect?: boolean;
  @attr width?: string;
  @observable point: any;
  @observable maxPoints: number;
  @observable studentid: string;
  @observable error: string;
  changePoint: Function | undefined;

  connectedCallback() {
    super.connectedCallback();
  };

  validate(point: any) {
    if (!isNaN(point) && parseFloat(point) <= this.maxPoints) {
      this.error = '';
      this.changePoint(Number(point), this.studentid);
      return;
    };
    if (parseFloat(point) > this.maxPoints) {
      this.error = 'Exceeds Max Points';
      return;
    }

    this.error = 'Enter a number';
    return;
  };

  change(point: any) {
    this.point = point;
    this.validate(point);
  };

  decreasePoint() {
    let point = this.point;
    point = parseFloat(this.point);
    point = isNaN(point) ? 0 : point;
    point--;
    this.point = point;
    this.validate(point);
  };

  increasePoint() {
    let point = this.point;
    point = parseFloat(this.point);
    point = isNaN(point) ? 0 : point;
    point++;
    this.point = point;
    this.validate(point);
  };
}

GradingInput.defineComponent();

export { GradingInput };
