import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<content>
  <div id="chart"></div>
</content>
`;

import './styles/layout.scss';
const styles = T.css``;

const mode = "open";
import {
  attr,
  RWSViewComponent,
  RWSView,
  observable,
} from "@rws-framework/client";

@RWSView('jnct-chart-bar', null, { template: rwsTemplate, styles, options: {mode} })
class ChartBar extends RWSViewComponent  {
  @attr chartconfig: any;

  chartconfigChanged(oldVal: string, newVal: any) {
    this.renderChart();
  }

  renderChart() {
    const el = this.shadowRoot.getElementById("chart");

    if (!el || !this.chartconfig) return;

    const Highcharts = require("highcharts");

    Highcharts.chart(el, this.chartconfig);
  }

  connectedCallback() {
    super.connectedCallback();

    this.renderChart();
  }
}

ChartBar.defineComponent();

export { ChartBar };
