import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="dash__loading" style="display: ${x => x.isLoading ? 'flex':'none'}">
    <img
      src="/images/loading_circle.png"
      alt="loading"
      class="dash__spinner" />
      Please wait...
</div>

<header class="flex flex__row" style="display: ${x => x.isLoading ? 'none':'flex'}">
    <div class="flex flex__row gap-32">
        <div class="title-container flex flex__row gap-8">
            <span class="material-symbols-outlined title-icon">collections_bookmark</span>
            <h1>Gradebook</h1>
        </div>
        ${T.when(x => !x.hideTotal, T.html`
            <div class="flex flex__row gap-20 pill pill__header">
                <span>Total grade: <span class="semibold">${x => isNaN(x.totalGrade)?"-":(Math.round(x.totalGrade).toFixed(1)+'%')}</span></span>
                <span>Points: <span class="semibold">${x => x.totalObtained} out of ${x => x.totalAttempted}</span></span>
            </div>
        `)}
    </div>
  
    <div class="flex flex__row gap-20">
        <jnct-searchbar :handleChange="${x=>x.search}"></jnct-searchbar>
        <fluent-select value="Actions" title="Actions" class="actions-container">
            <fluent-option value="activity" @click="${x => x.toggleCustom(false)}">
                <span>Activity</span>
            </fluent-option>
            ${T.when(x => x.hasRollup, T.html`
                <fluent-option value="custom" @click="${x => x.toggleCustom(true)}">
                    <span>Custom</span>
                </fluent-option>
            `)}
        </fluent-select>
    </div>
</header>
<div class="layout__container" style="display: ${x => x.isLoading ? 'none':'block'}">

    <div class="content-container box">
        <ul class="accordion-list flex flex__columns gap-12">
            ${T.repeat(x => x.chapters, T.html`
                <li class="accordion-item ${x => x.open_date_in_future ? 'locked' : ''}" 
                    id="${x => x.id}">
                    <div class="visible-info container flex flex__row">
                        <div class="info-container flex flex__row gap-16">
                            <div class="img-container" 
                                style="background-color: ${(x, c) => c.parent.isCustom ? x.color : 'transparent'}">${T.when((x, c) => !c.parent.isCustom, T.html`
                                <img src="${x => x.thumbnailUrl}" onerror="this.style.display='none'" alt="${x => x.title.replace(/<\/?[^>]+(>|$)/g, '')}" />`)}
                            </div>
                            <div class="flex flex__columns gap-8 text-container">
                              <div class="flex text-container gap-8">
                                <span class="material-symbols-outlined size-20">${x => x.open_date_in_future ? 'lock' : ''}</span>
                                <h2 :innerHTML="${x => x.title}"></h2>
                              </div>
                                <div class="flex flex__row gap-12">
                                    <div class="flex flex__rows gap-4 pill pill__content">
                                        <span>Grade: <span class="semibold">${x => isNaN(x.grade)?"-":(Math.round(x.grade).toFixed(1)+'%')}</span></span>
                                        <span>Points: <span class="semibold">${x => x.points} out of ${x => x.maxPoints} attempted</span></span>
                                    </div>
                                    <span class="pill pill__content">Activities: <span class="semibold">${x => x.assignments.length}</span></span>
                                  ${T.when(x => x.open_date_in_future, T.html`
                                    <span>Opens on: <span class="semibold">${x => x.open_date}</span></span>
                                  `)}
                                </div>
                            </div>
                        </div>
                        <div 
                            tabindex="0"
                            @click="${(x, c) => c.parent.toggleItem(x)}" 
                            @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? c.parent.toggleItem(x) : ''}"
                            class="opener-container">
                            <span class="material-symbols-outlined accordion-opener">keyboard_arrow_down</span>
                        </div>
                    </div>
                    <div class="hidden-content">
                        <div class="grid-container">
                            <div class="header-row">
                                <div class="header-cell">
                                    <div class="header-cell-container">
                                        <span>Activity</span>
                                    </div>
                                </div>
                                <div class="header-cell">
                                    <div class="header-cell-container">
                                        <span>Grade</span>
                                    </div>
                                </div>
                                <div class="header-cell">
                                    <div class="header-cell-container">
                                        <span>Status</span>
                                    </div>
                                </div>
                                <div class="header-cell">
                                    <div class="header-cell-container">
                                        <span>Submitted</span>
                                    </div>
                                </div>
                            </div>
                        
                            ${T.repeat(x => x.assignments, T.html`
                                <div class="data-row ${(x, c) => c.parentContext.parent.getLockClass(x)}">
                                    <div class="data-cell">
                                      <span class="material-symbols-outlined size-20">${(x, c) => c.parentContext.parent.getLockClass(x) ? 'lock' : ''}</span>
                                        <span class="date-element" 
                                              :innerHTML="${(x, c) => c.parentContext.parent.the_time(x)}">
                                        </span>
                                        <div class="flex flex__columns align-left">
                                            <a class="title-element ${(x, c) => c.parentContext.parent.getLockClass(x)}" href="${(x, c) => c.parentContext.parent.getLockClass(x) ? null : x.activity_results_url}" title="${(x, c) => c.parentContext.parent.htmlTrim(x.activity)}" tabindex="0" :innerHTML="${x => x.activity}"></a>
                                            ${T.when(x => x.orig_due_date, T.html`
                                                <span class="annotation">Extended from: ${x => x.orig_due_date}</span>
                                            `)}
                                        </div>
                                    </div>
                                    <div class="data-cell grading-elements">
                                        <span class="semibold">${x => isNaN(x.grade) ? '-' : Math.round((x.grade / x.grade_total) * 1000) / 10 + '%'}</span>
                                        <span>Points: <span class="semibold">${x => x.grade} out of ${x => x.grade_total}</span></span>
                                    </div>
                                    <div class="data-cell">
                                        ${T.when((x, c) => x.grade_messages.length > 0, T.html`
                                            <jnct-status-badge short-badge="true" type="${(x, c) => c.parentContext.parent.getMappedStatus(x.grade_messages[0].label)}"></jnct-status-badge>
                                        `)}
                                    </div>
                                    <div class="data-cell">${x => x.submit_date} ${x => x.submit_time}</div>
                                </div>
                            `)}
                        </div>                                                                       
                    </div>
                </li>
            `)}
        </ul>
    </div>
</div>`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

fluent-select {
  width: 200px;
  min-width: auto;
  font-family: inherit;
}
fluent-select::part(control) {
  width: 200px;
  height: 40px;
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Color-palette-Neutral-20, #EEEFF0);
  color: var(--Color-palette-Neutral-60, #5B5C5E);
  font-family: inherit;
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}

header {
  margin-bottom: 20px;
  margin-top: -25px;
  position: sticky;
  top: -10px;
  width: 100%;
  z-index: 2;
  padding: 20px 32px 10px;
  box-sizing: border-box;
  background: #fff;
  transition: all 0.2s ease-in-out;
}
header .title-container .title-icon {
  font-size: 22px;
}
header .title-container h1 {
  font-weight: 400;
  font-size: 20px;
}

.layout__container {
  box-sizing: border-box;
  padding: 24px 32px;
  overflow: visible;
}
.layout__container .accordion-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
.layout__container .accordion-list .accordion-item {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20, #EEEFF0);
  overflow: hidden;
  max-height: 100px;
  transition: all 0.2s ease-in-out;
}
.layout__container .accordion-list .accordion-item.open {
  max-height: fit-content;
  overflow: visible;
}
.layout__container .accordion-list .accordion-item.open .accordion-opener {
  transform: rotate(180deg);
}
.layout__container .accordion-list .accordion-item.open .hidden-content {
  display: block;
}
.layout__container .accordion-list .accordion-item .img-container {
  border-radius: 8px;
  overflow: hidden;
  width: fit-content;
  height: 60px;
  min-width: 5px;
}
.layout__container .accordion-list .accordion-item .img-container img {
  width: 60px;
  height: 100%;
  transition: all 0.5s ease-in-out 0.8s;
}
.layout__container .accordion-list .accordion-item .text-container h2 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}
.layout__container .accordion-list .accordion-item .opener-container {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  transition: all 0.2s ease-in-out;
}
.layout__container .accordion-list .accordion-item .opener-container .accordion-opener {
  position: relative;
  z-index: 1;
  display: block;
  font-size: 24px;
}
.layout__container .accordion-list .accordion-item .opener-container:hover, .layout__container .accordion-list .accordion-item .opener-container:focus-visible {
  cursor: pointer;
  background: var(--neutral-8, rgba(155, 160, 165, 0.08));
}
.layout__container .accordion-list .accordion-item .hidden-content {
  display: none;
}
.layout__container .accordion-list .accordion-item .hidden-content .grid-container {
  width: 100%;
  box-sizing: border-box;
}
.layout__container .accordion-list .accordion-item .hidden-content .grid-container .header-row {
  overflow: hidden;
  margin: 20px 0;
  border-radius: 8px;
  background: var(--neutral-20, rgba(27, 28, 30, 0.2));
  box-sizing: border-box;
  width: 100%;
  gap: 10px;
  display: grid;
  grid-template-columns: 60% 15% 10% 10%;
  align-items: center;
}
.layout__container .accordion-list .accordion-item .hidden-content .grid-container .header-row .header-cell {
  padding: 8px 0;
}
.layout__container .accordion-list .accordion-item .hidden-content .grid-container .header-row .header-cell:first-child {
  padding-left: 14px;
}
.layout__container .accordion-list .accordion-item .hidden-content .grid-container .header-row .header-cell .header-cell-container {
  text-align: left;
  font-weight: 500;
}
.layout__container .accordion-list .accordion-item .hidden-content .grid-container .data-row {
  gap: 10px;
  align-items: center;
  display: grid;
  grid-template-columns: 60% 15% 10% 10%;
}
.layout__container .accordion-list .accordion-item .hidden-content .grid-container .data-row .data-cell {
  overflow: visible;
  padding: 8px 0;
}
.layout__container .accordion-list .accordion-item .hidden-content .grid-container .data-row .data-cell:first-child {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 8px 5px;
}
.layout__container .accordion-list .accordion-item .hidden-content .grid-container .data-row .data-cell .date-element {
  border: 1px solid var(--Color-palette-Neutral-30, #BCBCBD);
  border-radius: 8px;
  width: 75px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
}
.layout__container .accordion-list .accordion-item .hidden-content .grid-container .data-row .data-cell .title-element {
  color: #0F75B8;
  text-decoration: none;
  max-height: 20px;
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
}
.layout__container .accordion-list .accordion-item .hidden-content .grid-container .data-row .data-cell .title-element:hover {
  color: #094871;
}
.layout__container .accordion-list .accordion-item .hidden-content .grid-container .data-row .data-cell .title-element.locked {
  color: var(--neutral-70);
  cursor: not-allowed;
}
.layout__container .accordion-list .accordion-item .hidden-content .grid-container .data-row .data-cell .title-element.locked:hover {
  color: inherit;
}
.layout__container .accordion-list .accordion-item .hidden-content .grid-container .data-row .data-cell.grading-elements {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.layout__container .accordion-list .accordion-item .hidden-content .grid-container .data-row .data-cell jnct-status-badge::part(status-badge-container) {
  margin: 0;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 12px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex__row {
  flex-direction: row;
}
.flex__columns {
  flex-direction: column;
}
.flex.gap-4 {
  gap: 4px;
}
.flex.gap-8 {
  gap: 8px;
}
.flex.gap-12 {
  gap: 12px;
}
.flex.gap-16 {
  gap: 16px;
}
.flex.gap-20 {
  gap: 20px;
}
.flex.gap-32 {
  gap: 32px;
}
.flex.text-container {
  align-items: start;
}

.pill {
  border-radius: 100px;
  border: 1px solid var(--neutral-20);
}
.pill__header {
  padding: 8px 24px;
}
.pill__content {
  padding: 4px 12px;
}

.semibold {
  font-weight: 500;
}

.annotation {
  font-size: 12px;
}

.align-left {
  align-items: start;
}

.dash__loading {
  height: calc(100vh - var(--header_height) - var(--full_footer_height) - 28px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 160%;
  background-color: #fff;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.dash__spinner {
  animation: rotate 1s linear infinite;
  background-position: center center;
  background-repeat: no-repeat;
  height: 64px;
  width: 64px;
}

.locked {
  background-color: var(--neutral-20);
}

.size-20 {
  font-size: 20px;
}`;

const mode = "open";
import { RWSViewComponent, RWSView, RWSInject, observable, attr, ngAttr } from "@rws-framework/client";
import ActivitiesService, {ActivitiesServiceInstance} from '../../services/ActivitiesService';
import { statusToIconNameMap } from "./_statusMap";
import TimeFormatService, {TimeFormatServiceInstance} from "../../services/TimeFormatService";

@RWSView('gradebook-student', null, { template: rwsTemplate, styles, options: {mode} })
class GradebookStudent extends RWSViewComponent  {
    @observable chapters: any[] = [];
    @observable activities: any[] = [];
    @observable isLoading: boolean = true;
    @observable isCustom: boolean = false; // using_custom_rollup
    @observable totalGrade: string;
    @observable totalObtained: number;
    @observable totalAttempted: number;
    @observable hasRollup: boolean = true;
    @observable courseThumbnails: any[] = [];
    @observable launchCounter: number = 0;
    @observable hideTotal: boolean = false;

    constructor(
      @RWSInject(ActivitiesService) protected activityService: ActivitiesServiceInstance,
      @RWSInject(TimeFormatService) protected timeFormatService: TimeFormatServiceInstance,
    ) {
        super()
    }

    async connectedCallback () {
        super.connectedCallback()
        const selectItem = this.shadowRoot.querySelector('.actions-container') as HTMLSelectElement

        //@ts-ignore
        if (!Cookies.get('viewL')) {
            //@ts-ignore
            Cookies.set('viewL', "custom")
        }
        //@ts-ignore
        selectItem.value = Cookies.get('viewL')
        
        this.courseThumbnails = await this.getCourse()
        this.fetchData(false)
        document.querySelector('.layout__border').addEventListener('scroll', (event) => {
            this.shadowRoot.querySelector('header').style.boxShadow = event.target.scrollTop > 30 ? '0 20px 25px -30px #777' : 'none';
        })
    }

    fetchData(forceReload: boolean = false) {
        this.$emit("jnct:gradebookService:fetch", {
            force: forceReload,
            callback: (data: any) => {
                this.hasRollup = data.rollup;
                this.isCustom = data.using_rollup;
                this.isLoading = true;
                this.totalGrade = data.score_to_date;
                this.totalAttempted = data.score_to_date_attempted;
                this.totalObtained = data.score_to_date_obtained;
                this.hideTotal = data.no_total_column;

                // If the gradebook cache doesn't match we display the right one here
                const selectItem = this.shadowRoot.querySelector('.actions-container') as HTMLSelectElement
                //@ts-ignore
                selectItem.value = this.isCustom? "custom" : "activity"
                
                let id = 0;

                this.activities = []
                data.groups.forEach((group, index) => {
                    const matchingObject = this.courseThumbnails.find(item => item.title === group.title);
                    this.activities[index] = {
                        id: matchingObject != undefined ? matchingObject._id : id++,
                        title: group.title,
                        color: group.color ? group.color : 'blue',
                        grade: group.score_to_date,
                        points: this.helper(group.score_to_date_obtained),
                        maxPoints: group.score_to_date_attempted == '-'? 0 : this.helper(group.score_to_date_attempted),
                        assignments: group.items,
                        open_date: this.checkDate(group),
                        open_date_in_future: group.open_date_in_future,
                        thumbnailUrl: (matchingObject != undefined && matchingObject.thumbnailUrl) ? matchingObject.thumbnailUrl : '/none.jpg'
                    }
                })

                this.activities = this.activities.filter((activity) => {
                    return activity.assignments.length > 0
                })
                this.chapters = this.activities
                this.isLoading = false

                const imageContainers = this.shadowRoot.querySelectorAll('.img-container')
                if (this.isCustom) {
                    imageContainers.forEach(imageContainer => {
                        imageContainer.classList.add('custom')
                    })
                } else {
                    imageContainers.forEach(imageContainer => {
                        imageContainer.classList.remove('custom')
                    })
                }
            }
        })
    }

    toggleItem(item: any) {
        const selector = `.accordion-item[id='${item.id}']`;
        const element = this.shadowRoot.querySelector(selector);
        if (element) {
            element.classList.toggle('open');
        }
    }

    toggleCustom(status: boolean) {
        this.isLoading = true;
        //@ts-ignore
        Cookies.set('viewL', status? "custom" : "lesson")
        this.fetchData(true)
    }

    getCourse(): Promise<any> {
        return new Promise((resolve) => {
          this.$emit('jnct:dashboardService:getCourse', (data) => {
            resolve(data.lessonsData)
          })
        })
    }

    getMappedStatus(status: string) {
        const iconName = statusToIconNameMap[status];
        if (iconName) {
            return iconName;
        }
    }

    checkDate(item) {
      if (!item.open_date) return '';
      if (new Date(item.open_date) <= new Date()) return '';
      return this.timeFormatService.longDateTime(item.open_date);
    }

    getLockClass(item) {
      if (item.open_date_in_future) return 'locked';
      const open_date = this.checkDate(item);
      if (open_date) return 'locked';
    }

    the_time(x: any) {
        const due = this.abbreviateMonth(x.due_date)
        const time = this.timeFormat(x.due_time)
        if (due == '-') {
            return '<span style="text-align: center; height: 40px; line-height: 40px">No Date</span>'
        }
        return due + '<br/>' + time
    }

    helper(score: any) {
        if (isNaN(score) || typeof(score) == typeof("")) return score
        return parseFloat((score).toFixed(1))
    }

    timeFormat(string: string) {
        if (!string) return "-"
        string = string.replace(/[a-z]/g, (match) => {
          return match.toUpperCase();
        });
        string = string.replace(/([0-9])([AP]M)/, '$1 $2');
        return string;
    }

    abbreviateMonth(date: string) {
        const monthMap = {
            'January': 'Jan',
            'February': 'Feb',
            'March': 'Mar',
            'April': 'Apr',
            'May': 'May',
            'June': 'Jun',
            'July': 'Jul',
            'August': 'Aug',
            'September': 'Sep',
            'October': 'Oct',
            'November': 'Nov',
            'December': 'Dec'
        };

        const dateParts = date.split(' ');
        const month = dateParts[0];
        const day = dateParts[1];
        const abbreviatedMonth = monthMap[month];

        if (abbreviatedMonth) {
            return `${abbreviatedMonth} ${day}`;
        }
    
        return date;
    }

    htmlTrim(text: string) {
        const cleanText = text.replace(/<.*?>/g, '');
        return cleanText;
    }

    search = (value: any) => {
        if (value) {
            this.chapters = this.activities.filter((obj) => {
                if (obj.title.toLowerCase().includes(value.toLowerCase())) {
                    return obj;
                }
                if (obj.assignments.some(assignment => assignment.activity.toLowerCase().includes(value.toLowerCase()))) {
                    return obj;
                }
            });
        } else {
            this.chapters = this.activities
        }
    };
}

GradebookStudent.defineComponent();

export{
  GradebookStudent
}
