import { AdjustGradeModal } from './adjustModal/component';
import { OverrideAssessmentModal } from './overrideModal/component';
import { RosterRecord } from "src/types/users";
import {Course} from "../../types/course";
import { Activity } from "src/types/activities";

export class GradebookDropdownActions {
  //@ts-ignore
  static csrfToken = Cookies.get('csrf_token'); 

  static createDropdownELement(div: HTMLElement, TD: HTMLElement, assessments, student, assessment_id: string, session, course: Course) {
    const el = document.createElement('span');
    el.innerHTML = 'more_vert';
    el.classList.add("material-symbols-outlined");
    el.classList.add("dropdown__icon");
    div.appendChild(el);
    TD.appendChild(div);
    el.addEventListener('click', () => {
      const assessment = assessments.filter(assess => assess.activity_id ? assess.activity_id === assessment_id : assess.id === assessment_id)[0];
      const dropdown = document.createElement('gradebook-dropdown');
      //@ts-ignore
      dropdown.options = [];
      const noGrading = assessment.type === 'project' && session.needs_grading && session.contains_overdue && !session.manually_accepted;
      //@ts-ignore
      if (!noGrading) dropdown.options.push({name: session.needs_grading ? 'Grade Activity' : 'Adjust Grade', value: session.needs_grading ? 'grade' : 'adjust'})
      if (assessment.type === 'assessment') {
        //@ts-ignore
        dropdown.options.push({name: 'Manage Overrides', value: 'override'});
      } 
      if (session.contains_overdue === true && !session.manually_accepted) {
        //@ts-ignore
        dropdown.options.push({name: 'Manage Late Work', value: 'late-work'})
      }
      //@ts-ignore
      dropdown.student = student;
      //@ts-ignore
      dropdown.assessment = assessment;
      //@ts-ignore
      dropdown.course = course;
      el.appendChild(dropdown)
    });
  }

  // edit selected assessment button
  static getModalConfig(course: Course, assessments: any, index: string = undefined) {
    const assessment = {
      lessons: course.lessonsData.map(({ _id, title }) => ({ id: _id, title })),
      type: '',
    };

    if (index !== undefined) { // edit (not add)
      let due_date = assessments[index].due_date || assessments[index].close_date;
      $.extend(assessment, {
        id: assessments[index].id,
        title: assessments[index].report_title,
        max_score: assessments[index].max_score,
        show: assessments[index].show,
        due_date: due_date,
        lesson: assessments[index].lesson,
        type: assessments[index].type
      });
      if (assessments[index].actual) assessment.type = assessments[index].actual;
    }

    return assessment;
  };

  // download csv button
  static getCSV(course_assessments: any, viewL: string, viewP: boolean, reports: any, myself: any, students: any, course_title: string) {
    let csvString = "student id,student name,student email";

    const assessments = [...course_assessments];
    assessments.forEach((assessment) => {
      if (viewL == '' && assessment.type == 'lesson' || viewL.length > 1 && assessment.type != 'lesson') {
        return
      };

      csvString += ",\"" + assessment.report_title.replace(/<[^>]*>/g, '') + "\"";
    });

    csvString += "\r\n";
    csvString += "max_score,,";
  
    assessments.forEach((assessment) => {
      if (viewL == '' && assessment.type == 'lesson' || viewL.length > 1 && assessment.type != 'lesson') {
        return
      };

      csvString += "," + (assessment.max_score || "");
    });

    csvString += "\r\n";

    const csvArray = [];
    Object.keys(reports).forEach((studentId) => {
      if (studentId == myself.id) return;
      const userScore = [];
      reports[studentId].forEach((score,i) => {
        if (viewL == "" && assessments[i].type == 'lesson' || viewL.length > 1 && assessments[i].type != 'lesson') {
          return
        }
        // All this is duplicating the asP function with the ng-ifs in the template to give the same
        // values in the download; obviously brittle as changes are needed in 2 (or really 3) places
        // TODO if gradebook ng-repeats are re-factored this is likely a place to refactor as well
        if (viewL) {
          if (viewP) {
              userScore.push(
                students[studentId].lesson[assessments[i].id] === undefined?
                  '-' : (students[studentId].lesson[assessments[i].id].toFixed(1) + '%')
              )
            } else {
              userScore.push(score)
            }
        } else {
          if (viewP) {
            if (i) {
              userScore.push(
                (score === '' || score === '-' || !assessments[i].max_score)?
                  '-' : (((score*100)/assessments[i].max_score).toFixed(1) + "%")
              )
            } else {
              // total on the first column is special
              userScore.push(students[studentId].percent.toFixed(1)+'%')
            }
          } else {
            // not viewing rollup or by percent; plain scores
            userScore.push(score)
          }
        }
      });
      const userScoreStr = userScore.join(",");
      const user = students[studentId];
      csvArray.push(studentId + ",\"" + user.lastname + ", " + user.firstname + "\"," + user.email + "," + userScoreStr + "\r\n");
    });
    csvArray.sort((a,b) => {
      let aa = a.substr(26).toLowerCase();
      let bb = b.substr(26).toLowerCase();
      return aa == bb ? 0 : aa < bb ? - 1 : 1;
    });
    csvArray.forEach((s) => {
      csvString += s
    });

    /* Works in Chrome, Firefox and Safari */
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
    element.setAttribute('download', `${course_title}.csv`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  static openAdjustModal(student: RosterRecord, assessment: Activity, course: Course) {
    const modal = document.createElement('adjust-grade-modal') as AdjustGradeModal;
    student._id = student.id;
    modal.student = student;
    modal.assessment = assessment;
    modal.course = course;
    document.body.appendChild(modal);
  }

  static openOverrideModal(student: RosterRecord, assessment: Activity, course: Course) {
    const modal = document.createElement('override-assessment-modal') as OverrideAssessmentModal;
    student._id = student.id;
    modal.student = student;
    modal.assessment = assessment;
    modal.course = course;

    document.body.appendChild(modal);
  }
};
