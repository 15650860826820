import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<grading-header :disabled="${x => x.disabledSave}" :save="${x => x.saveHandler}"></grading-header>

${T.when(x => !x.loaded, T.html`
  <jnct-loading-spinner></jnct-loading-spinner>
`)}

${T.when(x => x.loaded, T.html`
<section class="grading__board board">

  <a class="board__title" href="${x => x.component?.link}">
    ${x => x.component?.title}
    <span class="material-symbols-outlined"> arrow_forward </span>
  </a>

  <ul class="board__list">
    ${T.repeat(x => x.component?.students, T.html`
      <li class="student">
        <div class="student__data data">
          <div class="data__container">
            <div class="data__title">Student</div>
            <div class="data__info">${x => x.name}</div>
          </div>
          <div class="data__container">
            <div class="data__title">Number of Posts</div>
            <div class="data__info">${x => x.count}</div>
          </div>
          <div class="data__container">
            <div class="data__title">Number of Words</div>
            <div class="data__info">${x => x.word}</div>
          </div>
          <div class="data__container">
            <div class="data__title">
              Grade 
              <span class="data__title--thin">
                (Max Points ${x => x.max_score})
              </span>
            </div>
            <grading-input
              :point="${x => x.grade ?? null}"
              :maxPoints="${x => x.max_score}"
              :changePoint="${(x, c) => c.parent.changePoint}"
              :studentid="${x => x.id}"
              class="padding-0-8">
            </grading-input>
          </div>
        </div>

        <div class="student__posts">
          ${T.repeat(x => x.messages, T.html`
            <div class="student__post post">
              <div class="post__date">
                Posted on ${(x, c) => c.parentContext.parent.formatDate(x[0])}
              </div>
              <div class="post__text" :innerHTML="${(x, c) => c.parentContext.parent.sanitizeHtml(x[1])}">
              </div>
            </div>
          `)}
        </div>
      </li>
    `)}
  </ul>
</section>
`)}
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.grading__board {
  display: flex;
  padding: 2px 22px 0px 22px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}
.grading__board .board__title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-decoration: none;
  color: var(--neutral-90);
  font-size: 1.71429em;
  font-weight: 600;
  line-height: 140%;
}
.grading__board .board__list {
  width: 100%;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  list-style: none;
}
.grading__board .board__list .student {
  display: flex;
  padding: 8px 0px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}
.grading__board .board__list .student .data {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding: 0px 16px;
}
.grading__board .board__list .student .data__container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
}
.grading__board .board__list .student .data__title {
  padding: 8px;
  color: var(--neutral-90);
  font-size: 1em;
  font-weight: 600;
  line-height: 170%;
}
.grading__board .board__list .student .data__title--thin {
  color: var(--neutral-90);
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
}
.grading__board .board__list .student .data__info {
  padding: 8px;
  color: var(--neutral-70);
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
}
.grading__board .board__list .student__posts {
  width: 100%;
}
.grading__board .board__list .student__posts .post {
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  background: var(--neutral-8);
}
.grading__board .board__list .student__posts .post:not(:last-child) {
  border-bottom: 1px solid var(--neutral-20);
}
.grading__board .board__list .student__posts .post__date {
  display: flex;
  width: 190px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  color: var(--neutral-70);
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
}
.grading__board .board__list .student__posts .post__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  color: rgba(29, 36, 51, 0.8);
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
}

.padding-0-8 {
  padding: 0px 8px;
}`;


import { observable, RWSViewComponent, RWSView, RWSInject, attr } from "@rws-framework/client";
import EventsService, {EventsServiceInstance} from "../../../services/EventsService";
import {RosterRecord} from "../../../types/users";

type StudentType = {
  id: string,
  name: string,
  count: number,
  messages: MessageType[],
  word: number,
  grade: string | number,
  max_score: number,
}

type MessageType = string[];

type ComponentType = {
  id: string,
  title: string,
  link: string,
  course_id: string,
  students: StudentType[]
};

@RWSView('grading-board', null, { template: rwsTemplate, styles })
class GradingBoard extends RWSViewComponent  {
  @observable component: ComponentType;
  @observable loaded: boolean = false;
  @observable disabledSave: boolean = true;

  constructor(
    @RWSInject(EventsService) protected eventsService: EventsServiceInstance,
  ) {
    super();
  }

   async connectedCallback() {
    super.connectedCallback();
    const students = await this.eventsService.getRoster() as RosterRecord[];
    const students_ids = students.map((student: RosterRecord) => student._id);
    //we need this timeout because of angularJS
    setTimeout(() => {
      this.$emit('jnct:gradingcenter:getboards', (data: any) => {

        this.changePoint = this.changePoint.bind(this);

        this.component = {
          id: data[0]?.component_id,
          title: data[0]?.thread,
          link: data[0]?.link,
          course_id: data[0]?.course_id,
          students: data.filter((st: StudentType) => students_ids.includes(st.id)),
        };

        this.loaded = true;
      });
    }, 0);
  };

  formatDate(dateString: string) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();
    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
  };

  saveHandler = this.save.bind(this);

  goToGradingCenter() {
    this.$emit('jnct:navigation:gotogradingcenter');
  };

  sanitizeHtml(line: string) {
    //added iframe, img, video, audio tags
    const allowedTags = [
      'address', 'article', 'aside', 'footer', 'header',
      'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hgroup',
      'main', 'nav', 'section',
      'blockquote', 'dd', 'div', 'dl', 'dt', 'figcaption', 'figure',
      'hr', 'li', 'main', 'ol', 'p', 'pre', 'ul',
      'a', 'abbr', 'b', 'bdi', 'bdo', 'br', 'cite', 'code', 'data', 'dfn',
      'em', 'i', 'kbd', 'mark', 'q',
      'rb', 'rp', 'rt', 'rtc', 'ruby',
      's', 'samp', 'small', 'span', 'strong', 'sub', 'sup', 'time', 'u', 'var', 'wbr',
      'caption', 'col', 'colgroup', 'table', 'tbody', 'td', 'tfoot', 'th',
      'thead', 'tr', 'img', 'video', 'audio','iframe',
    ];
    if (!line) {
      return;
    }
    return this.domService.sanitizeHTML(line, allowedTags);
  };

  changePoint(value: string | number, id: string) {
    this.disabledSave = false;
    this.component.students.forEach(student => {
      if (student.id === id) {
        student.grade = value;
      };

      return student;
    })
  };

  async save() {
    //@ts-ignore
    const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
    //@ts-ignore
    const csrfToken = Cookies.get('csrf_token'); 

    try {
      const response = await fetch(APIprefix + 'gradebook/' + this.component.course_id + '/board_grades/' + this.component.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken 
        },
        body: JSON.stringify(this.component.students),
      });

      if (!response.ok) {
        throw new Error('Please try again later.');
      }

      this.$emit('jnct:dashboardService:flush');
      this.$emit('jnct:notifications:success', 'Success! Score captured');
      this.disabledSave = true;
    } catch (error) {
        this.disabledSave = true;
        this.$emit('jnct:notifications:error');
        console.error('There was a problem with the fetch operation:', error.message);
    }
  };
}

GradingBoard.defineComponent();

export { GradingBoard };
