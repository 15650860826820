import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<fluent-dialog id="filter-modal" trap-focus modal class="filter__modal">
  <header class="modal__header">
    <h1>Filter by Type</h1>
    <div class="filter" @click="${x => x.applyDefaultFilter()}">
      Default Filter
    </div>
  </header>

  <ul class="list">
    ${T.repeat(x => x.types.filter(el => el.type !== 'Auto' && el.type !== 'graded'), T.html`
      <li class="list__item">
        <div for="${x => x.type}">${x => x.title}</div>

        <div
          class="checkbox ${x => x.chosen ? 'active' : ''}"
          tabindex="0"
          @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? c.parent.check(x.title) : ''}"
          @click="${(x, c) => c.parent.check(x.title)}">
          ${T.when(x => x.chosen, T.html`
            <span class="material-symbols-outlined checkbox__mark"> check </span>
         `)}
        </div> 
      </li>
    `)}
  </ul>

  <ul class="list">
    ${T.repeat(x => x.types.filter(el => el.type === 'graded'), T.html`
      <li class="list__item">
        <div for="${x => x.type}">${x => x.title}</div>
        <div
          class="checkbox ${x => x.chosen ? 'active' : ''}"
          tabindex="0"
          @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? c.parent.check(x.title) : ''}"
          @click="${(x, c) => c.parent.check(x.title)}">
          ${T.when(x => x.chosen, T.html`
            <span class="material-symbols-outlined checkbox__mark"> check </span>
         `)}
        </div> 
      </li>
    `)}
  </ul>

  <ul class="list">
    ${T.repeat(x => x.types.filter(el => el.type === 'Auto'), T.html`
      <li class="list__item">
        <div for="${x => x.type}">${x => x.title}</div>
        <div
          class="checkbox ${x => x.chosen ? 'active' : ''}"
          tabindex="0"
          @keyup="${(x, c) => (c.event as KeyboardEvent).keyCode === 13 ? c.parent.check(x.title) : ''}"
          @click="${(x, c) => c.parent.check(x.title)}">
          ${T.when(x => x.chosen, T.html`
            <span class="material-symbols-outlined checkbox__mark"> check </span>
         `)}
        </div> 
      </li>
    `)}
  </ul>

  <div class="modal__flex buttons">
    <button
      class="button__secondary"
      tabindex="0"
      title="Exit modal"
      @click="${x => x.close()}">
      Cancel
    </button>
    <button
      class="button__primary"
      tabindex="0"
      title="Apply changes"
      @click="${x => x.apply()}">
      Apply
    </button>
  </div>

  <header class="modal__header">
    <h1>Filter by Chapter</h1>
    <div class="filter" @click="${x => x.clearFilter()}">
      Clear Filter
    </div>
  </header>

  <ul class="list chapters">
    ${T.repeat(x => x.chapters, T.html`
      <li @click="${(x, c) => c.parent.chooseChapter(x.id)}" class="list__item chapter ${x => x.chosen ? 'active' : ''}">
        <div :innerHTML="${x => x.title}"></div>
        ${T.when(x => x.chosen, T.html`
          <span class="material-symbols-outlined"> check </span>
        `)}
      </li>
    `)}
  </ul>
</fluent-dialog>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.filter__modal::part(positioning-region) {
  height: fit-content;
  max-height: calc(100vh - 70px);
  overflow: auto;
  width: 448px;
  margin: 40px auto 30px;
  border-radius: 12px;
}
.filter__modal::part(control) {
  box-sizing: border-box;
  padding: 24px;
  height: auto;
  width: 100%;
}
.filter__modal .modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}
.filter__modal .modal__header h1 {
  margin: 0px;
  color: var(--neutral-90);
  font-size: 1.42857em;
  font-weight: 500;
  line-height: 150%;
}
.filter__modal .modal__text {
  margin-bottom: 48px;
  color: var(--neutral-90);
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}
.filter__modal .modal__flex {
  display: flex;
  justify-content: space-between;
}
.filter__modal .filter {
  color: var(--link);
  text-align: right;
  cursor: pointer;
  font-size: 1.14286em;
  font-weight: 400;
  line-height: 160%;
}
.filter__modal .list {
  list-style: none;
  display: flex;
  display: flex;
  padding: 16px;
  margin: 0px 0px 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-20);
  background: white;
}
.filter__modal .list.chapters {
  padding: 0px;
  gap: 0px;
}
.filter__modal .list__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--neutral-70);
  font-size: 1em;
  font-weight: 400;
  line-height: 170%;
}
.filter__modal .list__item.chapter {
  box-sizing: border-box;
  padding: 12px 16px;
  cursor: pointer;
}
.filter__modal .list__item.chapter:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.filter__modal .list__item.chapter:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.filter__modal .list__item.chapter:hover, .filter__modal .list__item.chapter.active {
  background: var(--neutral-20);
}
.filter__modal .buttons {
  margin-bottom: 16px;
}

.checkbox {
  position: relative;
  width: 22px;
  height: 22px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}
.checkbox.active {
  border-color: var(--primary_color);
  background-color: var(--primary_color);
}
.checkbox__mark {
  position: absolute;
  color: var(--primary_text);
  left: 2px;
  font-size: 19px;
  top: -1px;
  font-weight: 300;
}`;


import { observable, RWSViewComponent, RWSView, attr } from "@rws-framework/client";

type AssessmentType = {
  title: string,
  type: string,
  chosen: boolean | string
}

type ChapterType = {
  title: string,
  id: string,
  chosen: boolean | string
}

@RWSView('filter-modal', null, { template: rwsTemplate, styles })
class GradingCenterFilterModal extends RWSViewComponent  {
  @observable chapters: ChapterType[];
  @observable types: AssessmentType[];
  @observable chaptersChanged: boolean = false;
  @observable action?: Function | undefined;

  connectedCallback() {
    super.connectedCallback();
  };

  apply() {
    if (!this.chaptersChanged) {
      this.chaptersChanged = this.chapters.some(chapter => chapter.chosen === true);
    };

    this.action(this.types, this.chapters, this.chaptersChanged);
    this.close();
  }

  close() {
    this.remove();
  };

  //we do deep copy of array of objects. we do it to make html re-render
   deepCopy(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(this.deepCopy.bind(this));
    }

    const newObj = {};
    for (let key in obj) {
      newObj[key] = this.deepCopy(obj[key]);
    }
    return newObj;
  };

  check(title: string) {
    const changedCheck = this.deepCopy(this.types);
    changedCheck.forEach((el: AssessmentType) => el.title === title ? el.chosen = !el.chosen : el.chosen);
    this.types = changedCheck;
    return;
  }

  chooseChapter(id: string) {
    const copy = this.deepCopy(this.chapters);
    const chapters = copy.map((el: ChapterType) => {
      if (el.id == id) {
        el.chosen = !el.chosen;
      }

      return el;
    });

    this.chaptersChanged = true;
    this.chapters = chapters;
  };

  applyDefaultFilter() {
    const defaultChoice = this.deepCopy(this.types);
    this.types = defaultChoice.map((el: AssessmentType) => {
      if (el.type === 'Auto' || el.type === 'graded') {
        el.chosen = false;
      } else {
        el.chosen = true;
      }

      return el;
    })
  }

  clearFilter() {
    const unselected = this.deepCopy(this.chapters);
    this.chaptersChanged = false;
    this.chapters = unselected.map((el: ChapterType) => {
      if (el.chosen) {
        el.chosen = false;
      }

      return el;
    });
  };
}

GradingCenterFilterModal.defineComponent();

export { GradingCenterFilterModal };
