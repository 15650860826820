import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="wrapper">
  <div class="spinner"><jnct-loading-spinner></jnct-loading-spinner></div>
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.spinner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}`;

const mode = "open";
import {
  attr,
  RWSViewComponent,
  RWSView,
  RWSInject,
} from "@rws-framework/client";
import { AssessmentAttempt } from "../../../types/reports";
import StudentDeepDiveLearnosityService, {
  StudentDeepDiveLearnosityServiceInstance,
} from "../../../services/StudentDeepDiveLearnosityService";

@RWSView('jnct-assessment-attempt-learnosity-iframe', null, { template: rwsTemplate, styles, options: {mode} })
class JunctionAssessmentAttemptLearnosityIframe extends RWSViewComponent  {
  @attr attempt: AssessmentAttempt;

  app = null;
  modal: HTMLElement | null = null;

  constructor(
    @RWSInject(StudentDeepDiveLearnosityService)
    protected service: StudentDeepDiveLearnosityServiceInstance
  ) {
    super();
  }

  init() {
    if (!this.isConnected) return;

    this.modal = this.embedLearnoistyIframeOutsideShadowDOM();

    this.setAndTrackPosition();
    window.addEventListener("resize", this.setAndTrackPosition);

    this.service
      .initializeApp(this.attempt)
      .then((app) => {
        this.app = app;
        this.adjustLearnosityDom(this.modal);
      })
      .catch((err) => {
        this.$emit(
          "jnct:notifications:error",
          "Failed to load learnosity player"
        );
        console.error(err);
      });
  }

  deInit() {
    window.removeEventListener("resize", this.setAndTrackPosition);

    if (this.app) this.app.reset();
    if (this.modal) this.modal.remove();
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.init();
  }

  /**
   * Web components use Shadow DOM, but Learnosity does not support being embedded inside of it,
   * and it fails with an error like "node not found in DOM".
   *
   * For that reason, we must create a container in real DOM and overlay it over the intended position.
   */
  embedLearnoistyIframeOutsideShadowDOM() {
    const wrapper = this.shadowRoot.querySelector(".wrapper");
    const exists = document.getElementById("learnosity_assess");

    if (exists) exists.remove();

    const modal = document.createElement("div");
    const div = document.createElement("div");

    div.setAttribute("id", "learnosity_assess");
    modal.append(div);
    document.body.append(modal);

    return modal;
  }

  setAndTrackPosition = () => {
    const target = this.modal;
    const source = this.shadowRoot.querySelector(".wrapper");

    const box = source.getBoundingClientRect();

    target.style.boxSizing = "border-box";
    target.style.position = "fixed";
    target.style.top = `${box.top}px`;
    target.style.left = `${box.x}px`;
    target.style.width = `${box.width}px`;
    target.style.height = `${box.height}px`;
    target.style.overflow = `hidden`;
    // I'm sorry.
    target.style.zIndex = "100000";
  };

  adjustLearnosityDom(modal: HTMLElement) {
    const items = modal.querySelector<HTMLDivElement>(".lrn-items-region");
    const container = modal.querySelector<HTMLDivElement>(".slides-container");
    const control = modal.querySelector<HTMLDivElement>(".slides-control");

    if (!items || !container || !control) return;

    /**
     * By default learnosity shows a horizontal scrollbar due to incorrect sizing,
     * code below adjusts it.
     */
    items.style.display = "flex";
    items.style.alignItems = "center";
    items.style.justifyContent = "center";
    container.style.width = "calc(100% - 15px)";
    control.style.width = "calc(100% - 15px)";
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.deInit();
  }
}

JunctionAssessmentAttemptLearnosityIframe.defineComponent();

export { JunctionAssessmentAttemptLearnosityIframe };
