import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="input" id="customSelect">
  <span class="selected_option" @click="${x=>x.showOptions = !x.showOptions}">
    <div
      class="left_side"
      style="color: ${x=>x.value.border}; background: ${x=>x.value.background};"
    >
      Aa
    </div>
    <div class="right_side">
      <span>${x=>x.value.name}</span>
      <span class="material-symbols-outlined">
        ${x=>x.showOptions ? "expand_less" : "expand_more"}
      </span>
    </div>
  </span>
  ${T.when(x=>x.showOptions, T.html`
  <div class="dropdown-content">
    ${T.repeat(x=>x.colors, T.html`
    <span
      class="option ${(x, c) => c.parent.value.name == x.name ? 'selected': 'not_selected'}"
      data-value="option1"
      @click="${(x, c) => c.parent.value = x}"
    >
      <span class="left">
        <div
          class="color"
          style="color: ${x=>x.border}; background: ${x=>x.background}; border: 1px solid ${x=>x.border} "
        >
          Aa
        </div>
        ${x=>x.name}
      </span>
      ${T.when((x, c) => c.parent.value.name == x.name, T.html`<span
        class="material-symbols-outlined right"
      >
        check </span
      >`)}
    </span>
    `)}
  </div>
  `)}
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.input {
  position: relative;
  display: flex;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  height: 44px;
  justify-content: center;
  background: #eeeff0;
  cursor: pointer;
}

.selected_option {
  overflow: hidden;
  border-radius: 12px;
  display: flex;
  width: 100%;
}
.selected_option span {
  display: flex;
  align-items: center;
}
.selected_option .left_side {
  background-color: blue;
  padding: 10px;
  height: 44px;
  box-sizing: border-box;
}
.selected_option .right_side {
  display: flex;
  padding: 10px 8px 10px 16px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.dropdown-content {
  top: 44px;
  left: 0px;
  border-radius: 12px;
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100%;
  z-index: 1;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  overflow: scroll;
  overflow-x: hidden;
  height: 300px;
}

.option {
  padding: 16px 10px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  color: #3b3c3e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}
.option .color {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}
.option .left {
  display: flex;
  display: flex;
  gap: 16px;
  align-items: center;
}
.option .right {
  font-size: 16px;
}

.selected {
  background-color: #EEEFF0;
  cursor: default;
}

.selected:hover {
  background-color: #EEEFF0;
}

.not_selected:hover {
  background-color: #ddd;
}`;

const mode = "open";
import {
  RWSViewComponent,
  RWSView,
  observable,
  attr,
} from "@rws-framework/client";

interface color {
  name: string;
  background: string;
  border: string;
}
export const colors: color[] = [
  { name: "Red", background: "#F7D4D7", border: "#570F16" },
  { name: "Orange", background: "#FFE3CC", border: "#652E01" },
  { name: "Yellow", background: "#FFF2CC", border: "#664D00" },
  { name: "Lime Green", background: "#DFF9C5", border: "#33580D" },
  { name: "Green", background: "#D6F5DD", border: "#145222" },
  { name: "Turquoise", background: "#D2F9F9", border: "#0B5B5B" },
  { name: "Blue", background: "#CCE5FF", border: "#003166" },
  { name: "Purple", background: "#EBD4F7", border: "#280561" },
  { name: "Pink", background: "#FCE", border: "#660043" },
  { name: "White", background: "#FFF", border: "#000" },
  { name: "Light Gray", background: "#EBEBEB", border: "#333" },
  { name: "Dark Gray", background: "#CFCFCF", border: "#000" },
  { name: "Black", background: "#000", border: "#FFF" },
];
@RWSView('jnct-select-color-input', null, { template: rwsTemplate, styles, options: {mode} })
class JunctionSelectColorInput extends RWSViewComponent  {

  @observable value: color = {
    name: "Red",
    background: "#F7D4D7",
    border: "#570F16",
  };
  @observable showOptions: boolean = false;

  @attr afterChange: Function;
  @attr fieldName: string;
  @attr default: string
  defaultChanged(oldVal, newVal){
    this.value = colors.filter((color) => color.name.toLowerCase() == newVal.toLowerCase())[0]

  }
  colors = colors

  valueChanged() {
    this.showOptions = false;
    if (typeof this.afterChange === "function") {
      this.afterChange(this.fieldName, this.value["name"]);
    }
  }

  connectedCallback() {
    super.connectedCallback();
  }
}

JunctionSelectColorInput.defineComponent();

export { JunctionSelectColorInput };
