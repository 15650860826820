import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`${T.when(x => !x.loaded, T.html`
  <section class="container__loader">
    <jnct-loading-spinner></jnct-loading-spinner>
  </section>
`)}

${T.when(x => x.loaded, T.html`
<section class="report report__container">
  <course-header
    :course_title="${x => x.course.title}"
    :name="${x => x.name}"
    :logo="${x => x.course.collegeLogoUrl}"
    :logo_alt="course logo">
  </course-header>

  <div class="report__main">
    <report-row
      class="width-100"
      :data_list="${x => x.data_list}">
    </report-row>
  </div>

  <report-chart-row
    class="width-100"
    :chart="${x => x.grade_chart}"
    :data_list="${x => x.grade_data}">
  </report-chart-row>

  <report-chart-row
    :chart="${x => x.chapter_chart}"
    class="width-100">
  </report-chart-row>

  <div class="box">
    <div class="box__chart">
      <div class="box__chart--title">Student Engagement</div>
      <high-chart :chart="${x => x.engaged_chart}" class="pie__chart"></high-chart>
    </div>
    <div class="box__container">
      <button class="button__primary ${x => x.engagement?.disengaged.length ? '' : 'disabled'}" @click="${x => x.engagement?.disengaged.length ? x.openEmailModal() : ''}">
        Email Disengaged Students
      </button>

      <fluent-tabs activeid="disengaged">
        <fluent-tab class="tab" id="disengaged">
          Disengaged
          <span class="material-symbols-outlined" aria-hidden="true"> info </span>
          <fluent-tooltip
              anchor="disengaged"
              position="bottom"
              delay="200">
              Students who have used this course for less than 30 minutes in the past week or less than 60 minutes in the past two weeks.
          </fluent-tooltip>
        </fluent-tab>
        <fluent-tab class="tab" id="struggling">
          Struggling
          <span class="material-symbols-outlined" aria-hidden="true"> info </span>
          <fluent-tooltip
              anchor="struggling"
              position="bottom"
              delay="200">
              Students who are actively using the application but whose average scores are less than 70%.
          </fluent-tooltip>
        </fluent-tab>
        <fluent-tab class="tab" id="ontrack">
          On-Track
          <span class="material-symbols-outlined" aria-hidden="true"> info </span>
          <fluent-tooltip
              anchor="ontrack"
              position="bottom"
              delay="200">
              Students whose average scores are over 70%.
          </fluent-tooltip>
        </fluent-tab>
  
        <fluent-tab-panel id="disengagedPanel">
          ${T.when(x => x.engagement.disengaged?.length > 0, T.html`
            <ul class="panel panel__list">
              ${T.repeat(x => x.engagement.disengaged, T.html`
                <li id="${x => x._id}" class="panel__item">
                  ${x => x.name}
                </li>
              `)}
            </ul>
          `)}

          ${T.when(x => x.engagement.disengaged?.length === 0, T.html`
            <div class="no__data">
              No Disengaged Students
            </div>
          `)}
        </fluent-tab-panel>
        <fluent-tab-panel id="strugglingPanel">
          ${T.when(x => x.engagement.struggling?.length > 0, T.html`
            <ul class="panel panel__list">
              ${T.repeat(x => x.engagement.struggling, T.html`
                <li id="${x => x._id}" class="panel__item">
                  ${x => x.name}
                </li>
              `)}
            </ul>
          `)}

          ${T.when(x => x.engagement.struggling?.length === 0, T.html`
            <div class="no__data">
              No Struggling Students
            </div>
          `)}
        </fluent-tab-panel>
        <fluent-tab-panel id="ontrackPanel">
          ${T.when(x => x.engagement.engaged?.length > 0, T.html`
            <ul class="panel panel__list">
              ${T.repeat(x => x.engagement.engaged, T.html`
                <li id="${x => x._id}" class="panel__item">
                  ${x => x.name}
                </li>
              `)}
            </ul>
          `)}
          ${T.when(x => x.engagement.engaged?.length === 0, T.html`
            <div class="no__data">
              No On-Track Students
            </div>
          `)}
        </fluent-tab-panel>
      </fluent-tabs>
    </div>
  </div>
</section>
`)}
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.container__loader {
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.report__container {
  max-width: 1115px;
  margin: 0px auto;
  padding: 14px 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.report__main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.report .width-100 {
  width: 100%;
}
.report .pie__chart {
  width: 365px;
}
.report .no__data {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--neutral-40);
  text-align: center;
  font-family: inherit;
  font-size: 1.42857rem;
  font-weight: 500;
  line-height: 150%;
}
.report .box {
  display: flex;
  padding: 24px 16px;
  align-items: flex-start;
  gap: 64px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--neutral-20);
  background: #FFF;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.08);
}
.report .box__chart {
  display: flex;
  gap: 68px;
  flex-direction: column;
}
.report .box__chart--title {
  padding: 16px 0px 0px 32px;
  color: var(--gray-1);
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
}
.report .box__container {
  width: 100%;
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: flex-start;
}
.report .box__container .button__primary {
  margin-left: auto;
}
.report .panel__list {
  margin: 0px;
  padding: 0px;
  max-height: 337px;
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  list-style: none;
}
.report .panel__item {
  flex-basis: 50%;
  box-sizing: border-box;
  padding: 8px 16px;
  color: var(--link);
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 170%;
}
.report .panel__item:nth-child(4n+3), .report .panel__item:nth-child(4n+4) {
  background-color: var(--neutral-8);
}`;


import { observable, RWSViewComponent, RWSView, attr } from "@rws-framework/client";
import { Course } from "../../types/course";
import { Chart } from "../../types/chart";
import {RosterRecord} from "../../types/users";

import { EmailStudentsModal } from './modal/component';

type DataType = {
  main: string,
  text: string,
}

@RWSView('performance-report', null, { template: rwsTemplate, styles })
class PerformanceReport extends RWSViewComponent  {
  @observable name: string = 'Course Performance Report';
  @observable loaded: boolean = false;
  @observable course: Course;
  @observable students: RosterRecord[];
  @observable engagement: any;
  @observable grade_chart: Chart;
  @observable chapter_chart: Chart;
  @observable engaged_chart: Chart;
  @observable data_list: any[];
  @observable average: any;
  @observable grade_data: DataType[];
  @observable students_scores: any;
  @observable lesson_scores: any;

  getCourse() {
    return new Promise((resolve) => {
      this.$emit('jnct:dashboardService:getCourse', (data: Course) => {
        resolve(data)
      });
    })
  };

  getEngagement() {
    return new Promise((resolve) => {
      this.$emit('jnct:dashboardService:getEngagement', (data: any) => {
        resolve(data)
      });
    })
  };

  getStudents() {
    return new Promise((resolve) => {
      this.$emit('jnct:dashboardService:getRoster', (data: RosterRecord) => {
        resolve(data)
      });
    })
  };

  getAverage() {
    return new Promise((resolve) => {
      this.$emit('jnct:dashboardService:getAverage', (data: RosterRecord) => {
        resolve(data)
      });
    })
  };

   connectedCallback() {
    super.connectedCallback();
    setTimeout(async () => {
      this.course = await this.getCourse() as Course;
      this.engagement = await this.getEngagement() as any;
      this.students = await this.getStudents() as RosterRecord[];
      this.average = await this.getAverage() as any;
      this.students_scores = this.average.user_scores;
      const student_ids =  this.students.map((student: RosterRecord) => student._id);
      const filteredData = Object.keys(this.students_scores)
        .filter(id => student_ids.includes(id))
        .reduce((acc, id) => {
            acc[id] = this.students_scores[id];
            return acc;
        }, {});
      const scores = Object.values(filteredData).map((userScore: any) => userScore.percent);
      const average_score = Math.round(this.average.percent);
      const median_score = this.getMedian(scores);
      this.data_list = [
        {
          box: average_score + '%',
          title: 'Average Grade',
        },
        {
          box: this.students.length,
          title: 'Students Enrolled',
        },
      ];
      this.grade_data =  [
        {
          main: average_score + '%',
          text: 'Average Grade',
        },
        {
          main: median_score + '%',
          text: 'Median Grade',
        }
      ];

      this.lesson_scores = this.average.scores.filter(score => score[1] !== 'class');

      const grade_range = ['0-60', '60-65', '65-70', '70-75', '75-80', '80-85', '85-90', '90-95', '95-100'];
      const grade_calculation = this.calculateSegments(scores, grade_range)
      this.grade_chart = {
        icon: 'insert_chart',
        title: 'Grade Distribution',
        type: 'column',
        x_data: grade_range,
        y_title: 'Students',
        color: {hover: '#119CBB', select: '#119CBB'},
        series: [
          {
            name: 'Grade in %',
            color: '#0D758C',
            data: Object.values(grade_calculation)
          }
        ]
      };

      this.chapter_chart = {
        icon: 'insert_chart',
        title: 'Grade By Chapter',
        type: 'bar',
        x_data: this.lesson_scores.map(score => score[0]),
        y_title: 'Average Grade',
        color: {hover: '#00CAB1', select: '#00CAB1'},
        series: [
          {
            name:'Grade by Chapter',
            color: '#BDE6E1',
            data: this.lesson_scores.map(score => Math.round(score[1])),
          }
        ]
      };

      this.engaged_chart = {
        type: 'pie',
        series: [
          {
            name:'Students',
            colorByPoint: true,
            data: [
              {
                name: 'Disengaged',
                y: this.engagement.disengaged.length,
                color: '#12A2C2',
                tooltip: 'Students who have used this course for less than 30 minutes in the past week or less than 60 minutes in the past two weeks.',
              },
              {
                name: 'Struggling',
                y: this.engagement.struggling.length,
                color: '#9CB8BE',
                tooltip: 'Students who are actively using the application but whose average scores are less than 70%.',
              },
              {
                name: 'On-Track',
                y: this.engagement.engaged.length,
                color: '#094E5D',
                tooltip: 'Students whose average scores are over 70%.',
              },
            ],
          }
        ]
      }
      this.loaded = true;
    }, 30)
  };

  getMedian(scores: any[]) {
    if (!scores || scores.length === 0) {
      return 0;
    };
    const user_scores = [...scores].sort((a, b) => a - b);
    const half = Math.floor(user_scores.length / 2);
    const median = user_scores.length % 2
    ? user_scores[half]
    : (user_scores[half - 1] + user_scores[half]) / 2;
  
    return Math.round(median);
  }

   calculateSegments(scores, segments) {
    let segmentCounts = {};
    let parsedSegments = segments.map(segment => {
        let [min, max] = segment.split('-').map(Number);
        return { min, max, label: segment };
    });
    parsedSegments.forEach(segment => {
        segmentCounts[segment.label] = 0;
    });
    // Count the scores in each segment
    scores.forEach(score => {
      if (score === 100) {
        segmentCounts['95-100']++;
        return;
      }
      parsedSegments.forEach(segment => {
        if (score >= segment.min && score < segment.max) {
          segmentCounts[segment.label]++;
        }
      });
    });

    return segmentCounts;
  };

  openEmailModal() {
    const modal = document.createElement('email-students-modal') as EmailStudentsModal;
    modal.students = this.engagement.disengaged;
    modal.course_title = this.course.title;
    document.body.appendChild(modal);
  };
}

PerformanceReport.defineComponent();

export { PerformanceReport };
