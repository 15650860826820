import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`${T.when(x => !x.updateInProgress, T.html`
<iframe
  class="layer learnosity-iframe"
  src="../../../modules/contents/components/templates/assessment/iframe.html"
  id="learnosity-iframe"
  allowfullscreen="true"
  title="iframe for loading assessment"
  aria-label="iframe for loading assessment">
</iframe>
`)}

${T.when(x => x.updateInProgress, T.html`
<div class="loader">
  <jnct-loading-spinner></jnct-loading-spinner>
</div>
`)}
`;

import './styles/layout.scss';
const styles = T.css`.learnosity-iframe,
#learnosity-iframe {
  border: none;
  width: 100%;
  min-height: 520px;
}

.details .lrn {
  width: calc(75% - 2px);
  border: 1px solid grey;
  display: inline-block;
}
.details .lrn section {
  padding: 0;
}
.details .lrn section .lrn-tool-panel .lrn-tool-panel-search {
  padding: 10px !important;
}
.details .lrn section .lrn-tool-panel .lrn-tool-panel-search .lrn-form-control {
  padding: 0 !important;
}
.details .lrn section .lrn-tool-panel .lrn-tool-panel-search .lrn-input-group {
  height: 100% !important;
}
.details .lrn section .lrn-tool-panel .lrn-tool-panel-search .lrn-input-group input, .details .lrn section .lrn-tool-panel .lrn-tool-panel-search .lrn-input-group button {
  padding: 3px 10px !important;
}
.details .lrn section .lrn-tool-panel .lrn-tool-panel-search .lrn-btn-primary {
  margin-top: 0 !important;
}
.details .lrn section .lrn-tool-panel .lrn-form-list-search .lrn-main-search-button {
  margin: 0 !important;
}
.details .lrn .lrn-list-view .lrn-list-view-item.lrn-list-view-item-active {
  background-color: red;
}
.details .lrn .lrn-list-view .lrn-list-view-item > div {
  padding: 5px !important;
}
.details .lrn .lrn-list-view .lrn-list-view-item > div .lrn-list-view-labels {
  text-align: center;
}
.details .lrn .lrn-list-view .lrn-list-view-item > div .lrn-list-view-heading-wrapper {
  margin-bottom: 0 !important;
}
.details .lrn .lrn-list-view .lrn-list-view-item > div .lrn-list-view-heading-wrapper .lrn-list-view-heading {
  width: 90%;
}
.details .lrn .lrn-list-view .lrn-list-view-item > div .lrn-list-view-heading-wrapper button {
  font-size: 13px !important;
  font-weight: bold !important;
}
.details .lrn .lrn-list-view .lrn-list-view-item > div .lrn-list-view-desc {
  padding-top: 0 !important;
  font-size: 12px;
  font-style: italic;
}
.details .lrn .lrn-list-view .lrn-list-view-item > div:first-of-type {
  text-align: center;
  position: relative;
}
.details .lrn .lrn-list-view .lrn-list-view-item > div:first-of-type:before {
  content: "Quick view";
  font-size: 12px;
  color: #333;
  cursor: pointer;
}
.details .lrn .lrn-list-view .lrn-list-view-item > div:nth-last-of-type(2) {
  text-align: center;
  position: relative;
  cursor: pointer;
}
.details .lrn .lrn-list-view .lrn-list-view-item > div:nth-last-of-type(2):before {
  content: "\f0c9";
  font-family: "FontAwesome";
  font-size: 26px;
  display: inline-block;
  width: 60%;
  height: 30px;
  line-height: 30px;
  color: gray;
  text-align: center;
  position: absolute;
  top: calc(50% - 15px);
  left: 20%;
}
.details .lrn .lrn-list-view .lrn-list-view-item > div:nth-last-of-type(2):hover {
  background-color: grey;
}
.details .lrn .lrn-list-view .lrn-list-view-item > div:nth-last-of-type(2) .lrn-list-view-meta, .details .lrn .lrn-list-view .lrn-list-view-item > div:nth-last-of-type(2) .lrn-flyout {
  display: none !important;
}

button:focus {
  outline: 2px solid #83bffc !important;
  box-shadow: 0 0 3px 3px #83bffc !important;
  border-radius: 2px;
  border: 1px solid #fff !important;
}

.test-submit.item-next {
  background-color: #e81e75 !important;
  border: 1px solid #55bab7 !important;
}
.test-submit.item-next:before {
  color: #55bab7 !important;
}

button.test-submit > span.btn-label {
  color: white !important;
  font-weight: 700;
}

.teal-circle {
  display: inline-block;
  position: relative;
  float: right;
  z-index: 101;
  top: 5px;
  right: -5px;
  padding: 14px;
  line-height: 50%;
  color: white;
  text-align: center;
  font-weight: 700;
  width: 70px;
  height: 35px;
  border-radius: 14px;
  margin-top: -12px;
  background-color: #8AC14D;
  font-size: 15px;
}

.learnosity-item .lrn-item-processed {
  visibility: visible !important;
  z-index: 1 !important;
  opacity: 1 !important;
  position: inherit !important;
}

body {
  height: 100%;
  width: 99%;
  overflow: hidden;
  margin-top: 0;
  position: fixed;
}

.studentReview {
  min-height: 550px;
  width: 99%;
  overflow: hidden;
  margin-top: 0;
}

.lrn.lrn_widget.lrn_charactermap_wrapper {
  z-index: 11500;
}

.lrn-qe {
  width: 100% !important;
}

#component > section .details.mo-space {
  width: calc(100% - 2px);
  margin-left: 0;
}

.details.mo-space .lrn {
  width: calc(100% - 2px) !important;
}

.wide-author {
  display: inline-block;
  width: 160px;
  text-align: center;
}

.lrn-right-region {
  top: 25px !important;
  width: 50px !important;
  margin-left: 5px !important;
}

.lrn-top-left-region {
  min-height: 12px !important;
}

.lrn-top-right-region {
  top: -15px !important;
}

.lrn-assess {
  margin: 0 !important;
}
.lrn-assess.lrn-vertical-stretch {
  min-height: 500px !important;
}
.lrn-assess .lrn-right-region .right-wrapper .menu-overlay {
  height: 350px !important;
}
.lrn-assess input {
  width: 40px;
}

.slides-control-zindex {
  z-index: 100;
}

.lrn_btn_blue {
  border: 2px solid #55bab7 !important;
  background-color: #55bab7 !important;
  color: #FFF !important;
  text-transform: uppercase !important;
}
.lrn_btn_blue:active, .lrn_btn_blue:hover {
  background-color: #55bab7 !important;
}
.lrn_btn_blue:hover {
  filter: brightness(80%);
}
.lrn_btn_blue.item-next {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}
.lrn_btn_blue.item-next:hover {
  background-color: #55bab7 !important;
  border: 1px solid #55bab7 !important;
}
.lrn_btn_blue.item-next:hover::before {
  color: #e81e75 !important;
}
.lrn_btn_blue:after {
  border: none !important;
}
.lrn_btn_blue:focus {
  outline: 2px solid #83bffc !important;
  box-shadow: 0 0 3px 3px #83bffc !important;
  border-radius: 2px;
  border: 1px solid #fff !important;
}

.hint, .btn-hint {
  display: inline;
  margin: 10px 0 0 10px;
  color: blue;
}

.btn-hint {
  margin: 20px !important;
}

.lrn {
  font-family: LearnosityMath, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}
.lrn .lrn-group-top .lrn-top-left-region .test_title_element {
  position: relative;
  top: 12px;
  left: 12px;
}

.lrn-dialog-body#start-page-label {
  height: 10% !important;
  min-height: inherit !important;
}
.lrn-dialog-body#start-page-description {
  height: 25% !important;
  top: initial !important;
  bottom: initial !important;
}

.lrn-dialog-footer {
  height: 60% !important;
}

.distractor_style {
  background-color: #fbe3e3;
  padding: 5px 10px;
}
.distractor_style_valid {
  background-color: #ebf6e7;
}

div.progress > span {
  background: #55bab7 !important;
}

.edititem:before {
  content: "\f040";
  font-family: FontAwesome;
  top: -2px !important;
}

.hamburglar:before {
  content: "\f0c9";
  font-family: FontAwesome;
  top: -2px !important;
}

.floppy:before {
  content: "\f0c7";
  font-family: FontAwesome;
}

.informant:before {
  font-family: FontAwesome !important;
  content: "\f05a";
}

.lrn button.lrn_btn.test-fullscreen-btn:before {
  font-family: FontAwesome !important;
  content: "\f0b2" !important;
  margin-left: 3px;
}

.loader {
  width: 100%;
  height: 100%;
}`;


import { observable, RWSViewComponent, RWSView, attr } from "@rws-framework/client";
import { LearnosityAPI } from './api';
import { LearnosityData } from './data';

// @ts-ignore
const APIversion = window.edrnaConfig.learnosity.version;

@RWSView('learnosity-item', null, { template: rwsTemplate, styles })
class LearnosityItem extends RWSViewComponent  {
  @attr data: any;
  @attr sessions: any;
  @attr course: any;
  @attr inGradebook: boolean = false;
  @attr isItemList: boolean = false;
  @attr activityId: string;
  @attr courseId: string;
  @attr items: string[];
  @attr students: any;
  @observable message: any;
  @observable scores: any;
  @observable ScoringItems: any;
  @observable session: any;
  @observable itemsApp: any;
  @observable isProfessor: boolean = false;
  @observable feedbackApp: any;
  @observable iframeWindow: any;
  @observable showingToc: string = 'none';
  @observable all_student_questions: any = {};
  @observable updateInProgress: boolean = false;
  isValidJSON(jsonString: string) {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  };

  attributeChangedCallback(attrName: any, oldVal: any, newVal: any) {
    if (newVal && this.isValidJSON(newVal)) {
      //@ts-ignore
      this[attrName] = JSON.parse(newVal);

      if (attrName == 'data') {
        this.scores = JSON.parse(newVal);
      };

      if (attrName == 'sessions') {
        this.message = JSON.parse(newVal);
        this.init();
      };
    }
  };

  connectedCallback() {
    super.connectedCallback();
    document.documentElement.addEventListener('jnct:learnosity:savescores', this.saveScoresHandler, true)
  };

  disconnectedCallback() {
    document.documentElement.removeEventListener('jnct:learnosity:savescores', this.saveScoresHandler, true)
    super.disconnectedCallback()
  }

  saveScoresHandler = this.saveScores.bind(this);

  async init() {
    this.ScoringItems = [];
    this.session = this.message?.session;
    if (this.session?.session_id) {
      this.goForth();
      return;
    };
    if (this.message?.session_id) {
      const request = {
        session_id: [this.message.session_id],
        user_id: [this.message.user_id]
      };
      const signature = await LearnosityAPI.getSignature(request);
      const scores = await LearnosityAPI.getScores(signature);

      if (scores.length) {
        this.session = scores[0];
        this.session.isProfessor = true;
        this.goForth();
      } else {
        console.error("No answers found", "session problem 2")
      };

      return;
    };

    if (!this.scores['showTable']) {
      console.error("No answers found", "session problem");
    }
  };

  async goForth() {
    this.$emit('jnct:gradingcenter:helpers:gasend', ["event", "grading center", "session reviewed", this.session.session_id])
    const STATE = LearnosityData.STATE;
    // 3 cases: this.scores.config from assessment info box, score.scores on drilldown, and neither from grade center
    // todo - what about from gradebook?
    let config;
    if (this.scores) {
      config = this.scores.config? this.scores.config : this.scores;
    } else {
      config = {correct_answers: true, due_date: new Date(1970,1,1)};
    };

    if (config.correct_answers) {
      config[STATE['SHOW_CORRECT_ANSWERS']] = STATE['AFTER_DUE_DATE'];
    };

    // default to showing correct answers in review mode
    let review_correct_answers = true;
    // however, before the due date if config[STATE['SHOW_CORRECT_ANSWERS']] = STATE['AFTER_DUE_DATE'] is set we do NOT show it
    const after_due_date = config[STATE['SHOW_CORRECT_ANSWERS']] == STATE['AFTER_DUE_DATE'] && new Date() < config.due_date;
    const after_close_date = config[STATE['SHOW_CORRECT_ANSWERS']] == STATE['AFTER_CLOSE_DATE'] && new Date() < config.close_date;
    const never = config[STATE['SHOW_CORRECT_ANSWERS']] == STATE['NEVER'];
    if (after_due_date || after_close_date || never) {
      review_correct_answers = false;
    };

    if (config[STATE['SHOW_CORRECT_ANSWERS']] == STATE['AFTER_MAX_ATTEMPTS']) {
      const scores = this.scores.scores || [];
      const completedScores = scores.filter((score: any) => score.status === 'Completed').length;
      if (completedScores < config.submission_attempts) {
        review_correct_answers = false
      };
    };

    let request;
    if (this.isItemList) {
      request = LearnosityData.getAssessmentDeepDiveRequest(this.activityId, this.courseId, this.items)
    } else {
      request = LearnosityData.getRequest(this.session, review_correct_answers)
    }

    if (!this.session.isProfessor) {
      this.isProfessor = false;
      request.config.regions.right.splice(2,1); // remove the save button
    } else {
      this.isProfessor = true;
    }

    const signature = await LearnosityAPI.getItemSignature(request);

    if (signature) {
      //adding the iframe for learnosity
      await this.reloadScript();
      const itemsAPI = this.scriptLoaded.bind(null, 'https://items.learnosity.com?' + APIversion, this.iframeWindow)
      await itemsAPI();

      const liload = setInterval(() => {
        if (this.iframeWindow.LearnosityItems) {
          this.itemsApp = this.iframeWindow.LearnosityItems.init(signature, {readyListener: this.readyListener()});
          clearInterval(liload);
        }
      },100)
    }
  };

  //iframe for learnosity
  async reloadScript() {
    return new Promise<void | Event>((resolve, reject) => {
      const iframe = this.shadowRoot.getElementById('learnosity-iframe');
      if (!iframe) {
        reject();
      } else {
        // Causes the iframe to reload
        //@ts-ignore
        iframe.src = iframe.src;
        //@ts-ignore
        iframe.onload = () => {
          //@ts-ignore
          this.iframeWindow = iframe.contentWindow;
          resolve();
        }
      }
    });
  };

  async scriptLoaded(path: any, iframeWindow: any) {
    return new Promise<void | Event>((resolve, reject) => {
      const document = iframeWindow.document;
      const existing = document.querySelector('[src="' + path + '"]');
      if (existing) {
        resolve();
      } else {
        const script = document.createElement('script');
        script.src = path;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      }
    });
  };

  readyListener() {
    return () => {

      if (this.isItemList) return;

      const document = this.iframeWindow.document;
      $(document).find('.toc').css('display', this.showingToc);
      $(document).find('.slides-control').addClass('slides-control-zindex');

      let showFeedback = false;
      if (!this.session.isProfessor) {
        $.each(this.itemsApp.questions(), (responseID, question) => {
          if (question.getMetadata().rubric_reference) {
            showFeedback = true;
            return false; // break each loop
          }
        });
      } else {
        showFeedback = true;
      };

      if (showFeedback) {
        $(document).find('.lrn_widget').wrap('<div class="row"></div>').wrap('<div class="col-lg-7" style="overflow: auto"></div>');
      }

      const items = this.itemsApp.getItems();
      const item_questions = this.itemsApp.questions();
      this.itemsApp.getActivity().items.forEach((item: any) => { // this is just to keep them in order (they are, but not guaranteed)
        items[item].questions.forEach((quest: any) => {  // this is the array in the correct order
          const question = item_questions[quest.response_id];
          const responseID = quest.response_id;
          let i;
          let feedback = question.getMetadata().rubric_reference ? question.getMetadata().rubric_reference : '';
          if (feedback) {
            i = -1 + this.ScoringItems.push({ id: this.ScoringItems.length, reference: feedback });
          };

          if (question.getQuestion().validation && !question.getQuestion().validation.unscored) {
            const newContent = document.createElement('div');
            newContent.classList.add('col-lg-5');

            // Set the HTML content you want to append
            newContent.innerHTML =
              (this.session.isProfessor? '	<span class="junction_score"> Adjust Score&nbsp;' : '') +
              (this.session.isProfessor? '  	  <input size="4" name="junction_' + responseID + '" class="junction_score" style="width: 100px" type="number" min="0">' : '') +
              (this.session.isProfessor? '	</span> ' : '') +
              (feedback?            '   <div id="junction_' + i + '" class="learnosity-item" data-reference="' + i + '"/>' : '');

            this.iframeWindow.document.getElementById(responseID).closest('.row').append(newContent);
            if (this.session.isProfessor) {
              //this for change save button state
              const inputElement = this.iframeWindow.document.querySelector('[name="junction_' + responseID + '"]');
              const inputChangeHandler = () => {
                this.$emit('disabledSaveChanged', { disabledSave: false });
              };
              inputElement.addEventListener('input', inputChangeHandler);
            }
          } else {
            $(document).find('#' + responseID).closest('.row').append('<div class="col-lg-5"><span class="junction_score"/></div>');
          }

          try {
            const ul = $('#' + responseID).find("ul.lrn_correctAnswerList");
            quest.validation?.alt_responses?.forEach((alt: any) => {
              if (typeof alt.value === 'string') {
                ul.append(`<li>${alt.value}</li>`);
              } else {
                ul.find('li').each((i: any, li_str: any) => {
                  //@ts-ignore
                  const li_el = $(li_str);
                  //@ts-ignore
                  const span = li_el.find('span');
                  const num = parseInt(span.text());
                  //@ts-ignore
                  const div = li_el.find('div');
                  alt.value.forEach((altr: any, i: any) => {
                    if (i + 1 == num && (!question.isAttempted() || quest.validation.match_all_possible_responses) && altr) {
                      div.text(div.text() + " / " + altr);
                    }
                  });
                });
              }
            });
          } catch (e) {
            /* ignore */
            console.error(e);
          };
        });
      });

      const assessApp = this.itemsApp.assessApp();
      const element: any = [];
      const score_list: any = [];
      const session_responses: any = {};
      this.session.responses.forEach((item_question: any) => { session_responses[item_question.response_id] = item_question });
      this.itemsApp.getActivity().items.forEach((item: any) => { // this is just to keep them in order (they are, but not guaranteed)
        items[item].questions.forEach((quest: any) => {// this is the array in the correct order
          const item_question = session_responses[quest.response_id];
          if (!item_question) {
            return;// should never happen
          };
          let points = item_question?.score !== null ? item_question.score : "-";
          let color = "#4a700a";// this is learnosity green and different from alert success #8AC14D;
          let ariascore = 'question score is ' + points + ' out of ' + (item_question && item_question.max_score);
          if (points === "-") {
            color = '#F4C620';// this is learnosity red and different from alert red #E65033;
            ariascore = 'question is not graded or not attempted';
          } else if (points === 0) {
            color = "#dd002f";
          };

          let max_score = item_question?.max_score;
          if (!max_score && this.itemsApp.getQuestions()[item_question.response_id].validation) {
            max_score = this.itemsApp.getQuestions()[item_question.response_id].validation.max_score;
          };
          if (max_score) {// NOTE: do not fall in here if null, undefined OR 0 (ZERO).
            const score = points === '-' ? undefined : points;
            score_list.push({ score: score, max_score: max_score });
            points = points + "/" + max_score;
            element.push('<div style="background-color: ' + color + '" class="teal-circle" aria-label="' + ariascore + '">' + points + '</div>');
          } else {
            element.push('<div style="background-color: lightgrey" class="teal-circle" aria-label="unscored">n/a</div>');
          }
        });
      });

      $(document).find('.lrn_qr').each(function(i) {
        $(this).prepend(element[i]);
      })
      $(document).find('input.junction_score').each(function(p) {
        if (score_list[p]) {
          $(this).attr('value', score_list[p].score).attr('max', score_list[p].max_score);
        }
      })

      const feedbackCode = async (session_id: any, state: any)=>  {
        // only show feedback if isProfessor (initial/resume) or if there is any feedback for the student (in review mode)
        if (!session_id) {
          return;
        };
        const feedback = {
          'rendering_type': 'inline',
          'name': 'Items API demo - feedback activity.',
          'state': state,
          'user_id': this.session.user_id, // sneakily setting the user_id to the student here so we can diplay it in review mode wo instructor knowledge
          'activity_id': this.session.session_id,
          'session_id': session_id,
          'items': this.ScoringItems,
          'type': 'submit_practice', // stop using 'feedback' since it won't return data from the Data API
          'config': {},
        };

        const signature = await LearnosityAPI.getItemSignature(feedback);

        if (this.iframeWindow.LearnosityItems) {
          this.feedbackApp = this.iframeWindow.LearnosityItems.init(signature, {
            readyListener: () => {
              this.ScoringItems?.forEach((x: any) => {
                $('#junction_' + x.id).css('overflow','scroll').css('margin-bottom', '10px')
                .find('.row').first().css('margin','0px')
              });

              //checking for feedback change
              const questions = this.feedbackApp.questions();
              for (const questionId in questions) {
                if (questions.hasOwnProperty(questionId)) {
                    const question = questions[questionId];
                    question.on('changed', () => {
                      this.$emit('disabledSaveChanged', { disabledSave: false });
                    });
                }
              };
            },
            errorListener: function(e: any) {
              console.log('swallow', e);
            }
          });
        };
      };

      //@ts-ignore
      const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;

      if (this.ScoringItems.length) {
        //@ts-ignore
        const csrfToken = Cookies.get("csrf_token");
        fetch(APIprefix + 'assessment/feedback/' + this.session.session_id + '/' + (this.session.isProfessor ? 1 : 0), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': csrfToken
          }
        })
        .then((response) => {
            return response.json();
        })
        .then((feedback) => {
            if (!this.session.isProfessor && !feedback.feedback_session_id) {
                $('.lrn_widget').closest('.col-lg-7').removeClass('col-lg-7').addClass('col-lg-12');
            }
            feedbackCode(feedback.feedback_session_id, feedback.state);
        })
        .catch(function(error) {
            console.error('Error:', error);
        });
      };

      assessApp.on("item:changed", () => {
        if (this.showingToc === "inherit") {
          this.toggleRightNav('inherit');
        };
      });

      assessApp.on("button:questions:clicked", () => {
        this.toggleRightNav(this.showingToc)}
      );

      assessApp.on("button:save:clicked",async () => {
        this.feedbackApp && typeof this.feedbackApp.save == 'function' && this.feedbackApp.save({
          success: () => {
            this.$emit('jnct:gradingcenter:helpers:gasend', ["event", "grading center", "feedback saved", this.session.user_id])
          },
          error: function(e: any) {
            console.error('error', e);
          }
        });
        await this.saveScores();
      });
    }
  };

  async saveScores(e=undefined) {
    const give_max = e?.detail?.givemax;

    this.$emit('disabledSaveChanged', { disabledSave: true });
    if (!this.session) {
      return;
    };

    this.feedbackApp && typeof this.feedbackApp.save == 'function' && this.feedbackApp.save({
      success: () => {
        this.$emit('jnct:gradingcenter:helpers:gasend', ["event", "grading center", "feedback saved", this.session.user_id])
      },
      error: function(e: any) {
        console.error('error', e);
      }
    });

    if (!give_max) {
      const payload = {
        'sessions': [{
          user_id: this.session.user_id,
          activity_id: this.session.activity_id,
          session_id: this.session.session_id,
          //@ts-ignore
          responses: []
        }],
        'courseId': this.course._id,
        // If you do this from the grade center, this.scores is undefined and retain id scenario may be broken
        'componentId': this.scores.id || this.session.id || this.session.activity_id,
      };
      //@ts-ignore
      $.each(this.itemsApp.questions(), (responseID: any, question: any) => {
        let score: any = this.iframeWindow.document.querySelector('input[name="junction_'+responseID+'"]')?.value;
        let max_score = this.iframeWindow.document.querySelector('input[name="junction_'+responseID+'"]')?.getAttribute('max');

        // Is the real max score on the question? In that case do we need to use that instead of the one stored on the response? I *think* so
        const new_max_score = question.getScore()? question.getScore().max_score : 0;
        if (new_max_score || !max_score) {
          max_score = new_max_score;
        };
        if (give_max) score = max_score;

        // score is "" if not attempted or not auto scored and not manually scored yet; score is undefined if no scoring is available
        if (score !== '' && score !== undefined) {
          if (isNaN(parseFloat(score)) || isNaN(parseFloat(max_score)) || parseFloat(score) > parseFloat(max_score)) {
            const message = 'Failed to save score: Please make sure all scores are numbers between 0 and their respective max scores.';
            this.$emit('jnct:notifications:error', message);
            console.error('score data');

            payload.sessions[0].responses = [];
            return false; // break the each loop
          };
          payload.sessions[0].responses.push({
            response_id: responseID,
            attempted: true,
            score: parseFloat(score),
            max_score: parseFloat(max_score) || 1
          });
        }
      })
      if (payload.sessions[0].responses.length && this.session.isProfessor) {
        const responseObj = await LearnosityAPI.regradeUpdate(payload);
        let success = 'Scores captured.';
        if (this.inGradebook) success = 'Score updated. The revised student grade will appear when you reload the page.';

        this.$emit('jnct:gradingcenter:helpers:gasend', ["event", "grading center", "score saved", this.session.score])
        this.$emit('jnct:notifications:success', success)

        //to close adjust modal in gradebook
        if (this.inGradebook) {
          //@ts-ignore
          document.documentElement.querySelector('adjust-grade-modal')?.close();
        };
        const update = {
          'user_ids': responseObj.user_ids,
          'component_ids': responseObj.component_ids,
        };

        LearnosityAPI.recurse(responseObj, update, 10, this.course._id);

        const message = {
          session_id: this.session.session_id,
          message: "",
          link: this.createLink(this.message),
          title: this.message.title,
          activity_id: this.session.activity_id,
        };

        this.$emit('jnct:gradingcenter:message', {message: message, courseId: this.course._id, user_id: this.session.user_id})

        let this_score = 0;
        payload.sessions[0].responses.forEach(function(response) {
          if (response.score) {
            this_score += response.score;
          };
        });

        this.scores?.scores.forEach((score: any) => {
          if (score.session_id == this.session.session_id) {
            score.score = this_score; // does not have the autodeduct policy applied (straight learnosity data)
            score.policy_applied = false; // AUTODEDUCT very important so not to apply policy twice
          }
        });

        const outcome_payload = {
          'scores': this.scores ? this.scores.scores : [],
          'on_behalf_of': this.session.user_id,
          'component': {
            'title': this.scores ? this.scores.title : this.message.title,
            'activity_id': this.scores.id || this.session.id || this.session.activity_id,
          }
        };

        if (!outcome_payload.scores.length) {
          return;
        };

        await LearnosityAPI.postOutcome(outcome_payload, this.course._id, this.session);

        // AUTODEDUCT purely for display on the table in the modal (potentially updated score above)
        // If you do this from the grade center, there is no table (and scope.scores is undefined)
        if (this?.scores.policy) {
          this.scores?.scores.forEach((score: any) => {
            if (score.session_id == this.session.session_id) {
              //@ts-ignore
              var delta = new Date(score.dt_completed) - new Date(this.scores.due_date || "2199-12-31");
              if (delta > 0) {
                // there is a policy and score is overdue
                score.score = score.score * (100 - Math.min(this.scores.policy.penalty * Math.ceil(delta / 1000 / this.scores.policy.per), this.scores.policy.max)) / 100;
                score.policy_applied = true;
              }
            }
          });
        };

        this.$emit('jnct:dashboardService:flush');
      } else {
        // This may be completely irrelevant
        const message = 'No score changed';
        this.$emit('jnct:notifications:info', message);
      }
      return;
    };
    if (give_max) {
    this.updateInProgress = true;
    const load_sessions = {};
    const all_questions = {};
    // Iterate over each student's session responses;
    Object.keys(this.students).forEach(student_id => {
      (this.students[student_id].session.responses || []).forEach(response => {
        if (response.max_score) {
          // Keep track of all questions and items referenced in responses
          all_questions[response.question_reference] = true;
          if (!this.items.includes(response.item_reference)) {
           this.items.push(response.item_reference);
          }
          // Keep track of session IDs that need to be loaded
          load_sessions[this.students[student_id].session.session_id] = true;
        }
      });
    });
    const load_session =  Object.keys(load_sessions);
    const sessions = await LearnosityAPI.getResponses(this.course._id, load_session)
    const questions = Object.keys(all_questions);

    sessions.forEach(session => {
      if (this.students[session.user_id]) {
        Object.assign(this.students[session.user_id].session, session);
        this.students[session.user_id].feedback = session.feedback;
      }
    });
    
    this.all_student_questions = LearnosityAPI.getAllResponses(questions, this.students);
    const max_scores = await LearnosityAPI.getMaxScores(this.items);
    const entries = LearnosityAPI.calculateData(this.all_student_questions, this.activityId, max_scores);

    const payload = {
      'courseId': this.course._id, 
      'componentId': this.course.componentId,
      'sessions': Object.keys(entries).map((e) => {
        return entries[e]
      }).filter((e) => {
        console.log(e)
        return e.responses.length
      })
    };

    const responseObj = await LearnosityAPI.regradeUpdate(payload);
    let success = 'Scores captured.';
    if (this.inGradebook) success = 'Score updated. The revised student grade will appear when you reload the page.';

    this.$emit('jnct:gradingcenter:helpers:gasend', ["event", "grading center", "score saved", this.session.score])
    this.$emit('jnct:notifications:success', success)

    const result = await LearnosityAPI.recurseMaxPoints(responseObj, payload, 10, this.course._id);
    if (result) {
      this.$emit('jnct:notifications:success', result.message);
      this.send_messages(result.payload, result.updated);
      this.$emit('jnct:dashboardService:flush');
    }
    return
    }
  };

  async send_messages(payload, res?) {
    this.updateInProgress = false;
    var outcome_payload = []
    payload.sessions.forEach(async session => {
      const message = {
        session_id: session.session_id,
        message: "",
        link: this.createLink(this.message),
        title: this.message.title,
        activity_id: session.activity_id,
      };

      this.$emit('jnct:gradingcenter:message', {message: message, courseId: this.course._id, user_id: session.user_id});
      // res can be undefined if we don't come from the right branch
      if (!res) return;
      outcome_payload.push({
        'scores': [
          res.activity_student_score[this.activityId][session.user_id]
        ],
        'on_behalf_of': session.user_id,
        'component': {
          'id': this.course.componentId,
          'title': this.message.title,
          'activity_id': this.activityId || session.activity_id,
        }
      })
    })

    try {
      //@ts-ignore
      const APIprefix = window.edrnaConfig.host + window.edrnaConfig.serverPrefix;
      //@ts-ignore
      const csrfToken = Cookies.get('csrf_token'); 

      const response = await fetch(`${APIprefix}assessment/outcome/${this.course._id}/${this.course.componentId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify(outcome_payload)
      });
    
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  createLink(params: any) {
    if (!params.link) {
      params.link = "/" + params.courseId + "/" + params.lessonId + "/" + params.pageId;
    }
    return params.link;
  };

  //show/hide questions list on the learnosity
  toggleRightNav(showingToc?: string) {
     this.showingToc = (showingToc === 'none') ? 'inherit' : 'none';
     const document = this.iframeWindow.document;

     $(document).find('.toc').css('display', this.showingToc);
      if (this.showingToc === "inherit") {
        $(document).find('.slides-control').removeClass('slides-control-zindex');
      } else {
        $(document).find('.slides-control').addClass('slides-control-zindex');
      }
  };
};

LearnosityItem.defineComponent();

export { LearnosityItem };
