import {RWSService} from '@rws-framework/client';
import {Course} from "../types/course";
import {RosterRecord} from "../types/users";

class JunctionUserService extends RWSService {
  static defaultEventOptions = {
    bubbles: true,
    composed: true,
    cancelable: true
  }

  _dispatch(type: string, detail?: Object, options?: Object) {
    return document.documentElement.dispatchEvent(new CustomEvent(type, Object.assign(Object.assign({
      detail
    }, JunctionUserService.defaultEventOptions), options)));
  }

  getUser(): Promise<any> {
    return new Promise((resolve) => {
      this._dispatch('jnct:junctionuser:getuser', (data: any) => {
        resolve(data)
      })
    })
  };

  isAdmin(user: RosterRecord): boolean {
    return (user.accountType === "Junction");
  };

  _profRights(course: Course, user: RosterRecord, mustHaveEditRights: boolean) {
    if (!course || !user) return false;
    if (this.isAdmin(user)) return true;

    if ((course.instructors||[]).length && course.instructors.includes(user.mongoId)) {
      return true;
    }
    if (!mustHaveEditRights && (course.assistants||[]).length && course.assistants.includes(user.mongoId)) {
      return true;
    }

    // coordinators have edit rights on sections
    if (user.program_administrator && (sessionStorage.getItem('section_list')||"").includes(course._id)) {
      return true
    }

    // coordinators have rights on templates
    if (
      !mustHaveEditRights &&
      user.program_administrator &&
      (sessionStorage.getItem('section_template_list')||"").includes(course._id)
    ) {
      return true
    }
    return false;
  };

  hasProfessorRights (course: Course, user: RosterRecord) {
    return this._profRights(course, user, false);
  };

  hasProfessorEditRights (course: Course, user: RosterRecord) {
    return this._profRights(course, user, true);
  };
}

export default JunctionUserService.getSingleton()

export {JunctionUserService as JunctionUserServiceInstance}
