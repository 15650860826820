import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<div class="content">
  <slot></slot>
  <ul class="row reset-ul changes">
    <div class="changes__title">Following fields will be updated:</div>
    ${T.repeat(x => x.displayedChanges, T.html` ${x => typeof x.value ===
    'string' ? T.html`
    <li class="reset-li changes__item">
      <span class="changes__key">${x => x.label}</span>
      <span class="changes__value">${x => x.value}</span>
    </li>
    ` :T.html`
    <li class="reset-li changes__item--multi">
      <span class="changes__key">${x => x.label}</span>
      <ul class="reset-ul changes--multi">
        ${T.repeat(x => x.value, T.html`
        <li class="reset-li changes__item">
          <span class="changes__key">${x => x.label}</span>
          <span class="changes__value">${x => x.value}</span>
        </li>
        `)}
      </ul>
    </li>
    ` } `)}
  </ul>
  <div class="row center">Are you sure?</div>
</div>
`;

import './styles/layout.scss';
const styles = T.css`body {
  display: block;
}

:root {
  font-size: 14px;
  --jnct-warning-color: #c59326;
  --jnct-info-color: #247ed3;
  --jnct-error-color: #ca1515;
  --jnct-success-color: #34802E;
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
}

.button__primary {
  padding: 8px 16px;
  color: var(--primary_text);
  text-align: center;
  border-radius: var(--border-radius);
  background: var(--primary_color);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 40px;
}
.button__primary:hover {
  box-shadow: 999px 999px 0px 0px rgba(255, 255, 255, 0.2) inset;
}
.button__primary.disabled, .button__primary:disabled {
  background: rgba(var(--primary_rgb), 0.2);
  cursor: not-allowed;
}
.button__secondary {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--neutral-30);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
}
.button__secondary:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__secondary:active {
  background: var(--neutral-30);
}
.button__secondary:disabled, .button__secondary.disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__secondary:disabled:hover, .button__secondary.disabled:hover {
  box-shadow: none;
}
.button__secondary .material-symbols-outlined {
  font-size: 24px;
  font-weight: 300;
}
.button__danger {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  border: 2px solid var(--action-error);
  color: var(--action-error);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  cursor: pointer;
}
.button__danger:hover {
  box-shadow: 999px 999px 0px 0px rgba(180, 180, 180, 0.12) inset;
}
.button__danger:active {
  background: var(--action-error);
}
.button__danger:disabled {
  border: 1px solid var(--neutral-20);
  color: var(--neutral-30);
  cursor: not-allowed;
}
.button__tertiary {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--border-radius);
  background: var(--neutral-20);
  color: var(--neutral-90);
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 150%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__tertiary:hover {
  box-shadow: 999px 999px 0px 0px rgba(0, 0, 0, 0.15) inset;
}
.button__tertiary:active {
  background: var(--neutral-30);
}
.button__tertiary:disabled {
  background: var(--neutral-20);
  color: var(--neutral-50);
}
.button__no-border {
  display: flex;
  padding: 8px 24px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  background-color: white;
  color: var(--neutral-90);
  font-size: 1.14286rem;
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link {
  padding: 0px;
  border: none;
  background-color: transparent;
  font-size: 1.28571rem;
  color: var(--link);
  font-weight: 500;
  font-family: inherit;
  line-height: 160%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button__link:hover {
  text-decoration: underline;
}

.link {
  color: var(--link);
  font-weight: 400;
  line-height: 170%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.text__warning {
  color: var(--action-error);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 170%;
}
.text__error {
  color: var(--action-error);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

fluent-checkbox::part(control) {
  background-color: white;
  border-color: black;
}

fluent-checkbox.checked::part(control) {
  background-color: var(--primary_color);
  border-color: var(--primary_color);
  --foreground-on-accent-rest: var(--primary_text);
}

fluent-switch::part(switch) {
  width: 32px;
  background: #eeeff0;
  border: none;
  height: 18px;
}

fluent-switch.checked::part(switch) {
  width: 32px;
  background: var(--primary_color);
  height: 18px;
}

fluent-switch {
  --neutral-foreground-rest: var(--primary_text);
}

fluent-tab.tab {
  margin-right: 4px;
  display: flex;
  gap: 8px;
  padding: 10px 24px;
  color: var(--neutral-90);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--neutral-20);
  border-bottom: none;
  background: rgba(155, 160, 165, 0.08);
}
fluent-tab.tab:last-child {
  margin-right: 0px;
}
fluent-tab.tab[aria-selected=true] {
  background-color: white;
  z-index: 2;
  color: var(--neutral-90);
  font-weight: 600;
}

fluent-tab-panel {
  padding: 0px 0px 4px 3px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--neutral-20);
  border-top: none;
  font-family: inherit;
}

fluent-tabs {
  width: 100%;
  font-family: inherit;
}

.custom__input {
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}
.custom__input::placeholder {
  color: var(--neutral-50);
}
.custom__input:hover, .custom__input:active {
  border-color: var(--neutral-30);
}
.custom__input:focus {
  box-shadow: 0px 0px 0px 2px #0097E0;
}

.reset-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reset-li {
  text-indent: 0;
}

.cursor {
  cursor: pointer;
}

.content {
  padding: 28px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
  width: calc(100% - 48px);
  height: 100%;
}
.content .row {
  display: flex;
  justify-content: space-between;
}
.content .row.center {
  justify-content: center;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
}
.content .row button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  text-align: center;
  background: transparent;
}
.content .row .text {
  color: #000;
  align-self: stretch;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
}
.content .row .select_text {
  color: var(--Color-palette-Neutral-70, #3b3c3e);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}
.content .row .title_text {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.content .options_row {
  border-radius: 12px;
  border: 1px solid var(--Color-palette-Neutral-20, #eeeff0);
  background: var(--White, #fff);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 24px;
}
.content .options_row .form_options {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.content .options_row .form_options .option {
  height: 24px;
  display: flex;
  justify-content: space-between;
}
.content .options_row .form_options .option .change {
  cursor: pointer;
  overflow: hidden;
  color: #0f75b8;
  text-align: right;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}
.content .options_row .form_options .hidden {
  display: none;
}
.content .options_row .sub {
  box-shadow: none;
}
.content .options_row .header {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  display: flex;
  gap: 8px;
  justify-content: space-between;
}
.content .options_row .header .rm_changes {
  cursor: pointer;
  overflow: hidden;
  color: var(--Color-palette-Action-Error, #de1135);
  text-align: right;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}

.changes {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: start;
  gap: 8px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 160%;
  font-style: normal;
}
.changes__item {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
}
.changes__item--multi {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.changes__value {
  font-weight: 500;
  white-space: nowrap;
}
.changes--multi {
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}`;

const mode = "open";
import {
  attr,
  RWSViewComponent,
  RWSView,
  RWSInject,
} from "@rws-framework/client";
import flatpickr from "flatpickr";

import { GradedState } from "../../../../types/activities";
import { sortBy, groupBy } from "../../../../helpers/utils";

import { Update, UpdateModel, groups } from "../common";
import { JunctionActivitySettingsForm } from "../activitySettingsForm/component";
import ActivityDateTimeService, {
  ActivityDateTimeServiceInstance,
} from "../../../../services/ActivityDateTimeService";

@RWSView('jnct-activity-settings-change-summary', null, { template: rwsTemplate, styles, options: {mode} })
class JunctionActivitySettingsChangeSummary extends RWSViewComponent  {
  @attr buPayloadToSend: Partial<Update> = {};
  @attr presets: Array<{ _id: string; name: string }> = [];

  labels = UpdateModel.labels();

  get displayedChanges(): Array<{
    label: string;
    value: string | Array<{ label: string; value: string }>;
  }> {
    const entries = (
      Object.entries(this.buPayloadToSend) as Array<
        [keyof Update, Update[keyof Update]]
      >
    )
      .map(([key, value]) => this.formatChangedProperty(key, value))
      .flat();

    const { defaultGroup = [], ...restGroups } = groupBy(
      entries,
      ({ group }) => group || "defaultGroup"
    );

    const groupedEntries = [
      ...defaultGroup,
      ...Object.entries(restGroups).map(([group, items]) => ({
        label: group,
        value: items,
        property: items[0] ? items[0].property : "",
      })),
    ].sort();

    // sort by order of labels definition
    const keyOrdering = Object.keys(this.labels);

    return sortBy(groupedEntries, (entry) =>
      keyOrdering.indexOf(entry.property)
    );
  }

  formatChangedProperty(
    property: keyof Update,
    value: Update[keyof Update]
  ): Array<{ label: string; value: string; group?: string; property: string }> {
    switch (property) {
      case "graded": {
        return [
          {
            label: this.labels.graded,
            value: value ? GradedState.GRADED : GradedState.NOT_GRADED,
            property,
          },
        ];
      }
      // boolean
      case "scramble":
      case "lock_back_button":
      case "requires_comment":
      case "confidence":
      case "studycenter":
      case "suppress_until_available":
      case "show": {
        return [
          {
            label: this.labels[property],
            value: value ? "Yes" : "No",
            property,
          },
        ];
      }
      // number
      case "submission_attempts":
      case "resume_max_attempts":
      case "max_score": {
        return [
          { label: this.labels[property], value: String(value), property },
        ];
      }
      // dates
      case "open_date":
      case "due_date":
      case "close_date": {
        // TODO: fix DI of ActivityDateTimeService and use instance instead
        const zone =
          window.localStorage.getItem("activity_settings_tz") || "local";
        const utcIsoString = value as string;
        const displayedDate = new Date(
          ActivityDateTimeServiceInstance.fromUtc(utcIsoString, zone)
        );

        return [
          {
            label: this.labels[property],
            value: flatpickr.formatDate(displayedDate, "M j, Y h:i K"),
            property,
          },
        ];
      }
      // enums
      case "show_score": {
        return [
          { label: this.labels.show_score, value: value as string, property },
        ];
      }

      case "show_correct_answers": {
        return [
          {
            label: this.labels.show_correct_answers.showIndicator,
            group: this.labels.show_correct_answers.group,
            value: value as string,
            property,
          },
        ];
      }
      case "show_responses": {
        return [
          {
            label: this.labels.show_responses.showCorrect,
            group: this.labels.show_responses.group,
            value: value as string,
            property,
          },
        ];
      }

      case "inline": {
        return [
          {
            label: this.labels.inline.showIndicator,
            group: this.labels.inline.group,
            value: JunctionActivitySettingsForm.displayedInlineShowIndicator(
              value as Update["inline"]
            ),
            property,
          },
          {
            label: this.labels.inline.showCorrect,
            group: this.labels.inline.group,
            value:
              JunctionActivitySettingsForm.displayedInlineShowCorrectAnswer(
                value as Update["inline"]
              ),
            property,
          },
        ];
      }

      case "policy": {
        const policy = value as Update["policy"] | undefined;

        return [
          {
            label: this.labels.policy,
            value:
              policy &&
              "penalty" in policy &&
              "per" in policy &&
              "humanly_per" in policy &&
              "max" in policy
                ? `Set deduction ${policy.penalty} per ${policy.per} ${policy.humanly_per} (max ${policy.max})`
                : "-",
            property,
          },
        ];
      }
      case "time_limit": {
        const { value: valueMinutes, suffix } =
          JunctionActivitySettingsForm.formatTimeLimit(value as number);

        return [
          {
            label: this.labels.time_limit,
            value: valueMinutes ? `${valueMinutes} ${suffix}` : suffix,
            property,
          },
        ];
      }
      case "preset_id": {
        const preset = this.presets.find((preset) => preset._id === value);

        return [
          {
            label: this.labels.preset_id,
            value: preset ? preset.name : "",
            property,
          },
        ];
      }
      default: {
        return [{ label: property, value: String(value), property }];
      }
    }
  }
}

JunctionActivitySettingsChangeSummary.defineComponent();

export { JunctionActivitySettingsChangeSummary };
